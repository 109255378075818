import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router, ParamMap, NavigationStart, RouterLinkActive } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { filter } from 'rxjs/operators';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.css']
})
export class CategoryDetailsComponent implements OnInit {
  
  categoryId: string;
  category: any;
  currentCategory: any;
  products: any;
  categoryName: any;
  subCategoryRouteFlag: boolean = false;
  orderRouteLink$: any;
  breadCrumbs: any;
  private paramMapSubscription: Subscription;
  id : any;
  
  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private breadService:BreadcrumbService,
    private messageService: MessageSubjectService,
    public translate:TranslateService
    ) {
      this.breadCrumbs=this.breadService.getBread();
    }
    
    
    @ViewChild('$product', { static: true }) $product: RouterLinkActive;    
    @ViewChild('$categoryfields', { static: true }) $categoryfields: RouterLinkActive;
    @ViewChild('$subcategory', { static: true }) $subcategory: RouterLinkActive;
    
    ngOnInit() {
      this.breadCrumbs=this.breadService.getBread();
      
      this.route.paramMap.subscribe((map) => {
        this.categoryId = map.get('categoryId');
        this.categoryName = decodeURIComponent(map.get('categoryName'));
        // this.getAllProducts();
        this.getCategory();
      });
      this.paramMapSubscription = this.route.paramMap.subscribe(
        ( paramMap: ParamMap ) : void => {
          
          this.id = paramMap.get( "categoryId" );
          
        }	);
        this.currentCategory = JSON.parse(sessionStorage.getItem('currentCategory'))   
        console.log("currentCategory",this.currentCategory)  
      }

      useLanguage(language: string) {
        console.log(language)
        this.translate.use(language);
    }
      
      
      getCategory() {
        
        this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
          this.category = data.categories[0];
          if(!data['categories'][0]['parentId']) {
            if(this.category.name != null) {
              this.messageService.broadcast('bcLabelOverwrite', {
                key: "categorydetails",
                alternativeKey: 'categorydetails',
                labelName: 'Category: '+decodeURIComponent(this.category.name),
                path: decodeURI(this.router.url)
              });
            }
          }
        });
        
      }
      
      //router for sub categories pending
      // getAllProducts() {
      //   this.adminService.getAllCategoryProduct(this.categoryId).subscribe((data) => {
      //     this.products = data.products;
      //     if(this.products.length == 0){
      //       if(this.category['parentId'] != null) {
      //         this.router.navigate(['/subcategorydetails/'+ this.categoryId + '/' + this.categoryName + '/subcategory/' + this.categoryId ]);

      //       }else {
      //         this.router.navigate(['/categorydetails/'+ this.categoryId + '/' + this.categoryName + '/subcategory/' + this.categoryId ]);
      //       }
      //     }else {

      //       if(this.category['parentId'] != null) {
      //         this.router.navigate(['/subcategorydetails/'+ this.categoryId + '/' + this.categoryName + '/categoryproduct/' + this.categoryId ]);

      //       }else {
      //         this.router.navigate(['/categorydetails/'+ this.categoryId + '/' + this.categoryName + '/categoryproduct/' + this.categoryId ]);
      //       }
      //     }
      //   });
      // }

      setup() {
        if(!this.category['parentId']) {
          if(this.categoryName != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "categorydetails",
              alternativeKey: 'categorydetails',
              labelName: 'Category: '+decodeURIComponent(this.categoryName),
              path: decodeURI(this.router.url),
            });
          }
        }
        else {
          if(this.categoryName != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "subcategorydetails",
              alternativeKey: 'subcategorydetails'+ this.categoryId,
              labelName: 'Sub Category: '+decodeURIComponent(this.categoryName),
              path: decodeURI(this.router.url)
              
            });
          }
        }
      }
      
    }
    
    
    
    