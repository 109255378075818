import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/directives/modal.sevice';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortableComponent } from 'ngx-bootstrap';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { ProductPriceFilters } from 'src/app/shared/constants/filter-model/product-price-filters.model';
import { sortConstant } from 'src/app/shared/constants/sort-constant';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.css']
})
export class PriceListComponent implements OnInit {
  
  storeCode: any;
  products: Array<any> =[];
  categories: Array<any> = [];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  priceList: any;
  getPriceList: Array<any> = []
  productPrice: Array<any> =[];
  priceListData : Array<any> =[]
  currentPriceList: Array<any> =[];
  dropdownElements: Array<any> =[]
  noProductOnCategoryFlag: any;
  product: any;
  productPriceList: any;
  currentProduct: any;
  storeProductPriceForm: Array<any> = [];
  user: any;
  restrictDate: any;
  priceListForm: Array<any> = [];
  priceConditions: any;
  isFuturePrice: Boolean;
  selectedCategory: any;
  subscription: Subscription;
  currencies: Array<any> = [];
  activeDates: Array<any> = [];
  dateInvalid: Array<Boolean> = [];
  editPriceListForm: FormGroup;
  invalidFutureDate: Array<any> = [];
  invalidFuturePriceList: Array<any> = [];
  store: any;
  currency : string;
  searchName: any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  numberOfPages : number=1;
  filters: ProductPriceFilters;
  pagination: any;
  types: any;
  sort: Array<any> = [];
  fields: any = {

  fieldName: null,
  sortDirection: null
};
  // sort: Array<any> = sortConstant.PRODUCT_PRICE_SORT;
  
  @ViewChild('editPriceList',{static: true}) public editPriceList: ElementRef;
  @ViewChild('updateAllPrice',{static:true}) public updateAllPrice: ElementRef;
  dropSelectedCategory: any;
  
  constructor(private storeService: StoreService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private messageService: MessageSubjectService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private datePipe: DatePipe,
    private nestedCategory: NestedCategoryService
    ) {
      this.filters =  new ProductPriceFilters();
      this.firstSortedList = this.priceList;
      this.dropSelectedCategory = "Select Category"
    }
    numberValid:any;
    
    ngOnInit() {
      this.numberValid = "^[.]?[0-9]+[.]?[0-9]*$"
      const currentDate:Date = new Date();
      this.restrictDate = new Date(currentDate);
      this.restrictDate = this.datePipe.transform(this.restrictDate.setDate(currentDate.getDate()+1),'yyyy-MM-dd')
      this.user = JSON.parse(sessionStorage.getItem("user"));
      
      this.route.paramMap.subscribe((map) => {
        this.storeCode = map.get('storecode')
        this.getStore();
        // this.getAllCategories();
        this.storeService.getAllCategoryByStoreCode(this.storeCode).subscribe((data) => {
          this.categories = data.categories;
          this.dropdownElements= []
          this.dropdownElements =  this.nestedCategory.getProductCategoryTree(this.categories);
          console.log(this.dropdownElements)
        });
        
      })
    }
    
    
    
    
    getStore() {
      this.storeService.getStoreByCode(this.storeCode).subscribe((data) => {
        this.store = data['stores'][0];
        if(!this.store['parentCode']) {
          if(this.store.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "regiondetails",
              alternativeKey: 'regiondetails',
              labelName: 'Region: '+this.store.name,
              path: this.router.url
            });
          }
          if(this.store['currency'] != undefined) {
            this.currency = this.store.currency
          }else {
            this.currency = "USD";
          }
        }else {
          if(this.store.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "subregiondetails",
              alternativeKey: 'subregiondetails'+this.storeCode,
              labelName: 'Subregion: '+this.store.name,
              path: this.router.url
            });
          }
          if(this.store['currency'] != undefined) {
            this.currency = this.store.currency
          }else {
            this.currency = "USD";
          }
        }
        console.log(this.currency)
        this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      });
      
    }
    
    
    
    
    storeProductPriceModel() {
      this.storeProductPriceForm = []
      for(let i=0; i< this.products.length;i++) {
        let futurePriceValue = null;
        let futurePriceActiveFromDate = null;
        let currentPriceValue = null;
        let currentPriceCurrency = null;
        let futureCurrencyPrice = null;
        console.log(this.currency)
        try {
          futurePriceValue = this.products[i].futurePrice.value;
          futurePriceActiveFromDate = this.products[i].futurePrice.activeFromDate;
          futureCurrencyPrice = this.products[i].futurePrice.currency;
        } catch (error) {
          futurePriceValue = null;
          futurePriceActiveFromDate = null;
          futureCurrencyPrice = null;
        }
        try {
          currentPriceValue = this.products[i].currentPrice.value;
          currentPriceCurrency = this.products[i].currentPrice.currency;
          
        } catch (error) {
          currentPriceValue = null;
          
        }
        this.storeProductPriceForm[i] = {
          currentPrice: {
            value: currentPriceValue,
            currency: this.currency,
            action:  'PRICE_FOL'
          },
          futurePrice: {
            value: futurePriceValue,
            currency: this.currency,
            action:"PRICE_FOL",
            activeFromDate: futurePriceActiveFromDate
          },
          productId: this.products[i].productId,
          createdBy:  this.products[i].createdBy,
          modifiedBy: this.user.name
        }
      }
      console.log(this.storeProductPriceForm)
    }
    
    
    
    getAllCategories() {
      this.storeService.getAllCategoryByStoreCode(this.storeCode).subscribe((data) => {
        this.categories = data.categories;
        this.dropdownElements= []
        this.dropdownElements =  this.nestedCategory.getProductCategoryTree(this.categories);
      });
    }
    
    config = {
      hasSelect: true
    }
    
    
    
    onSelectCategory(event) {
      this.currentPage = 1;
      var categoryId = null;
      if(event['id']) {
        categoryId = event.id;
      }else {
        categoryId = 'Select Category'
      }
      console.log(event)
      this.selectedCategory = categoryId;
      if(categoryId != "Select Category") {
        this.getProductByCategoryId(categoryId);
      }else {
        this.selectedCategory = null;
        this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        this.noProductOnCategoryFlag = false;
      }
    }
    
    getProductByCategoryId(categoryId) {
        this.filters['categoryId'] =  categoryId;
        this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        // this.storeProductPriceModel()
        // if (this.products.length == 0) {
        //   this.noProductOnCategoryFlag = true;
        // } else {
        //   this.noProductOnCategoryFlag = false;
        // }
    }
    
    
    // getProductByStoreCode(storeCode) {
    //   this.storeService.getProductsByStoreCode(storeCode).subscribe((data) => {
    //     this.products = data.storePrices;
    //     console.log(this.products)
    //     this.storeProductPriceModel()      
    //   });
    // }
    
    getProductsByQuery(filters,itemsPerPage,pageNumber,sort) {
      this.products = [];
      this.pagination = null;
      this.allCheck = false;
      this.storeService.getProductsByQuery(this.storeCode,filters,itemsPerPage,pageNumber,sort).subscribe((data) => {
        this.products = data.storePrices;
        console.log(this.products)
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.storeProductPriceModel()     
        // this.noDataShow = this.noDataService.noData(this.stores);
      });
    }

    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['categoryId'] = this.searchName;  
        this.currentPage = 1;
        this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }
    hideCross(event) {
      this.filters['categoryId'] = null;
      this.currentPage = 1;
      this.searchName = '';
      this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    openPriceList(product) {
      this.product = product;
      this.getPriceListByProductId(this.product.productId);
      this.modalService.open(this.editPriceList, { size: 'lg', windowClass: 'edit-modal' });
    }
    
    currentPrice: any = {};
    getPriceListByProductId(productId) {
      this.activeDates = [];
      this.dateInvalid = [];
      this.invalidFuturePriceList = [];
      this.storeService.getProductPriceListByPriceId(productId, this.storeCode).subscribe((data) => {
        this.priceList = data.price.futurePrices;
        console.log(this.priceList)
        this.currentPrice = data.price.currentPrice;
        console.log(this.currentPrice)
        if(this.currentPrice == null) {
          this.currentPrice = {
            value: '',
            currency: this.currency,
            action: "PRICE_FOL",
          }
        }
        if(this.priceList != null) {
          for(let i=0; i< this.priceList.length; i++) {
            this.activeDates[i] = this.priceList[i].activeFromDate;
            this.dateInvalid[i] = false;
            this.invalidFuturePriceList[i] = false;
            this.invalidCurrentP = false;
          }
        }else {
          this.priceList = [{
            value: '',
            currency: this.currency,
            action: "PRICE_FOL",
            activeFromDate: ''
          }]
        }
        console.log(this.priceList)
      })
    }
    
    updatePriceList() {
      let price = {
        currentPrice: this.currentPrice,
        futurePrices: [],
        createdBy: this.user.name,
        modifiedBy: this.user.name
      };
      for(let i=0; i< this.priceList.length;i++) {
        if(this.priceList[i].value != '' || this.priceList[i].value != null) {
          price['futurePrices'][i] = this.priceList[i]
        }
      }
      
      this.storeService.manageProductPriceList(this.product.productId,this.storeCode,price).subscribe((data) => {
        this.modalService.dismissAll(this.editPriceList);
        this.priceList = [];
        this.currentPrice = {}
        this.ngOnInit();
      })
      
    }
    
    
    priceListModel() {
      this.priceConditions = {
        priceOption: '',
        changeType: '',
        price: '',
        condition: '',
        activeFrom : '',
      }
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.products.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    selectedType: any = '';
    changeTypeFunc(event) {
      if(event.target.value == "percentage"){
        this.selectedType = "values in percentage"
      }else {
        this.selectedType = "values in amount"
      }
    }
    
    invalidCurrentPrice: Array<any> = []
    invalidFuturePrice : Array<any> = []
    
    
    UpdateAll() {
      let value = []
      let valueDifference = [];
      console.log(this.storeProductPriceForm);
      console.log(this.priceConditions)
      if(this.priceConditions.changeType == "percentage") {
        if(this.priceConditions.condition == "increase") {
          if(this.priceConditions.priceOption == "currentPrice") { 
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              value[i] = (this.storeProductPriceForm[i].currentPrice.value) * ((this.priceConditions.price) / 100);
              valueDifference[i] = this.storeProductPriceForm[i].currentPrice.value + value[i];
              this.storeProductPriceForm[i].currentPrice.value = valueDifference[i];
            }
          }else if(this.priceConditions.priceOption == "futurePrice") {
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              value[i] = (this.storeProductPriceForm[i].futurePrice.value) * ((this.priceConditions.price) / 100);
              valueDifference[i] = this.storeProductPriceForm[i].futurePrice.value + value[i];
              this.storeProductPriceForm[i].futurePrice.value = valueDifference[i];
              if(this.priceConditions.activeFrom != "") {
                this.storeProductPriceForm[i].futurePrice.activeFromDate = this.priceConditions.activeFrom;
              }
            }
          }
        }else if(this.priceConditions.condition == "decrease") {
          if(this.priceConditions.priceOption == "currentPrice") { 
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              value[i] = (this.storeProductPriceForm[i].futurePrice.value) * ((this.priceConditions.price) / 100);
              valueDifference[i] = this.storeProductPriceForm[i].futurePrice.value - value[i];
              this.storeProductPriceForm[i].currentPrice.value = valueDifference[i];
            }
          }else if(this.priceConditions.priceOption == "futurePrice") {
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              value[i] = (this.storeProductPriceForm[i].futurePrice.value) * ((this.priceConditions.price) / 100);
              valueDifference[i] = this.storeProductPriceForm[i].futurePrice.value - value[i];
              this.storeProductPriceForm[i].futurePrice.value = valueDifference[i];
              if(this.priceConditions.activeFrom != "") {
                this.storeProductPriceForm[i].futurePrice.activeFromDate = this.priceConditions.activeFrom;
              }
            }
          }
          
        }
      }else if(this.priceConditions.changeType == "amount") {
        if(this.priceConditions.condition == "increase") {
          if(this.priceConditions.priceOption == "currentPrice") { 
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              valueDifference[i] = this.storeProductPriceForm[i].currentPrice.value + this.priceConditions.price;
              this.storeProductPriceForm[i].currentPrice.value = valueDifference[i];
            }
          }else if(this.priceConditions.priceOption == "futurePrice") {
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              valueDifference[i] = this.storeProductPriceForm[i].futurePrice.value + this.priceConditions.price;
              this.storeProductPriceForm[i].futurePrice.value = valueDifference[i];
              if(this.priceConditions.activeFrom != "") {
                this.storeProductPriceForm[i].futurePrice.activeFromDate = this.priceConditions.activeFrom;
              }
            }
          }
        }else if(this.priceConditions.condition == "decrease") {
          if(this.priceConditions.priceOption == "currentPrice") { 
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              valueDifference[i] = this.storeProductPriceForm[i].currentPrice.value - this.priceConditions.price;
              this.storeProductPriceForm[i].currentPrice.value = valueDifference[i];
            }
          }else if(this.priceConditions.priceOption == "futurePrice") {
            for(let i=0; i< this.storeProductPriceForm.length;i++){
              valueDifference[i] = this.storeProductPriceForm[i].futurePrice.value - this.priceConditions.price;
              this.storeProductPriceForm[i].futurePrice.value = valueDifference[i];
              if(this.priceConditions.activeFrom != "") {
                this.storeProductPriceForm[i].futurePrice.activeFromDate = this.priceConditions.activeFrom;
              }
            }
          }
        }
      }
      this.modalService.dismissAll(this.updateAllPrice);
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.products.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
        
        this.allCheck = this.myValue;
      }
    }
    
    deleteSingle(id) {
      
    }
    
    
    
    
    updateAllModal(){
      this.isFuturePrice = false;
      this.selectedType = ''
      this.modalService.open(this.updateAllPrice, { size: 'lg', windowClass: 'update-price-modal', scrollable: true  });
      this.priceListModel()
    } 
    
    closeUpdateModal() {
      this.modalService.dismissAll(this.updateAllPrice);
    }
    
    
    closeModal() {
      this.modalService.dismissAll(this.editPriceList);
      this.priceList= []
    }
    
    notEmptyCurrentPrice: Array<any>= [];
    noDateEmpty: Array<any> = [];
    updateProductPrice() {
      let productPrices = [];
      productPrices = this.storeProductPriceForm;
      for(let i=0; i< productPrices.length; i++) {
        this.notEmptyCurrentPrice[i]= false;
        this.noDateEmpty[i] = false;
        this.invalidCurrentPrice[i] = false;
        this.invalidFuturePrice[i] = false;
        if(productPrices[i].futurePrice != null) {
          if((productPrices[i].currentPrice.value !=null) && (productPrices[i].futurePrice.value != null) &&( productPrices[i].futurePrice.activeFromDate == null || productPrices[i]['futurePrice']['activeFromDate'] == '')) {
            this.noDateEmpty[i] = true;
          }
          if((productPrices[i].currentPrice.value !=null) && (productPrices[i].futurePrice.activeFromDate != null) &&  productPrices[i].futurePrice.value == null) {
            this.invalidFuturePrice[i] = true;
          }
          if(productPrices[i].futurePrice.activeFromDate < this.restrictDate) {
            this.invalidFutureDate[i] = true;
          }
          if(productPrices[i].futurePrice.value == null &&( productPrices[i].futurePrice.activeFromDate == null)) {
            productPrices[i].futurePrice = null
          }
        }
        if(productPrices[i].currentPrice.value == null) {
          this.invalidCurrentPrice[i] = true;
          if(productPrices[i].currentPrice.value == null && productPrices[i].futurePrice != null) {
            this.notEmptyCurrentPrice[i] = true;
          }
        }
        if(productPrices[i].createdBy == null) {
          productPrices[i].createdBy = this.user.name;
        }
      }
      console.log(productPrices)
      // if(!this.invalidFuturePrice.includes(true) && !this.notEmptyCurrentPrice.includes(true) && !this.invalidCurrentPrice.includes(true) && !this.invalidFutureDate.includes(true) && !this.noDateEmpty.includes(true)) {
      this.storeService.ManageProductStorePrice(this.storeCode,productPrices).subscribe((data) => {
        if(this.selectedCategory != null) {
          this.getProductByCategoryId(this.selectedCategory)
        }else {
          this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        }
      });      
    }
    
    
    resetForm() {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.ngOnInit();
          this.invalidCurrentPrice = [];
          this.invalidFuturePrice = [];
          this.invalidFutureDate = [];
          this.notEmptyCurrentPrice = [];
          this.noDateEmpty= []
          this.closeResetModal("dialogBox");
          
          this.ngOnInit();
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }
    
    
    firstSortedList: any;
    
    
    listSorted(list: any){
      this.firstSortedList = list;
    }
    
    @ViewChild(SortableComponent, {static: false}) sortableComponent: SortableComponent;
    public addPriceList() {
      this.priceList.push({
        value: '',
        currency: this.currency,
        action: "PRICE_FOL",
        activeFromDate: ''
      });
      this.priceList = [...this.priceList];      
    }
    
    public removePriceList(index) {
      this.dateInvalid[index] = false;
      this.priceList.splice(index,1);
      this.priceList = [...this.priceList];
      this.invalidFuturePriceList[index] = false;
      this.checkDuplicateDate();
    }
    
    
    
    
    
    priceOptionEvent(event) {
      if(event.target.value == "futurePrice") {
        this.isFuturePrice = true;
      }else {
        this.isFuturePrice = false;
      }
    }
    
    openResetModal(id: string){
      let data = {};
      data = {
        value: 'reset'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeResetModal(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    resetUpdateForm() {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.priceListModel();
          this.closeResetModal("dialogBox");
          
          this.ngOnInit();
          this.selectedType = ''
          this.isFuturePrice = false;
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }
    
    
    checkDuplicateDate() {
      for(let i=0; i< this.priceList.length; i++) {
        this.dateInvalid[i] = false;
      }
      for(let i=0; i< this.priceList.length; i++) {
        for(let k=i+1 ; k < this.priceList.length; k++) {
          if(this.priceList[i].activeFromDate != '') {
            if(this.priceList[i].activeFromDate == this.priceList[k].activeFromDate) {
              this.dateInvalid[i] = true;
              this.dateInvalid[k] = true;
            }
          }
        }
      }
    }
    
    validateCurrentPrice(event,i) {
      if(event.target.value != '') {
        if(event.target.value >= 0) {
          this.invalidCurrentPrice[i] = false;
        }else {
          this.invalidCurrentPrice[i] = true;
        }
        
        if(this.notEmptyCurrentPrice[i]) {
          this.notEmptyCurrentPrice[i] = false;
        }
      }else {
        this.invalidCurrentPrice[i] = true;
      }
    }
    
    validateFuturePrice(event,i) {
      if(event.target.value != '') {
        if(event.target.value >= 0) {
          this.invalidFuturePrice[i] = false;
        }else {
          this.invalidFuturePrice[i] = true;
        }
      }else {
        this.invalidFuturePrice[i] = false;
        this.noDateEmpty[i] = false;
      }
    }
    
    invalidCurrentP: Boolean;
    
    negativeValidation(event,j) {
      if(event.target.value != "") {
        if(event.target.value >= 0) {
          this.invalidFuturePriceList[j] = false
        }else {
          this.invalidFuturePriceList[j] = true;
        }
      }else {
        this.invalidFuturePriceList[j] = false;
      }
    }
    
    
    validateFutureDate(event,i) {
      if(event.target.value != '') {
        this.invalidFutureDate[i] = false;
        this.noDateEmpty[i] = false;
        if(event.target.value < this.restrictDate) {
          this.invalidFutureDate[i] = true;
        }else {
          this.invalidFutureDate[i] = false;
        }
      }
    }
    
    resetPriceList() {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.getPriceListByProductId(this.product.productId);
          
          this.closeResetModal("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }
    
    noNegativeCurrentPrice : Boolean  = false;
    
    negativeCurrentValidation(event) {
      if(event.target.value != "") {
        if(event.target.value >= 0) {
          this.noNegativeCurrentPrice = false;
        }else {
          this.noNegativeCurrentPrice = true;
        }
      }else {
        this.noNegativeCurrentPrice = false;
      }
    }
    
    
    // -------  pagination methods ------ //
    
    onChangePage(event) {
      this.currentPage = event;
      this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
  
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value
        this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }else {
        this.filters['type'] = null;
        this.getProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if(this.router.url.includes("pricelist")){
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName="createdDate"
        console.log(this.fields.fieldName)
        console.log(this.sort)
      } else {
        this.sort[0].sortDirection = await null
  
      }
  
      this.getProductsByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
    }
    
  }
  