import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { GetAllStoresComponent } from '../components/get-all-stores/get-all-stores.component';
import { AdminHomeComponent } from 'src/app/admin/admin-home/admin-home.component';
import { AddNewStoreComponent } from '../components/add-new-store/add-new-store.component';
import { StoreDetailsComponent } from '../components/store-details/store-details.component';
import { GetAllSubStoresComponent } from '../components/sub-store-components/get-all-sub-stores/get-all-sub-stores.component';
import { EditStoreComponent } from '../components/edit-store/edit-store.component';
import { AddNewSubStoreComponent } from '../components/sub-store-components/add-new-sub-store/add-new-sub-store.component';
import { GetAllStoreCatalogsComponent } from '../components/store-catalog-components/get-all-store-catalogs/get-all-store-catalogs.component';
import { AddNewStoreCatalogComponent } from '../components/store-catalog-components/add-new-store-catalog/add-new-store-catalog.component';
import { GetAllCategoriesComponent } from '../components/store-categories/get-all-categories/get-all-categories.component';
import { GetAllSubcategoriesComponent } from '../components/store-categories/get-all-subcategories/get-all-subcategories.component';
import { StoreCategoryDetailsComponent } from '../components/store-categories/store-category-details/store-category-details.component';
import { PriceListComponent } from '../components/price-list/price-list.component';
import { GetAllProductsComponent } from '../components/store-category-products/get-all-products/get-all-products.component';
import { ViewStoreProductComponent } from '../components/view-store-product/view-store-product.component';


const storeRoutes: Routes = [
  {
    path: '',
    component: AdminHomeComponent,
    children: [
      {
        path: 'regions',
        component: GetAllStoresComponent,
        // data: { breadcrumbItem: { key: 'stores', labelName: 'stores' } }
      },
      {
        path: 'addnewregion',
        component: AddNewStoreComponent,
        data: { breadcrumbItem: { key: 'addnewregion', labelName: 'Add Region',alternativeKey: 'addnewregion'} }
      },
      {
        path: 'regiondetails/:storecode/:storename',
        component: StoreDetailsComponent,
        data: { breadcrumbItem: { key: 'regiondetails', labelName: 'Regions',alternativeKey: 'regiondetails'} },
        children: [
          {
            path: 'subregions/:storecode',
            component: GetAllSubStoresComponent
          },
          {
            path: "editregions/:storecode",
            component: EditStoreComponent
          },
          {
            path: 'catalogs/:storecode',
            component: GetAllStoreCatalogsComponent
          },
          {
            path: 'categories/:storecode',
            component: GetAllCategoriesComponent
          },
          {
            path: 'pricelist/:storecode',
            component: PriceListComponent
          }
        ]
      },
      {
        path: 'regioncategorydetails/:storecode/:parentcategoryid',
        component: StoreCategoryDetailsComponent,
        data: { breadcrumbItem: { key: 'regioncategorydetails', labelName: 'Category',alternativeKey: 'regioncategorydetails',child: [] }},
        children: [
          {
            path: "subcategories/:storecode/:parentcategoryid",
            component: GetAllSubcategoriesComponent
          }, 
          {
            path: "products/:storecode/:parentcategoryid",
            component: GetAllProductsComponent
          },
        ]
        
      },
      {
        path: "viewproduct/:parentcategoryid/:storecode/:productid",
        component: ViewStoreProductComponent
      },
      {
        path: 'subregioncategorydetails/:storecode/:parentcategoryid',
        component: StoreCategoryDetailsComponent,
        children: [
          {
            path: "subcategories/:storecode/:parentcategoryid",
            component: GetAllSubcategoriesComponent
          },
          {
            path: "products/:storecode/:parentcategoryid",
            component: GetAllProductsComponent
          },
          // {
          //   path: "viewproduct/:storecode/:productid",
          //   component: ViewStoreProductComponent
          // }
        ]
        
      },
      {
        path: 'subregiondetails/:storecode/:storename',
        component: StoreDetailsComponent,
        children: [
          {
            path: 'subregions/:storecode',
            component: GetAllSubStoresComponent
          },
          {
            path: "editregions/:storecode",
            component: EditStoreComponent
          },
          {
            path: 'catalogs/:storecode',
            component: GetAllStoreCatalogsComponent
          },
          {
            path: 'categories/:storecode',
            component: GetAllCategoriesComponent
          },
          {
            path: 'pricelist/:storecode',
            component: PriceListComponent
          }
        ]
      },
      {
        path: 'addnewsubstore/:storecode',
        component: AddNewSubStoreComponent,
      },
      {
        path: 'addnewcatalog/:storecode',
        component: AddNewStoreCatalogComponent,
        data: { breadcrumbItem: { key: 'addnewcatalog', labelName: 'Add Catalog',alternativeKey: 'addnewcatalog'} }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(storeRoutes)
  ],
  exports: [
    RouterModule
  ]
  
})
export class StoreRouterModule { }
