import { Directive, EventEmitter, ElementRef, HostListener, Output } from '@angular/core';
 
@Directive({
  selector: '[clickOutsideEvent]'
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {
  }

  @Output()
  clickOutsideEvent = new EventEmitter<Event>();

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement): void {
      if (!targetElement) {
          return;
      }

      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      console.log(this.elementRef.nativeElement.contains(targetElement))
      console.log(clickedInside)
      if (!clickedInside) {
          this.clickOutsideEvent.emit(event);
          console.log(this.clickOutsideEvent)
      }
  }
}