import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit {

 data: string = '';
  @Input() test: string = '';
  @Output() onClickFindName =  new EventEmitter();
  @Output() onClickCross = new EventEmitter();

  showCross: boolean = false;

  constructor() { }

  ngOnInit() {
   this.data = ''
    this.showCross = false;
  }

  findName() {
    this.showCross = true;
    this.onClickFindName.emit(this.data);
  }

  hideCross() {
    this.showCross = false;
    this.data = '';
    this.onClickCross.emit(this.data);
  }

}
