import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { CategoryAssociationFilters } from 'src/app/shared/constants/filter-model/category-association-filters.model';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-product-associations',
  templateUrl: './category-product-associations.component.html',
  styleUrls: ['./category-product-associations.component.css']
})
export class CategoryProductAssociationsComponent implements OnInit {
  categoryProductAssociations: any[];
  categoryId: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  myBucketUrl: any;
  subscription: Subscription;
  associationProduct:  Array<any> = []
  productIds : Array<any> = [];
  showNoData: boolean = false;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  filters: CategoryAssociationFilters;
  pagination: any;
  searchName: string;
  numberOfPages:number=1;
  types: Array<any> = [];
  products: Array<any> = [];
  sort: Array<any> = [];
  fields: any = {

  fieldName: null,
  sortDirection: null
};

  // sort:Array<any> = sortConstant.CATEGORY_ASSOCIATION_SORT;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private router:Router,private breadService:BreadcrumbService,
    private messageService: MessageSubjectService,
    private noDataService: NoDataService,
    public translate : TranslateService) { 
      this.filters = new CategoryAssociationFilters();
    }
    
    ngOnInit() {
      this.getTypes();
      this.route.paramMap.subscribe((map) => {
        this.categoryId = map.get('categoryId');
        this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        // this.getCategoryById();
      });
    }
    
    
    getCategoryById() {
      this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
        if(!data['categories'][0]['parentId']) {
          if(data['categories'][0].name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "categorydetails",
              alternativeKey: "categorydetails",
              labelName: 'Category: '+data['categories'][0].name,
              path: this.router.url
            });
          }
        }else {
          if(data['categories'][0].name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "subcategorydetails",
              alternativeKey: "subcategorydetails"+this.categoryId,
              labelName: 'Sub Category: '+data['categories'][0].name,
              path: this.router.url
            });
          }
        }
      })
    }
    
     getAllAssociationByQuery(filters,itemsPerPage,currentPage,sort) {
      this.allCheck = false;
      this.categoryProductAssociations = [];
      this.pagination = null;
      this.adminService.getAllCategoryProductAssociationByQuery(this.categoryId,filters,itemsPerPage,currentPage,sort).subscribe((data) => {
        this.categoryProductAssociations = data.associations;
        console.log(this.categoryProductAssociations)
        console.log(data,'associatons')
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.getProductsByIds(this.categoryProductAssociations);
        this.showNoData = this.noDataService.noData(this.categoryProductAssociations);
      });
    }

    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['type'] = this.searchName  
        this.currentPage = 1;
        this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }
    
    hideCross(event) {
      // this.filters['type'] = null;
      delete this.filters.type
      this.searchName = '';
      this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    
    getProductsByIds(data) {
      var productIds = [];
      this.checkboxItems = [];
      if(data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          this.checkboxItems[i] = false;
          productIds[i] = data[i].productId;
        }
        console.log(productIds)
        this.getProductById(productIds)
      }
    }
    
    getProductById(productIds) {
      this.products = []
      this.adminService.getProductById(productIds).subscribe((data) => {
        this.associationProduct = data.products;
        for(let i=0; this.categoryProductAssociations.length;i++) {
          for(let j=0; j< this.associationProduct.length;j++) {
            if(this.categoryProductAssociations[i].productId == this.associationProduct[j].id) {
              this.products[i] = {
                id: '',
                name: '',
                imageRef: '',
                type: ''
              }
              this.products[i]['id'] = this.categoryProductAssociations[i].id;
              this.products[i]['name'] = this.associationProduct[i].name;
              this.products[i]['imageRef'] = this.getBackground(this.associationProduct[i].imageRef);
            }
          }
          this.getAllAssociationsTypes()
        }
      });
    }
    
    
    getAllAssociationsTypes() {
      this.adminService.getAllProductAssociationType().subscribe((data) => {
        for(let i=0; i< this.categoryProductAssociations.length;i++) {
          for(let j=0 ; j< data.associationTypes.length;j++) {
            if(this.categoryProductAssociations[i].type ==   data.associationTypes[j].code) {
              this.products[i]['type'] = (data.associationTypes[j].name)
            }
          }
        }
      });
      console.log(this.products)
    }
    
    
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    checkAll() {
      this.allCheck = !this.allCheck;
      for (let a = 0; a < this.categoryProductAssociations.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.categoryProductAssociations.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    delete() {
      
      this.openModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.closeModal("dialogBox");
          
          let associationIds: Array<any> = [];
          for (let i = 0; i < this.categoryProductAssociations.length; i++) {
            if (this.checkboxItems[i] == true) {
              associationIds.push(this.categoryProductAssociations[i].id);
              this.adminService.deleteCategoryProductAssociations(this.categoryId, associationIds).subscribe((data) => {
                this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
              });
            }
          }
          
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        } else if (res == "no") {
          this.closeModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
    
    
    
    openModal(id: string) { 
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal(id: string) {
      this.deleteDialogService.closeDialog();
      //console.log(event.target)
    }
    
    
    
    // -------  pagination methods ------ //
    
    onChangePage(event) {
      this.currentPage = event;
      console.log(this.currentPage)
      this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    // ------- type method ---------// 
    
    
    getTypes() {
      this.adminService.getAllProductAssociationType().subscribe((data) => {
        this.types = data.associationTypes;
        console.log(this.types)
      });
      console.log(this.types)
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value;
        this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }else {
        // this.filters['type'] = null;
        delete this.filters.type
        this.getAllAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      console.log("associationlist")
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName="productAssociations.name"
        console.log(this.sort)
      } else {
        this.sort[0].sortDirection = null;
       
      }
  
      this.getAllAssociationByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
  

      
  
    }
    
  
  