import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PpBreadcrumbsService, Breadcrumb } from 'pp-breadcrumbs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.prod';
import { MessageService } from 'src/app/services/message.service';
import { truncateSync } from 'fs';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
    
    productId: string;
    product: any;
    currentProduct: any
    currentCategory: any;
    id: any;
    breadCrumbs: any;
    user:any
    productName: any;
   
    constructor(private adminService: AdminService,
        private route: ActivatedRoute,
        private breadcrumbsService: BreadcrumbService,
        private router: Router,
        private messageService: MessageSubjectService,
        public translate:TranslateService) { }
        
        
        ngOnInit() {
            this.route.paramMap.subscribe((map) => {
                this.productId = map.get('productId');
                this.id = map.get('productId');
                this.productName = decodeURIComponent(map.get('productName'))
                
                console.log(this.productName)
                
                this.getProduct();  
                
            })
        }
        
        
        
        getProduct() {
            this.adminService.getProductById(this.productId).subscribe((data) => {
                if(data['products'][0].name != null) {
                    this.messageService.broadcast('bcLabelOverwrite', {
                        key: "productdetails",
                        alternativeKey: 'productdetails',
                        labelName: 'Product: '+data['products'][0].name,
                        path: this.router.url
                    });
                }
            })            
        }
        
        
        setup() {
                if(this.productName != null) {
                    this.messageService.broadcast('bcLabelOverwrite', {
                        key: "productdetails",
                        alternativeKey: "productdetails",
                        labelName: 'Product: '+decodeURIComponent(this.productName),
                        path: decodeURI(this.router.url)
                    });
                }
                
               
               
            
        }
    }