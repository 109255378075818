import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CustomerService } from '../../customer-service/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NoDataService } from 'src/app/services/no-data.service';
import { OrderHistoryFilters } from 'src/app/shared/constants/filter-model/order-history-filters.model';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.css']
})
export class OrderHistoryComponent implements OnInit {
  
  constructor(private customerService: CustomerService,
    private route: ActivatedRoute,
    private router :Router,
    private ngbModalService: NgbModal,
    private noDataService: NoDataService) {  this.filters = new OrderHistoryFilters(); }

    customerId: any;
    orderHistory: Array<any> = [];
    filters: any =  OrderHistoryFilters;
    order: any;
    noDataShow: Boolean = false;
    currentPage : number =1;
    itemsPerPage :number = 10;
    pagination: any;
    numberOfPages:number=1;
    totalItems: number;
    totalOrders: any;
    sort: Array<any> = [];
    orderId:any;
    totalQuantity: any;
    fields: any = {
    fieldName: null,
    sortDirection: null
  };
    @ViewChild('orderDetails',{static: true}) public orderDetails: ElementRef;


    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.customerId = map.get('customerid');
        this.sort[0]=this.fields;
        this.sort[0].fieldName="createdDate"
        this.sort[0].sortDirection="DESCENDING"
        // this.getOrderHistory();
        this.getOrderHistoryWithPagination(this.filters,this.customerId,this.itemsPerPage,this.currentPage,this.sort)
        
      });
    }
        
    openOrderDetailsModal(orderId) {
      console.log(this.customerId)
      console.log(this.orderId)
      this.router.navigateByUrl('orderhistorydetails/'+orderId)
    }

    closeDetailsModal() {
      this.ngbModalService.dismissAll()
    }

    getOrderHistoryWithPagination(filters,customerId,itemsPerPage,currentPage,sort) {
      this.pagination=null;
      this.customerService.getAllOrderHistoryWithPagination(filters,customerId,itemsPerPage,currentPage,sort).subscribe((data) => {
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.orderHistory = data['orderHistory'];
        this.noDataShow = this.noDataService.noData(this.orderHistory);
        
      })
    }

    onChangePage(event) {
      this.currentPage = event;
      this.getOrderHistoryWithPagination(this.filters,this.customerId,this.itemsPerPage,this.currentPage,this.sort);
    }

    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      console.log(this.itemsPerPage);
      this.getOrderHistoryWithPagination(this.filters,this.customerId,this.itemsPerPage,this.currentPage,this.sort);
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
    
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='createdDate'
        console.log(this.sort)
      } else {
        
        this.sort[0].sortDirection =  null
        console.log(this.sort[0].sortDirection)
      }
  
      this.getOrderHistoryWithPagination(this.filters, this.customerId,this.itemsPerPage, this.currentPage, this.sort);
  
    }
    
   
  }
  