import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { v4 as uuid } from 'uuid';
import { AdminService } from 'src/app/admin/admin.service';



@Component({
  selector: 'app-category-features-options',
  templateUrl: './category-features-options.component.html',
  styleUrls: ['./category-features-options.component.css']
})
export class CategoryFeaturesOptionsComponent implements OnInit {

  featureId: any;
  categoryFeatureOption: any;
  selectedFiles: FileList;
  categoryProductFeatures: any[];
  categoryId: any;
  price: any;
  currentCategoryFeature: any;
  uid: any;
  categoryFeatureOptions: Array<any> = [];
  myBucketUrl: any;
  currentCategoryFeatureOption: any;
  updateCategoryFeatureOption: any;
  basePrice: any;
  featureOptionIndex: any;
  categoryIndex: any;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.myBucketUrl = environment.myBucketUrl;
    this.route.paramMap.subscribe((map) => {
      this.featureId = map.get('featureId');
      //console.log(this.featureId);
      this.categoryId = map.get('categoryId');
      //console.log(this.categoryId);
      this.categoryIndex = map.get('index');
      this.getCategoryProductFeature();
    });
  }

  getCategoryProductFeature() {
    this.adminService.getAllCategoryFeaturesById(this.categoryId).subscribe((data) => {
      this.categoryProductFeatures = data.features;
      //console.log(this.categoryProductFeatures);

      this.categoryFeatureOption = {
        options: {
          name: '',
          description: '',
          imageRef: '',
          price: {}
        }
      }

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.categoryProductFeatures.length; i++) {
        if (this.categoryProductFeatures[i].id == this.featureId) {
          this.currentCategoryFeature = this.categoryProductFeatures[i];
          this.categoryFeatureOptions = this.categoryProductFeatures[i].options;
          //console.log(this.categoryProductFeatures[i].options)
          //console.log(this.categoryFeatureOptions)
        }
      }
      //console.log(this.currentCategoryFeature);
      //console.log(this.categoryFeatureOptions)
    });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openEditModal(id: string, index, currentCategoryFeatureOption) {
    this.modalService.open(id);
    this.featureOptionIndex = index;
    this.currentCategoryFeatureOption = currentCategoryFeatureOption;
    //console.log(this.currentCategoryFeatureOption)
    //console.log(index);

    this.updateCategoryFeatureOption = {
      options: {
        name: this.currentCategoryFeatureOption.name,
        description: this.currentCategoryFeatureOption.description,
        imageRef: this.currentCategoryFeatureOption.imageRef,
        price: this.currentCategoryFeatureOption.price
      }
    }
    this.basePrice = this.currentCategoryFeatureOption.price.value;
    //console.log(this.price);
    //console.log(this.basePrice)
  }

  closeEditModal(id) {
    this.modalService.open(id);
  }


  updateOption(id) {
    this.updateCategoryFeatureOption.options.price["value"] = this.basePrice;
    this.updateCategoryFeatureOption.options.price["currency"] = "USD";

    this.currentCategoryFeature.options[this.featureOptionIndex] = (this.updateCategoryFeatureOption.options)



    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.currentCategoryFeature.imageRef != "") {
        this.imageUploadService.editImage(file, this.currentCategoryFeature.imageRef).subscribe((data) => {
          this.updateCategoryFeatureServiceCall(id);
        })
      } else {
        this.imageUploadService.uploadImage(file).subscribe((data) => {
          this.updateCategoryFeatureOption.imageRef = data.documentId;

          this.updateCategoryFeatureServiceCall(id);
        })
      }
    } else {
      this.updateCategoryFeatureServiceCall(id);
    }



    this.adminService.updateCategoryFeatureById(this.categoryId, this.featureId, this.currentCategoryFeature).subscribe((data) => {
      this.modalService.close(id);
      this.router.navigate(['/categoryfeaturedetails/' + this.categoryId + '/' + this.featureId + '/' + this.categoryIndex + '/addcategoryfeatureoption/' + this.categoryId + '/' + this.featureId + '/' + this.categoryIndex]);
    });
  }

  onSubmit(id) {
    this.categoryFeatureOption.options.price["value"] = this.price;
    this.categoryFeatureOption.options.price["currency"] = "USD";
    
    this.currentCategoryFeature.options.push(this.categoryFeatureOption.options)

    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);

      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.categoryFeatureOption.imageRef = data.documentId;

        this.updateCategoryFeatureServiceCall(id);
      })
    } else {
      this.updateCategoryFeatureServiceCall(id);
    }
    
  }

updateCategoryFeatureServiceCall(id){
  this.adminService.updateCategoryFeatureById(this.categoryId, this.featureId, this.currentCategoryFeature).subscribe((data) => {
    this.modalService.close(id);
    this.router.navigate(['/categoryfeaturedetails/' + this.categoryId + '/' + this.featureId + '/' + this.categoryIndex + '/addcategoryfeatureoption/' + this.categoryId + '/' + this.featureId + '/' + this.categoryIndex]);
  });
}

  getBackground(image) {
    let uid = uuid();
    if (image == "") {
      image = "noImage.jpg";
      return this.sanitizer.bypassSecurityTrustStyle('url(/assets/images/' + image + ')');
    }
    else {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.myBucketUrl + image + "?" + uid +')');
    }
  }


}
