import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DeleteDialogService } from '../delete-dialog/delete-dialog.service';

@Component({
  selector: 'app-claim-dialog',
  templateUrl: './claim-dialog.component.html',
  styleUrls: ['./claim-dialog.component.css']
})
export class ClaimDialogComponent implements OnInit {

  value: any;
  status: any;
  responseMessage: any;
  title = 'Angular-Interceptor';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
  private deleteDialogService: DeleteDialogService){
    console.log(data);
  
}


  ngOnInit() {
  }

  closeModal(id: string) {
    // this.modalService.close(id);
    this.deleteDialogService.closeDialog();
  }

}
