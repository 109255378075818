import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../customer-service/customer.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { PointType } from './loyalty-point-model';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { LoyaltyPointFilters } from 'src/app/shared/constants/filter-model/loyalty-point-filters.model';

@Component({
  selector: 'app-loyalty-points',
  templateUrl: './loyalty-points.component.html',
  styleUrls: ['./loyalty-points.component.css']
})
export class LoyaltyPointsComponent implements OnInit {

  customerId: string;
  noDataShow: Boolean = false;
  loyaltyPointList: any[];
  pagination: any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  numberOfPages : number=1;
  filters: LoyaltyPointFilters;
  pointType: PointType
  pointTypes: Array<any> =[];
  pointKeys: Array<any> =[];
  sort: Array<any> = [];
  fields: any = {

  fieldName: null,
  sortDirection: null
};
  // sort:Array<any> = sortConstant.LOYALTY_POINT;
  
  constructor(private route: ActivatedRoute,
    private customerService: CustomerService,
    private noDataService: NoDataService) {
      this.pointType = new PointType();
      this.filters = new LoyaltyPointFilters()
     }
  
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.customerId = map.get('customerid');
        this.sort[0]=this.fields
        this.sort[0].fieldName="earnDate";
        this.sort[0].sortDirection="DESCENDING";
        this.getLoyaltyPointHistoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        this.getAllTypes()
      
      })
    }
    
    getAllTypes() {
    this.pointTypes = this.pointType.getActionTypeValues();
    this.pointKeys = this.pointType.getPointTypeKeys();
  
    
    }
    
    // getLoyaltyPointHistory(customerId) {
    //   this.customerService.getLoyaltyPointById(customerId).subscribe((response) => {
    //     this.loyaltyPointList = response.loyaltyPoints;   
    //   })
    // }
    
    getLoyaltyPointHistoryByQuery(filters,itemsPerPage,currentPage,sort){
      this.loyaltyPointList = []
      this.pagination=null;
      this.filters['customerId'] = this.customerId;
      this.customerService.getLoyaltyPointsbyQuery(filters,itemsPerPage,currentPage,sort).subscribe((data) => {
        this.loyaltyPointList = data.loyaltyPoints;
        console.log(data)
        this.pagination  = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        
        this.noDataShow = this.noDataService.noData(this.loyaltyPointList);
        
        console.log(this.numberOfPages)
      });
    }
    
      // -------  pagination methods ------ //
    
      onChangePage(event) {
        this.currentPage = event;
        this.getLoyaltyPointHistoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
      
      addItemsPerPage(event) {
        this.itemsPerPage = event;
        this.currentPage = 1;
        this.getLoyaltyPointHistoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    
    onSelectType(event) {
     if(event.target.value != '') {
      // this.filters['type'] = event.target.value
      delete this.filters.type
      this.getLoyaltyPointHistoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);   
     }else {
      //  this.filters['type'] = null;
      delete this.filters.type
      this.getLoyaltyPointHistoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);   

     }
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='earnDate'
        console.log(this.sort)
      } else {
        
        // this.sort[0].sortDirection = await null
        delete this.sort[0].sortDirection
        console.log(this.sort[0].sortDirection)
      }
  
      this.getLoyaltyPointHistoryByQuery(this.filters, this.itemsPerPage, this.currentPage,this.sort);
  
    }
    
    
  }
  