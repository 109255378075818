import { Injectable, ErrorHandler } from '@angular/core';
import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public errorDialogService: ErrorDialogService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // const token: string = localStorage.getItem('token');
        
        // if (token) {
        //     request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        // }
        
        // if (!request.headers.has('Content-Type')) {
        //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        // }
        
        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // //console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                console.log("myerror",error)
                if(error.error.messages) {
                    data = { 
                        reason: error && error.error && error.error.reason ? error.error.reason : error.error.statusReason,
                        reasonMessage: error.error.messages[0],
                        status: error.status,
                        correlationId: error.error.correlationId,
                        url: error.url
                    };
                }
                else if(error.status == 503){ 
                    var message = "Service Unavailable"
                    data = {
                        reason: message,
                        status: error.status,
                        correlationId: error.error.correlationId,
                        url: error.url
                    }
                }
                else if(error.status == 0) {
                    var message = "Unknown Error"
                    data = {
                        reason: message,
                        status: error.status,
                        correlationId: error.error.correlationId,
                        url: error.url
                    }
                }
                else if(error.status == 500) {
                    var message = "Internal Server"
                    data = {
                        reason: message,
                        status: error.status,
                        correlationId: error.error.correlationId,
                        url: error.url
                    }
                }
                else  {
                    data = {
                        status: error.status,
                        correlationId: error.error.correlationId,
                        url:error.url
                    };
                }
                this.errorDialogService.openDialog(data);
                return throwError(error);
            }));
        }
        
    }
    