import { Injectable } from '@angular/core';
import { FeatureRefService } from '../feature-ref-service/feature-ref.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductFeatureRefService implements FeatureRefService {

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
    //console.log(this.tenant)
  }

  user: any;
  tenant: any;
  headers: Headers;


  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }

  // serverRootUrl = environment.BASE_URL;
  serverRootUrl = environment.PRODUCT_BASE_URL
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  productRootUrl = this.serverRootUrl + "/api/v1/products/";

  getAllFeatures(id): Observable<any> {
    return this.http.get<any>(this.productRootUrl + id + "/features", this.getHeaderTenant());

  }

  getAllFeaturesByQuery(id, filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": [
        {
          "fieldName": "name"
        }
      ]
    }
    return this.http.post<any>(this.productRootUrl + id + "/features/query", requestBody, this.getHeaderTenant());
  }


  createFeatureById(id, feature,cascadeToProduct): Observable<any> {
    let request = {};
    request['feature'] = feature
    return this.http.post<any>(this.productRootUrl + id + "/features", request, this.getHeaderTenant());
  }

  deleteFeatureByFeatureId(id, featureId,cascadeToProduct) {
    return this.http.delete<any>(this.productRootUrl + id + "/features?featureIds=" + featureId, this.getHeaderTenant());
  }

  updateFeatureByFeatureId(id, featureId, feature,cascadeToProduct) {
    let request = {};
    request['feature'] = feature
    return this.http.put<any>(this.productRootUrl + id + "/features/" + featureId, request, this.getHeaderTenant());
  }

  getFeaturesByProductId(id) {
    let requestBody = {}
    return this.http.post<any>(this.productRootUrl + id + "/features/query", requestBody, this.getHeaderTenant());
  }
}
