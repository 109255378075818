import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureTypeRefServiceService {

 
  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;

  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }


  serverRootUrl = environment.PRODUCT_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"

  productFeatureTypeRootUrl = this.serverRootUrl + "/api/v1/productfeaturetypes/";



  createType(featureType): Observable<any> {
    let request = {};
    request['featureType'] = featureType
    return this.http.post<any>(this.productFeatureTypeRootUrl,request, this.getHeaderTenant());
  }

  getAllTypes():Observable<any> {
    return this.http.get<any>(this.productFeatureTypeRootUrl, this.getHeaderTenant());  }

  
  deleteTypeByTypeCodes(featureTypeCodes) {
    return this.http.delete<any>(this.productFeatureTypeRootUrl+"?productFeatureTypeCodes="+featureTypeCodes, this.getHeaderTenant());
  }

  updateTypeByTypeCode(featureType, featureTypeCode) {
    let request = {};
    request['featureType'] = featureType
    return this.http.put<any>(this.productFeatureTypeRootUrl+featureTypeCode,request, this.getHeaderTenant())
  }
}
