import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { ModalService } from '../directives/modal.sevice';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class MyerrorHandlerService implements ErrorHandler {

  constructor(private modalService: ModalService,
              private dialog: MatDialog) {
  }
  

  handleError(error) {
    let str: string = '';
    str = error.message;
    if (str.includes('ExpressionChangedAfterItHasBeenCheckedError:')) {
     
    } else {
      //console.error(error)
    }


  }
 

}
