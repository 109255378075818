import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { environment } from 'src/environments/environment.prod';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-addnew-product-feature',
  templateUrl: './addnew-product-feature.component.html',
  styleUrls: ['./addnew-product-feature.component.css']
})
export class AddnewProductFeatureComponent implements OnInit {

  @ViewChild('addNewFeatureForm', {static: false}) myForm: NgForm;
  user: any;
  myBucketUrl: any;
  productFeatures: any;
  featureTypes: any[];
  selectedFiles: FileList;
  productId: any;
  basePrice: any;
  price: any;
  uid: any;
  currentFields: any;
  showFields: boolean = false;
  convertedTypeField: Array<string> = [];
  fieldCheckbox: Array<boolean> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  featureFields: any;
  value: Array<any> = [];
  fields: any;
  featureOptions: Array<string> = []
  catOptions: Array<Boolean> = [];
  
  subscription: Subscription;
  
  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService) { }

  ngOnInit() {
    console.log('category-feature')
    this.myBucketUrl = environment.myBucketUrl;
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.getFeatureType();



    this.productFeatures = {
      name: '',
      description: '',
      defaultUseCount: '',
      maxUseCount: '',
      minUseCount: '',
      price: {},
      imageRef: '',
      type: '',
      createdBy: this.user.name,
      modifiedBy: this.user.name,
      options: [],
      fields: {}
    }

  }

  
  resetForm() {
    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal("dialogBox");
       
        this.ngOnInit();
        this.myForm.resetForm();
        this.value = []
        this.price = ""
        this.showFields = false;
       
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    let data = {};
    data = {
        value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal(id: string) {
    this.deleteDialogService.closeDialog();
  }

  getFeatureType() {
    this.route.paramMap.subscribe((map) => {
      this.productId = map.get('productId');
      this.adminService.getAllProductFeatureType().subscribe((data) => {
        this.featureTypes = data.featureTypes;
      })
    })
    }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }


  selectCode(event) {
    this.currentFields = event.target.value;
    this.adminService.getAllFeatureFields(this.currentFields).subscribe((data) => {
      this.featureFields = data.productFeatureFields;
      //console.log(this.featureFields)
      this.optionFieldsCondition(this.featureFields)
    });

    this.showFields = true;
  }

  optionFieldsCondition(featureFields) {
    this.value = []
      for (let i = 0; i < featureFields.length; i++) {
        if(featureFields[i].options) {
          if((featureFields[i].options.length==1) || (featureFields[i].options.length==0) ) {
            this.catOptions [i] =false;    
          }
          else {
            this.catOptions[i] = true;
          }
        }
        //console.log(this.catOptions)
        if (featureFields[i].type == "BOOLEAN") {
          this.value[i] = false;
        }
        let type = featureFields[i].type;
        this.convertedTypeField[i] = (environment.typeConversion[type]);
      }
  }


  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  onSubmit() {
    if (this.featureFields != null) {
      this.fields = {};
      for (let i = 0; i < this.featureFields.length; i++) {
        let fieldName = this.featureFields[i].key;

        if (this.featureFields[i].type == "INTEGER") {
          this.productFeatures.fields[fieldName] = parseInt(this.value[i])
        } else if (this.featureFields[i].type == "DECIMAL") {
          this.productFeatures.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.productFeatures.fields[fieldName] = this.value[i]
        }
      }
    }
    this.productFeatures.price["value"] = this.price;
    this.productFeatures.price["currency"] = "USD";

    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);

      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.productFeatures.imageRef = data.documentId;

        this.addNewProductFeatureServiceCall();
      })
    } else {
      this.addNewProductFeatureServiceCall();
    }

    

  }

  addNewProductFeatureServiceCall(){
    this.adminService.createProductFeatureByProductId(this.productId, this.productFeatures).subscribe((data) => {
      this.router.navigate(['/productdetails/' + this.productId + '/productfeatures/' + this.productId])
    });
  }
}