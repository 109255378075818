import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FeatureRefComponent } from '../feature-ref/feature-ref.component';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { ProductFeatureRefService } from '../../product-feature-service/product-feature-ref-service/product-feature-ref.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { AdminService } from 'src/app/admin/admin.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/services/message.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-product-feature-ref',
  templateUrl: '../feature-ref/feature-ref.component.html',
  styleUrls: ['./product-feature-ref.component.css']
})
export class ProductFeatureRefComponent  extends FeatureRefComponent  implements OnInit {

  featureRefService: any;
  disableAll:Boolean=true
  user:any;

  constructor( public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public deleteDialogService: DeleteDialogService,
    public modalService: ModalService,
    public http: HttpClient,
    public backgroundImageService: BackgroundImageService,
    public router: Router,
    public adminService: AdminService,
    public messageService: MessageSubjectService,
    public translate:TranslateService,
    public noDataService: NoDataService,
    public dataService:MessageService) {  super(route,sanitizer,deleteDialogService,modalService,http,
      backgroundImageService,router,adminService,messageService,noDataService,translate,dataService )
      
      this.featureRefService = new ProductFeatureRefService(this.http)
    }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.getTypes();
    this.getAllFeaturesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
    this.featureLink = "addnewfeature"
    this.featureDetails = "productfeaturedetails";
    this.editFeature = "editproductfeature";
    this.adminService.getProductById(this.categoryId).subscribe((data) => {
      if(environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant) && data.products[0].workflowState.history.length != 0){
      if(data['products'][0].workflowState.status=='REVIEW'){
        // this.dataSubscription=this.dataService.getMessage().subscribe((data)=>{
      
        //   this.disableAll=data.disable
        //   this.dataService.clearMessage()
        // })
        this.disableAll=false
        
      }
    }
      if(data['products'][0].name != null) {
        this.messageService.broadcast('bcLabelOverwrite', {
          key: "productdetails",
          alternativeKey: 'productdetails',
          labelName: 'Product: '+data['products'][0].name,
          path: decodeURI(this.router.url)
        });
      }
    })
  }




  
}
