import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../customer-service/customer.service';
import { LoyaltyService } from '../../marketing/loyalty-service/loyalty.service';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  
  customerId: string;
  customer: any;
  showBlankWindow:boolean;
  showHaveProfile:boolean;

  constructor(private route: ActivatedRoute,
    private customerService: CustomerService) {
      this.showBlankWindow=false;
      this.showHaveProfile=false;
     }

  
  ngOnInit() {
    this.route.paramMap.subscribe((map) => {
      this.customerId = map.get('customerid');
      this.getCustomerDetailsById(this.customerId);
    })

  }
    
  getCustomerDetailsById(customerId) {
 
 this.customerService.getCustomerById(customerId).subscribe((response) => {
  this.customer = response.customers[0];
  //console.log(this.customer)
})
  
this.customerService.getLoyaltyProfileById(customerId).subscribe((data)=>
 {
   console.log(data);
  //  if(data["loyaltyProfile"]!=null )
  if(data.statusCode==200 && data.loyaltyProfile!=null )
   {
    this.showBlankWindow=false;
     this.showHaveProfile=true;
     this.customerService.getCustomerById(customerId).subscribe((response) => {
      this.customer = response.customers[0];
      //console.log(this.customer)
    })
   }
   else{
     this.showBlankWindow=true;
     this.showHaveProfile=false;

   }  
 }
 ) }

}
