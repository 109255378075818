import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoDataService {


  noDataShow: boolean = false;

  constructor() { }

  noData(data) {
    if(data.length > 0) {
      this.noDataShow = false;
    }else {
      this.noDataShow = true;
    }
    return this.noDataShow;
  }


}
