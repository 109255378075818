import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BucketUrl } from 'src/app/bucket';
import { Urls } from 'src/app/shared/common/urls';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import * as glob from '../../services/globals';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { environment } from 'src/environments/environment.prod';
const shajs = require('sha.js');

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  user: any = null;
  userFlag: boolean = false;
  bucketUrl: BucketUrl;
  logo: string;
  headerDetails: Array<any> = [];
  activeFlag: Array<Boolean> = [];
  show: Boolean = false;
  constructor(private router: Router, private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private messageService: MessageSubjectService) {
    this.bucketUrl = new BucketUrl();
  }

  ngOnInit() {
    this.logo = Urls.LOGO_URL;

    this.user = JSON.parse(sessionStorage.getItem("user"))
    if (this.user != null) {

      this.router.navigate(['/adminhome/productcategories'])
    } else {
      if (environment.WORKFLOW_ENABLED) {
        this.user = {
          name: "caseuser",
          password: "",
          tenant: "Select Brand",
          customTenant: ''
        }
      } else {
        this.user = {
          name: "john",
          password: "",
          tenant: "Select Brand",
          customTenant: ''
        }
      }

    }


    this.getHeaderDetails();

  }

  getHeaderDetails() {
    this.headerService.getHeaderDetails().subscribe((data) => {
      this.headerDetails = data;
    })
  }

  showCustom(event) {
    if (event.target.value == "custom") {
      this.show = true;
      // this.user.tenant = this.user.customTenant;
    }
    else {
      this.show = false;
    }
  }

  getPasswordForUser(userName) {
    let hash = shajs('sha256')
      .update(userName + this.user.password)
      .digest('hex');
    return hash
    //return 'redhatpam1!'
  }


  onSubmit() {
    // this.oktaAuth.loginRedirect()

    let timestamp = new Date();
    sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));

    if (this.show) {
      this.user.tenant = this.user.customTenant;
    }
    if (this.user.tenant == "Select Brand" || this.user.tenant == null) {
      window.alert("Brand is required")
    } else {
      if (this.user.password !== 'dcpadmin' && (this.user.name == 'caseuser' || this.user.name == 'john')) {
        window.alert("Incorrect Password")
      } else {
        //set password according to sha if workflow is enabled
        if (environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant)) {
          this.user.password = this.getPasswordForUser(this.user.name)
        }
        this.router.navigate(['/productcategories']);
        sessionStorage.setItem('user', JSON.stringify(this.user));

      }
    }

    for (let i = 0; i < this.headerDetails.length; i++) {
      if (i == 0) {
        this.activeFlag[0] = true
      } else {
        this.activeFlag[i] = false;
      }
    }
    localStorage.setItem("activeFlag", JSON.stringify(this.activeFlag));
    this.messageService.broadcast('primary',
      {
        key: "Products", labelName: "Categories", path: "productcategories",
        terminalOnly: false,
        afterBaseOnly: false,
        pathParamList: [],
        queryParams: undefined,
        fragment: undefined,
        child: []
      }
    )
  }
}
