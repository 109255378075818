import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StoreService } from '../../services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreTypeRefService } from 'src/app/admin/api-configuration/api-configuration-services/type-services/store-type-ref-services/store-type-ref.service';
import { StoreFieldRefService } from 'src/app/admin/api-configuration/api-configuration-services/field-services/store-field-ref-service/store-field-ref.service';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { BucketUrl } from 'src/app/bucket';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapterService } from 'src/app/shared/datepicker/custom-adapter.service';
import { CustomDateParserFormatterService } from 'src/app/shared/datepicker/custom-date-parser-formatter.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import csc from 'country-state-city'
import { GenerateTimeslotService } from 'src/app/services/generate-timeslot.service';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
@Component({
  selector: 'app-edit-store',
  templateUrl: './edit-store.component.html',
  styleUrls: ['./edit-store.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapterService },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService }
  ]
})
export class EditStoreComponent implements OnInit {
  
  storeCode: any;
  store: any;
  editStore: any;
  user: any;
  storeFields: any;
  storeType: any;
  storeTypes: Array<any> = [];
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  value: Array<any> = [];
  convertedTypeField: Array<any> = [];
  catOptions: Array<Boolean> = [];
  subscribe: Subscription;
  currentFields: any;
  currentImageRef: any;
  previousImageRef: any;
  bucketUrl: BucketUrl;
  selectedFiles: FileList;
  fields: any;
  parentStoreName: any;
  lastIndexOfMultipleArray: Array<any> = [];
  model2: string;
  currencies: Array<any> = [];
  units: Array<any> = [];
  subscription: Subscription;
  minDate: { year: number, month: number, day: number };
  curbsideTimeslots: Array<any> = [{
    startTime: '',
    endTime: '',
    maxOrders: ''
  }]
  showCurbDetails: Boolean = false;
  countries: Array<any> = [];
  states: Array<any> = [];
  cities: Array<any> = [];
  addTimeslotsRef: NgbModalRef;
  documentArray: Array<Boolean> = [];
  currentImageRefForField: Array<any> = [];
  previousImageRefForField: Array<any> = [];
  selectedFilesForFields: Array<any> = [];
  restrictedFromDate:any
  restrictedThroughDate:any

  @ViewChild('addTimeSlots', { static: true }) public addTimeSlots: ElementRef;
  
  
  constructor(private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router,
    private storeTypeRefService: StoreTypeRefService,
    private storeTypeFieldRefService: StoreFieldRefService,
    private deleteDialogService: DeleteDialogService,
    private modalService: NgbModal,
    private backgroundImageService: BackgroundImageService,
    private imageUploadService: ImageUploadService,
    private messageService: MessageSubjectService,
    private generateTimeSlotService: GenerateTimeslotService,
    private dateValidations: DateValidationsService) {
      const current = new Date()
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate() + 1
      };
      this.storeTypeRefService.getHeaderTenant();
      this.storeService.ngOnInit()
      this.storeTypeFieldRefService.getHeaderTenant()
    }
    
    ngOnInit() {
      this.currentFields = ''
      this.storeTypeRefService.getHeaderTenant();
      this.storeService.ngOnInit()
      this.storeTypeFieldRefService.getHeaderTenant()
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.route.paramMap.subscribe((map) => {
        this.storeCode = map.get('storecode');
        //console.log(this.storeCode)
        this.storeTypeRefService.getHeaderTenant();
        this.getAllStoreTypes();
        this.getStoreByStoreCode()
        this.getCurrency();
        this.getUnits();
        this.getAllCountries();
      })
      
    }
    
    getAllCountries() {
      this.countries = csc.getAllCountries();
    }
    
    
    getCurrency() {
      this.storeService.getAllCurrency().subscribe((data) => {
        this.currencies = data;
      })
    }
    
    getAllStatesByCountryId(id) {
      this.states = csc.getStatesOfCountry(id);
      for (let i = 0; i < this.states.length; i++) {
        if (this.store.address.state == this.states[i].name) {
          this.cities = csc.getCitiesOfState(this.states[i].id);
          break;
        }
      }
    }
    
    getUnits() {
      this.storeService.getAllUnits().subscribe((data) => {
        this.units = data;
      });
    }
    
    getStoreByStoreCode() {
      this.storeService.getStoreByCode(this.storeCode).subscribe((data) => {
        this.store = data.stores[0];
        this.previousImageRef = this.store.imageRef;        
        console.log(this.store)
        this.storeType = this.store.type;
        if (!this.store['parentCode']) {
          if (this.store.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "regiondetails",
              alternativeKey: 'regiondetails',
              labelName: 'Region: ' + decodeURIComponent(this.store.name),
              path: decodeURI(this.router.url)
            });
          }
        }
        else {
          if (this.store.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "subregiondetails",
              alternativeKey: 'subregiondetails' + this.storeCode,
              labelName: 'Subregion: ' + decodeURIComponent(this.store.name),
              path: decodeURI(this.router.url)
            });
          }
        }
        
        this.getStoreFieldByTypeCode(this.store.type)
        this.editStoreModel()
        
        this.getParentStoreName();
        
        
      })
    }
    
    editStoreModel() {
      let numberOfCurbsidePickupPoints = '';
      let serviceDistance = ''
      let curbsideSupported: Boolean;
      let address1 = '';
      let address2 = '';
      let city = '';
      let country = '';
      let landmark = '';
      let postalCode = '';
      let latitude = '';
      let longitude = '';
      let state = '';
      let unit = '';
      
      try {
        address1 = this.store['address']['address1'];
        address2 = this.store['address']['address2'];
        city = this.store['address']['city'];
        country = this.store['address']['country'];
        landmark = this.store['address']['landmark'];
        postalCode = this.store['address']['postalCode'];
        latitude = this.store['address']['latitude'];
        longitude = this.store['address']['longitude'];
        state = this.store['address']['state'];
        for (let i = 0; i < this.countries.length; i++) {
          if (this.store.address.country == this.countries[i].name) {
            this.getAllStatesByCountryId(this.countries[i].id);
            break;
          }
        }
      } catch (error) {
        address1 = '';
        address2 = '';
        city = '';
        country = '';
        landmark = '';
        postalCode = '';
        latitude = '';
        longitude = '';
        state = ''
        
      }
      try {
        if (this.store.curbsideTimeslots.length > 0) {
          for (let i = 0; i < this.store['curbsideTimeslots'].length; i++) {
            this.curbsideTimeslots[i] = {
              startTime: '',
              endTime: '',
              maxOrders: ''
            }
            this.curbsideTimeslots[i]['startTime'] = this.store['curbsideTimeslots'][i]['startTime']
            this.curbsideTimeslots[i]['endTime'] = this.store['curbsideTimeslots'][i]['endTime']
            this.curbsideTimeslots[i]['maxOrders'] = this.store['curbsideTimeslots'][i]['maxOrders']
          }
        }
        
      } catch (error) {
        this.curbsideTimeslots = [{
          startTime: '',
          endTime: '',
          maxOrders: ''
        }]
      }
      try {
        curbsideSupported = this.store.curbsideSupported;
        if (this.store.curbsideSupported) {
          this.showCurbDetails = true
        } else {
          this.showCurbDetails = false;
        }
      } catch (error) {
        curbsideSupported = false
      }
      try {
        numberOfCurbsidePickupPoints = this.store.numberOfCurbsidePickupPoints;
      } catch (error) {
        numberOfCurbsidePickupPoints = '';
      }
      
      try {
        serviceDistance = this.store.serviceDistance;
      } catch (error) {
        serviceDistance = '';
      }
      
      try {
        unit = this.store.unit;
      } catch (error) {
        unit = ''
      }
      
      this.editStore = {
        name: this.store.name,
        code: this.store.code,
        description: this.store.description,
        imageRef: this.store.imageRef,
        parentCode: this.store.parentCode,
        type: this.store.type,
        fromDate: this.store.fromDate,
        throughDate: this.store.throughDate,
        createdBy: this.store.createdBy,
        modifiedBy: this.user.name,
        fields: this.store.fields,
        curbsideSupported: curbsideSupported,
        numberOfCurbsidePickupPoints: numberOfCurbsidePickupPoints,
        serviceDistance: serviceDistance,
        curbsideTimeslots: '',
        unit: unit,
        currency: this.store.currency,
        address: {
          address1: address1,
          address2: address2,
          city: city,
          country: country,
          landmark: landmark,
          latitude: latitude,
          longitude: longitude,
          postalCode: postalCode,
          state: state
        }
      }
      
      
    }
    
    
    addMultipleListValue(index) {
      this.multipleArray[index].push(({
        listValue: ''
      }));
      this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
    }
    
    removeListValue(index, k) {
      this.multipleArray[index].splice(k, 1);
      this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
    }
    
    resetForm() {
      
      this.openResetModal("dialogBox");
      
      this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.closeResetModal("dialogBox")
          this.showBulkDetails = false;
          this.bulkAdditionModel();
          this.ngOnInit();
          // this.currentImageRef = '';
          // this.selectedFiles = null;
          this.restrictedFromDate = null;
          this.restrictedThroughDate = null;
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
    openResetModal(id: string) {
      let data = {};
      data = {
        value: 'reset'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeResetModal(id: string) {
      this.deleteDialogService.closeDialog();
    }
    
    
    getAllStoreTypes() {
      this.storeTypes = [];
      this.storeTypeRefService.getAllTypes().subscribe((data) => {
        this.storeTypes = data.storeTypes;
      })
    }
    
    getStoreFieldByTypeCode(typeCode) {
      this.listArray = [];
      this.storeFields = [];
      this.storeTypeFieldRefService.getAllFieldsByTypeCode(typeCode).subscribe((data) => {
        this.storeFields = data.fields;
        this.optionFieldsCondition(this.storeFields);
        if (this.storeFields != null) {
          for (let i = 0; i < this.storeFields.length; i++) {
            this.previousImageRefForField[i] = this.store.fields[this.storeFields[i].key];
            this.multipleArray[i] = new Array<any>()
            this.listArray[i] = new Array<any>()
            
            this.multipleArray[i].push({
              listValue: ''
            })
            if (this.storeFields[i].list) {
              for (const key of Object.keys(this.store.fields)) {
                if (this.storeFields[i].key == key) {
                  if ((this.store.fields[this.storeFields[i].key].length != 0)) {
                    for (let j = 0; j < this.store.fields[this.storeFields[i].key].length; j++) {
                      this.listFlag[i] = true;
                      this.multipleArray[i][j] = ({
                        listValue: this.store.fields[this.storeFields[i].key][j]
                      })
                    }
                  }
                }
              }
            }
            else {
              this.listFlag[i] = false;
              if (this.storeFields[i].type == "BOOLEAN") {
                if (this.store.fields[this.storeFields[i].key] != true) {
                  this.value[i] = false;
                } else {
                  this.value[i] = (this.store.fields[this.storeFields[i].key])
                }
              } else {
                this.value[i] = (this.store.fields[this.storeFields[i].key])
              }
            }
          }
        }
        
      })
    }
    
    
    onCheck(index) {
      this.ck[index] = !this.ck[index];
      this.value[index] = this.ck[index];
      //console.log(this.ck)
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    selectFile(event, isField, indexOfField) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      if (isField) {
        this.selectedFilesForFields[indexOfField] = event.target.files;
        reader.onload = (_event) => {
          this.currentImageRefForField[indexOfField] = reader.result;
        }
      } else {
        this.selectedFiles = event.target.files;
        reader.onload = (_event) => {
          this.currentImageRef = reader.result;
        }
      }
    }
    
    
    resetImage(isField, indexOfField) {
      if (isField) {
        this.selectedFilesForFields[indexOfField] = null;
        this.currentImageRefForField[indexOfField] = '';
        this.previousImageRefForField[indexOfField] = '';
        this.editStore.fields[this.storeFields[indexOfField].key] = '';
        this.store.fields[this.storeFields[indexOfField].key] = ''
        this.value[indexOfField] = ''
      } else {
        this.currentImageRef = '';
        this.selectedFiles = null;
        this.previousImageRef = '';
        this.editStore.imageRef = '';
      }
    }
    
    
    optionFieldsCondition(storeFields) {
      for (let i = 0; i < storeFields.length; i++) {
        if (this.storeFields[i].type == "DOCUMENT") {
          this.documentArray[i] = true;
        } else {
          this.documentArray[i] = false;
        }
        this.selectedFilesForFields[i] = null;
        this.currentImageRefForField[i] = null;
        this.previousImageRefForField[i] = null;
        if (storeFields[i].options) {
          if ((storeFields[i].options.length == 1) || (storeFields[i].options.length == 0)) {
            this.catOptions[i] = false;
          }
          else {
            this.catOptions[i] = true;
          }
        }
        if (storeFields[i].list) {
          this.listFlag[i] = true;
        } else {
          this.listFlag[i] = false;
        }
        //console.log(this.listFlag)
        //console.log(this.catOptions)
        if (storeFields[i].type == "BOOLEAN") {
          this.value[i] = false;
        }
        let type = storeFields[i].type;
        this.convertedTypeField[i] = (environment.typeConversion[type]);
        //console.log(environment.typeConversion[type])
        //console.log(this.convertedTypeField)
        
      }
    }
    
    selectCode(event) {
      this.currentFields = event.target.value;
      //console.log(this.currentFields)
      this.storeTypeFieldRefService.getAllFieldsByTypeCode(this.currentFields).subscribe((data) => {
        this.storeFields = data.fields;
        this.optionFieldsCondition(this.storeFields);
        for (let i = 0; i < this.storeFields.length; i++) {
          this.multipleArray[i] = new Array<any>();
          this.listArray[i] = new Array<any>();
          this.lastIndexOfMultipleArray[i] = new Array<any>();
          this.multipleArray[i].push({
            listValue: ''
          })
          //console.log(this.store.type)
          if (this.store.type == this.currentFields) {
            this.previousImageRefForField[i] = this.store.fields[this.storeFields[i].key];
            if (this.storeFields[i].list) {
              for (const key of Object.keys(this.store.fields)) {
                if (this.storeFields[i].key == key) {
                  if ((this.store.fields[this.storeFields[i].key].length != 0)) {
                    for (let j = 0; j < this.store.fields[this.storeFields[i].key].length; j++) {
                      this.listFlag[i] = true;
                      this.multipleArray[i][j] = ({
                        listValue: this.store.fields[this.storeFields[i].key][j]
                      })
                    }
                  }
                } else {
                  this.multipleArray[i].push({
                    listValue: ''
                  })
                }
              }
            }
            else {
              this.listFlag[i] = false;
              this.value[i] = (this.store.fields[this.storeFields[i].key])
            }
          }
          else {
            this.value[i] = ""
            this.editStore.fields = {}
            this.multipleArray[i].push({
              listValue: ''
            })
            //console.log(this.multipleArray)
          }
          this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
        }
        
      });
    }
    
    validateFromThroughDate() {
      this.dateValidations.validateFromThroughDate(this.editStore)
    }
    
    async onSubmit() {
      this.validateFromThroughDate()
      this.editStore.curbsideTimeslots = this.curbsideTimeslots
      if (!this.editStore.curbsideSupported) {
        delete this.editStore.numberOfCurbsidePickupPoints;
        delete this.editStore.serviceDistance;
        delete this.editStore.serviceDistanceUnit
        delete this.editStore.curbsideTimeslots;
      }
      if (this.storeFields != null) {
        this.fields = {};
        for (let i = 0; i < this.storeFields.length; i++) {
          let fieldName = this.storeFields[i].key;
          if (this.storeFields[i].type == "INTEGER") {
            this.editStore.fields[fieldName] = parseInt(this.value[i])
          } else if (this.storeFields[i].type == "DECIMAL") {
            this.editStore.fields[fieldName] = parseFloat(this.value[i])
          }
          else {
            this.editStore.fields[fieldName] = this.value[i];
          }
          if (this.storeFields[i].list) {
            for (let j = 0; j < this.multipleArray.length; j++) {
              if ((this.multipleArray[j] != null) && (this.multipleArray[j].length > 0)) {
                for (let k = 0; k < this.multipleArray[j].length; k++) {
                  this.listArray[j][k] = (this.multipleArray[j][k].listValue)
                }
              }
            }
            if (this.listArray[i].length > 0) {
              if (this.storeFields[i].type == "INTEGER") {
                this.editStore.fields[this.storeFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
              } else if (this.storeFields[i].type == "DECIMAL") {
                this.editStore.fields[this.storeFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
              } else {
                this.editStore.fields[this.storeFields[i].key] = this.listArray[i]
              }
            }
            else {
              this.editStore.fields[this.storeFields[i].key] = []
            }
          }
        }
      }      

      await this.uploadImageRef();
      if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
        for (let index = 0; index < this.selectedFilesForFields.length; index++) {
          if (this.selectedFilesForFields[index] != null) {
            const file = this.selectedFilesForFields[index].item(0);
            if (this.editStore.fields[this.storeFields[index].key] != undefined) {
              if (this.editStore.fields[this.storeFields[index].key] != "") {
                let data = await this.callServiceToEditImage(file, this.editStore.fields[this.storeFields[index].key]);
                if (index == this.selectedFilesForFields.length - 1) {
                  this.updateStore();
                }
              } else {
                let data = await this.callServiceToUploadImage(file);
                this.editStore.fields[this.storeFields[index].key] = data.documentId;
                if (index == this.selectedFilesForFields.length - 1) {
                  this.updateStore();
                }
              }
            } else {
              let data = await this.callServiceToUploadImage(file);
              this.editStore.fields[this.storeFields[index].key] = data.documentId;
              if (index == this.selectedFilesForFields.length - 1) {
                this.updateStore();
              }
            }
          } else {
            if (index == this.selectedFilesForFields.length - 1) {
              this.updateStore();
            }
          }
        }
      } else {
        this.updateStore();
      }
    }
    
    async uploadImageRef() {
      if (this.selectedFiles != null) {
        const file = this.selectedFiles.item(0);
        if (this.editStore.imageRef != undefined) {
          if((this.editStore.imageRef != "")) {
            let data = await this.callServiceToEditImage(file,this.editStore.imageRef)
          }else {
            let data = await this.callServiceToUploadImage(file);
            this.editStore.imageRef = data.documentId;
          }
        } else {
          let data = await this.callServiceToUploadImage(file);
          this.editStore.imageRef = data.documentId;
        }
      }
    }

    async callServiceToUploadImage(file: any): Promise<any> {
      return this.imageUploadService.uploadImage(file).toPromise();
    }
    
    async callServiceToEditImage(file: any, imageRef: any): Promise<any> {
      return this.imageUploadService.editImage(file, imageRef).toPromise();
    }
    
    getParentStoreName() {
      //console.log(this.store.parentCode)
      if (this.store.parentCode != null) {
        this.storeService.getStoreByCode(this.store.parentCode).subscribe((data) => {
          this.parentStoreName = data.stores[0].name;
        })
      }
    }
    updateStore() {
      this.storeService.updateStoreByCode(this.editStore, this.storeCode).subscribe((data) => {
        if(data['success']) {
          if(this.selectedFiles != null || this.selectedFilesForFields != null) {
            let timestamp = new Date();
            sessionStorage.setItem('timeStamp',JSON.stringify(timestamp));
          }
        }
        if (this.store.parentCode != null) {
          let breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
          if(breadcrumbList[breadcrumbList.length-1].child.length > 1) {
            this.router.navigate(['/subregiondetails/' + this.store.parentCode + '/' + this.parentStoreName + '/subregions/' + this.store.parentCode])
          }else {
            this.router.navigate(['/regiondetails/' + this.store.parentCode + '/' + this.parentStoreName + '/subregions/' + this.store.parentCode])
          }
          this.messageService.broadcast('childSplice',{
            key: 'subregiondetails',
            alternativeKey: "subregiondetails"+this.store.code
          })
        } else {
          this.router.navigate(["/regions"]);
        }
      })
    }
    
    getStateByCountry(event) {
      var selectCountry = event.target
      var countryId = selectCountry.options[selectCountry.selectedIndex].dataset.countryid
      this.states = csc.getStatesOfCountry(countryId);
      this.cities = [];
    }
    
    getCityByState(event) {
      var selectState = event.target;
      var stateId = selectState.options[selectState.selectedIndex].dataset.stateid;
      this.cities = csc.getCitiesOfState(stateId);
    }
    
    addTimeSlot() {
      this.curbsideTimeslots.push({
        startTime: '',
        endTime: '',
        maxOrders: ''
      })
    }
    
    removeTimeSlot(index) {
      this.curbsideTimeslots.splice(index, 1)
    }
    
    showCurbsideDetails() {
      this.showCurbDetails = !this.showCurbDetails
      if (!this.showCurbDetails) {
        this.editStore.numberOfCurbsidePickupPoints = '',
        this.editStore.serviceDistance = '',
        this.curbsideTimeslots = [{
          startTime: '',
          endTime: '',
          maxOrders: ''
        }
      ]
      
    }
  }
  
  openTimeSlotList() {
    this.showBulkDetails = false;
    this.bulkAdditionModel();
    this.addTimeslotsRef = this.modalService.open(this.addTimeSlots, { size: 'xl', windowClass: 'timeslot-modal' });
  }
  
  closeTimeSlotList() {
    this.addTimeslotsRef.dismiss();
  }
  
  addTimeslots() {
    this.editStore.curbsideTimeslots = this.curbsideTimeslots;
    this.closeTimeSlotList();
  }
  
  showBulkSlotDetails() {
    this.showBulkDetails = !this.showBulkDetails;
    this.bulkAdditionModel();
  }
  showBulkDetails: Boolean = false;
  selectedCondition: any;
  
  bulkAdditionModel() {
    this.bulkAddition = {
      startTime: '',
      endTime: '',
      condition: '',
      timeInterval: '',
      maxOrder: ''
    }
  }
  
  addInBulk() {
    var message;
    for(let i=0; i< this.curbsideTimeslots.length;i++) {
      if (this.curbsideTimeslots[i].startTime != "" || this.curbsideTimeslots[i].endTime != "" || this.curbsideTimeslots[i].maxOrders != "") {
        message = 'Your previous timeslots will be overwritten'
      }else {
        message = null;
      }
    }
    this.openWarningModal("dialogBox",message);
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.bulkSlot()
        this.closeWarningModal("dialogBox");
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
        
      } else if (res == "no") {
        this.closeWarningModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
    
  }
  
  bulkSlot() {
    var start = this.bulkAddition.startTime.split(":");
    var end = this.bulkAddition.endTime.split(":");
    if (start[0] == "00") {
      start[0] = "24"
    }
    if (end[0] == "00") {
      end[0] = "24"
    }
    this.bulkAddition.startTime = start[0] + ":" + start[1];
    this.bulkAddition.endTime = end[0] + ":" + end[1];
    let slotConfig;
    if (this.bulkAddition.timeInterval != '') {
      
      var start = this.bulkAddition.startTime.split(":");
      var end = this.bulkAddition.endTime.split(":");
      if (start[0] == "00") {
        start[0] = "24"
      }
      if (end[0] == "00") {
        end[0] = "24"
      }
      this.bulkAddition.startTime = start[0] + ":" + start[1];
      this.bulkAddition.endTime = end[0] + ":" + end[1];
      console.log(this.bulkAddition)
      slotConfig = {
        "configSlotHours": "0",
        "configSlotMinutes": this.bulkAddition.timeInterval,
        "configSlotPreparation": "0",
        "timeArr": [
          { "startTime": this.bulkAddition.startTime, "endTime": this.bulkAddition.endTime },
        ]
      }
    }
    
    var result = this.generateTimeSlotService.createSlots(slotConfig)
    console.log(result)
    for (let i = 0; i < result.length; i++) {
      this.curbsideTimeslots[i] = {
        startTime: '',
        endTime: '',
        maxOrders: ''
      }
      this.curbsideTimeslots[i].startTime = result[i].timeSlotStart;
      this.curbsideTimeslots[i].endTime = result[i].timeSlotEnd;
      this.curbsideTimeslots[i].maxOrders = this.bulkAddition.maxOrder;
    }
    console.log(this.curbsideTimeslots)
    this.showBulkDetails = false;
    this.bulkAdditionModel();
  }
  
  
  
  
  
  
  resetTimeSlots() {
    this.openResetModal("reset");
    this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        console.log(res);
        this.curbsideTimeslots = [];
        console.log(this.store['curbsideTimeslots'].length)
        if (this.store['curbsideTimeslots'].length > 0) {
          for (let i = 0; i < this.store['curbsideTimeslots'].length; i++) {
            this.curbsideTimeslots[i] = {
              startTime: '',
              endTime: '',
              maxOrders: ''
            }
            this.curbsideTimeslots[i]['startTime'] = this.store['curbsideTimeslots'][i]['startTime']
            this.curbsideTimeslots[i]['endTime'] = this.store['curbsideTimeslots'][i]['endTime']
            this.curbsideTimeslots[i]['maxOrders'] = this.store['curbsideTimeslots'][i]['maxOrders']
          }
        } else {
          this.curbSideModel();
        }
        this.closeResetModal("dialogBox");
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();
        
      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }
  
  
  curbSideModel() {
    this.curbsideTimeslots = [
      {
        startTime: '',
        endTime: '',
        maxOrders: ''
      }
    ]
  }
  
  resetBulk() {
    this.bulkAdditionModel();
  }
  bulkAddition: any;
  
  openWarningModal(id: string,message) {
    let data = {};
    data = {
      value: 'Add',
      responseMessage: message
    };
    this.deleteDialogService.openDialog(data);
  }
  
  closeWarningModal(id: string) {
    this.deleteDialogService.closeDialog();
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }
}
