import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { FiltersConstant } from 'src/app/shared/constants/filters-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { StoreService } from '../../../services/store.service';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { CatalogFilters } from 'src/app/shared/constants/filter-model/catalog-filters.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-all-store-catalogs',
  templateUrl: './get-all-store-catalogs.component.html',
  styleUrls: ['./get-all-store-catalogs.component.css']
})
export class GetAllStoreCatalogsComponent implements OnInit {

  catalogs: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  myBucketUrl: any;
  subscribe: Subscription;
  storeCode: any;
  image: Array<any> = [];
  noDataShow: Boolean = false;
  searchName: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  numberOfPages: number = 1;
  filters: any = CatalogFilters;
  types: Array<any> = [];
  pagination: any;
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  // sort: Array<any> =sortConstant.CATALOG_SORT
  // sort:CatalogSort[]=[];

  constructor(private storeService: StoreService,
    private adminService: AdminService,
    private router: Router,
    private modalService: ModalService,
    private deleteDialogService: DeleteDialogService,
    private BackgroundImageService: BackgroundImageService,
    private route: ActivatedRoute,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService,
    public translate:TranslateService) {
    this.filters = {}
    // this.sort.push(new CatalogSort())
    console.log(this.filters)
  }

  ngOnInit() {
    this.route.paramMap.subscribe((map) => {
      this.storeCode = map.get('storecode')
      this.adminService.ngOnInit();
      this.storeService.ngOnInit();
      this.getStore();
      this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
      this.getTypes()

    });

  }

  getStore() {
    this.storeService.getStoreByCode(this.storeCode).subscribe((data) => {
      if (!data['stores'][0]['parentCode']) {
        if (data['stores'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "regiondetails",
            alternativeKey: 'regiondetails',
            labelName: 'Region: ' + data['stores'][0].name,
            path: this.router.url
          });
        }
      } else {
        if (data['stores'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "subregiondetails",
            alternativeKey: 'subregiondetails' + this.storeCode,
            labelName: 'Subregion: ' + data['stores'][0].name,
            path: this.router.url
          });
        }
      }
    });

  }

  getAllCatalogByQuery(filters, itemsPerPage, currentPage, sort) {
    this.catalogs = [];
    this.pagination = null;
    this.allCheck = false;
    this.filters['storeCode'] = this.storeCode;

    this.storeService.getAllCatalogsByQuery(filters, itemsPerPage, currentPage, sort).subscribe((data) => {
      this.catalogs = data.catalogs;
      this.pagination = data.pagination;
      this.totalItems = this.pagination.totalNumberOfRecords;
      this.numberOfPages = this.pagination.numberOfPages;
      this.noDataShow = this.noDataService.noData(this.catalogs);
      this.backgroundImage(this.catalogs)
    })
  }

  backgroundImage(data) {
    if (data.length > 0) {
      this.checkboxItems = []
      for (let i = 0; i < data.length; i++) {
        data[i].imageRef = this.getBackground(data[i].imageRef);
        this.checkboxItems[i] = false;
      }
    }
  }

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.catalogs.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }



  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.catalogs.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }



  getBackground(image) {
    return this.BackgroundImageService.getBackground(image);
  }



  delete() {
    this.openModal1("dialogBox");
    this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        let catalogIds: Array<any> = [];
        for (let i = 0; i < this.catalogs.length; i++) {
          if (this.checkboxItems[i] == true) {
            catalogIds.push(this.catalogs[i].id)
          }
        }
        this.adminService.deleteCatalogById(catalogIds).subscribe((data) => {
          this.closeModal1("dialogBox")
          this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
        })
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal1(id: string) {
    let data = {};
    data = {
      value: 'delete'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  // -------  pagination methods ------ //

  onChangePage(event) {
    this.currentPage = event;
    this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);

  }

  addItemsPerPage(event) {
    this.itemsPerPage = event;
    this.currentPage = 1;
    this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  // ------ search bar method ----------- //

  findName(event) {
    this.searchName = event;
    if (this.searchName != '') {
      this.filters['name'] = this.searchName;
      this.currentPage = 1;
      this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    }
  }
  hideCross(event) {
   delete this.filters['name'];
    this.searchName = '';
    this.currentPage = 1;
    this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  // ------- type method ---------// 


  getTypes() {
    this.adminService.getAllCatalogType().subscribe((data) => {
      this.types = data.catalogTypes;
    })
  }

  onSelectType(event) {
    this.currentPage = 1;
    if (event.target.value != '') {
      this.filters['type'] = event.target.value
      this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    } else {
     delete this.filters['type']
      this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    }
  }

  async onSelectSort(event) {
    this.sort[0] = this.fields
    console.log(this.sort)
    if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
      this.sort[0].sortDirection = await event.target.value
      this.sort[0].fieldName='name'
      console.log(this.sort)
    } else {
      delete this.sort

    }

    this.getAllCatalogByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);

  }


}
