import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/admin/admin.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { BucketUrl } from 'src/app/bucket';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { TranslateService } from '@ngx-translate/core';
import { CasscadeToProductModalService } from 'src/app/shared/casscadetoproduct-modal/casscadetoproduct-modal.service';
const cleanDeep = require('clean-deep');


@Component({
  selector: 'app-edit-category-product-associations',
  templateUrl: './edit-category-product-associations.component.html',
  styleUrls: ['./edit-category-product-associations.component.css']
})
export class EditCategoryProductAssociationsComponent implements OnInit {

  user: any;
  myBucketUrl: any;
  categoryId: any;
  associationId: any;
  associationIndex: any;
  categoryProductAssociations: any;
  categoryProductAssociation: any;
  editCategoryProductAssociation: any;
  selectedProduct: any;
  selectedCategory: any;
  associationFields: Array<any>
  value: Array<any> = []
  catOptions: Array<Boolean> = [];
  fields: any;
  convertedTypeField: Array<string> = [];
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  currentFields: any;
  productAssociationsTypes: Array<any>
  products: Array<any>;
  productFlagArray: Array<any> = [];
  categories: Array<any>;
  availableCategory: Array<any> = [];
  associationProduct: any;
  subscription: Subscription;
  dropdownElements: Array<any> = [];
  currentCategory: any;
  currentImageRef: any;
  previousImageRef: any;
  selectedFiles: FileList;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  bucketUrl: BucketUrl;
  lastIndexOfMultipleArray: Array<any> = [];
  breadCrumbs: any;
  noProducts: boolean = false;
  showFields: boolean;
  selectedDropdownCat: any;
  documentArray: Array<Boolean> = [];
  currentImageRefForField: Array<any> = [];
  previousImageRefForField: Array<any> = [];
  selectedFilesForFields: Array<any> = [];
  cascadeToProduct : boolean = false;
  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;

  constructor(private route: ActivatedRoute,
    private adminService: AdminService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private nestedCategory: NestedCategoryService,
    private noDataService: NoDataService,
    private backgroundImageService: BackgroundImageService,
    private ngbModalService: NgbModal,
    private messageService: MessageSubjectService,
    public translate: TranslateService,
    public casscadeToProductService : CasscadeToProductModalService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.bucketUrl = new BucketUrl();
    this.getCategoryProductAssociation()
  }

  config = {
    hasSelect: false
  }

  resetForm() {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");

        this.ngOnInit();

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  getCategoryProductAssociation() {
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');
      this.associationId = map.get('associationId');
      this.associationIndex = map.get('index');

      this.adminService.getAllCategoryProductAssociations(this.categoryId).subscribe((data) => {
        this.categoryProductAssociations = data.associations;
        this.categoryProductAssociation = this.categoryProductAssociations[this.associationIndex];
        //console.log(this.categoryProductAssociation)
        this.editCategoryProductAssociation = {
          productId: this.categoryProductAssociation.productId,
          type: this.categoryProductAssociation.type,
          modifiedBy: this.categoryProductAssociation.modifiedBy,
          fields: this.categoryProductAssociation.fields
        }
        this.getProductById()
        this.getAllCategory()
        this.getProductAssociationFields()
        this.getAllAssociationTypes()
      });
    });
  }

  getProductById() {
    this.adminService.getProductById(this.editCategoryProductAssociation.productId).subscribe((data) => {
      this.selectedProduct = data.products[0]
      if (this.selectedProduct.name != null) {
        this.messageService.broadcast('bcLabelOverwrite', {
          key: 'editcategoryassociation',
          alternativeKey: 'editcategoryassociation',
          labelName: 'Association: ' + this.selectedProduct.name,
          path: decodeURI(this.router.url)
        })
      }
      this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
        this.selectedCategory = data.categories[0];
        //console.log(this.selectedCategory)
      })
      //console.log(this.selectedProduct)
    })
  }

  getAllAssociationTypes() {
    this.adminService.getAllProductAssociationType().subscribe((data) => {
      this.productAssociationsTypes = data.associationTypes
      console.log(this.productAssociationsTypes)
    });
  }


  getAllCategory() {
    this.categories = [];
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');
      this.adminService.getAllCategory().subscribe((data) => {
        this.categories = data.categories;

        for (let i = 0; i < this.categories.length; i++) {
          if (this.categoryId != this.categories[i].id) {
            this.availableCategory.push(this.categories[i]);
          }
        }

        this.dropdownElements = this.nestedCategory.getProductCategoryTree(this.categories);
      });
    });
    this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
      this.currentCategory = data.categories[0];
    });
  }

  getProductAssociationFields() {
    //console.log(this.categoryProductAssociation.type)
    this.adminService.getAllAssociationFields(this.categoryProductAssociation.type).subscribe((data) => {
      this.associationFields = data.fields;
      this.optionFieldsCondition(this.associationFields);
      for (let i = 0; i < this.associationFields.length; i++) {
        this.previousImageRefForField[i] = this.categoryProductAssociation.fields[this.associationFields[i].key];
        this.multipleArray[i] = new Array<any>()
        this.listArray[i] = new Array<any>()
        this.multipleArray[i].push({
          listValue: ''
        })
        if (this.associationFields != null) {
          if (this.associationFields[i].list) {
            for (const key of Object.keys(this.categoryProductAssociation.fields)) {
              if (this.associationFields[i].key == key) {
                if ((this.categoryProductAssociation.fields[this.associationFields[i].key].length != 0)) {
                  for (let j = 0; j < this.categoryProductAssociation.fields[this.associationFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.categoryProductAssociation.fields[this.associationFields[i].key][j]
                    })
                    // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                  }
                }
              }
            }
          }
          else {
            this.listFlag[i] = false;
            if (this.associationFields[i].type == "BOOLEAN") {
              if (this.categoryProductAssociation.fields[this.associationFields[i].key] != true) {
                this.value[i] = false;
              } else {
                this.value[i] = (this.categoryProductAssociation.fields[this.associationFields[i].key])
              }
            } else {
              this.value[i] = (this.categoryProductAssociation.fields[this.associationFields[i].key])
            }
            //console.log(this.value[i])
          }
        }
      }

    });
  }

  optionFieldsCondition(associationFields) {
    for (let i = 0; i < associationFields.length; i++) {
      if (this.associationFields[i].type == "DOCUMENT") {
        this.documentArray[i] = true;
      } else {
        this.documentArray[i] = false;
      }
      this.selectedFilesForFields[i] = null;
      this.currentImageRefForField[i] = null;
      this.previousImageRefForField[i] = null;
      if (associationFields[i].options) {
        if ((associationFields[i].options.length == 1) || (associationFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      if (associationFields[i].list) {
        this.listFlag[i] = true;
      } else {
        this.listFlag[i] = false;
      }
      //console.log(this.catOptions)
      if (associationFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = associationFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
      //console.log(environment.typeConversion[type])
      //console.log(this.convertedTypeField)
    }
  }
  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRefForField[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.selectedFilesForFields[indexOfField] = null;
      this.currentImageRefForField[indexOfField] = '';
      this.previousImageRefForField[indexOfField] = '';
      this.editCategoryProductAssociation.fields[this.associationFields[indexOfField].key] = '';
      this.categoryProductAssociation.fields[this.associationFields[indexOfField].key] = ''
      this.value[indexOfField] = ''
    } else {
      this.currentImageRef = '';
      this.selectedFiles = null;
      this.previousImageRef = '';
      this.editCategoryProductAssociation.imageRef = '';
    }
  }

  selectCode(event) {
    this.currentFields = event.target.value;
    this.adminService.getAllAssociationFields(this.currentFields).subscribe((data) => {
      this.associationFields = data.fields;
      //console.log(this.associationFields)
      this.optionFieldsCondition(this.associationFields);
      for (let i = 0; i < this.associationFields.length; i++) {
        this.multipleArray[i] = new Array<any>();
        this.listArray[i] = new Array<any>();
        this.lastIndexOfMultipleArray[i] = new Array<any>();
        this.multipleArray[i].push({
          listValue: ''
        })
        if (this.categoryProductAssociation.type == this.currentFields) {
          this.previousImageRefForField[i] = this.categoryProductAssociation.fields[this.associationFields[i].key];
          if (this.associationFields[i].list) {
            for (const key of Object.keys(this.categoryProductAssociation.fields)) {
              if (this.associationFields[i].key == key) {
                if ((this.categoryProductAssociation.fields[this.associationFields[i].key].length != 0)) {
                  for (let j = 0; j < this.categoryProductAssociation.fields[this.associationFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.categoryProductAssociation.fields[this.associationFields[i].key][j]
                    })
                    // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                  }
                }
              }
            }
          }
          else {
            this.value[i] = (this.categoryProductAssociation.fields[this.associationFields[i].key])
            this.listFlag[i] = false;
          }
        }
        else {
          this.value[i] = ""
          this.editCategoryProductAssociation.fields = {}
          this.multipleArray[i].push({
            listValue: ''
          })
        }
        this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
      }
    });
    this.showFields = true;
  }


  onSelectCategory(event) {
    this.selectedDropdownCat = null
    this.selectedDropdownCat = event.id;
    this.adminService.getAllCategoryProduct(this.selectedDropdownCat).subscribe((data) => {
      this.products = data.products;
      this.noProducts = this.noDataService.noData(this.products);
      for (let i = 0; i < this.products.length; i++) {
        this.productFlagArray[i] = false;
        if (this.associationProduct != null) {
          if (this.selectedProduct.id.includes(this.products[i].id)) {
            this.productFlagArray[i] = true;
            //console.log(this.productFlagArray)
          }
          else {
            this.productFlagArray[i] = false;
          }
        }
      }
    });
  }


  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  addAssociationProduct(selectedProduct, id) {
    this.associationProduct = selectedProduct;
    //console.log(selectedProduct)
    //console.log(this.associationProduct)
    this.editCategoryProductAssociation.productId = this.associationProduct.id;
    this.adminService.getProductById(this.associationProduct.id).subscribe((data) => {
      this.selectedProduct = data.products[0];
      this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
        this.selectedCategory = data.categories[0];
      });
    })
    //console.log(this.editCategoryProductAssociation.productId)
    for (let i = 0; i < this.products.length; i++) {
      this.productFlagArray[i] = false;
      if (this.products[i].id == this.editCategoryProductAssociation.productId) {
        this.productFlagArray[i] = true;
      }
      this.closeNgbModal(id);
    }
    // this.getProductById()
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image)
  }



  async onSubmit() {
    if (this.associationFields != null) {
      this.fields = {};
      for (let i = 0; i < this.associationFields.length; i++) {
        let fieldName = this.associationFields[i].key;
        if (this.associationFields[i].type == "INTEGER") {
          this.editCategoryProductAssociation.fields[fieldName] = parseInt(this.value[i])
        } else if (this.associationFields[i].type == "DECIMAL") {
          this.editCategoryProductAssociation.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editCategoryProductAssociation.fields[fieldName] = this.value[i];
        }
        if (this.associationFields[i].list) {
          //console.log(this.multipleArray.length)
          for (let j = 0; j < this.multipleArray.length; j++) {
            //console.log(this.multipleArray)
            //console.log(this.multipleArray[j])
            if ((this.multipleArray[j] != null) && (this.multipleArray[j].length > 0)) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                //console.log(this.multipleArray[j].length)
                //console.log( this.editCategoryProductAssociation.fields)
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.associationFields[i].type == "INTEGER") {
              this.editCategoryProductAssociation.fields[this.associationFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.associationFields[i].type == "DECIMAL") {
              this.editCategoryProductAssociation.fields[this.associationFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.editCategoryProductAssociation.fields[this.associationFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.editCategoryProductAssociation.fields[this.associationFields[i].key] = []
          }
        }
      }
    }

    await this.uploadImageRef();

    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          if (this.editCategoryProductAssociation.fields[this.associationFields[index].key] != undefined) {
            if (this.editCategoryProductAssociation.fields[this.associationFields[index].key] != "") {
              let data = await this.callServiceToEditImage(file, this.editCategoryProductAssociation.fields[this.associationFields[index].key]);
              if (index == this.selectedFilesForFields.length - 1) {
                this.editCategoryAssociationServiceCall();
              }
            } else {
              let data = await this.callServiceToUploadImage(file);
              this.editCategoryProductAssociation.fields[this.associationFields[index].key] = data.documentId;
              if (index == this.selectedFilesForFields.length - 1) {
                this.editCategoryAssociationServiceCall();
              }
            }
          } else {
            let data = await this.callServiceToUploadImage(file);
            this.editCategoryProductAssociation.fields[this.associationFields[index].key] = data.documentId;
            if (index == this.selectedFilesForFields.length - 1) {
              this.editCategoryAssociationServiceCall();
            }
          }
        } else {
          if (index == this.selectedFilesForFields.length - 1) {
            this.editCategoryAssociationServiceCall();
          }
        }
      }
    } else {
      this.editCategoryAssociationServiceCall();
    }


  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.editCategoryProductAssociation.imageRef != undefined) {
        if ((this.editCategoryProductAssociation.imageRef != "")) {
          let data = await this.callServiceToEditImage(file, this.editCategoryProductAssociation.imageRef)
        } else {
          let data = await this.callServiceToUploadImage(file);
          this.editCategoryProductAssociation.imageRef = data.documentId;
        }
      } else {
        let data = await this.callServiceToUploadImage(file);
        this.editCategoryProductAssociation.imageRef = data.documentId;
      }
    } else {

    }
  }

  async callServiceToEditImage(file: any, imageRef: any): Promise<any> {
    return this.imageUploadService.editImage(file, imageRef).toPromise();
  }

  async callServiceToUploadImage(file: any): Promise<any> {
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  editCategoryAssociationServiceCall() {
    let editProductAssociation = this.editCategoryProductAssociation
    editProductAssociation.fields = cleanDeep(editProductAssociation.fields)
    this.adminService.updateCategoryAssociation(this.categoryId, this.associationId, editProductAssociation,this.cascadeToProduct).subscribe((data) => {
      if (data['success']) {
        if (this.selectedFiles != null || this.selectedFilesForFields != null) {
          let timestamp = new Date();
          sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
        }
      }
      if (!this.currentCategory['parentId']) {
        this.router.navigate(['/categorydetails/' + this.categoryId + '/' + this.currentCategory.name + '/categoryassociations/' + this.categoryId])
      } else {
        this.messageService.broadcast('splice', {
          key: 'editcategoryassociation',
          alternativeKey: 'editcategoryassociation'
        })
        this.router.navigate(['/subcategorydetails/' + this.categoryId + '/' + this.currentCategory.name + '/categoryassociations/' + this.categoryId])

      }
    });
  }

  openModal1(id: string) {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //console.log(event.target)
  }
  openNgbModal(content) {
    this.products = [];
    this.selectedDropdownCat = null;
    this.ngbModalService.open(content, { size: 'lg', windowClass: 'add-product' });

  }

  closeNgbModal(content) {
    this.ngbModalService.dismissAll(content);
    this.products = [];
    this.selectedDropdownCat = null;
    this.selectedCategory = null;
  }

  onClickCascade(){
    this.openModal2("dialogBox");
    this.subscription = this.casscadeToProductService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.cascadeToProduct=true
        this.onSubmit()
        this.closeModal2("dialogBox");
        this.subscription.unsubscribe();
        this.casscadeToProductService.clearResponse();
      } else if (res == "no") {
        this.cascadeToProduct=false
        this.onSubmit()
        this.closeModal2("dialogBox")
        this.subscription.unsubscribe();
        this.casscadeToProductService.clearResponse();
      }
    })
  }

  closeModal2(id:string){
    this.casscadeToProductService.closeDialog()
  }

  openModal2(id: string){
    let data={}
    data ={
      value : 'casscade'
    }
    this.casscadeToProductService.openDialog(data);
  }
}

