import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssociationTypeRefServiceService {

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;

  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }


  // serverRootUrl = environment.BASE_URL;
  serverRootUrl=environment.PRODUCT_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  productAssociationTypeRootUrl = this.serverRootUrl + "/api/v1/productassociationtypes/";


  createType(associationType): Observable<any> {
    let request = {};
    request['associationType'] = associationType
    return this.http.post<any>(this.productAssociationTypeRootUrl, request, this.getHeaderTenant())
  }

  getAllTypes():Observable<any> {
    return this.http.get<any>(this.productAssociationTypeRootUrl, this.getHeaderTenant());
  }

  
  deleteTypeByTypeCodes(associationTypeCode) {
    return this.http.delete<any>(this.productAssociationTypeRootUrl+"?productAssociationTypeCodes="+associationTypeCode, this.getHeaderTenant())
  }

  updateTypeByTypeCode(associationType, associationTypeCode) {
    let request = {};
    request ['associationType'] = associationType
    return this.http.put<any>(this.productAssociationTypeRootUrl+associationTypeCode, request, this.getHeaderTenant());
  }
}
