import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/admin/admin.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { BucketUrl } from 'src/app/bucket';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { TranslateService } from '@ngx-translate/core';
const cleanDeep = require('clean-deep');

@Component({
  selector: 'app-edit-product-association',
  templateUrl: './edit-product-association.component.html',
  styleUrls: ['./edit-product-association.component.css']
})
export class EditProductAssociationComponent implements OnInit {


  productId: any;
  productAssociations: any[];
  productAssociation: any;
  associationIndex: any;
  editProductAssociation: any;
  user: any;
  myBucketUrl: any;
  selectedProduct: any;
  associationProductId: any;
  selectCategory: any;
  products: any[];
  productFlagArray: Array<any> = [];
  productIds: Array<any> = [];
  availableProductsIds: any[];
  availableProducts: any[];
  productAssociationsTypes: any[];
  categories: any[];
  associationFields: any;
  catOptions: Array<Boolean> = [];
  fields: any;
  convertedTypeField: Array<string> = [];
  value: Array<any> = [];
  currentFields: any;
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  subscription: Subscription;
  dropdownElements: Array<any> = [];
  productName: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  bucketUrl: BucketUrl;
  lastIndexOfMultipleArray: Array<any> = [];
  breadCrumbs: any;
  categoryId: any;
  noProducts: Boolean = false;
  currentImageRef: any;
  selectedFiles: FileList;
  previousImageRef: any;
  documentArray: Array<Boolean> = [];
  currentImageRefForField: Array<any> = [];
  previousImageRefForField: Array<any> = [];
  selectedFilesForFields: Array<any> = [];
  disableAll:Boolean= false
  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;



  constructor(private route: ActivatedRoute,
    private adminService: AdminService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private router: Router,
    public imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private nestedCategory: NestedCategoryService,
    private backgroundImageService: BackgroundImageService,
    private ngbModalService: NgbModal,
    private messageService: MessageSubjectService,
    private noDataService: NoDataService,
    public translate: TranslateService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((map) => {
      this.productId = map.get('productId');

      this.associationIndex = map.get('associationIndex')

      this.categoryId = null;
      this.user = JSON.parse(sessionStorage.getItem("user"));
      this.myBucketUrl = environment.myBucketUrl;

      this.getProductAssociation();
      this.getAllAssociationTypes();
      this.getAllCategory();
    });

  }




  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  resetForm() {

    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");

        this.selectedProduct = this.productAssociation.productId;
        for (let i = 0; i < this.productFlagArray.length; i++) {
          this.productFlagArray[i] = false;
        }
        this.ngOnInit();
        this.getProductAssociationFields();

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openModal1(id: string) {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }



  getProductAssociation() {


    this.getProductName();

    this.adminService.getProductAssociationsByType(this.productId).subscribe((data) => {
      this.productAssociations = data.associations;
      this.productAssociation = this.productAssociations[this.associationIndex];
      console.log(this.productAssociation)
      //console.log(this.productAssociation)

      this.editProductAssociation = {
        productId: this.productAssociation.productId,
        type: this.productAssociation.type,
        modifiedBy: this.productAssociation.modifiedBy,
        fields: this.productAssociation.fields
      }

      this.getProductById();
      this.getProductAssociationFields()

    });

  }

  getProductName() {
    this.adminService.getProductById(this.productId).subscribe((data) => {
      this.productName = data.products[0].name;
      if(environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant) && data.products[0].workflowState.history.length != 0){
        if (data.products[0].workflowState.status == 'REVIEW') {
          this.disableAll = true
        }
      }
    })
  }

  getProductAssociationFields() {
    //console.log(this.productAssociation.type)
    this.adminService.getAllAssociationFields(this.productAssociation.type).subscribe((data) => {
      this.associationFields = data.fields;
      this.optionFieldsCondition(this.associationFields);
      for (let i = 0; i < this.associationFields.length; i++) {
        this.previousImageRefForField[i] = this.productAssociation.fields[this.associationFields[i].key];
        this.multipleArray[i] = new Array<any>()
        this.listArray[i] = new Array<any>()
        this.multipleArray[i].push({
          listValue: ''
        })
        if (this.associationFields != null) {
          if (this.associationFields[i].list) {
            for (const key of Object.keys(this.productAssociation.fields)) {
              if (this.associationFields[i].key == key) {
                if ((this.productAssociation.fields[this.associationFields[i].key].length != 0)) {
                  for (let j = 0; j < this.productAssociation.fields[this.associationFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.productAssociation.fields[this.associationFields[i].key][j]
                    })
                    // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                  }
                }
              }
            }
          }

          else {
            this.listFlag[i] = false;
            if (this.associationFields[i].type == "BOOLEAN") {
              if (this.productAssociation.fields[this.associationFields[i].key] != true) {
                this.value[i] = false;
              } else {
                this.value[i] = (this.productAssociation.fields[this.associationFields[i].key])
              }
            } else {
              this.value[i] = (this.productAssociation.fields[this.associationFields[i].key])
            }
          }
        }
      }
    });
  }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRefForField[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.selectedFilesForFields[indexOfField] = null;
      this.currentImageRefForField[indexOfField] = '';
      this.previousImageRefForField[indexOfField] = '';
      this.editProductAssociation.fields[this.associationFields[indexOfField].key] = '';
      this.productAssociation.fields[this.associationFields[indexOfField].key] = ''
      this.value[indexOfField] = ''
    } else {
      this.currentImageRef = '';
      this.selectedFiles = null;
      this.previousImageRef = '';
      this.editProductAssociation.imageRef = '';
    }
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  selectCode(event) {
    this.currentFields = event.target.value;
    this.adminService.getAllAssociationFields(this.currentFields).subscribe((data) => {
      this.associationFields = data.fields;
      //console.log(this.associationFields)
      this.optionFieldsCondition(this.associationFields);
      for (let i = 0; i < this.associationFields.length; i++) {
        this.multipleArray[i] = new Array<any>();
        this.listArray[i] = new Array<any>();
        this.lastIndexOfMultipleArray[i] = new Array<any>();
        this.multipleArray[i].push({
          listValue: ''
        })
        if (this.productAssociation.type == this.currentFields) {
          this.previousImageRefForField[i] = this.productAssociation.fields[this.associationFields[i].key];
          if (this.associationFields[i].list) {
            for (const key of Object.keys(this.productAssociation.fields)) {
              if (this.associationFields[i].key == key) {
                if ((this.productAssociation.fields[this.associationFields[i].key].length != 0)) {
                  for (let j = 0; j < this.productAssociation.fields[this.associationFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.productAssociation.fields[this.associationFields[i].key][j]
                    })
                  }
                }
              }
            }
          }
          else {
            this.listFlag[i] = false;
            this.value[i] = (this.productAssociation.fields[this.associationFields[i].key])
          }
        }
        else {
          this.value[i] = ""
          this.editProductAssociation.fields = {}
          this.multipleArray[i].push({
            listValue: ''
          })
        }
        this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
      }
    });

  }

  optionFieldsCondition(associationFields) {
    for (let i = 0; i < associationFields.length; i++) {

      if (this.associationFields[i].type == "DOCUMENT") {
        this.documentArray[i] = true;
      } else {
        this.documentArray[i] = false;
      }
      this.selectedFilesForFields[i] = null;
      this.currentImageRefForField[i] = null;
      this.previousImageRefForField[i] = null;
      if (associationFields[i].options) {
        if ((associationFields[i].options.length == 1) || (associationFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      //console.log(this.catOptions)
      if (associationFields[i].list) {
        this.listFlag[i] = true;
      } else {
        this.listFlag[i] = false;
      }
      if (associationFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = associationFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
      //console.log(environment.typeConversion[type])
      //console.log(this.convertedTypeField)

    }
  }

  getAllAssociationTypes() {
    this.adminService.getAllProductAssociationType().subscribe((data) => {
      this.productAssociationsTypes = data.associationTypes
      //console.log(this.productAssociationsTypes)
    });
  }

  onSelectCategory(event) {
    this.categoryId = []
    this.categoryId = event.id;
    this.adminService.getAllCategoryProduct(this.categoryId).subscribe((data) => {
      this.products = data.products;
      this.noProducts = this.noDataService.noData(this.products);
      for (let i = 0; i < this.products.length; i++) {
        this.productFlagArray[i] = false;
        this.productIds.push(this.products[i].id)
        if (this.selectedProduct != null) {
          if (this.selectedProduct.id.includes(this.products[i].id)) {
            this.productFlagArray[i] = true;
            //console.log(this.productFlagArray)
          }
          else {
            this.productFlagArray[i] = false;
          }
        }
      }

      this.availableProductsIds = this.removeCurrentProduct(this.productIds, this.productId);
      this.adminService.getProductById(this.availableProductsIds).subscribe((data) => {
        this.availableProducts = data.products;

      });
    });

  }

  getAllCategory() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;
      this.dropdownElements = this.nestedCategory.getProductCategoryTree(this.categories);
    });
  }

  getProductById() {
    let editProductAssociation = this.editProductAssociation
    console.log(editProductAssociation, 'servicemethod')
    let categoryFields = cleanDeep(editProductAssociation.fields)
    console.log(categoryFields, 'servicemethod')
    editProductAssociation.fields = categoryFields
    console.log(editProductAssociation)
    this.adminService.getProductById(this.editProductAssociation.productId).subscribe((data) => {
      this.selectedProduct = data.products[0]
     
      if (this.selectedProduct.name != null) {
        this.messageService.broadcast('bcLabelOverwrite', {
          key: 'editproductassociation',
          alternativeKey: 'editproductassociation',
          labelName: 'Association: ' + this.selectedProduct.name,
          path: decodeURI(this.router.url)
        })
      }
      this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
        this.selectCategory = data.categories[0];
        //console.log(this.selectCategory)
      })
      //console.log(this.selectedProduct)
    })
  }


  removeCurrentProduct(products, productId) {
    return products.filter((ele) => {
      return ele != productId
    });
  }


  addAssociationProduct(selectProductId, id) {
    this.associationProductId = selectProductId
    this.adminService.getProductById(this.associationProductId).subscribe((data) => {
      this.selectedProduct = data.products[0];
      this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
        this.selectCategory = data.categories[0];
        this.closeNgbModal(id);
        // window.location.reload();
      })
    })

    this.editProductAssociation.productId = this.associationProductId
    for (let i = 0; i < this.products.length; i++) {
      this.productFlagArray[i] = false;
      if (this.products[i].id == this.editProductAssociation.productId) {
        this.productFlagArray[i] = true;
      }
    }
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }

  async callServiceToEditImage(file: any, imageRef: any): Promise<any> {
    return this.imageUploadService.editImage(file, imageRef).toPromise();
  }

  async callServiceToUploadImage(file: any): Promise<any> {
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  async onSubmit() {
    if (this.associationFields != null) {
      this.fields = {};
      for (let i = 0; i < this.associationFields.length; i++) {
        let fieldName = this.associationFields[i].key;
        if (this.associationFields[i].type == "INTEGER") {
          this.editProductAssociation.fields[fieldName] = parseInt(this.value[i])
        } else if (this.associationFields[i].type == "DECIMAL") {
          this.editProductAssociation.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editProductAssociation.fields[fieldName] = this.value[i];
        }
        if (this.associationFields[i].list) {
          //console.log(this.multipleArray.length)
          for (let j = 0; j < this.multipleArray.length; j++) {
            //console.log(this.multipleArray)
            //console.log(this.multipleArray[j])
            if ((this.multipleArray[j] != null) && (this.multipleArray[j].length > 0)) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                //console.log(this.multipleArray[j].length)
                //console.log( this.editProductAssociation.fields)
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.associationFields[i].type == "INTEGER") {
              this.editProductAssociation.fields[this.associationFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.associationFields[i].type == "DECIMAL") {
              this.editProductAssociation.fields[this.associationFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.editProductAssociation.fields[this.associationFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.editProductAssociation.fields[this.associationFields[i].key] = []
          }
        }
      }
    }

    await this.uploadImageRef();

    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          if (this.editProductAssociation.fields[this.associationFields[index].key] != undefined) {
            if (this.editProductAssociation.fields[this.associationFields[index].key] != "") {
              let data = await this.callServiceToEditImage(file, this.editProductAssociation.fields[this.associationFields[index].key]);
              if (index == this.selectedFilesForFields.length - 1) {
                this.editProductAssociationServiceCall();
              }
            } else {
              let data = await this.callServiceToUploadImage(file);
              this.editProductAssociation.fields[this.associationFields[index].key] = data.documentId;
              if (index == this.selectedFilesForFields.length - 1) {
                this.editProductAssociationServiceCall();
              }
            }
          } else {
            let data = await this.callServiceToUploadImage(file);
            this.editProductAssociation.fields[this.associationFields[index].key] = data.documentId;
            if (index == this.selectedFilesForFields.length - 1) {
              this.editProductAssociationServiceCall();
            }
          }
        } else {
          if (index == this.selectedFilesForFields.length - 1) {
            this.editProductAssociationServiceCall();
          }
        }
      }
    } else {
      this.editProductAssociationServiceCall();
    }

  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.editProductAssociation.imageRef != undefined) {
        if ((this.editProductAssociation.imageRef != "")) {
          let data = await this.callServiceToEditImage(file, this.editProductAssociation.imageRef)
        } else {
          let data = await this.callServiceToUploadImage(file);
          this.editProductAssociation.imageRef = data.documentId;
        }
      } else {
        let data = await this.callServiceToUploadImage(file);
        this.editProductAssociation.imageRef = data.documentId;
      }
    } else {

    }
  }



  editProductAssociationServiceCall() {
    let editProductAssociation = this.editProductAssociation
    editProductAssociation.fields = cleanDeep(editProductAssociation.fields)
    this.adminService.updateProductAssociation(this.productId, this.productAssociation.id,editProductAssociation).subscribe((data) => {
      if (data['success']) {
        if (this.selectedFiles != null || this.selectedFilesForFields != null) {
          let timestamp = new Date();
          sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
        }
      }
      this.router.navigate(["/productdetails/" + this.productId + '/' + this.productName + "/productassociation/" + this.productId]);
    });
  }

  openNgbModal(content) {
    this.products = [];
    this.categoryId = null
    this.ngbModalService.open(content, { size: 'lg', windowClass: 'add-product' });
  }

  closeNgbModal(content) {
    this.products = []
    this.ngbModalService.dismissAll(content)
  }


  config = {
    hasSelect: false
  }

}
