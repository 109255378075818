import { Component, OnInit, Inject } from '@angular/core';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DeleteDialogService } from './delete-dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {


  value: any;
  status: any;
  responseMessage: any;
  title = 'Angular-Interceptor';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
  private deleteDialogService: DeleteDialogService){
    console.log(data);
  this.value = data;
  this.status = data;
  if(data['responseMessage'] != null) {
    this.responseMessage = data['responseMessage'];
  }
  console.log(this.responseMessage)
}

 ngOnInit(){
  
  }
  

  onClickYes(){
    this.deleteDialogService.getResponseComponentMethod("yes");
  }

  onClickNo(){
    this.deleteDialogService.getResponseComponentMethod("no");
  }

  closeModal(id: string) {
    // this.modalService.close(id);
    this.deleteDialogService.closeDialog();
  }
}
