import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { TypeRefServiceService } from '../../api-configuration-services/type-services/type-ref-service/type-ref-service.service';
import { CategoryTypeRefServiceService } from '../../api-configuration-services/type-services/category-type-ref-service/category-type-ref-service.service';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageSubjectService } from 'src/app/services/message-subject.service';

@Component({
	selector: 'app-type-ref-component',
	templateUrl: './type-ref-component.component.html',
	styleUrls: ['./type-ref-component.component.css']
})
export class TypeRefComponentComponent implements OnInit {

	types: any[];
	itemCheck: boolean = false;
	allCheck: boolean = false;
	checkboxItems: Array<boolean> = [];
	myValue: boolean = false;
	flag: boolean = false;
	user: any;
	typeRefService: any;
	typeName: any;
	configureFieldLink: string;
	subscription: Subscription;
	typeForm: any;
	currentType: any;
	editTypeForm: any;

	codes: Array<any> = [];
	codeInvalid: boolean = false;
	editModelRef: any;
	addModelRef: any;
	showNoData: Boolean = false;


	@ViewChild('editType', { static: true }) public editType: ElementRef;
	@ViewChild('addType', { static: true }) public addType: ElementRef;

	constructor(public formBuilder: FormBuilder,
		public http: HttpClient,
		public deleteDialogService: DeleteDialogService,
		public modalService: NgbModal,
		public autoCodeGenerator: AutoNameCodeGeneratorService,
		public messageService: MessageSubjectService) {

		this.user = JSON.parse(sessionStorage.getItem('user'));
	}
	ngOnInit() {
	}


	getAllType() {
		this.typeRefService.getAllTypes().subscribe((data) => {
			this.types = data.types;
			for (let i = 0; i < this.types.length; i++) {
				this.checkboxItems[i] = false;
			}
		});
	}


	checkCode(event) {
		let value: any;
		value = event.target.value;
		if (this.codes.includes(value)) {
			this.codeInvalid = true;
		} else {
			this.codeInvalid = false;
		}
		this.typeForm.code = this.autoCodeGenerator.generateCode(value);
	}

	openAddModal(id) {
		this.typeFormModel()
		this.addModelRef = this.modalService.open(this.addType, { size: 'lg', windowClass: 'editType-modal', scrollable: true });
	}

	typeFormModel() {
		if (this.typeName == "feature") {
			this.typeForm = {
				name: '',
				code: '',
				hasOption: false,
				description: '',
				createdBy: this.user.name,
				// modifiedBy: this.user.name
			}
		} else {
			this.typeForm = {
				name: '',
				code: '',
				description: '',
				createdBy: this.user.name,
				// modifiedBy: this.user.name
			}
		}
	}

	newSubmit() {
		console.log("hello")
	}



	async onSubmit() {
		// try {
		console.log(this.typeForm)
		await this.typeRefService.createType(this.typeForm).subscribe(async (data) => {
			await this.closeAddTypeModal();
			this.getAllType()
		});
		// } catch (error) {
		// 	this.closeAddTypeModal();
		// }
	}

	openEditModel(id, type) {
		this.currentType = type;
		console.log(this.currentType)
		this.editModelRef = this.modalService.open(this.editType, { size: 'lg', windowClass: 'editType-modal', scrollable: true });
		this.editTypeModel();

	}

	closeEditTypeModal() {
		this.editModelRef.dismiss();
	}

	closeAddTypeModal() {
		this.addModelRef.dismiss();
	}

	editTypeModel() {
		if (this.typeName == "feature") {
			var hasOption = false;
			try {
				console.log(this.currentType.hasOption)
				hasOption = this.currentType.hasOption
				console.log("hasOption", hasOption)
			} catch (error) {
				hasOption = false
			}
			this.editTypeForm = {
				name: this.currentType.name,
				code: this.currentType.code,
				hasOption: hasOption,
				description: this.currentType.description,
				// createdBy: this.currentType.createdBy,
				modifiedBy: this.user.name
			}
		} else {
			this.editTypeForm = {
				name: this.currentType.name,
				code: this.currentType.code,
				description: this.currentType.description,
				// createdBy: this.currentType.createdBy,
				modifiedBy: this.user.name
			}
		}
		console.log(this.editTypeForm)
	}

	onEdit(id) {
		console.log(this.editTypeForm)
		this.typeRefService.updateTypeByTypeCode(this.editTypeForm, this.currentType.code).subscribe((data) => {
			this.closeEditTypeModal();
			this.getAllType()
		});
	}

	checkAll() {
		this.allCheck = !this.allCheck;
		for (let a = 0; a < this.types.length; a++) {
			this.checkboxItems[a] = this.allCheck;
		}
	}

	onChange(i: number) {
		this.checkboxItems[i] = !this.checkboxItems[i];
		this.myValue = true;
		for (let a = 0; a < this.types.length; a++) {
			if (this.checkboxItems[a] == false) {
				this.myValue = false;
			}
		}
		this.allCheck = this.myValue;
	}

	delete() {
		this.openDeleteModal("dialogBox");
		this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
			if (res == "yes") {
				let typeIds = []
				for (let i = 0; i < this.types.length; i++) {
					if (this.checkboxItems[i] == true) {
						typeIds.push(this.types[i].code)
					}
				}
				this.typeRefService.deleteTypeByTypeCodes(typeIds).subscribe((data) => {
					this.getAllType();
					this.allCheck = false
				})
				this.closeDeleteModal("dialogBox");
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();

			} else if (res == "no") {
				this.closeDeleteModal("dialogBox")
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();
			}
		})
	}


	// deleteSingle(id) {

	// 	this.openDeleteModal("dialogBox");
	// 	this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
	// 		if (res == "yes") {

	// 			this.typeRefService.deleteTypeByTypeCodes(id).subscribe((data) => {
	// 				this.closeDeleteModal("dialogBox");
	// 				this.getAllType();
	// 				this.subscription.unsubscribe();
	// 				this.deleteDialogService.clearResponse();
	// 			})
	// 		} else if (res == "no") {
	// 			this.closeDeleteModal("dialogBox")
	// 			this.subscription.unsubscribe();
	// 			this.deleteDialogService.clearResponse();
	// 		}
	// 	})
	// }

	openDeleteModal(id: string) {
		let data = {};
		data = {
			value: 'delete'
		};
		this.deleteDialogService.openDialog(data);
	}

	closeDeleteModal(id: string) {
		this.deleteDialogService.closeDialog();
	}

	setUp(labelName) {

		this.messageService.broadcast('bcLabelNew', {
			key: this.configureFieldLink,
			alternativeKey: this.configureFieldLink,
			labelName: this.typeName + ' Fields: ' + labelName,
			path: '/' + this.configureFieldLink + '/' + labelName,
			child: []
		})
	}

	resetAddForm() {
		this.openResetModal("reset");
		this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
			if (res == "yes") {
				this.typeFormModel()
				this.closeResetModal("dialogBox");

				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();

			} else if (res == "no") {
				this.closeResetModal("dialogBox")
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();
			}
		});
	}

	resetEditForm() {
		this.openResetModal("reset");
		this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
			if (res == "yes") {
				this.editTypeModel()
				this.closeResetModal("dialogBox");

				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();

			} else if (res == "no") {
				this.closeResetModal("dialogBox")
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();
			}
		});
	}

	openResetModal(id) {
		let data = {};
		data = {
			value: 'reset'
		};
		this.deleteDialogService.openDialog(data);
	}
	closeResetModal(id) {
		this.deleteDialogService.closeDialog();
	}

	onHasOption(type) {
		console.log("this.typeForm.hasOption1", this.typeForm.hasOption)
		if (type == "ADD") {
			this.typeForm.hasOption = !(this.typeForm.hasOption)
			console.log("this.typeForm.hasOption", this.typeForm.hasOption)
		} else if (type == "EDIT") {
			console.log(this.editTypeForm.hasOption)
			this.editTypeForm.hasOption = !this.editTypeForm.hasOption
			console.log(this.editTypeForm.hasOption)
		}
	}
}
