import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class TierService {
  user: any;
  tenant: any;
  headers: Headers;
  editedTier: {};
  SERVER_ROOT_URL = environment.TIER_BASE_URL;
  // tierRootUrl = this.serverRootUrl + "/loyaltyprofile/api/v1/loyaltyprofiles/tiers/"
  // tierRootUrl = this.serverRootUrl + "/loyaltytier/api/v1/loyaltytiers/"
  tierRootUrl = this.SERVER_ROOT_URL + "/api/v1/loyaltytiers/"

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
    console.log(this.SERVER_ROOT_URL)
    console.log(this.tierRootUrl)
  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  getTierById(tierIds) {
    const options = {
      headers: this.getHeaderTenant()
    }
    console.log(tierIds , 'service')
    return this.http.get(this.tierRootUrl + '?tierIds=' + tierIds, options);

  }
  getHeaderTenant() {
    return new HttpHeaders({
      'X-TENANT': this.tenant
    });
  }

  public getAllTiers() {
    const options = {
      headers: this.getHeaderTenant()
    }
    return this.http.get(this.tierRootUrl + 'all', options);
  }


  public deleteTier(tierIds) {
    const options = {
      headers: this.getHeaderTenant()

    }
    return this.http.delete(this.tierRootUrl +"?tierIds="+tierIds, options);
  }


  public addTier(requestbody) {
    const options = {
      headers: this.getHeaderTenant()
    }
    console.log(requestbody);
    return this.http.post(this.tierRootUrl, requestbody, options)
  }


  public editTier(tierId, requestbody) {
    const options = {
      headers: this.getHeaderTenant()
    }
    let url = decodeURI(this.tierRootUrl  + decodeURIComponent(tierId))
    return this.http.put(url, requestbody, options)
  }
}
