import { Injectable } from '@angular/core';
import { FieldRefService } from '../field-ref-service/field-ref.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreFieldRefService  implements FieldRefService {

  constructor(private http: HttpClient) { 
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
}

user: any;
tenant:any;
headers: Headers;

getHeaderTenant() {
  this.user = JSON.parse(sessionStorage.getItem("user"));
  this.tenant = this.user.tenant;
  return { headers: {'X-TENANT':this.tenant} }
}


serverRootUrl = environment.STORE_BASE_URL;
// serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
// serverRootUrl = "35.175.79.20"
storeTypeFieldRootUrl = this.serverRootUrl + "/productstore/api/v1/productstoretypes/";


  getAllFieldsByTypeCode(storeTypeCode):Observable<any>{
    return this.http.get<any>(this.storeTypeFieldRootUrl+ storeTypeCode+'/fields', this.getHeaderTenant());
  }

 createFieldsByTypeCode(field, storeTypeCode):Observable<any> {
  let request = {}; 
  request['field'] = field;
  return this.http.post<any>(this.storeTypeFieldRootUrl+storeTypeCode+'/fields', request, this.getHeaderTenant());
 }

 deleteFieldsByFieldIds(fieldIds,storeTypeCode):Observable<any> {
  return this.http.delete<any>(this.storeTypeFieldRootUrl+ storeTypeCode + "?fieldIds="+fieldIds, this.getHeaderTenant());
 }

 updateFieldByFieldId(fieldId, field, storeTypeCode) {
  let request = {};
  request['field'] = field;
  return this.http.put<any>(this.storeTypeFieldRootUrl + storeTypeCode +'/fields/'+fieldId, request, this.getHeaderTenant());
 }
}
