import { Component, OnInit, HostListener, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-treeview-dropdown',
  templateUrl: './treeview-dropdown.component.html',
  styleUrls: ['./treeview-dropdown.component.css']
})
export class TreeviewDropdownComponent implements OnInit {
  
  @Input() items: Array<any> = [];
  @Input() name: string;
  @Output() onClickValue = new EventEmitter(); 
  @Input() config = {
    hasSelect : Boolean
  }

  constructor() { 
    console.log(this.items)
    this.dropSelectedCategory = "Select Category";
  }
  
  
  ngOnInit() {
    this.dropSelectedCategory = "Select Category";
    console.log(this.items)
  }
  
  showDropdown : Boolean = false;
  
  
  
  hideShowMethod(event,item) {
    item.childVisible = !item.childVisible;
    event.stopPropagation();
  } 
  
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.showDropdown = false;
  }
  
  dropSelectedCategory: any;
  
  onClick(event,item) {
    console.log(item);
    if(item['name']) {
      this.dropSelectedCategory = item.name;
    }else {
      this.dropSelectedCategory = "Select Category"
    }
    this.onClickValue.emit(item);
    
  }
  
}
