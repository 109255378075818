import { Component, OnInit, HostListener } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-addnew-catalog-product',
    templateUrl: './addnew-catalog-product.component.html',
    styleUrls: ['./addnew-catalog-product.component.css']
})
export class AddnewCatalogProductComponent implements OnInit {

    catalogId: string;
    catalog: any;
    categories: any[];
    catalogProducts: any[];
    productFlagArray: Array<boolean> = [];
    products: any[];
    myBucketUrl: any;
    catalogProductIds: Array<any> = [];
    noProductOnCategoryFlag: boolean = false;
    allCategories: Array<any> = [];
    dropdownElements: Array<any> = [];
    user: any;
    imageArray: Array<any> = [];
    showDropdown : Boolean = false;

    constructor(private adminService: AdminService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private router: Router,
        private nestedCategoryService: NestedCategoriesService,
        private backgroundImageService: BackgroundImageService,
        private nestedCategory: NestedCategoryService,
        public translate : TranslateService) { 
        }

        config = {
            hasSelect: false
          }
    ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.categories = []
        this.myBucketUrl = environment.myBucketUrl;
        this.adminService.ngOnInit()
        this.categories = []
        this.adminService.getAllCategory().subscribe((data) => {
            this.categories = data.categories;    
            this.dropdownElements= []
            this.dropdownElements = this.nestedCategory.getProductCategoryTree(this.categories)
            console.log(this.dropdownElements);
            // this.dropdownElements =  this.nestedCategoryService.getNestedProductCategories(this.categories);
            //console.log(this.categories)
        }); 
    
        //console.log(this.categories)
        this.getAllCatalogProducts();

    }

    getAllCatalogProducts() {
        this.route.paramMap.subscribe((map) => {
            this.catalogId = map.get('catalogId');

            this.adminService.getAllCatalogProductById(this.catalogId).subscribe((data) => {
                this.catalogProductIds = data.productIds;
            })
        })
    }

    getAllCategory() {
        this.adminService.getAllCategory().subscribe((data) => {
            this.categories = data.categories;

           this.dropdownElements =  this.nestedCategoryService.getNestedProductCategories(this.categories);
        });
    }


    getBackground(image) {
        return this.backgroundImageService.getBackground(image);
      }

    addNewCatalogProduct(productId: any) {
        //console.log(productId)
        this.adminService.addNewProductToCatalog(productId, this.catalogId).subscribe((data) => {

            this.router.navigate(["catalogdetails/" + this.catalogId + "/catalogproducts/" + this.catalogId]);
        })
    }

    onSelectCategory(event) {
        // var selectElement = event.target;
        // var categoryId = selectElement.value;
        console.log(event)
        this.adminService.getAllCategoryProduct(event.id).subscribe((data) => {
            this.products = data.products;

            if (this.products.length == 0) {
                this.noProductOnCategoryFlag = true;
            } else {
                this.noProductOnCategoryFlag = false;
            }

            //console.log(this.products)
            for (let i = 0; i < this.products.length; i++) {
                this.products[i].imageRef = this.getBackground(this.products[i].imageRef);
                if (this.catalogProductIds.includes(this.products[i].id)) {
                    this.productFlagArray[i] = true;
                    //console.log(this.productFlagArray)
                }
            }
        })
    }


    // hideShowMethod(event,item) {
    //     item.childVisible = !item.childVisible;
    //     event.stopPropagation();
    //   } 

      dropSelectedCategory: any;
      
    //   onClickValue(event,item) {
    //       console.log(item);
    //     this.dropSelectedCategory = item.name;
    //     this.adminService.getAllCategoryProduct(item.id).subscribe((data) => {
    //         this.products = data.products;

    //         if (this.products.length == 0) {
    //             this.noProductOnCategoryFlag = true;
    //         } else {
    //             this.noProductOnCategoryFlag = false;
    //         }

    //         //console.log(this.products)
    //         for (let i = 0; i < this.products.length; i++) {
    //             this.products[i].imageRef = this.getBackground(this.products[i].imageRef);
    //             if (this.catalogProductIds.includes(this.products[i].id)) {
    //                 this.productFlagArray[i] = true;
    //                 //console.log(this.productFlagArray)
    //             }
    //         }
    //     })
        
    //   }

//       @HostListener("window:scroll", [])
// onWindowScroll() {
//  this.showDropdown = false;
// }

test(event,item) {
    console.log(item)
}
}
