import { Component, OnInit } from '@angular/core';
import { FieldRefComponent } from '../field-ref/field-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { FeatureFieldRefService } from '../../api-configuration-services/field-services/feature-field-ref-service/feature-field-ref.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-feature-field-ref',
  templateUrl: '../field-ref/field-ref.component.html',
  styleUrls: ['./feature-field-ref.component.css']
})
export class FeatureFieldRefComponent extends FieldRefComponent implements OnInit {

	fieldRefService: any;
	constructor(
		public route: ActivatedRoute,
		public modalService: NgbModal,
		public router: Router,
		public http: HttpClient,
		public deleteDialogService: DeleteDialogService,
		public autoCodeGenerator: AutoNameCodeGeneratorService) {
			super(route, modalService, router, http,deleteDialogService,autoCodeGenerator)

		this.fieldRefService = new FeatureFieldRefService(http)
	}
	ngOnInit() {
		this.getAllFields()
		this.formFieldModel()
		this.addOptionFields()
		this.fieldName = "feature"
		this.fieldDescription = "List of feature fields"
	}




}
