import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogTypeRefServiceService {
  
  constructor(public http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  user: any;
  tenant: any;
  headers: Headers;
  
  getHeaderTenant() {
    return { headers: {'X-TENANT':this.tenant} }
  }
  
  
  // serverRootUrl = environment.BASE_URL;
  serverRootUrl =environment.PRODUCT_CATALOG_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com";
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  productCatalogTypeRootUrl = this.serverRootUrl+"/api/v1/productcatalogtypes/";
  
  
  createType(catalogType) {
    let request = {};
    request['catalogType'] = catalogType;
    return this.http.post<any>(this.productCatalogTypeRootUrl, request,this.getHeaderTenant()); 
  }
  
  getAllTypes():Observable<any> {
    return this.http.get<any>(this.productCatalogTypeRootUrl ,  this.getHeaderTenant())
  }
  
  deleteTypeByTypeCodes(catalogTypeCodes) {
    return this.http.delete<any>(this.productCatalogTypeRootUrl + "?productCatalogTypeCodes=" + catalogTypeCodes ,  this.getHeaderTenant());
  }
  
  updateTypeByTypeCode(catalogType, catalogTypeCode) {
    let request = {};
    request['catalogType'] = catalogType;
    return this.http.put<any>(this.productCatalogTypeRootUrl + catalogTypeCode, request ,  this.getHeaderTenant());
  }
}
