
export class Type {
    public map = new Map();
    public array : Array<any>;

    constructor() {
        this.map.set("BOOLEAN", "Boolean")
        this.map.set("STRING", "Text")
        this.map.set("DOCUMENT", "Image")
        this.map.set("DECIMAL", "Decimal")
        this.map.set("INTEGER", "Number")
        this.map.set("DATE", "Date")
        this.map.set("TIME", "Time")
        this.map.set("DATETIME", "Date Time")
    }

    
    public getKeys():any {
        let typeKey: Array<any> = [];
        for (let key of this.map.keys()) {
            typeKey.push(key);
        }
        return typeKey;
    }

    public getValues(){
        let typeValue: Array<any> = [];
        for(let value of this.map.values()) {
            typeValue.push(value);
        }
        return typeValue;
    }
} 
