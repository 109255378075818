import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { environment } from 'src/environments/environment.prod';
import { BucketUrl } from 'src/app/bucket';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';

@Component({
  selector: 'app-edit-category-features',
  templateUrl: './edit-category-features.component.html',
  styleUrls: ['./edit-category-features.component.css']
})
export class EditCategoryFeaturesComponent implements OnInit {

  user: any;
  myBucketUrl: any;
  allCategoryFeatures: any;
  featureTypes: any[];
  selectedFiles: FileList;
  categoryId: any;
  basePrice: any;
  price: any;
  uid: any;
  editCategoryFeatures: any;
  featureIndex: any;
  currentCategoryFeature: any;
  featureId: any;
  featureFields: Array<any> = []
  featureOptions: Array<string> = [];
  value: Array<any> = [];
  convertedTypeField: Array<string> = [];
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  currentFields: any;
  showFields: boolean;
  bucketUrl: BucketUrl;
  catOptions: Array<Boolean> = [];

  feature: any;
  subscription: Subscription;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService) {
    this.bucketUrl = new BucketUrl();
  }



  ngOnInit() {
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.getCategoryFeature();

    this.getFeatureType();

  }

  resetForm() {
    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal("dialogBox");

        this.ngOnInit();

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //console.log(event.target)
  }


  getFeatureType() {
    this.adminService.getAllProductFeatureType().subscribe((data) => {
      this.featureTypes = data.featureTypes;
    })
  }

  getCategoryFeature() {

    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');
      this.featureId = map.get('featureId');
      this.featureIndex = map.get('index');


      this.adminService.getAllCategoryFeaturesById(this.categoryId).subscribe((data) => {
        this.allCategoryFeatures = data.features;
        //console.log(this.allCategoryFeatures)
        this.currentCategoryFeature = this.allCategoryFeatures[this.featureIndex]
        this.editProductFeatureModel();
        this.price = this.currentCategoryFeature.price.value;
        this.adminService.getAllFeatureFields(this.editCategoryFeatures.type).subscribe((data) => {
          this.featureFields = data.productFeatureFields;
          this.optionFieldsCondition(this.featureFields);
          for (let i = 0; i < this.featureFields.length; i++) {
            if (this.featureFields != null) {
              this.value[i] = (this.currentCategoryFeature.fields[this.featureFields[i].key])
            }
          }
        });
      });
    })
  }


  editProductFeatureModel() {
    this.editCategoryFeatures = {
      name: this.currentCategoryFeature.name,
      description: this.currentCategoryFeature.description,
      defaultUseCount: this.currentCategoryFeature.defaultUseCount,
      maxUseCount: this.currentCategoryFeature.maxUseCount,
      minUseCount: this.currentCategoryFeature.minUseCount,
      price: this.currentCategoryFeature.price,
      imageRef: this.currentCategoryFeature.imageRef,
      type: this.currentCategoryFeature.type,
      createdBy: this.currentCategoryFeature.createdBy,
      modifiedBy: this.user.name,
      options: this.currentCategoryFeature.options,
      fields: this.currentCategoryFeature.fields
    }

  }


  selectCode(event) {
    this.currentFields = event.target.value;
    //console.log(this.currentFields)
    this.adminService.getAllFeatureFields(this.currentFields).subscribe((data) => {
      this.featureFields = data.productFeatureFields;
      //console.log(this.featureFields)
      this.optionFieldsCondition(this.featureFields);
      for (let i = 0; i < this.featureFields.length; i++) {
        if (this.currentCategoryFeature.type == this.currentFields) {
          this.value[i] = (this.currentCategoryFeature.fields[this.featureFields[i].key])
          //console.log(this.value)

        }
        else {
          this.value[i] = ""
          this.editCategoryFeatures.fields = {}
        }

      }
    });
    this.showFields = true;
  }

  optionFieldsCondition(featureFields) {
    for (let i = 0; i < featureFields.length; i++) {
      if (featureFields[i].options) {
        if (featureFields[i].options.length == 1) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      //console.log(this.catOptions)
      if (featureFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = featureFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
      //console.log(environment.typeConversion[type])
      //console.log(this.convertedTypeField)
    }
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  getBackground(image) {
    if (image == "") {
      image = "noImage.jpg";
      return this.sanitizer.bypassSecurityTrustStyle('url(/assets/images/' + image + ')');
    }
    else {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.myBucketUrl + image + ')');
    }
  }

  onSubmit() {
    if (this.featureFields != null) {
      for (let i = 0; i < this.featureFields.length; i++) {
        let fieldName = this.featureFields[i].key;
        if (this.featureFields[i].type == "INTEGER") {
          this.editCategoryFeatures.fields[fieldName] = parseInt(this.value[i])
        } else if (this.featureFields[i].type == "DECIMAL") {
          this.editCategoryFeatures.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editCategoryFeatures.fields[fieldName] = this.value[i]
        }

      }
    }
    this.editCategoryFeatures.price["value"] = this.price;
    this.editCategoryFeatures.price["currency"] = "USD";


    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.currentCategoryFeature.imageRef != "") {
        this.imageUploadService.editImage(file, this.currentCategoryFeature.imageRef).subscribe((data) => {
          this.editCategoryFeaturesServiceCall();
        })
      } else {
        this.imageUploadService.uploadImage(file).subscribe((data) => {
          this.editCategoryFeatures.imageRef = data.documentId;

          this.editCategoryFeaturesServiceCall();
        })
      }
    } else {
      this.editCategoryFeaturesServiceCall();
    }
  }

  editCategoryFeaturesServiceCall() {
    this.adminService.updateCategoryFeatureById(this.categoryId, this.featureId, this.editCategoryFeatures).subscribe((data) => {
      this.router.navigate(['/categorydetails/' + this.categoryId + '/categoryfeatures/' + this.categoryId]);
    });
  }

}
