import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../services/store.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { environment } from 'src/environments/environment.prod';
import { AdminService } from 'src/app/admin/admin.service';
import { SortableComponent } from 'ngx-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';




@Component({
  selector: 'app-view-store-product',
  templateUrl: './view-store-product.component.html',
  styleUrls: ['./view-store-product.component.css']
})
export class ViewStoreProductComponent implements OnInit  {
  
  parentCategoryId: any;
  storeCode: any;
  products: Array<any> = [];
  product: any;
  productId: any;
  editProductForm: any;
  user: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  prodOptions: Array<Boolean> = [];
  productOptions: Array<any> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  productFields: any[];
  fields: any;
  convertedTypeField: Array<any> = [];
  checkbox = "checkbox";
  value: Array<any> = [];
  hideField: Array<any> = [];
  productPrice: any;
  priceList: Array<any> = [];
  priceListForm: Array<any> = [];
  restrictDate: any;
  currencies: Array<any> = []
  editProductPrice: any;
  selectCurrentCurrency: Array<any>= [];
  dateInvalid: Array<any> = [];
  invalidFuturePriceList: Array<any> = []
  invalidCurrentPrice: Boolean;
  subscription: Subscription;
  notEmptyCurrentPrice: Boolean ;
  invalidFuturePrice: Boolean;
  noDateEmpty: Boolean ;
  invalidFutureDate: Boolean ;
  invalidCurrentP: Boolean;
  store: any;
  currency: string;
  constructor(private route: ActivatedRoute,
    private storeService: StoreService,
    private backgroundImageService: BackgroundImageService,
    private adminService: AdminService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private deleteDialogService: DeleteDialogService) { 
      const currentDate:Date = new Date();
      this.restrictDate = new Date(currentDate);
      this.restrictDate = this.datePipe.transform(this.restrictDate.setDate(currentDate.getDate()+1),'yyyy-MM-dd')         
      this.firstSortedList = this.priceList;
      this.firstSortedList = this.priceListForm;
    }
    
    @ViewChild('editPriceList',{static: false}) public editPriceList: ElementRef;
    
    singleSelect: any = [];
    productPrices: any;
    ngOnInit() {
      const currentDate:Date = new Date();
      this.restrictDate = new Date(currentDate);
      this.restrictDate = this.datePipe.transform(this.restrictDate.setDate(currentDate.getDate()+1),'yyyy-MM-dd')
      this.user = JSON.parse(sessionStorage.getItem("user"));
      this.route.paramMap.subscribe((map) => {
        this.parentCategoryId = map.get('parentcategoryid')
        this.storeCode = map.get('storecode');
        this.productId = map.get('productid'); 
        this.getStore();
        this.storeService.getProductByCategoryId(this.parentCategoryId, this.storeCode).subscribe((data) => {
          this.products = data.products;
          for(let i=0; i< this.products.length; i++) {
            if(this.products[i].id == this.productId) {
              this.product = this.products[i];
              console.log(this.selectCurrentCurrency)
              console.log(this.product)
              this.storeService.getProductPriceByCategoryId(this.parentCategoryId,this.storeCode).subscribe((data) => {
                for(let i=0; i<data.storePrices.length; i++) {
                  if(data.storePrices[i].productId == this.productId) {
                   this.productPrices = data.storePrices[i];
                   this.productPriceModel();
                   break;
                  }
                }
                console.log(this.productPrices)
               })
              this.productModel();
              //console.log(this.editProductPrice)
              if(Object.keys(this.product.fields).length != 0) {
                //console.log(this.product.fields)
                //console.log(Object.keys(this.product.fields).length != 0)
                this.getProductFieldsByCategoryId();
              }
              //console.log(this.product)
              break;
            }
          }
        })
      })
    }
    
    getStore() {
      this.storeService.getStoreByCode(this.storeCode).subscribe((data) => {
        this.store = data['stores'][0];
        if(this.store['currency'] != undefined) {
          this.currency = this.store.currency
        }else {
          this.currency = "USD";
        }
      });
    }

    getProductFieldsByCategoryId() {
      this.adminService.getProductFieldsByCategoryId(this.product.categoryId).subscribe((data) => {
        this.productFields = data.fields;
        //console.log(this.productFields)
        this.optionFieldsCondition(this.productFields)
        if (this.productFields != null) {
          for (let i = 0; i < this.productFields.length; i++) {
            this.multipleArray[i] = new Array<any>()
            this.lastIndexOfMultipleArray[i] = new Array<any>();
            this.listArray[i] = new Array<any>()
            this.multipleArray[i].push({
              listValue: ''
            })
            if (this.productFields[i].list) {
              for (const key of Object.keys(this.product.fields)) {
                if(this.productFields[i].key == key) {
                  if((this.product.fields[this.productFields[i].key].length != 0)) {
                    for( let j=0;j< this.product.fields[this.productFields[i].key].length; j++) {
                      this.listFlag[i] = true;
                      this.multipleArray[i][j] = ({
                        listValue: this.product.fields[this.productFields[i].key][j]
                      })
                    }
                  }
                }  
              }    
            }
            else {
              this.value[i] = (this.product.fields[this.productFields[i].key]);
              this.listFlag[i] = false;
            }
            this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
          }
        }
        for(let i=0; i<this.value.length;i++) {
          this.hideField[i] = true;
          if(this.value[i] == '' || this.value[i] == undefined) {
            //console.log("hello");
            this.hideField[i] = false;
          }else {
            this.hideField[i] = true;
          }
        }
      });
    }
    
    optionFieldsCondition(productFields) {
      try {
        for (let i = 0; i < productFields.length; i++) {
          if (productFields[i].options) {
            if ((productFields[i].options.length == 1) || (productFields[i].options.length == 0)) {
              this.prodOptions[i] = false;
            }
            else {
              this.prodOptions[i] = true;
            }
          }
          if (this.productFields[i].list) {
            this.listFlag[i] = true;
          }
          if (productFields[i].type == "BOOLEAN") {
            this.value[i] = false;
          }
          let type = productFields[i].type;
          this.convertedTypeField[i] = (environment.typeConversion[type]);
        }
      } catch (error) {
        
      }
    }
    
    
    
    productModel() {
      this.editProductForm = {
        name: this.product.name,
        description: this.product.description,
        fromDate: this.product.fromDate,
        throughDate: this.product.throughDate,
        imageRef: this.product.imageRef,
        createdBy: this.product.createdBy,
        modifiedBy: this.user.name,
        modifiedDate: this.product.modifiedDate,
        fields: this.product.fields,
        // basePrice: this.product.basePrice,
        categoryId: this.product.categoryId,
      }
      console.log(this.editProductForm)
      this.productPriceModel();
      
    }
    
    productPriceModel() {
      let futurePriceValue = null;
      let currentPriceValue = null;
      let futurePriceActiveFromDate = null;
      try {
        futurePriceValue = this.productPrices.futurePrice.value
        futurePriceActiveFromDate = this.productPrices.futurePrice.activeFromDate
      } catch (error) {
        futurePriceActiveFromDate = null;
        futurePriceValue = null;
      }
      
      try {
        currentPriceValue = this.productPrices.currentPrice.value;
        
      } catch (error) {
        currentPriceValue = null;
      }
      this.editProductPrice = {
        currentPrice: {
          value: currentPriceValue,
          currency: this.currency,
          action: 'PRICE_FOL',
        },
        futurePrice: {
          value: futurePriceValue,
          currency: this.currency,
          action: 'PRICE_FOL',
          activeFromDate: futurePriceActiveFromDate
        },
        productId: this.productId,
        createdBy: this.product.createdBy,
        modifiedBy: this.user.name
      }
      console.log(this.editProductPrice)
      
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    
    onSubmit() {
      console.log(this.editProductPrice);
      let editProductPriceModel = this.editProductPrice;
      
      if(editProductPriceModel.futurePrice != null) {
        if((editProductPriceModel.currentPrice.value !=null) && (editProductPriceModel.futurePrice.value != null) &&( editProductPriceModel.futurePrice.activeFromDate == null || editProductPriceModel['futurePrice']['activeFromDate'] == '')) {
          this.noDateEmpty = true;
        }
        if((editProductPriceModel.currentPrice.value !=null) && (editProductPriceModel.futurePrice.activeFromDate != null) &&  editProductPriceModel.futurePrice.value == null) {
          this.invalidFuturePrice = true;
        }
        if(editProductPriceModel.futurePrice.activeFromDate < this.restrictDate) {
          this.invalidFutureDate = true;
        }
        if(editProductPriceModel.futurePrice.value == null &&( editProductPriceModel.futurePrice.activeFromDate == null)) {
          editProductPriceModel.futurePrice = null
        }
      }
      
      if(editProductPriceModel.currentPrice.value == null) {
        this.invalidCurrentPrice = true;
        if(editProductPriceModel.currentPrice.value == null && editProductPriceModel.futurePrice != null) {
          this.notEmptyCurrentPrice = true;
        }
      }
      // console.log(editProductPriceModel)
      this.storeService.ManageProductStorePrice(this.storeCode,[editProductPriceModel]).subscribe((data) => {
        this.ngOnInit();
      });
      // //console.log(this.editProductForm);
      // //console.log(this.product.price.id);
      // this.storeService.updatePriceByStorePriceId(this.editProductForm.price,this.product.price.id).subscribe((data) => {
      //   this.ngOnInit();
      // })
    }
    
    resetForm() {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.ngOnInit();
          
          this.closeResetModal("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }
    
    
    // convertDate(date) {
    //   date = new Date();
    //   //console.log(this.datePipe.transform(date,"dd-MM-yyyy"))
    //   return this.datePipe.transform(date,"dd-MM-yyyy");
    
    // }
    
    
    // openPriceList(product,id) {
    //   this.priceList = []
    //   //console.log(product);
    //   this.modalService.open(id);
    //   // this.productList = product;
    //   this.getPriceListByStorePriceId();
    // }
    
    openPriceList(product) {
      // console.log(product);
      this.product = product;
      this.getPriceListByStorePriceId();
      this.modalService.open(this.editPriceList, { size: 'lg', windowClass: 'edit-modal'});
    }
    
    
    closeModal() {
      this.modalService.dismissAll(this.editPriceList);
      this.priceList= []
    }
    
    currentPrice: any = {};
    getPriceListByStorePriceId() {
      this.storeService.getProductPriceListByPriceId(this.productId, this.storeCode).subscribe((data) => {
        this.priceList = data.price.futurePrices;
        this.currentPrice = data.price.currentPrice;
        if(this.currentPrice == null) {
          this.currentPrice = {
            value: '',
            currency: this.currency,
            action: "PRICE_FOL",
          }
        }
        if(this.priceList != null) {
          for(let i=0; i< this.priceList.length; i++) {
            this.dateInvalid[i] = false;
            this.invalidFuturePriceList[i] = false;
            this.invalidCurrentP = false;
          }
        }else {
          this.priceList = [{
            value: '',
            currency: this.currency,
            action: "PRICE_FOL",
            activeFromDate: ''
          }]
        }
      })
    }
    
    updatePriceList() {
      let price = {
        currentPrice: this.currentPrice,
        futurePrices: [],
        createdBy: this.user.name,
        modifiedBy: this.user.name
      };
      for(let i=0; i< this.priceList.length;i++) {
        if(this.priceList[i].value != '' || this.priceList[i].value != null) {
          price['futurePrices'][i] = this.priceList[i]
        }
      }
      
      this.storeService.manageProductPriceList(this.productId,this.storeCode,price).subscribe((data) => {
        this.modalService.dismissAll(this.editPriceList);
        this.priceList = [];
        this.currentPrice = {}
        this.ngOnInit();
      })
      
    }
    
    
    firstSortedList: any;
    
    
    listSorted(list: any){
      this.firstSortedList = list;
    }
    
    
    @ViewChild(SortableComponent, {static: false}) sortableComponent: SortableComponent;
    public addPriceList() {
      this.priceList.push({
        value: '',
        currency: this.currency,
        action: "PRICE_FOL",
        activeFromDate: ''
      });
      this.priceList = [...this.priceList];
    }
    
    public removePriceList(index) {
      this.dateInvalid[index] = false;
      this.priceList.splice(index,1);
      this.priceList = [...this.priceList];
      this.invalidFuturePriceList[index] = false;
      this.checkDuplicateDate();
    }
    
    checkDuplicateDate() {
      for(let i=0; i< this.priceList.length; i++) {
        this.dateInvalid[i] = false;
      }
      for(let i=0; i< this.priceList.length; i++) {
        for(let k=i+1 ; k < this.priceList.length; k++) {
          if(this.priceList[i].activeFromDate == this.priceList[k].activeFromDate) {
            this.dateInvalid[i] = true;
            this.dateInvalid[k] = true;
          }
        }
      }
    }
    
    negativeValidation(event,j) {
      if(event.target.value != "") {
        if( j == 0) {
          this.invalidCurrentP = false;
        }
        if(event.target.value >= 0) {
          this.invalidFuturePriceList[j] = false
        }else {
          this.invalidFuturePriceList[j] = true;
        }
      }else {
        this.invalidFuturePriceList[j] = false;
        if( j == 0) {
          this.invalidCurrentP = true;
        }
      }
    }
    
    
    validateCurrentPrice(event) {
      if(event.target.value != '') {
        if(event.target.value >= 0) {
          this.invalidCurrentPrice = false;
        }else {
          this.invalidCurrentPrice = true;
        }
        
        if(this.notEmptyCurrentPrice) {
          this.notEmptyCurrentPrice = false;
        }
      }else {
        this.invalidCurrentPrice = true;
      }
    }
    
    validateFuturePrice(event) {
      if(event.target.value != '') {
        if(event.target.value >= 0) {
          this.invalidFuturePrice = false;
        }else {
          this.invalidFuturePrice = true;
        }
      }else {
        this.invalidFuturePrice = false;
        this.noDateEmpty = false;
      }
    }
    
    validateFutureDate(event) {
      if(event.target.value != '') {
        this.invalidFutureDate = false;
        this.noDateEmpty = false;
        if(event.target.value < this.restrictDate) {
          this.invalidFutureDate = true;
        }else {
          this.invalidFutureDate = false;
        }
      }
    }
    
    noNegativeCurrentPrice : Boolean  = false;

    
    negativeCurrentValidation(event) {
      if(event.target.value != "") {
        if(event.target.value >= 0) {
          this.noNegativeCurrentPrice = false;
        }else {
          this.noNegativeCurrentPrice = true;
        }
      }else {
        this.noNegativeCurrentPrice = false;
      }
    }
    

    resetPriceList() {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.getPriceListByStorePriceId();
          
          this.closeResetModal("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }

    openResetModal(id: string){
      let data = {};
      data = {
        value: 'reset'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeResetModal(id: string){
      this.deleteDialogService.closeDialog();
    }
  }
  
  