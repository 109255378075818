import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LoyaltyService } from '../loyalty-service/loyalty.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../../admin.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { Subscription } from 'rxjs';
import { loyaltyRules } from './loyalty-rule-model/loyalty-rules';
import { NoDataService } from 'src/app/services/no-data.service';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { LoyaltypointRuleFilters } from 'src/app/shared/constants/filter-model/loyaltypoint-rule-filters.model';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-loyalty-point-rules',
  templateUrl: './loyalty-point-rules.component.html',
  styleUrls: ['./loyalty-point-rules.component.css']
})
export class LoyaltyPointRulesComponent implements OnInit {

  constructor(private loyaltyService: LoyaltyService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private backgroundImageService: BackgroundImageService,
    private ngbModalService: NgbModal,
    private adminService: AdminService,
    private nestedCategoriesService: NestedCategoriesService,
    private noDataService: NoDataService,
    private dateValidations: DateValidationsService) {
    this.filters = new LoyaltypointRuleFilters()
  }

  checkProductSelected: Array<any> = [];
  selectedProductIds: Array<any> = [];
  loyaltyList: any[];
  appliedRule: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  subscription: Subscription;
  loyaltyRulesType: Array<any> = [];
  selectedRule: string;
  ruleApplied: Boolean = false;
  EVENT_RULE_TYPE = "EVENT_RULE";
  SPENDING_RULE_TYPE = "SPENDING_RULE";
  PRODUCT_PURCHASE_RULE_TYPE = "PRODUCT_PURCHASE_RULE";
  isEventRule: Boolean = false;
  isSpendRule: Boolean = false;
  isProductPurchase: Boolean = false;
  user: any;
  loyalty: any;
  eventRuleTypeKeys: any[];
  eventRuleTypeValues: any[];
  loyaltyRulesClass: loyaltyRules;
  dropdownElements: any[];
  categories: any[];
  products: any[];
  selectedProducts: Array<any> = [];
  productFlagArray: Array<any> = [];
  availableProducts: any[];
  editReference: any;
  loyaltyId: string;
  loyaltyRule: any;
  noDataShow: Boolean = false;
  searchName: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  numberOfPages: number = 1;
  filters: LoyaltypointRuleFilters;
  pagination: any;
  types: any;
  sort: Array<any> = [];
  restrictedFromDate: any
  restrictedThroughDate: any

  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  // sort: Array<any> = sortConstant.LOYALTYPOINT_RULE_SORT;

  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;
  @ViewChild('addLoyaltyRule', { static: true }) public addLoyaltyRule: ElementRef;
  @ViewChild('editLoyaltyRule', { static: true }) public editLoyaltyRule: ElementRef;
  @ViewChild('addLoyaltyForm', { static: true }) public addLoyaltyForm: NgForm;
  @ViewChild('editLoyaltyForm', { static: false }) editLoyaltyForm: NgForm;


  ngOnInit() {
    this.loyaltyService.ngOnInit();
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    this.getLoyaltyPointRuleTypes();
    this.selectedRule = "null";
  }

  getLoyaltyRuleSummaryByType(type) {
    this.filters['type'] = type;
    this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  // getAllLoyaltyRuleSummary() {
  //   this.loyaltyList = [];
  //   this.allCheck = false;
  //   this.loyaltyService.getAllLoyaltyRuleSummary().subscribe((response) => {
  //     this.loyaltyList = response.summaries;
  //     this.noDataShow = this.noDataService.noData(this.loyaltyList);
  //     for(let i=0; i< this.loyaltyList.length; i++) {
  //       this.checkboxItems[i] = false;
  //     }
  //   })
  // }

  getLoyaltyRuleSummaryByQuery(filters, itemsPerPage, pageNumber, sort) {
    this.loyaltyList = [];
    this.allCheck = false;
    this.pagination = null;
    this.loyaltyService.getAllLoyaltyRuleSummaryByQuery(filters, itemsPerPage, pageNumber, sort).subscribe((data) => {
      this.loyaltyList = data.loyaltyPointRules;
      console.log(this.loyaltyList)
      console.log(data, 'summary')
      this.pagination = data.pagination;
      this.totalItems = this.pagination.totalNumberOfRecords;
      this.numberOfPages = this.pagination.numberOfPages;
      this.noDataShow = this.noDataService.noData(this.loyaltyList);
      this.getCheckboxes(this.loyaltyList)
    });
  }

  getCheckboxes(data) {
    if (data.length > 0) {
      this.checkboxItems = []
      for (let i = 0; i < data.length; i++) {
        this.checkboxItems[i] = false;
      }
    }
  }

  getLoyaltyPointRuleTypes() {

    this.loyaltyRulesType = [
      "SPENDING_RULE",
      "PRODUCT_PURCHASE_RULE",
      "EVENT_RULE"
    ];

  }

  onSelectloyaltyRulesType(event) {
    this.selectedRule = event.target.value;
    this.currentPage = 1;
    //console.log(this.selectedRule)
    if (this.selectedRule != "null") {
      this.ruleApplied = true;
      this.getLoyaltyRuleSummaryByType(this.selectedRule);
    } else if (this.selectedRule == "null") {
      // this.filters['type'] = null
      delete this.filters.type
      this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
      this.ruleApplied = false;
    }

  }



  // ---------- delete methods -------------//

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.loyaltyList.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.loyaltyList.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  // multiple delete method
  delete() {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");
        let loyaltyIds = []
        for (let i = 0; i < this.loyaltyList.length; i++) {
          if (this.checkboxItems[i] == true) {
            loyaltyIds.push(this.loyaltyList[i].id)
          }
        }
        this.loyaltyService.deleteLoyaltyRuleById(loyaltyIds).subscribe((data) => {
          this.checkForSelectedRule();
          this.allCheck = false
        })
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })

  }

  // single delete method
  deleteSingle(id) {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {

        this.closeModal1("dialogBox");
        this.loyaltyService.deleteLoyaltyRuleById(id).subscribe((data) => {
          this.checkForSelectedRule();
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        })
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal1(id: string) {
    let data = {};
    data = {
      value: 'delete'
    };
    this.deleteDialogService.openDialog(data);
  }
  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }



  // ----- add Loyalty Rule functionalites ----- //
  openLoyaltyModal(content, selectedRule) {
    this.appliedRule = selectedRule
    this.isEventRule = false;
    this.isProductPurchase = false;
    this.isSpendRule = false;
    this.loyaltyRulesClass = new loyaltyRules();
    this.eventRuleTypeKeys = this.loyaltyRulesClass.getEventRuleTypeKeys();
    this.eventRuleTypeValues = this.loyaltyRulesClass.getEventRuleTypeValues();
    this.selectedTypeRuleConditions(selectedRule);
    this.ngbModalService.open(content, { size: 'xl', windowClass: 'loyalty-modal' });
  }

  selectedTypeRuleConditions(selectedType) {
    if (selectedType == this.EVENT_RULE_TYPE) {
      this.isEventRule = true;
      this.loyaltyEventModel();
    }
    else if (selectedType == this.PRODUCT_PURCHASE_RULE_TYPE) {
      this.isProductPurchase = true;
      this.loyaltyProductPurchaseModel();
    }
    else if (selectedType == this.SPENDING_RULE_TYPE) {
      this.isSpendRule = true;
      this.loyaltySpendModel();
    }
  }

  loyaltySpendModel() {
    this.loyalty = {
      name: '',
      description: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,
      // modifiedBy: this.user.name,
      type: this.appliedRule,
      pointsMultiplier: '',
      valueThreshold: '',
    }
  }

  loyaltyProductPurchaseModel() {
    this.loyalty = {
      name: '',
      description: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,
      // modifiedBy: this.user.name,
      type: this.appliedRule,
      points: '',
      productIds: [],
    }
  }

  loyaltyEventModel() {
    this.loyalty = {
      name: '',
      description: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,
      // modifiedBy: this.user.name,
      type: this.appliedRule,
      points: '',
      eventType: ''
    }
  }

  closeLoyaltyModal(content) {
    this.loyalty = [];
    this.availableProducts = [];
    this.selectedProducts = [];
    this.products = [];
    this.checkProductSelected = [];
    this.selectedProductIds = []
    this.ngbModalService.dismissAll(content);
  }
  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.loyalty)
  }

  addLoyalty(content) {
    this.validateFromThroughDate()
    // for(let i=0;i<this.selectedProductIds.length;i++){

    // this.selectedProductIds[i] = parseInt(this.loyalty.productIds);
    // }
    // this.selectedProductIds.push(this.loyalty.productIds)
    // this.selectedProductIds=[]
    this.loyalty.productIds = this.selectedProductIds;
    console.log(this.loyalty.productIds)
    console.log(this.selectedProductIds, 'add')
    console.log(this.loyalty);
    this.loyaltyService.addNewloyaltyRule(this.loyalty).subscribe((response) => {
      this.closeLoyaltyModal(content);
      if (this.selectedRule != "null") {
        this.getLoyaltyRuleSummaryByType(this.selectedRule)
      } else {
        this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
      }
    })
  }


  // ---- add product condition functionality ---- //
  modalReference: any;
  addProductCondition(content) {

    this.getAllCategory()
    this.modalReference = this.ngbModalService.open(content, { size: 'xl', windowClass: 'add-product-modal', backdropClass: 'dark-backdrop' });
  }

  closeNgbModal() {
    this.products = [];
    this.selectedProductIds = [];
    this.modalReference.dismiss();
  }

  getAllCategory() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;

      this.dropdownElements = this.nestedCategoriesService.getNestedProductCategories(this.categories);
    });
  }


  onSelectCategory(event) {
    var selectElement = event.target;
    var categoryId = selectElement.value;
    //console.log(categoryId);
    // this.products = [];
    this.getAllProductsById(categoryId);

  }

  getAllProductsById(categoryId) {
    this.products = [];
    this.checkProductSelected = [];
    this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
      this.products = data.products;
      for (let i = 0; i < this.products.length; i++) {
        this.checkProductSelected[i] = false;
      }

      try {
        if (this.loyaltyRule) {
          if (this.loyaltyRule.type == this.PRODUCT_PURCHASE_RULE_TYPE) {
            this.selectedProductIds = this.loyaltyRule.productPurchaseRule.productIds;
          }
        }
      } catch (error) {

      }

      if (this.selectedProductIds.length > 0) {
        for (let i = 0; i < this.products.length; i++) {
          for (let j = 0; j < this.selectedProductIds.length; j++) {
            if (this.selectedProductIds[j] == this.products[i].id) {
              this.checkProductSelected[i] = true;
            }
          }
        }
        console.log(this.checkProductSelected)
      }
      this.availableProducts = this.products;
      for (let i = 0; i < this.availableProducts.length; i++) {
        this.availableProducts[i].imageRef = this.getBackground(this.availableProducts[i].imageRef);
      }
    });
  }


  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  selectProducts(event, i, productId) {
    //console.log(event.target, productId)
    if (event.target.checked) {
      //console.log(true);
      this.selectedProductIds.push(productId);
    } else {
      this.selectedProductIds.splice(this.selectedProductIds.indexOf(productId), 1)
      //console.log(false);
    }
    console.log(this.selectedProductIds);
  }


  addProducts(content) {

    this.adminService.getProductsByProductIds(this.selectedProductIds).subscribe((response) => {
      console.log('addproduct')
      console.log(response)
      this.selectedProducts = response.products;
      for (let i = 0; i < this.selectedProducts.length; i++) {
        this.selectedProducts[i].imageRef = this.getBackground(this.selectedProducts[i].imageRef);
      }
      console.log(this.selectedProducts)
      this.products = [];
      this.availableProducts = []
      this.modalReference.close();
    });

  }

  removeProduct(productId, i) {
    this.selectedProducts.splice(i, 1);
    this.selectedProductIds.splice(this.selectedProductIds.indexOf(productId), 1);
    //console.log(this.selectedProducts);
    //console.log(this.selectedProductIds)
  }


  resetAddForm(addLoyaltyForm: NgForm) {
    this.openResetModal("reset");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.selectedProducts = [];
        this.products = [];
        this.availableProducts = [];
        this.selectedProductIds = []
        this.checkProductSelected = [];
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.selectedTypeRuleConditions(this.appliedRule);
        addLoyaltyForm.resetForm()
        this.closeResetModal("dialogBox");
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }


  openResetModal(id) {
    let data = {};
    data = {
      value: 'reset'
    };
    if (id == 'delete') {
      data['value'] = id;
    }
    this.deleteDialogService.openDialog(data);
  }
  closeResetModal(id) {
    this.deleteDialogService.closeDialog();
  }


  // ----- edit loyalty ----- //

  editLoyaltyModal(content, loyaltyId) {
    this.loyalty = [];
    this.restrictedFromDate = null;
    this.restrictedThroughDate = null;

    this.editReference = this.ngbModalService.open(content, { size: 'xl', windowClass: 'loyalty-modal' });
    console.log(loyaltyId);
    this.loyaltyId = loyaltyId;
    console.log(this.loyaltyId)
    this.loyaltyService.getLoyaltyRuleById(loyaltyId).subscribe((response) => {
      this.loyaltyRule = response.loyaltyPointRules[0];
      this.onFromDate(response.loyaltyPointRules[0].fromDate);
      this.onThroughDate(response.loyaltyPointRules[0].throughDate);
      console.log(response, 'rules')
      console.log(this.loyaltyRule)
      this.selectedEditTypeRuleConditions(this.loyaltyRule.type);
      if (this.loyaltyRule.type == this.PRODUCT_PURCHASE_RULE_TYPE) {
        if (this.loyaltyRule.productPurchaseRule.productIds.length > 0) {
          this.getProductByProductId(this.loyaltyRule.productPurchaseRule.productIds);
        }
      }
    })
    this.isEventRule = false;
    this.isProductPurchase = false;
    this.isSpendRule = false;
    this.loyaltyRulesClass = new loyaltyRules();
    this.eventRuleTypeKeys = this.loyaltyRulesClass.getEventRuleTypeKeys();
    this.eventRuleTypeValues = this.loyaltyRulesClass.getEventRuleTypeValues();
  }


  getProductByProductId(productIds) {
    this.adminService.getProductsByProductIds(productIds).subscribe((response) => {
      this.selectedProducts = response.products;
      for (let i = 0; i < this.selectedProducts.length; i++) {
        this.selectedProducts[i].imageRef = this.getBackground(this.selectedProducts[i].imageRef);
      }
      //console.log(this.selectProducts)
      this.selectedProductIds = this.loyaltyRule.productPurchaseRule.productIds;
    })
  }

  selectedEditTypeRuleConditions(selectedType) {
    this.loyalty = {}
    if (selectedType == this.EVENT_RULE_TYPE) {
      this.isEventRule = true;
      this.editLoyaltyEventModel();
    }
    else if (selectedType == this.PRODUCT_PURCHASE_RULE_TYPE) {
      this.isProductPurchase = true;
      this.editLoyaltyProductPurchaseModel();
    }
    else if (selectedType == this.SPENDING_RULE_TYPE) {
      this.isSpendRule = true;
      this.editLoyaltySpendModel();
    }
  }


  editLoyaltySpendModel() {
    this.loyalty = {
      name: this.loyaltyRule.name,
      description: this.loyaltyRule.description,
      fromDate: this.loyaltyRule.fromDate,
      throughDate: this.loyaltyRule.throughDate,
      modifiedBy: this.user.name,
      type: this.loyaltyRule.type,
      pointsMultiplier: this.loyaltyRule.spendingRule.pointsMultiplier,
      valueThreshold: this.loyaltyRule.spendingRule.valueThreshold,
    }
    console.log("this.loyalty", this.loyalty)
  }

  editLoyaltyProductPurchaseModel() {
    this.loyalty = {
      name: this.loyaltyRule.name,
      description: this.loyaltyRule.description,
      fromDate: this.loyaltyRule.fromDate,
      throughDate: this.loyaltyRule.throughDate,
      modifiedBy: this.user.name,
      type: this.loyaltyRule.type,
      points: this.loyaltyRule.productPurchaseRule.points,
      productIds: this.loyaltyRule.productPurchaseRule.productIds
    }
    console.log(this.loyalty)
  }

  editLoyaltyEventModel() {
    this.loyalty = {
      name: this.loyaltyRule.name,
      description: this.loyaltyRule.description,
      fromDate: this.loyaltyRule.fromDate,
      throughDate: this.loyaltyRule.throughDate,
      modifiedBy: this.user.name,
      type: this.loyaltyRule.type,
      points: this.loyaltyRule.eventRule.points,
      eventType: this.loyaltyRule.eventRule.eventType
    }
  }

  closeEditModal() {
    this.editReference.dismiss();
    this.loyalty = [];
    this.loyaltyRule = [];
    this.availableProducts = [];
    this.selectedProducts = [];
    this.products = [];
    this.checkProductSelected = [];
    this.selectedProductIds = []

  }


  editLoyalty(content) {
    console.log(this.loyalty);
    this.loyaltyService.editLoyaltyRule(this.loyaltyRule.id, this.loyalty).subscribe((response) => {
      this.closeEditModal();
      if (this.selectedRule != "null") {
        this.getLoyaltyRuleSummaryByType(this.selectedRule)
      } else {
        this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
      }
    });
  }


  checkForSelectedRule() {
    if (this.selectedRule != "null") {
      this.getLoyaltyRuleSummaryByType(this.selectedRule)
    } else {
      this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    }
  }

  resetEditForm(editLoyaltyForm: NgForm) {
    this.openResetModal("reset");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        console.log(this.loyaltyRule)
        this.loyaltyService.getLoyaltyRuleById(this.loyaltyId).subscribe((response) => {
          console.log(response)
          this.loyaltyRule = response.loyaltyPointRules[0];
          console.log(this.loyaltyRule)
          this.selectedEditTypeRuleConditions(this.loyaltyRule.type);
          if (this.loyaltyRule.type == this.PRODUCT_PURCHASE_RULE_TYPE) {
            if (this.loyaltyRule.productPurchaseRule.productIds.length > 0) {
              this.getProductByProductId(this.loyaltyRule.productPurchaseRule.productIds);
            }
          }
        })
        this.closeResetModal("dialogBox");
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }


  // -------  pagination methods ------ //

  onChangePage(event) {
    this.currentPage = event;
    this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  addItemsPerPage(event) {
    this.itemsPerPage = event;
    this.currentPage = 1;
    this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }


  // ------ search bar method ----------- //

  findName(event) {
    this.searchName = event;
    if (this.searchName != '') {
      this.filters['name'] = this.searchName;
      this.currentPage = 1;
      this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    }
  }
  hideCross(event) {
    // this.filters['name'] = null;
    delete this.filters.name
    this.currentPage = 1;
    this.searchName = '';
    this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  async onSelectSort(event) {
    this.sort[0] = this.fields
    console.log(this.sort)
    if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING')) {
      this.sort[0].sortDirection = await event.target.value
      this.sort[0].fieldName = 'name'
      console.log(this.sort)
    } else {
      // this.sort[0].sortDirection = await null
      delete this.sort[0].sortDirection


    }

    this.getLoyaltyRuleSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);

  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }

}
