import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ErrorHandler, NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatInputModule } from "@angular/material";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'angularx-accordion';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { NgxDialogsModule } from 'ngx-dialogs';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxSpinnerModule } from "ngx-spinner";
import { AdminModule } from './admin/admin.module';
import { CustomersModule } from './admin/customers/customers.module';
import { StoreModule } from './admin/product-stores/stores/modules/store.module';
import { AppComponent } from './app.component';
import { appRoute } from './app.route';

import { ModalService } from './directives/modal.sevice';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { AddCommonCustomFieldsComponent } from './shared/add-common-custom-fields/add-common-custom-fields.component';
import { AutoNameCodeGeneratorService } from './shared/auto-name-code-generator/auto-name-code-generator.service';
import { BackgroundImageService } from './shared/background-image/background-image.service';
import { CustomAdapterService } from './shared/datepicker/custom-adapter.service';
import { CustomDateParserFormatterService } from './shared/datepicker/custom-date-parser-formatter.service';
import { DeleteDialogComponent } from './shared/delete-dialog/delete-dialog.component';
import { DeleteDialogService } from './shared/delete-dialog/delete-dialog.service';
import { LoaderComponent } from './shared/loader/loader.component';
import { LoaderService } from './shared/loader/loader.service';
import { MyerrorHandlerService } from './shared/myerror-handler.service';
import { NestedCategoriesService } from './shared/nested-categories-service/nested-categories.service';
import { PipesModule } from './shared/pipes/pipes.module';
import { PopupDeleteResetDialogComponent } from './shared/popup-delete-reset-dialog/popup-delete-reset-dialog.component';
import { PopupDeleteResetDialogService } from './shared/popup-delete-reset-dialog/popup-delete-reset-dialog.service';
import { EditFieldModalService } from './shared/popup-modal/edit-field-modal/edit-field-modal.service';
import { UserAuthModule } from './user-auth/user-auth.module';

import { CasscadetoproductModalComponent } from './shared/casscadetoproduct-modal/casscadetoproduct-modal.component';
import { CasscadeToProductModalService } from './shared/casscadetoproduct-modal/casscadetoproduct-modal.service';
import { ClaimDialogComponent } from './shared/claim-dialog/claim-dialog.component';



const config = {
  issuer: 'https://dev-281559.okta.com/oauth2/default',
  redirectUri: 'http://localhost:8080/',
  clientId: '0oa1iij2smUwRKsm0357',
  pkce: true
}




@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    AddCommonCustomFieldsComponent,
    ErrorDialogComponent,
    PopupDeleteResetDialogComponent,
    DeleteDialogComponent,
    CasscadetoproductModalComponent,
    ClaimDialogComponent


  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ClickOutsideModule,
    PipesModule,
    MatProgressSpinnerModule,
    AdminModule,
    CustomersModule,
    StoreModule,
    HttpClientModule,
    UserAuthModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    SortableModule.forRoot(),
    BrowserAnimationsModule,
    NgbModalModule,
    NgxDialogsModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    MatExpansionModule,
    AccordionModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    SelectDropDownModule,
    RouterModule.forRoot(appRoute, { onSameUrlNavigation: 'reload' })

  ],
  providers: [
    LoaderService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: MyerrorHandlerService, multi: false },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: NgbDateAdapter, useClass: CustomAdapterService },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
    { provide: LOCALE_ID, useValue: 'en-En' },
    DeleteDialogService,
    EditFieldModalService,
    NestedCategoriesService,
    BackgroundImageService,
    ErrorDialogService,
    MyerrorHandlerService,
    AutoNameCodeGeneratorService,
    PopupDeleteResetDialogService,
    CasscadeToProductModalService,
    DatePipe,
    ModalService,

    // {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  bootstrap: [AppComponent],
  exports: [
    RouterModule,
    DeleteDialogComponent,
  ],
  entryComponents: [ErrorDialogComponent,
    PopupDeleteResetDialogComponent,
    DeleteDialogComponent,
    ClaimDialogComponent,
    CasscadetoproductModalComponent
  ],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA
  //  ],

})
export class AppModule { }

