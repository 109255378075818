import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormBuilder, NgForm } from '@angular/forms';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
const cleanDeep = require('src/app/shared/library/deep-clean');

@Component({
  selector: 'app-addnew-category',
  templateUrl: './addnew-category.component.html',
  styleUrls: ['./addnew-category.component.css']
})
export class AddnewCategoryComponent implements OnInit {

  @ViewChild('addNewCategoryForm', { static: false }) myForm: NgForm;
  category: any;
  categoryTypes: any[];
  selectedFiles: FileList;
  flag: boolean;
  uid: any;
  user: any;
  myBucketUrl: any;
  fields: any;
  value: Array<any> = [];
  categoryTypeCode: any;
  categoryFields: any;
  currentFields: any;
  showFields: boolean = false;
  isrequired: boolean;
  convertedTypeField: Array<string> = [];
  categoryOptions: Array<string> = []
  catOptions: Array<Boolean> = [];
  fieldCheckbox: Array<boolean> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  documentArray: Array<Boolean> = [];
  fieldImageRef: Array<any> = [];
  subscription: Subscription;
  currentImageRef: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  selectedFilesForFields: Array<FileList> = [];
  breadCrumbs: any;
  restrictedFromDate:any
  restrictedThroughDate:any


  constructor(private adminService: AdminService,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private breadService: BreadcrumbService,
    private dateValidations: DateValidationsService) { }

  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.breadCrumbs = this.breadService.getBread();
    this.getAllCategoryTypes();
    console.log('addcategory')
    this.model();
  }

  deleteAfter(name, url) {
    var obj = new Object();
    obj["name"] = name;
    obj["url"] = url;
    this.breadService.deleteAfter(obj);
    this.router.navigateByUrl(url);
  }

  model() {
    this.category = {
      name: '',
      description: '',
      imageRef: '',
      type: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,
      // modifiedBy: this.user.name,
      fields: {}
    };
  }


  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }
  resetForm() {
    this.openModal();
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {

        this.closeModal();

        this.myForm.resetForm();
        this.model();
        this.showFields = false;
        this.selectedFiles = null;
        this.currentImageRef = "";
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }


  openModal() {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }
  closeModal() {
    this.deleteDialogService.closeDialog();
  }


  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
  }

  getAllCategoryTypes() {
    this.adminService.getAllCategoryTypes().subscribe((data) => {
      this.categoryTypes = data.categoryTypes;
    })
  }

  // selectFile(event) {
  //   this.selectedFiles = event.target.files;

  //   var reader = new FileReader();
  //   reader.readAsDataURL(event.target.files[0]);
  //   reader.onload = (_event) => {
  //     this.currentImageRef = reader.result;
  //   }
  // }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.fieldImageRef[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.fieldImageRef[indexOfField] = ""
      this.selectedFilesForFields[indexOfField] = null
    } else {
      this.currentImageRef = "";
      this.selectedFiles = null;
    }
  }

  optionFieldsCondition(categoryFields) {
    for (let i = 0; i < categoryFields.length; i++) {
      if (categoryFields[i].options) {
        if ((categoryFields[i].options.length == 1) || (categoryFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      if (categoryFields[i].list) {
        this.listFlag[i] = true;
      } else {
        this.listFlag[i] = false;
      }
      if (categoryFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = categoryFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
    }
  }


  selectCode(event) {
    this.value = [];
    this.catOptions = [];
    this.currentFields = event.target.value;
    this.adminService.getCategoryFieldsByCategoryTypeCode(this.currentFields).subscribe((data) => {
      this.categoryFields = data.fields;
      console.log(this.categoryFields)
      for (let i = 0; i < this.categoryFields.length; i++) {
        if (this.categoryFields[i].type == "DOCUMENT") {
          this.documentArray[i] = true;
        } else {
          this.documentArray[i] = false;
        }
        this.multipleArray[i] = new Array<any>();
        this.fieldImageRef[i] = null;
        this.selectedFilesForFields[i] = null;
        this.lastIndexOfMultipleArray[i] = new Array<number>();
        this.listArray[i] = new Array<any>()

        if (this.categoryFields[i].list) {
          this.lastIndexOfMultipleArray[i] = 0;
          this.multipleArray[i].push({
            listValue: ''
          })
          this.lastIndexOfMultipleArray[i] = 0;
        }
      }

      this.optionFieldsCondition(this.categoryFields)
    });

    this.showFields = true;
  }



  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.category)
  }
 

  async onSubmit() {
    this.validateFromThroughDate()
    if (this.categoryFields != null) {
      this.fields = {};
      for (let i = 0; i < this.categoryFields.length; i++) {
        let fieldName = this.categoryFields[i].key;
        if (this.categoryFields[i].type == "INTEGER") {
          this.category.fields[fieldName] = parseInt(this.value[i]);
        }
        else if (this.categoryFields[i].type == "DECIMAL") {
          this.category.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.category.fields[fieldName] = this.value[i];
          console.log(this.value[i])
        }
        if (this.categoryFields[i].list) {
          for (let j = 0; j < this.multipleArray.length; j++) {
            //console.log(this.multipleArray[j].length)
            if (this.multipleArray[j].length > 0) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                //console.log(this.multipleArray[j].length)
                //console.log(this.category.fields)
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.categoryFields[i].type == "INTEGER") {
              this.category.fields[this.categoryFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.categoryFields[i].type == "DECIMAL") {
              this.category.fields[this.categoryFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.category.fields[this.categoryFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.multipleArray[i] = []
            this.category.fields[this.categoryFields[i].key] = []
          }
        }
      }
      // this.category.fields = this.fields
    }




    await this.uploadImageRef();

    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          let data = await this.callServiceToUploadImage(file)
          this.category.fields[this.categoryFields[index].key] = data.documentId;
          if (index == this.selectedFilesForFields.length - 1) {
            this.addCategoryServiceCall()
          }
        } else {
          if (index == this.selectedFilesForFields.length - 1) {
            this.addCategoryServiceCall();

          }
        }
      }
    } else {
      this.addCategoryServiceCall();
    }

  }



  //console.log(this.category.fields)
  //checking for image Upload and uploading image logic
  // if (this.selectedFiles != null) {
  // const file = this.selectedFiles.item(0);
  //console.log(file)
  // this.imageUploadService.uploadImage(file).subscribe((data) => {
  // this.category.imageRef = data.documentId;

  // this.addCategoryServiceCall();
  // })
  // } else {
  // this.addCategoryServiceCall();
  // }


  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      let data = await this.callServiceToUploadImage(file);
      this.category.imageRef = data.documentId;
    }
  }

  async callServiceToUploadImage(file: any): Promise<any> {
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  addCategoryServiceCall() {
    //deepclean
    console.log('this.category', this.category)
    let category = this.category
    category.fields = cleanDeep(this.category.fields)
    this.adminService.addCategory(category).subscribe((data) => {
      this.router.navigate(["/productcategories"]);
    });
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }
}
