import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/directives/modal.sevice';
import { environment } from 'src/environments/environment.prod';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NgForm } from '@angular/forms';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
const cleanDeep = require('src/app/shared/library/deep-clean');

@Component({
  selector: 'app-addnew-product-association',
  templateUrl: './addnew-product-association.component.html',
  styleUrls: ['./addnew-product-association.component.css']
})
export class AddnewProductAssociationComponent implements OnInit {

  @ViewChild('addNewAssociationForm', {static: false}) myForm: NgForm;
  user: any;
  productAssociations: any;
  productId: any;
  productAssociationsTypes: any[];
  categories: any[];
  products: any[];
  productFlagArray: Array<boolean> = [];
  myBucketUrl: any;
  associationProductId: any;
  selectedProduct: any;
  selectCategory: any;
  productIds: Array<any> = [];
  availableProductsIds: any[]
  availableProducts: any[]
  currentField: any;
  associationFields: Array<any>;
  showFields: boolean = false;
  value: Array<any> = [];
  fields: any;
  featureOptions: Array<string> = []
  catOptions: Array<Boolean> = [];
  convertedTypeField: Array<string> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  productName: any;
  subscription: Subscription;
  dropdownElements: Array<any> = [];
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  breadCrumbs: any;
  noProducts: boolean;
  selectedFiles: FileList;
  documentArray: Array<Boolean> = [];
  fieldImageRef: Array<any> = [];
  selectedFilesForFields: Array<FileList> = [];
  currentImageRef: any;

  @ViewChild('addProduct',{static: true}) public addProduct: ElementRef;


  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    public imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private nestedCategory: NestedCategoryService,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private ngbModalService: NgbModal) { }

  ngOnInit() {
    console.log('category-association')
    this.categoryId  = null;
    this.myBucketUrl = environment.myBucketUrl;
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.route.paramMap.subscribe((map) => {
      this.productId = map.get('productId');
      this.getAllCategory()
      this.productAssociations = {
        // name: '',
        productId: '',
        type: '',
        // fromDate: '',
        // throughDate: '',
        createdBy: this.user.name,
        fields: {}
      }
      this.getAllAssociationTypes();
    });
    

  }

  resetForm() {

    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");

        this.ngOnInit();
        this.myForm.resetForm();
        this.showFields = false;
        this.selectedProduct = null
        for (let i = 0; i < this.productFlagArray.length; i++) {
          this.productFlagArray[i] = false;
        }

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

 openModal(id:string){
   this.modalService.open(id);
 }

 closeModal(id:string){
   this.modalService.close(id);
 }
  openModal1(id: string) {
    let data = {};
    data = {
        value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal1(id: string) {
        this.deleteDialogService.closeDialog();
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
  }
  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''    
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index,k) {
    this.multipleArray[index].splice(k,1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  getAllAssociationTypes() {
    
      this.getProduct();

      this.adminService.getAllProductAssociationType().subscribe((data) => {
        this.productAssociationsTypes = data.associationTypes;
      })
  }

  getProduct(){
    this.adminService.getProductsByProductIds(this.productId).subscribe((data) =>{
      this.productName = data.products[0].name;
    })
  }

  config = {
    hasSelect: false
  }

  onClickSaveCasscade(){
    
  }

  getAllCategory() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;
      this.dropdownElements = this.nestedCategory.getProductCategoryTree(this.categories)
    });
  }

  optionFieldsCondition(associationFields) {
    this.value = []
    for (let i = 0; i < associationFields.length; i++) {
      if (associationFields[i].options) {
        if ((associationFields[i].options.length == 1) || (associationFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      if (associationFields[i].list) {
        this.listFlag[i] = true;
      }else {
        this.listFlag[i] = false;
      }
      if (associationFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = associationFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
    }
  }

  selectCode(event) {
    this.currentField = event.target.value;
    this.adminService.getAllAssociationFields(this.currentField).subscribe((data) => {
      this.associationFields = data.fields;
      //console.log(this.associationFields)
      for(let i=0; i< this.associationFields.length;i++) {
        
        if(this.associationFields[i].type == "DOCUMENT"){
          this.documentArray[i] = true;
        }else{
          this.documentArray[i] = false;
        }

        this.multipleArray[i] = new Array<any>();
       this.listArray[i] = new Array<any>();
       this.lastIndexOfMultipleArray[i] = new Array<number>();
       if(this.associationFields[i].list) {
        this.lastIndexOfMultipleArray[i] = 0;
         this.multipleArray[i].push({
           listValue: ''
         })
       }
     }
      this.optionFieldsCondition(this.associationFields)
    });

    this.showFields = true;
  }
  categoryId: any = null;
  onSelectCategory(event) {
    this.categoryId = event.id
    this.availableProducts = [];
    this.adminService.getAllCategoryProduct(this.categoryId).subscribe((data) => {
      this.products = data.products;
      this.noProducts =  this.noDataService.noData(this.products);
      for (let i = 0; i < this.products.length; i++) {
        this.productFlagArray[i] = false;
        if(this.selectedProduct != null) {
          if (this.selectedProduct.id.includes(this.products[i].id)) {
            this.productFlagArray[i] = true;
        }
        else {
          this.productFlagArray[i] = false;
        }
        }
      }
      this.availableProducts = this.products;
    });

  }

  removeCurrentProduct(products, productId) {
    return products.filter((ele) => {
      return ele != productId
    });
  }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.fieldImageRef[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

     
  resetImage(isField, indexOfField) {
    if (isField) {
      this.fieldImageRef[indexOfField] = ""
      this.selectedFilesForFields[indexOfField] = null
    } else {
      this.currentImageRef = "";
      this.selectedFiles = null;
    }
  }



  addAssociationProduct(selectProductId, id) {

    this.associationProductId = selectProductId

    this.adminService.getProductById(this.associationProductId).subscribe((data) => {
      this.selectedProduct = data.products[0];

      this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
        this.selectCategory = data.categories[0];
        this.closeNgbModal(id)
      })
    })
    this.productAssociations.productId = this.associationProductId

    for (let i = 0; i < this.products.length; i++) {
      this.productFlagArray[i] = false;
      if (this.products[i].id == this.productAssociations.productId) {
        this.productFlagArray[i] = true;
      }
    }
  }


async onSubmit() {
    if (this.associationFields != null) {
      this.fields = {};
      for (let i = 0; i < this.associationFields.length; i++) {
        let fieldName = this.associationFields[i].key;

        if (this.associationFields[i].type == "INTEGER") {
          this.productAssociations.fields[fieldName] = parseInt(this.value[i])
        } else if (this.associationFields[i].type == "DECIMAL") {
          this.productAssociations.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.productAssociations.fields[fieldName] = this.value[i]
        }
        if (this.associationFields[i].list) {
          for(let j=0; j< this.multipleArray.length; j++) {
            //console.log(this.multipleArray[j].length)
            if(this.multipleArray[j].length > 0) {
               for(let k=0; k< this.multipleArray[j].length ; k++) {
                 //console.log(this.multipleArray[j].length)
                 //console.log( this.productAssociations.fields)
                 this.listArray[j][k] =(this.multipleArray[j][k].listValue)
                }
              }
            }
            if(this.listArray[i].length > 0) {
              if(this.associationFields[i].type == "INTEGER") {
                this.productAssociations.fields[this.associationFields[i].key] =   this.listArray[i].map(numStr => parseInt(numStr))
              } else if(this.associationFields[i].type == "DECIMAL") {
                this.productAssociations.fields[this.associationFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
              }else {
                this.productAssociations.fields[this.associationFields[i].key] = this.listArray[i]
              }
            }
            else {
              this.multipleArray[i] = []
              this.productAssociations.fields[this.associationFields[i].key] = []
            }
        }                
      }
    }
    //console.log(this.productAssociations)
    await this.uploadImageRef();

      if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
        for (let index = 0; index < this.selectedFilesForFields.length; index++) {
          if (this.selectedFilesForFields[index] != null) {
            const file = this.selectedFilesForFields[index].item(0);
            let data = await this.callServiceToUploadImage(file)
            this.productAssociations.fields[this.associationFields[index].key] = data.documentId;
            if(index == this.selectedFilesForFields.length-1) {
              this.addNewProductAssociationServiceCall()
            }
          }else{
            if(index == this.selectedFilesForFields.length-1) {
              this.addNewProductAssociationServiceCall();
              
            }  
          }
        }
      }else{
        this.addNewProductAssociationServiceCall();
      }
      
   
  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      let data = await this.callServiceToUploadImage(file);
      this.productAssociations.imageRef = data.documentId;
    } 
  }
  
  async callServiceToUploadImage(file: any): Promise<any>{
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  addNewProductAssociationServiceCall(){
    let association = this.productAssociations
    association.fields = cleanDeep(this.productAssociations.fields)
    this.adminService.createProductAssociation(this.productId, association).subscribe((data) => {
      this.router.navigate(["/productdetails/" + this.productId + '/' + this.productName +"/productassociation/" + this.productId]);
    });
  }

  getBackground(image) {
   return this.backgroundImageService.getBackground(image);
  }


  openNgbModal(content) {
    this.products = [];
    this.categoryId = null
    this.ngbModalService.open(content, { size: 'lg', windowClass: 'add-product' });
  }

  closeNgbModal(content) {
    this.products = []
    this.ngbModalService.dismissAll(content)
  }


}
