import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { Subscription } from 'rxjs';
import { LoyaltyService } from '../loyalty-service/loyalty.service';
import { TierService } from './tier.service';
import { Router } from '@angular/router';
import { NoDataService } from 'src/app/services/no-data.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tiers',
  templateUrl: './tiers.component.html',
  styleUrls: ['./tiers.component.css']
})
export class TiersComponent implements OnInit {

  constructor(private tierService: TierService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService, private router: Router,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService,
    public translate : TranslateService) { }
  tierList: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  subscription: Subscription;
  noDataShow: Boolean = false;
  breadCrumbs: any;

  ngOnInit() {
    this.tierService.ngOnInit();
    this.getTiersSummary();
  }

  getTiersSummary() {
    this.tierService.getAllTiers().subscribe((response) => {
      this.tierList = response["loyaltyTiers"];
      this.noDataShow = this.noDataService.noData(this.tierList);
      console.log(this.tierList)
      for (let i = 0; i < this.tierList.length; i++) {
        this.checkboxItems[i] = false;
      }
    })
  }



  // ---------- delete methods -------------//

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.tierList.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.tierList.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  // multiple delete method
  delete() {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");
        let tierIds = []
        for (let i = 0; i < this.tierList.length; i++) {
          if (this.checkboxItems[i] == true) {
            tierIds.push(this.tierList[i].tierId)
          }
        }
        this.tierService.deleteTier(tierIds).subscribe(data => {
          this.getTiersSummary();
          this.allCheck = false
        })
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })

  }

  // single delete method
  deleteSingle(id) {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");
        var deleteQuery = "?tierIds=" + id;
        this.tierService.deleteTier(deleteQuery).subscribe(data => {
          this.getTiersSummary();
        })
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal1(id: string) {
    let data = {};
    data = {
      value: 'delete'
    };
    this.deleteDialogService.openDialog(data);
  }
  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }

  setUpBreadCrumb(key,labelName,path) {
    this.messageService.broadcast('bcLabelNew', {
      key: key,
      alternativeKey: key,
      labelName: labelName,
      path: path,
      child: []
    })
}

}