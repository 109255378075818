import { Injectable, OnInit, ChangeDetectorRef } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { BucketUrl } from 'src/app/bucket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackgroundImageService implements OnInit{

  bucketUrl: BucketUrl;
  myBucketUrl: any;
  user: any;
  timestamp: any;

  constructor() {
    this.bucketUrl = new BucketUrl();
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
  }
  
  ngOnInit(){
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    let date = new Date(JSON.parse(sessionStorage.getItem('timeStamp')))
    this.timestamp = date.getTime();
   }


  getBackground(image) {
    // this.ngOnInit()
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    let date = new Date(JSON.parse(sessionStorage.getItem('timeStamp')))
    this.timestamp = date.getTime();
    if (image == "" || image == undefined) {
      image = "noImage.jpg";
      return '/assets/images/' + image ;    
    }
    else {
      let imageUrlWithTenant = environment.IMAGE_VIEW_URL.replace("{tenant}", this.user.tenant);
      let imageUrlWithDocumentId = imageUrlWithTenant.replace("{documentId}", image);
      return imageUrlWithDocumentId + '?' + this.timestamp;
    //   let url: any;
    //   url = environment.BASE_URL + "/documentstore/api/v1/documents/" + this.user.tenant + "/" + image + '?'+ this.timestamp;
    //   // url = "http://ec2-54-197-219-77.compute-1.amazonaws.com/documentstore/api/v1/documents/" + this.user.tenant + "/" + image + '?' + timestamp.getTime();
    //  console.log(url)
    //   return  url;
    }
  }
}

