import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-add-feature-options',
  templateUrl: './add-feature-options.component.html',
  styleUrls: ['./add-feature-options.component.css']
})
export class AddFeatureOptionsComponent implements OnInit {

  featureId: any;
  featureOption: any;
  selectedFiles: FileList;
  productFeatures: any[];
  productId: any;
  price: any;
  currentFeature: any;
  uid: any;
  featureOptions: Array<any> = [];
  myBucketUrl: any;
  currentFeatureOption: any;
  updateFeatureOption: any;
  basePrice: any;
  featureOptionIndex: any;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.myBucketUrl = environment.myBucketUrl;
    this.route.paramMap.subscribe((map) => {
      this.featureId = map.get('featureId');
      //console.log(this.featureId)
      this.productId = map.get('productId');
      //console.log(this.productId)

      this.getProductFeature()
    });


    // this.route.paramMap.subscribe((map) => {
    //   this.productId = map.get('productId')
    //   //console.log(this.productId)
    // })



  }

  getProductFeature() {
    this.adminService.getProductFeatureByProductId(this.productId).subscribe((data) => {
      this.productFeatures = data.productWithFeatures.features;
      //console.log(data)
      //console.log(this.productFeatures)

      this.featureOption = {
        options: {
          name: '',
          description: '',
          imageRef: '',
          price: {}
        }
      }

      for (let i = 0; i < this.productFeatures.length; i++) {
        if (this.productFeatures[i].id == this.featureId) {
          this.currentFeature = this.productFeatures[i];
          this.featureOptions = this.productFeatures[i].options;
          //console.log(this.productFeatures[i].options)
          //console.log(this.featureOptions)
        }
      }
      //console.log(this.currentFeature)
      //console.log(this.featureOptions)
    });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  openModal(id: string) {
    this.modalService.open(id);
    ////console.log(document.getElementById('mbody').clientHeight);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    ////console.log(event.target)
  }

  openEditModal(id: string, index, currentFeatureOption) {
    this.modalService.open(id);
    this.featureOptionIndex = index;
    this.currentFeatureOption = currentFeatureOption
    //console.log(this.currentFeatureOption)
    //console.log(index);

    this.updateFeatureOption = {
      options: {
        name: this.currentFeatureOption.name,
        description: this.currentFeatureOption.description,
        imageRef: this.currentFeatureOption.imageRef,
        price: this.currentFeatureOption.price
      }
    }
    this.basePrice = this.currentFeatureOption.price.value;
    //console.log(this.price)
  }

  closeEditModal(id) {
    this.modalService.open(id);
  }


  updateOption(id) {
    this.updateFeatureOption.options.price["value"] = this.basePrice;
    this.updateFeatureOption.options.price["currency"] = "USD";
    
    this.currentFeature.options[this.featureOptionIndex] = (this.updateFeatureOption.options)
    
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.currentFeatureOption.imageRef != "") {
        this.imageUploadService.editImage(file, this.currentFeatureOption.imageRef).subscribe((data) => {
          this.addFeatureOptionServiceCall(id);
        })
      } else {
        this.imageUploadService.uploadImage(file).subscribe((data) => {
          this.updateFeatureOption.options.imageRef = data.documentId;

          this.addFeatureOptionServiceCall(id);
        })
      }
    } else {
      this.addFeatureOptionServiceCall(id);
    }

    
  }

  onSubmit(id) {
    this.featureOption.options.price["value"] = this.price;
    this.featureOption.options.price["currency"] = "USD";

    this.currentFeature.options.push(this.featureOption.options)

    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);

      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.featureOption.option.imageRef = data.documentId;

        this.addFeatureOptionServiceCall(id);
      })
    } else {
      this.addFeatureOptionServiceCall(id);
    }


    
  }

  addFeatureOptionServiceCall(id){
    this.adminService.updateProductFeatureById(this.productId, this.featureId, this.currentFeature).subscribe((data) => {
      this.modalService.close(id);
    });
  }


  getBackground(image) {
    if (image == "") {
      image = "noImage.jpg";
      return this.sanitizer.bypassSecurityTrustStyle('url(/assets/images/' + image + ')');
    }
    else {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.myBucketUrl + image + ')');
    }
  }


}
