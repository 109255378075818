import { Component, OnInit } from '@angular/core';
import * as CanvasJS from '../../../../../assets/chart/canvasjs.min.js';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-rewarddetails',
  templateUrl: './rewarddetails.component.html',
  styleUrls: ['./rewarddetails.component.css']
})
export class RewarddetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.getCustomerUsage();
    this.loadPieForAge();
    this.getEarnChart();
    this.loadPieForCountry();
  }


  getCustomerUsage() {
    var chart = new CanvasJS.Chart("chartContainer", {
      exportEnabled: true,
      animationEnabled: true,
      title: {
        text: "Customer Usage Statistics",
        fontFamily: "Fjalla One",
        fontColor:'#12abdb'
      },
      axisY: {
        title: "No. of Customers",
        valueFormatString: "#,##0.##",
        suffix: "",
        prefix: ""
      },
      data: [{
        type: "splineArea",
        color: "rgba(54,158,173,.7)",
        markerSize: 5,
        xValueFormatString: "DD/MM/YYYY",
        yValueFormatString: "#,##0.##",
        dataPoints: [
          { x: new Date(2020, 0, 1), y: 3289 },
          { x: new Date(2020, 0, 2), y: 3830 },
          { x: new Date(2020, 0, 3), y: 2009 },
          { x: new Date(2020, 0, 4), y: 284 },
          { x: new Date(2020, 0, 5), y: 2396 },
          { x: new Date(2020, 0, 6), y: 1613 },
          { x: new Date(2020, 0, 7), y: 2821 },
          { x: new Date(2020, 0, 8), y: 2000 },
          { x: new Date(2020, 0, 9), y: 1397 },
          { x: new Date(2020, 0, 10), y: 2506 }

        ]
      }]
    });
    chart.render();
  }

  loadPieForAge(){
var piechart = new CanvasJS.Chart("piechartContainer", {
	exportEnabled: true,
	animationEnabled: true,
	title:{
    text: "Age-based Analysis",
    fontFamily: "Fjalla One",
    fontColor:'#12abdb'
	},
	legend:{
		cursor: "pointer",
		itemclick: this.explodePie
	},
	data: [{
    type: "pie",
    radius: 110,
    indexLabelFontSize: 12,
		showInLegend: true,
		toolTipContent: "{name}: <strong>{y}%</strong>",
		indexLabel: "{name} - {y}%",
		dataPoints: [
			{ y: 2, name: "<11 years" },
			{ y: 26, name: "11-20 years",},
			{ y: 20, name: "21-30 years" },
			{ y: 13, name: "31-40 years" },
			{ y: 7, name: "41-50 years" },
			{ y: 32, name: ">50 years"}
		]
	}]
});
piechart.render();
}


  explodePie (e) {
    if(typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === "undefined" || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
      e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
    } else {
      e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
    }
    e.piechart.render();
  
  }
getEarnChart()
{
  
var earnchart = new CanvasJS.Chart("earnchartContainer", {
  exportEnabled: true,
	theme:"light2",
	animationEnabled: true,
	title:{
    text: "EARN-vs-REDEEM",
    fontFamily: "Fjalla One",
    fontColor:'#12abdb',
    fontWeight: "normal",
	},
	axisY :{
		includeZero: false,
		title: "Number of Customer",
		suffix: ""
	},
	toolTip: {
		shared: "true"
	},
	legend:{
		cursor:"pointer",
		itemclick : this.toggleDataSeries
	},
	data: [
      {
		type: "spline",
		visible: true,
		showInLegend: true,
		yValueFormatString: "##",
		name: "Earned",
		dataPoints: [
			{ label: "2020-01-01", y: 0 },
			{ label: "2020-01-02", y: 3 },
			{ label: "2020-01-03", y: 2 },
			{ label: "2020-01-04", y: 3 },
			{ label: "2020-01-05", y: 5 },
			{ label: "2020-01-06", y: 6 },
			{ label: "2020-01-07", y: 3 },
			{ label: "2020-01-08", y: 3 },
			{ label: "2020-01-09", y: 1 },
			{ label: "2020-01-10", y: 0 }
		]
	},
	{
		type: "spline", 
		showInLegend: true,
		visible: true,
		yValueFormatString: "##",
		name: "Redeem",
		dataPoints: [
			{ label: "2020-01-01", y: 0 },
			{ label: "2020-01-02", y: 1 },
			{ label: "2020-01-03", y: 2 },
			{ label: "2020-01-04", y: 2 },
			{ label: "2020-01-05", y: 4 },
			{ label: "2020-01-06", y: 4 },
			{ label: "2020-01-07", y: 3 },
			{ label: "2020-01-08", y: 2 },
			{ label: "2020-01-09", y: 0 },
			{ label: "2020-01-10", y: 0 }
		]
	}
          ]
});
earnchart.render();
}

  toggleDataSeries(e) {
    if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible ){
      e.dataSeries.visible = false;
    } else {
      e.dataSeries.visible = true;
    }
    e.earnchart.render();
  }


  loadPieForCountry(){
    var piechart = new CanvasJS.Chart("countrychartContainer", {
      exportEnabled: true,
      animationEnabled: true,
      title:{
        text: "Top 5 Country-Based Analysis",
        fontFamily: "Fjalla One",
        fontColor:'#12abdb'
      },
      legend:{
        cursor: "pointer",
        itemclick: this.explodePie
      },
      data: [{
        type: "pie",
        radius: 110,
        indexLabelFontSize: 12,
        showInLegend: true,
        toolTipContent: "{name}: <strong>{y}%</strong>",
        indexLabel: "{name} - {y}%",
        dataPoints: [
          { y: 30, name: "India" },
          { y: 25, name: "USA" },
          { y: 20, name: "Japan",},
          { y: 15, name: "Australia" },
          { y: 10, name: "France" }
          
        ]
      }]
    });
    piechart.render();
    }



}
