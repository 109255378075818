import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BucketUrl } from 'src/app/bucket';
import { ModalService } from 'src/app/directives/modal.sevice';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { PromotionFilters } from 'src/app/shared/constants/filter-model/PromotionFilters';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { PromotionServiceService } from './promotions-service/promotion-service.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {

  promotions: any[];
  bucketUrl: BucketUrl;
  myBucketUrl: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  subscription: Subscription;
  noDataShow: Boolean = false;
  breadCrumbs: any;
  searchName: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  numberOfPages: number = 1;
  totalItems: number;
  filters: any = PromotionFilters;
  pagination: any;
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  isTenantOO :Boolean=false
  // sort: Array<any> =sortConstant.PROMOTION_SORT


  constructor(private promotionService: PromotionServiceService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService,
    public translate: TranslateService,
    private router: Router) {
    this.bucketUrl = new BucketUrl();
    this.filters = new PromotionFilters();
  }

  ngOnInit() {
    let user =JSON.parse(sessionStorage.getItem('user'))
    if(user.tenant=='Order Orchestration'){
      this.isTenantOO=true
    }
    this.promotionService.ngOnInit();
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  getPromotionByQuery(filters, itemsPerPage, pageNumber, sort) {
    this.promotions = [];
    this.pagination = null;
    this.allCheck = false;
    this.promotionService.getAllPromotionsByQuery(filters, itemsPerPage, pageNumber, sort).subscribe((data) => {
      this.pagination = data.pagination;
      this.promotions = data.promotions;
      this.totalItems = this.pagination.totalNumberOfRecords;
      this.numberOfPages = this.pagination.numberOfPages;
      console.log(this.promotions)
      this.backgroundImage(this.promotions);
      this.noDataShow = this.noDataService.noData(this.promotions);
    });
  }

  backgroundImage(promotions) {
    this.checkboxItems = []
    for (let i = 0; i < promotions.length; i++) {
      this.checkboxItems[i] = false;
    }
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.promotions.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.promotions.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  // multiple delete method
  delete() {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");
        let promotionIds = []
        for (let i = 0; i < this.promotions.length; i++) {
          if (this.checkboxItems[i] == true) {
            promotionIds.push(this.promotions[i].id)
          }
        }
        this.promotionService.deletePromotionById(promotionIds).subscribe((data) => {
          this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
          this.allCheck = false
        })
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })

  }


  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openModal1(id: string) {
    let data = {};
    data = {
      value: 'delete'
    };
    this.deleteDialogService.openDialog(data);
  }
  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }

  setUpBreadCrumb(key, labelName, path) {
    this.messageService.broadcast('bcLabelNew', {
      key: key,
      alternativeKey: key,
      labelName: labelName,
      path: path,
      child: []
    })
  }

  onChangePage(event) {
    this.currentPage = event;
    this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  addItemsPerPage(event) {
    this.itemsPerPage = event;
    this.currentPage = 1;
    this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }



  findName(event) {
    console.log(event);
    this.searchName = event;
    if (this.searchName != '') {
      this.filters['code'] = this.searchName;
      this.currentPage = 1;
      this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    }
  }

  hideCross(event) {
    this.searchName = '';
    // this.filters['code'] = null;
    delete this.filters.code
    this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);

  }

  async onSelectSort(event) {
    this.sort[0] = this.fields
    console.log(this.sort)
    if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING')) {
      this.sort[0].sortDirection = await event.target.value
      this.sort[0].fieldName = 'name'
      console.log(this.sort)
    } else {
      // this.sort[0].sortDirection = await null
      delete this.sort[0].sortDirection

    }

    this.getPromotionByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);

  }
}
