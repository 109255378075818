import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { SplitUrlService } from '../shared/split-url/split-url.service';
import { RemoveDotPipe } from '../shared/pipes/RemoveDotPipe';

@Component({
  selector: 'app-root',
  templateUrl: './errordialog.component.html',
  styleUrls: ['./errordialog.component.css']
})
export class ErrorDialogComponent {
  reason: any;
  status: any;
  correlationId: any;
  reasonMessage: any;
  title = 'Angular-Interceptor';
  showMore:any =false;
  url: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public splitUrlService: SplitUrlService) {
    console.log(data)
    this.reason = data;
    this.status = data;
    this.correlationId = data;
    this.showMore = false;
    this.url = this.splitUrlService.splitUrl(data['url'])[0];

    if(data['reasonMessage']) {
      this.reasonMessage = data.reasonMessage.key.replace(/\./g, " ")
    }
  }

  open(){
    //console.log("hello")
    //console.log(this.showMore)
    this.showMore = !this.showMore;
  }
}
