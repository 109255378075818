import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationExtras } from '@angular/router';
import { filter } from 'rxjs/operators';
import  {MessageSubjectService} from '../../services/message-subject.service';
import * as glob from '../../services/globals';



@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.css'],
})
export class BreadcrumbComponent implements OnInit {
    
    showChild: Boolean = false;
    routeDataName: string = 'breadcrumbItem';
    $router: any;    
    breadcrumbList: BreadcrumbItem[] = [];
    bcInitItem: BreadcrumbItem ;
    navState: any = {};
    activeChild: any = {};
    //isBrowserRefresh: boolean = false;
    breadcrumbHistoryList: Array<any> = [];
    private subscription_label: Subscription;
    private addNewSubscription_label : Subscription;
    private primary_label: Subscription;
    private nestedSubscription_label: Subscription;
    private splice_label:Subscription;
    private childSplice_label: Subscription;
    
    
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private messageService: MessageSubjectService) {
        // For handle browser back/forward/history scenarios.
        this.router.events.pipe(filter(value => value instanceof NavigationStart)).subscribe((value: any) => {
            this.navState = {
                id: value.id,
                navigationTrigger: value.navigationTrigger,
                restoredState: value.restoredState
            };
            // console.log(this.navState)
            //this.isBrowserRefresh = this.router.navigated;
        });
        
        this.router.events.pipe(filter(value => value instanceof NavigationEnd)).subscribe((value: any) => { 
            //Restore history list after browser refresh.
            if (this.breadcrumbHistoryList.length < 1 && window.sessionStorage.getItem('breadcrumbHistoryList') != null) {
                this.breadcrumbHistoryList = JSON.parse(window.sessionStorage.getItem('breadcrumbHistoryList'));
                // alert("history list")
            }
            
            //Browser back/forward/history.
            if (this.navState.navigationTrigger == 'popstate' && this.navState.restoredState != null) {
                for (let idx: number = this.breadcrumbHistoryList.length - 1; idx >= 0; idx--) {
                    if (this.breadcrumbHistoryList[idx].id == this.navState.restoredState.navigationId && 
                        this.breadcrumbHistoryList[idx].url == value.urlAfterRedirects) {
                            this.breadcrumbList = this.breadcrumbHistoryList[idx].breadcrumbList;
                            // console.log(this.breadcrumbList)
                            break;
                        }
                    }                
                    // alert("backward forward")
                }
                //Imperetive action.
                else {
                    let rootRoute: ActivatedRoute = this.activatedRoute.root;
                    // console.log(rootRoute)
                    
                    //Check and get cached breadcrumbList.             
                    if (this.breadcrumbList.length < 1 &&
                        //Get cached breadcrumbList when browser refresh.
                        window.sessionStorage.getItem('breadcrumbList') != null) {
                            this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));
                            // console.log(this.breadcrumbList)                            
                        }                
                        
                        //Refresh breadcrumb items.
                        this.refreshBreadcrumbs(rootRoute);
                        // }
                        // console.log(this.breadcrumbList)
                        for(let i=0; i< this.breadcrumbList.length; i++) {
                            if(this.breadcrumbList[i]['child'].length > 0) {
                                // console.log(this.activeChild)
                                this.activeChild = this.breadcrumbList[i]['child'][this.breadcrumbList[i]['child'].length-1];
                                break;
                            }
                        }
                        // console.log(this.activeChild)
                        
                    }            
                    
                    //Save breadcrumbList to session object after every breadcrumb update for browser refreshing action.
                    // window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList)); 
                    // alert("set breadcrumb")
                    //Save history item for browser back/forward.
                    let bcHistoryItem = {
                        id: this.navState.id,
                        url: value.urlAfterRedirects,
                        breadcrumbList: glob.deepClone(this.breadcrumbList)
                    }
                    this.breadcrumbHistoryList.push(bcHistoryItem);
                    //Save to sessionStorage for browser refresh.
                    if(this.breadcrumbHistoryList[0].url != '/') {
                        window.sessionStorage.setItem('breadcrumbHistoryList', JSON.stringify(this.breadcrumbHistoryList)); 
                    } 
                }); 
                
                this.primary_label = this.messageService.subscribe('primary',(eventData) => {
                    this.breadcrumbList.length = 0;
                    this.breadcrumbList  = []
                    this.breadcrumbList.push(eventData);
                    window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                    this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                    // console.log(this.breadcrumbList);
                })
                
                
                this.splice_label = this.messageService.subscribe('splice', (eventData) => {
                    for(let i=0; i<=this.breadcrumbList.length-1 ; i++) {
                        if(this.breadcrumbList[i].alternativeKey == eventData.alternativeKey) {
                            this.breadcrumbList.splice(i);
                            break;
                        }
                    }
                    window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                    this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                    // console.log(this.breadcrumbList);
                });
                
                this.childSplice_label = this.messageService.subscribe('childSplice',(eventData) => {
                    // console.log(eventData);
                    this.getChildBreadcrumb(this.breadcrumbList,eventData)
                    
                })
                
                this.nestedSubscription_label = this.messageService.subscribe('nestedLabel',(eventData) => {
                    // console.log(eventData)
                    // console.log(this.breadcrumbList[this.breadcrumbList.length-1])
                    this.breadcrumbList[this.breadcrumbList.length-1]['child'].push(eventData);
                    window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                    this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                    // console.log(this.breadcrumbList);
                    this.activeChild = eventData;
                })                
                this.addNewSubscription_label = this.messageService.subscribe("bcLabelNew",(eventData) => {
                    this.breadcrumbList.push(eventData);
                    window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                    this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                });
                this.subscription_label = this.messageService.subscribe('bcLabelOverwrite', (eventData) => {
                    //Update breadcrumb label with data sent from message service.
                    //eventData format: {key: 'string', labelName: 'string'}
                    // alert("subs label")
                    // console.log(eventData);
                    // console.log(this.breadcrumbList)
                    for (let idx= 0 ; idx<= this.breadcrumbList.length-1 ; idx++) {   
                        // console.log("inside list for")
                        // console.log(eventData)        
                        // console.log(this.breadcrumbList[idx].alternativeKey == eventData.alternativeKey) 
                        if (this.breadcrumbList[idx].alternativeKey == eventData.alternativeKey) {
                            // console.log(this.breadcrumbList[idx]);    
                            this.breadcrumbList[idx].labelName = eventData.labelName;
                            // this.breadcrumbList[idx].alternativeKey = eventData.alternativeKey;
                            this.breadcrumbList[idx].path = eventData.path;
                            // update = true;
                            // if(update) {
                            // console.log(this.breadcrumbList[idx]);    
                            window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                            this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                            // console.log(this.breadcrumbList);
                            break;
                        }
                    }    
                });
            }
            
            ngOnInit() {
                
            }
            
            getChildBreadcrumb(data,eventData) {
                for(let i=0; i< data.length;i++) {
                    if(data[i].alternativeKey == eventData.alternativeKey) {
                        data.splice(i);
                        break;
                    }
                    if(data[i].child.length > 0) {
                        this.getChildBreadcrumb(data[i].child,eventData);
                    }
                }
                window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                
            }
            
            openChild() {
                this.showChild = !this.showChild;
            }
            
            ngOnDestroy() {
                this.subscription_label.unsubscribe();
                this.addNewSubscription_label.unsubscribe();
                this.primary_label.unsubscribe();
                this.splice_label.unsubscribe();
                this.childSplice_label.unsubscribe();
            }
            
            loadBaseBreadcrumbList(rootRoute) {
                // console.log(rootRoute)
                this.breadcrumbList.length = 0;
                this.breadcrumbList = []
                this.bcInitItem = rootRoute.snapshot.data;
                this.bcInitItem.path = rootRoute['_routerState'].snapshot.url;
                // console.log(this.breadcrumbList)
                this.breadcrumbList.push(this.bcInitItem);
                window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                // console.log(this.breadcrumbList)
            }
            
            refreshBreadcrumbs(route: ActivatedRoute, pathParams: PathParams = { path: '', pathParamList: []}) {                
                //Recursively find the child route with breadcrumbItem object.
                let child: ActivatedRoute;
                
                if (route.firstChild) {
                    child = route.firstChild;
                    // console.log(child)    
                }
                else {
                    // console.log("return")
                    return;
                }
                
                //If the route doesn't have specified breadcrumbItem, add possible parent url, and recursive to its child.
                if (!child.snapshot.data.hasOwnProperty(this.routeDataName)) {
                    //Take URL parts from parents.
                    pathParams = this.getPathAndParams(child, pathParams);
                    //Recursive call.
                    this.refreshBreadcrumbs(child, pathParams);
                    return;
                }            
                
                if (this.breadcrumbList.length > 0) {            
                    if (child.snapshot.data[this.routeDataName['afterBaseOnly']]) {
                        //Reset to base breadcrumbs.
                        // console.log("reset")
                        this.loadBaseBreadcrumbList(child);
                    }
                    else {
                        //Find breadcrumb key and index.
                        // alert("inside splice")
                        // console.log(this.routeDataName)
                        let bcKey: string = child.snapshot.data[this.routeDataName].alternativeKey;
                        // console.log(bcKey)
                        let bcIndex: number = this.getBreadcrumbPositionByKey(bcKey);
                        // console.log(bcIndex)
                        if (bcIndex >= 0) {
                            //Remove the breadcrumb trailing items.
                            //Also remove the same item if already exists to handling hierarchical issue for URL segment parameter.
                            this.breadcrumbList.splice(bcIndex);
                            // console.log(this.breadcrumbList)
                        }
                        
                        //Remove existing breadcrumb of route for terminalOnly which should not be displayed, and
                        //no navigation action on breadcrumb for the terminalOnly route.
                        if (this.breadcrumbList.length > 0 && this.breadcrumbList[this.breadcrumbList.length - 1].terminalOnly) {
                            this.breadcrumbList.length = this.breadcrumbList.length - 1;
                        }
                    }                           
                }
                
                //Bypass adding breadcrumb for the current route if it contains a child with crossBranch 'y' and segmentParam 'y'.
                //Scenario: cross-branch navigation to a segment param route. In this case, the parent route should be excluded from the breadcrumb list.
                //Comment out below block to reproduce the issue.
                let cbpRoute: ActivatedRoute = this.findCrossBranchSegmentParamRoute(child);
                // console.log(cbpRoute)
                if (cbpRoute) {
                    //Recursive call.
                    this.refreshBreadcrumbs(child, pathParams);
                    return;
                }
                
                //Add URL parts for this route with breadcrumb item.
                pathParams = this.getPathAndParams(child, pathParams);            
                // console.log(pathParams)
                // console.log(child.snapshot.data)
                //Test: compare full url taken from routerState:
                //let te: string = this.router.routerState.snapshot.url;
                
                //Set breadcrumb item object.
                let breadcrumbItem: BreadcrumbItem = { 
                    key: child.snapshot.data[this.routeDataName].key,
                    alternativeKey: child.snapshot.data[this.routeDataName].key,
                    labelName: child.snapshot.data[this.routeDataName].labelName,
                    path: pathParams.path,
                    terminalOnly: child.snapshot.data[this.routeDataName].terminalOnly || false,
                    afterBaseOnly: child.snapshot.data[this.routeDataName].afterBaseOnly || false,
                    pathParamList: pathParams.pathParamList,
                    queryParams: pathParams.queryParams,
                    fragment: pathParams.fragment   ,
                    child: []             
                };
                //Add item to breadcrumb list.
                this.breadcrumbList.push(breadcrumbItem);            
                // console.log(this.breadcrumbList)
                //Recursive call.
                
                // this.refreshBreadcrumbs(child, pathParams);
                return;                 
            }    
            
            findCrossBranchSegmentParamRoute(route: ActivatedRoute): ActivatedRoute {
                //Recursively find the child route with matrix params crossBranch and segmentParam.
                let child: ActivatedRoute;
                if (route.firstChild) {
                    child = route.firstChild;
                }
                else {
                    return null;
                }
                
                if (child.snapshot.params['crossBranch'] == 'y' && child.snapshot.params['segmentParam'] == 'y') {
                    return child;
                }
                else {
                    this.findCrossBranchSegmentParamRoute(child);
                } 
            }
            
            openPageWithBreadcrumb(index: number) {
                console.log(index);
                //Check and get queryParams and fragment.
                let navigationExtras: NavigationExtras;
                if (this.breadcrumbList[index].queryParams) {
                    navigationExtras = {
                        queryParams: this.breadcrumbList[index].queryParams
                    };
                }   
                if (this.breadcrumbList[index].fragment) {
                    if (!navigationExtras) {
                        navigationExtras = {};
                    }
                    navigationExtras.fragment = this.breadcrumbList[index].fragment;
                }        
                
                //check and get matrix params.
                if (this.breadcrumbList[index].pathParamList && 
                    this.breadcrumbList[index].pathParamList.length > 0) {            
                        
                        //Ignore the pathParamList and do general path if object contains 'ignoreParam'.
                        if (this.breadcrumbList[index].pathParamList.find(x => x.ignoreParam)) {                
                            if (navigationExtras) {
                                this.router.navigate([this.breadcrumbList[index].path], navigationExtras);
                            }
                            else {
                                this.router.navigate([this.breadcrumbList[index].path]);
                            }
                        }
                        else {
                            if (navigationExtras) {
                                this.router.navigate(this.breadcrumbList[index].pathParamList, navigationExtras);
                            }
                            else {
                                this.router.navigate(this.breadcrumbList[index].pathParamList);
                            }
                        }            
                    }
                    //Do general path.
                    else {
                        // console.log(navigationExtras)
                        if (navigationExtras) {
                            // console.log("inside")
                            this.router.navigate([this.breadcrumbList[index].path], navigationExtras);
                        }
                        else {
                            // console.log(this.breadcrumbList[index].path)
                            this.router.navigate([this.breadcrumbList[index].path]);
                            // console.log(this.breadcrumbList[index].alternativeKey)
                            // console.log(index)
                            let bcIndex =this.getBreadcrumbPositionByKey(this.breadcrumbList[index].alternativeKey);
                            // console.log(bcIndex)
                            if (bcIndex >= 0) {
                                //Remove the breadcrumb trailing items.
                                //Also remove the same item if already exists to handling hierarchical issue for URL segment parameter.
                                for(let i=bcIndex; i<= this.breadcrumbList.length-1 ; i++) {
                                    // console.log(this.breadcrumbList[i+1])
                                    this.breadcrumbList.splice(i+1);
                                    // console.log("Splice")
                                   
                                }
                                // console.log("inside index")
                            }
                            // console.log(this.breadcrumbList);
                            window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                            this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                            // console.log(this.breadcrumbList);
                        }
                    }                
                }

             
                
                openSubPageWithBreadcrumb(index,event) {
                    let childAlternativeKey = event.target.id;
                    // console.log(childAlternativeKey)
                    let bcIndex;
                    // console.log(index,event.target.value)
                    // console.log(this.breadcrumbList[index].path);
                    for(let j=index; j< this.breadcrumbList.length ; j++) {
                        this.breadcrumbList.splice(j+1)
                    }
                    for(let i=0; i< this.breadcrumbList[index].child.length ; i++) {
                        
                        if(this.breadcrumbList[index].child[i].alternativeKey == childAlternativeKey) {
                            bcIndex = i;
                            // console.log(bcIndex)
                            break;
                        }
                    }
                    // console.log(bcIndex);
                    
                    this.router.navigate([this.breadcrumbList[index]['child'][bcIndex].path]);
                    if(bcIndex >=0) {
                        for(let i=bcIndex; i< this.breadcrumbList[index].child.length;i++) {
                            this.breadcrumbList[index].child.splice(i+1);
                        }
                    }
                    window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                    this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                    // console.log(this.breadcrumbList);
                    this.showChild = false;
                }
                
                openPageBreadcrumb(currentChild,index) {
                    // console.log(currentChild)
                    // console.log(index)
                    this.router.navigate([currentChild.path]);
                    for(let i=index; i< this.breadcrumbList.length ;i++) {
                        this.breadcrumbList.splice(i+1);
                        window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
                        this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));       
                        // console.log(this.breadcrumbList);
                    }
                }
                
                
                getBreadcrumbPositionByKey(key: string): number {
                    let rtnIndex: number = -1;
                    for (let idx: number = this.breadcrumbList.length - 1; idx >= 0; idx--) {
                        if (this.breadcrumbList[idx].alternativeKey == key) {
                            rtnIndex = idx;
                            break;
                        }
                    }
                    // console.log(rtnIndex)
                    return rtnIndex;
                }
                
                getPathAndParams(route: ActivatedRoute, pathParams: PathParams): PathParams {        
                    let thisPath: string = '';        
                    //Url param '/:id' is a segment.path. 
                    thisPath = route.snapshot.url.map(segment => segment.path).join('/'); 
                    if (thisPath != '') {
                        //Process matrix params.
                        //Format of pathParamList: ['path', {param data}, 'path', {param data}].
                        let matParams: any = route.snapshot.url.map(
                            segment => segment.parameters
                            
                            );
                            
                            if (matParams.length > 0 && Object.getOwnPropertyNames(matParams[0]).length > 0) {                
                                pathParams.pathParamList.push(thisPath);
                                let params: any = {};                
                                for (let item of matParams) {
                                    for (let prop of Object.keys(item)) {                        
                                        params[prop] = item[prop];                                                
                                    }                    
                                }
                                pathParams.pathParamList.push(params);                                
                            } 
                            
                            //Get query params if any - always for the last segment.            
                            if (route.snapshot.queryParamMap.keys.length > 0) {
                                pathParams.queryParams = {};
                                for (let key of route.snapshot.queryParamMap.keys) {
                                    pathParams.queryParams[key] = route.snapshot.queryParamMap.get(key);
                                }
                                ////Not good using below logic since this whould have proto and symbol properties.
                                //route.queryParamMap.subscribe(params => {
                                //    let paramMap: any = { ...params };
                                //    pathParams.queryParams = paramMap.params;
                                //});
                            }
                            //Get fragment if any - always for the last segment.
                            if (route.snapshot.fragment) {
                                route.fragment.subscribe(value => {
                                    pathParams.fragment = value;
                                });
                            }           
                            
                            pathParams.path += `/${thisPath}`;
                        }
                        return pathParams;
                    }
                }
                
                
                export class BreadcrumbItem {    
                    key: string = undefined;
                    alternativeKey: string = undefined;
                    labelName: string = undefined;
                    path: string = '';   
                    terminalOnly?: boolean = undefined;
                    afterBaseOnly?: boolean = undefined;
                    pathParamList?: Array<any> = [];
                    queryParams?: any = undefined;
                    fragment?: string = undefined;
                    child: Array<any> = [];
                }
                
                export class PathParams {
                    path: string = '';
                    pathParamList?: Array<any> = [];
                    queryParams?: any = undefined;
                    fragment?: string = undefined;
                }
                
                
                