import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/directives/modal.sevice';
import { TierService } from '../../tiers/tier.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { Router } from '@angular/router';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RewardService } from '../reward.service';
import { Action } from '../../promotions/promotion-model/promotion';
import { AdminService } from 'src/app/admin/admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import { Validators, NgForm } from '@angular/forms';
const cleanDeep = require('clean-deep');

@Component({
  selector: 'app-add-reward',
  templateUrl: './add-reward.component.html',
  styleUrls: ['./add-reward.component.css']
})
export class AddRewardComponent implements OnInit {

  reward: any;
  categories: Array<any> = [];
  dropdownElements: Array<any> = [];

  selectedFiles: FileList;
  subscription: Subscription;
  conditionModelArray: Array<any> = []
  currentImageRef: any;
  user: any;
  requestReward: Array<string> = [];
  dropdownList = [];
  dropdownSettings: IDropdownSettings;
  actionClass: Action;
  actionTypeKeys: Array<any> = []
  actionTypeValues: Array<any> = [];
  actionTypeValue: any;
  showOtherField: Boolean = false;
  showAmount: Boolean = false;
  actionProductId: any;
  selectedProduct: any = null;
  selectCategory: any;
  actionCatalogId: any;
  productFlagArray: Array<boolean> = [];
  catalogFlagArray: Array<boolean> = [];
  noProductOnCategoryFlag: boolean = false;
  products: Array<any> = [];
  catalogs: Array<any> = [];
  typeValue: any;
  selectedCouponSource: string;
  actionsForm: any;
  breadCrumbs: any;
  restrictedFromDate: any = null
  restrictedThroughDate: any = null
  dropdownDisable: boolean;
  selectedItems = [];
  test: any
  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;



  constructor(private modalService: ModalService,
    private tierService: TierService,
    private rewardService: RewardService,
    private adminService: AdminService,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private ngbModalService: NgbModal,
    private nestedCategoryService: NestedCategoriesService,
    private messageService: MessageSubjectService,
    public translate: TranslateService,
    private dateValidations: DateValidationsService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.actionClass = new Action();
    this.actionTypeKeys = this.actionClass.getActionTypeKeys();
    this.actionTypeValues = this.actionClass.getActionTypeValues();
    this.tierService.getAllTiers().subscribe(data => {
      this.dropdownList = data["loyaltyTiers"];
      console.log(this.dropdownList)
    })

    this.rewardModel();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'tierId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 7,
      allowSearchFilter: true
    };

  }


  rewardModel() {
    this.reward = {
      imageRef: '',
      name: '',
      description: '',
      points: '',
      couponSource: '',
      couponPartnerId: '',
      couponCodeInternal: '',
      couponActionInternal: {},
      instructions: ' ',

      tierIds: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,

    }
    this.requestReward = []
    this.actionModel();
    this.selectedItems = [
    ]
    console.log("reward", this.reward)
  }


  actionModel() {
    this.actionsForm = {
      type: '',
      quantity: '',
      amount: '',
      productId: '',
      catalogId: ''
    }
  }



  actionType(event) {
    this.actionTypeValue = event.target.value;
    // this.actionTypeConditions(this.actionTypeValue)
    if (this.actionTypeValue == "PROMO_ORDER_PERCENT") {
      this.showAmount = true;
      this.showOtherField = false;
      this.selectedProduct = null;
      this.actionsForm.quantity = null;
      this.actionsForm.productId = null;
      this.actionsForm.catalogId = null;
    }
    else if (this.actionTypeValue == "PROMO_GWP") {
      this.showAmount = false;
      this.showOtherField = true;
      this.actionsForm.amount = null
    }
    else {
      this.reward.LoyaltyRewardAction = {};
      this.selectedProduct = null;
      this.showAmount = false;
      this.showOtherField = false;
    }
  }

  onSelectType(event) {
    this.typeValue = event.target.value;
    if (this.typeValue == 'Product') {
      this.products = []
    }
    if (this.typeValue == "Catalog") {
      this.getAllCatalogs();
      this.catalogs = [];
    }
  }

  addProductInAction(productId, id) {
    if (this.typeValue == "Product") {

      this.actionProductId = productId;

      this.adminService.getProductById(this.actionProductId).subscribe((data) => {
        this.selectedProduct = data.products[0];

        this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
          this.selectCategory = data.categories[0];
          this.closeModel(id);
          this.filterProductInCategory()
        })
      })
      this.actionsForm.productId = this.actionProductId;
      this.actionsForm.catalogId = null;
    } else if (this.typeValue == "Catalog") {

      this.actionCatalogId = productId;
      this.adminService.getCatalogById(this.actionCatalogId).subscribe((data) => {
        this.selectedProduct = data.catalogs[0];
        this.closeModel(id);
        this.filterCatlog();

      });
      this.actionsForm.catalogId = this.actionCatalogId;
      this.actionsForm.productId = null;
    }

  }


  pushInReqTier(item) {
    console.log(item)
    this.requestReward.push(item);
    console.log(this.requestReward)
  }

  removeInReqTier(item) {
    var newArray = new Array();

    for (var i = 0; i < this.requestReward.length; i++) {
      if (item['tierId'] != this.requestReward[i]) {
        newArray.push(this.requestReward[i])
      }
    }
    this.requestReward = newArray;
    //console.log(this.requestReward);
  }

  onItemSelect(item: any) {
    console.log(item)

    this.pushInReqTier(item['tierId']);

  }
  onSelectAll(items: any) {
    this.requestReward = new Array();
    for (let i = 0; i < items.length; i++) {
      this.requestReward.push(items[i]['tierId'])
    }
    //this.requestReward=idList;
    //console.log(this.requestReward)
  }


  onItemDeSelect(item: any) {
    this.removeInReqTier(item);
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.selectedFiles = null;
    this.currentImageRef = "";
  }



  resetForm(resetForm: NgForm) {
    this.openResetModal("dialogBox");


    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      console.log("dialogres", res)
      if (res == "yes") {

        this.reward.tierIds = [{}];
        this.unSelectAllTier()
        this.requestReward = null;
        this.requestReward = new Array();
        // this.dropdownList = [];
        console.log(this.requestReward)
        console.log(this.dropdownList)
        // this.ngOnInit();
        // this.requestReward = [];
        this.selectedProduct = null;
        this.showOtherField = false;
        this.selectedCouponSource = null;
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.conditionModelArray = []
        this.selectedFiles = null;
        this.currentImageRef = "";
        this.closeResetModal("dialogBox");
        resetForm.resetForm()
        this.rewardModel();
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.reward)
  }

  addReward() {
    this.validateFromThroughDate()
    console.log('this.reward', this.reward)
    this.reward.tierIds = this.requestReward;
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.reward.imageRef = data.documentId;
        this.addRewardServiceCall();
      })
    } else {
      this.addRewardServiceCall();
    }
  }

  addRewardServiceCall() {
    console.log(this.reward);
    var requestBody = new Object();
    if (this.reward.couponSource == "INTERNAL") {
      // delete this.reward.couponPartnerId
      var actionRequestBody = new Object();
      let action = {}
      if (this.actionsForm.type == "PROMO_ORDER_PERCENT") {
        actionRequestBody = {
          amount: this.actionsForm.amount,
          type: this.actionsForm.type
        }
        console.log("inside PROMO_ORDER_PERCENT", actionRequestBody)
      } else if (this.actionsForm.type == "PROMO_GWP") {
        console.log("actionRequestBody", actionRequestBody)
        actionRequestBody = {
          productId: this.actionsForm.productId,
          catalogId: this.actionsForm.catalogId,
          type: this.actionsForm.type,
          quantity: this.actionsForm.quantity
        }
        console.log("inside PROMO_GWP", actionRequestBody)
        action = cleanDeep(actionRequestBody)
      }
      console.log("actionRequestBody", actionRequestBody)
      requestBody['loyaltyReward'] = {
        imageRef: this.reward.imageRef,
        name: this.reward.name,
        description: this.reward.description,
        points: this.reward.points,
        couponSource: this.reward.couponSource,
        couponCodeInternal: this.reward.couponCodeInternal,
        couponActionInternal: action,
        instructions: this.reward.instructions,
        tierIds: this.requestReward,
        fromDate: this.reward.fromDate,
        throughDate: this.reward.throughDate,
        createdBy: this.user.name,

      }

    } else if (this.reward.couponSource == "PARTNER") {
      requestBody['loyaltyReward'] = {
        imageRef: this.reward.imageRef,
        name: this.reward.name,
        description: this.reward.description,
        points: this.reward.points,
        couponSource: this.reward.couponSource,
        couponPartnerId: this.reward.couponPartnerId,
        instructions: this.reward.instructions,
        tierIds: this.requestReward,
        fromDate: this.reward.fromDate,
        throughDate: this.reward.throughDate,
        createdBy: this.user.name,

      }
    }
    // requestBody['loyaltyReward']=this.reward;
    // requestBody['loyaltyReward']['tierIds']=this.requestReward;
    console.log(requestBody);
    //var requestBodyObject = cleanDeep(requestBody)
    // console.log("demo",cleanDeep(requestBody))
    console.log(requestBody)
    this.rewardService.addReward(requestBody).subscribe(data => {
      console.log(data)
      this.messageService.broadcast('splice', {
        key: 'addreward',
        alternativeKey: 'addreward'
      })
      this.router.navigateByUrl('/marketingdetails/rewards')
    });

  }


  openResetModal(id) {
    let data = {};
    data = {
      value: 'reset'
    };
    if (id == 'delete') {
      data['value'] = id;
    }
    this.deleteDialogService.openDialog(data);
  }
  closeResetModal(id) {
    this.deleteDialogService.closeDialog();
  }


  onSelectCategory(event) {
    var selectElement = event.target;
    var categoryId = selectElement.value;

    this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
      this.products = data.products;

      if (this.products.length == 0) {
        this.noProductOnCategoryFlag = true;
      } else {
        this.noProductOnCategoryFlag = false;
      }
      this.filterProductInCategory()

    })
  }

  filterProductInCategory() {
    for (let i = 0; i < this.products.length; i++) {
      if (this.selectedProduct != null) {
        if (this.selectedProduct.id.includes(this.products[i].id)) {
          this.productFlagArray[i] = true;
        } else {
          this.productFlagArray[i] = false;
        }
      }
    }
  }

  filterCatlog() {
    for (let i = 0; i < this.catalogs.length; i++) {
      if (this.selectedProduct != null) {
        if (this.selectedProduct.id.includes(this.catalogs[i].id)) {
          this.catalogFlagArray[i] = true;
          // //console.log(this.productFlagArray)
        } else {
          this.catalogFlagArray[i] = false;
        }
      }
    }
  }

  getAllCatalogs() {
    this.adminService.getAllCatalog().subscribe((data) => {
      this.catalogs = data.catalogs
      this.filterCatlog()
    });
  }


  getAllCategory() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;

      this.dropdownElements = this.nestedCategoryService.getNestedProductCategories(this.categories);
    });
  }
  modalReference: any

  openModel(id) {
    // this.modalService.open(id);
    this.modalReference = this.ngbModalService.open(this.addProduct, { size: 'xl', windowClass: 'add-product-modal', backdropClass: 'dark-backdrop' });
    this.getAllCategory()
    this.getAllCatalogs()
  }

  closeModel(id) {
    // this.modalService.close(id)
    this.typeValue = ''
    this.catalogs = [];
    this.products = [];
    this.modalReference.dismiss();
  }

  onSelectCouponSource(event) {
    this.selectedCouponSource = event.target.value;
    if (this.selectedCouponSource == "INTERNAL") {
      this.reward.couponPartnerId = null
    } else if (this.selectedCouponSource == "PARTNER") {
      this.reward.couponCodeInternal = null;
      this.reward.couponActionInternal = null;
      this.selectedProduct = null;
      this.actionModel();
    }
  }
  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }

  unSelectAllTier() {
    this.dropdownDisable = true;
    this.dropdownDisable = false;
  }

}
