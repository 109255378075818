export class PointType {

    public pointTypes = new Map();

    constructor() {
        this.pointTypes.set("EARNED", "Earned");
        this.pointTypes.set("REWARD_REDEEMED", "Redeemed");
        this.pointTypes.set("MANUALLY_ADJUSTED", "Manually Adjusted");
    }

    public getPointTypeKeys():any {
        let pointTypeKey: Array<any> = [];
        for (let key of this.pointTypes.keys()) {
            pointTypeKey.push(key);
        }
        return pointTypeKey;
    }

    public getActionTypeValues(){
        let pointTypeValue: Array<any> = [];
        for(let value of this.pointTypes.values()) {
            pointTypeValue.push(value);
        }
        console.log(pointTypeValue);
        return pointTypeValue;
    }
}