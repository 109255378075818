import { Directive,Input,SimpleChanges } from '@angular/core';
import {Validator,AbstractControl} from "@angular/forms";
import {NG_VALIDATORS} from "@angular/forms";

@Directive({
  selector: '[notEqualValidator]',
   providers: [
        {provide: NG_VALIDATORS,useExisting:RequiredIfDirective, multi: true}
    ]
})
export class RequiredIfDirective implements Validator {

  constructor() { }

  @Input()
  notEqualValidator: string;

    validate(control:AbstractControl): {[Key: string]: any} | null{
      console.log(control)
      const  controlToCompare = control.parent.get(this.notEqualValidator);
      console.log(controlToCompare);
      console.log(controlToCompare.value == null)
      if(controlToCompare.value != null && control.value == null) {
        console.log("true")
        return {'noEqual': true}
      }
      return null;
    }


  // registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
  // private _onChange: () => void;

  // ngOnChanges(changes: SimpleChanges): void {
 
  //   if ('requiredIf' in changes) {
      
  //     if (this._onChange) this._onChange();
  //   }
  // }
}