import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { StoreService } from '../../services/store.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.css']
})
export class StoreDetailsComponent implements OnInit {
  
  storeCode: any;
  storeName: any;
  breadCrumbs: any;
  store: any;
  constructor(private route: ActivatedRoute,
    private breadService: BreadcrumbService,
    private router: Router,
    private storeService: StoreService,
    private messageService: MessageSubjectService,
    public translate:TranslateService) {
    }
    
    ngOnInit() {
      this.breadCrumbs=this.breadService.getBread();
      
      this.route.paramMap.subscribe((map) =>{
        this.storeCode = map.get('storecode');
        this.storeName = decodeURIComponent(map.get('storename'));
        this.getStore();
      })
    }
    
    getStore() {
      this.storeService.getStoreByCode(this.storeCode).subscribe((data) => {
        this.store = data['stores'][0];
        if(!this.store['parentCode']) {
          if(this.store.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "regiondetails",
              alternativeKey: 'regiondetails',
              labelName: 'Region: '+decodeURIComponent(this.store.name),
              path: decodeURI(this.router.url)
            });
          }
        }else {
          {
            if(this.store.name != null) {
              console.log(this.storeName)
              this.messageService.broadcast('bcLabelOverwrite', {
                key: "subregiondetails",
                alternativeKey: 'subregiondetails'+this.storeCode,
                labelName: 'Subregion: '+decodeURIComponent(this.store.name),
                path: decodeURI(this.router.url)
              });
            }
          }
        }
    })
  }

  setup() {
    if(!this.store['parentCode']) {
      if(this.storeName != null) {
        this.messageService.broadcast('bcLabelOverwrite', {
          key: "regiondetails",
          alternativeKey: 'regiondetails',
          labelName: 'Region: '+decodeURIComponent(this.storeName),
          path: this.router.url
        });
      }
    }
    else {
      if(this.storeName != null) {
        console.log(this.storeName)
        this.messageService.broadcast('bcLabelOverwrite', {
          key: "subregiondetails",
          // alternativeKey: 'substoredetails'+this.storeCode,
          labelName: 'Subregion: '+decodeURIComponent(this.storeName),
          path: this.router.url
        });
      }
    }
  }
}

