import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-feature-details',
  templateUrl: './product-feature-details.component.html',
  styleUrls: ['./product-feature-details.component.css']
})
export class ProductFeatureDetailsComponent implements OnInit {

  featureId: any;
  productId: any;
  index: any;

  constructor(private adminService: AdminService,
              private route: ActivatedRoute) { }
  ngOnInit() {

    this.route.paramMap.subscribe((map) => {
      this.featureId = map.get('featureId');

      this.productId = map.get('productId');

      this.index = map.get('index');
      //console.log(this.index)
    })
  }

}
