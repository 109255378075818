import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { BucketUrl } from 'src/app/bucket';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { FiltersConstant } from 'src/app/shared/constants/filters-constant';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { CatalogProductFilters } from 'src/app/shared/constants/filter-model/catalog-product-filters.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-catalog-product',
  templateUrl: './catalog-product.component.html',
  styleUrls: ['./catalog-product.component.css']
})
export class CatalogProductComponent implements OnInit {
  
  catalogId: string;
  catalog: any;
  products: any[];
  productIds: any[];
  
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  myBucketUrl: any;
  bucketUrl: BucketUrl;
  subscription: Subscription;
  noDataShow: Boolean = false;
  searchName: any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  numberOfPages:number=1;
  totalItems: number;
  filters: any =  CatalogProductFilters;
  pagination: any;
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  // sort:Array<any> = sortConstant.CATALOG_PRODUCT_SORT
  
  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private router: Router,
    private messageService: MessageSubjectService,
    public translate : TranslateService)  {
      this.bucketUrl = new BucketUrl();
      this.filters = {}
      
    }
    
    ngOnInit() {
      this.myBucketUrl = this.bucketUrl.getBucketUrl();
      
      this.route.paramMap.subscribe((map) => {
        this.catalogId = map.get('catalogId');
        this.getCatalogById();
        this.getAllProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
      });
      
      
      
    }
    getCatalogById() {
      this.adminService.getCatalogById(this.catalogId).subscribe((data) => {
        this.catalog = data.catalogs[0];
        if(data['catalogs'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "catalogdetails",
            alternativeKey: 'catalogdetails',
            labelName: 'Catalog : '+data['catalogs'][0].name,
            path: this.router.url
          });
        } 
      });
    }
    
    
    getAllProductsByQuery(filters,itemsPerPage,currentPage,sort) {
      this.products = [];
      this.pagination = null;
      this.allCheck = false;
      this.adminService.getAllCatalogProductByQuery(this.catalogId,filters,itemsPerPage,currentPage,sort).subscribe((data) => {
        this.products = data.products;
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.noDataShow = this.noDataService.noData(this.products);
        this.backgroundImage(this.products)
      });
    }

    backgroundImage(data) {
      if(data.length > 0) {
        this.checkboxItems = []
        for (let i = 0; i < data.length; i++) {
          data[i].imageRef = this.getBackground(data[i].imageRef);
          this.checkboxItems[i] = false;
        }
      }
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.products.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.products.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    delete() {
      
      this.openModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          let productArray: Array<any> = [];
          for (let i = 0; i < this.products.length; i++) {
            if (this.checkboxItems[i] == true) {
              productArray = this.products[i].id;
            }
          }
          this.adminService.deleteProductFromCatalog(this.catalogId, productArray).subscribe((data) => {
            this.closeModal("dialogBox");
            this.getAllProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
            this.subscription.unsubscribe();
            this.deleteDialogService.clearResponse();
          })
        } else if (res == "no") {
          this.closeModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
  
    
    openModal(id: string) {
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal(id: string) {
      this.deleteDialogService.closeDialog();
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }

      // -------  pagination methods ------ //
    
      onChangePage(event) {
        this.currentPage = event;
        this.getAllProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)

  
      }
      
      addItemsPerPage(event) {
        this.itemsPerPage = event;
        this.currentPage = 1;
        this.getAllProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
      }
      
      // ------ search bar method ----------- //
      
      findName(event) {
        this.searchName = event;
        if(this.searchName != '') {
          this.filters['name'] = this.searchName;  
          this.currentPage = 1;
          this.getAllProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
        }
      }
      hideCross(event) {
       delete this.filters['name'];
        this.searchName = '';
        this.currentPage = 1;
        this.getAllProductsByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
      }

      async onSelectSort(event) {
        this.sort[0] = this.fields
        console.log(this.sort)
        if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING')) {
          this.sort[0].sortDirection = await event.target.value
          this.sort[0].fieldName='name'
        } else {
         delete this.sort
        }
    
        this.getAllProductsByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
      }
    
  }
  