import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../../customer-service/customer.service';
import { RewardClaimedFilters } from 'src/app/shared/constants/filter-model/reward-claimed-filters.model';
import { sortConstant } from 'src/app/shared/constants/sort-constant';

@Component({
  selector: 'app-loyalty-profile',
  templateUrl: './loyalty-profile.component.html',
  styleUrls: ['./loyalty-profile.component.css']
})
export class LoyaltyProfileComponent implements OnInit {
  
  constructor(private route: ActivatedRoute,
    private customerService: CustomerService) {
      this.filters = new RewardClaimedFilters()
     }
    
    customerId: string;
    tierList : any[];
    loyaltyProfile: any;
    tierLevel: string;
    accumulatedPoints: string;
    rewardsEarned: Array<any> = [];
    totalAccumulatedPoints: any;
    currentTierIndex : any;
    searchName: any;
    currentPage : number =1;
    itemsPerPage :number = 10;
    numberOfPages:number=1;
    totalItems: number;
    filters: RewardClaimedFilters;
    pagination: any;
    types: any;
    
    name:string=null
   
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.customerId = map.get('customerid');
        //this.customerId ="3bb771e7-cfd8-457b-b3d4-d0aaa8293726";
        this.getAllLoyaltyTiers(this.customerId);
        this.getLoyaltyProfileByCustomerId(this.customerId);
        this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
      });
    }
    
    
    getAllLoyaltyTiers(customerId) {
      this.customerService.getAllTiers().subscribe((response)=> {
        this.tierList = response["loyaltyTiers"];
        this.getLoyaltyProfileByCustomerId(customerId);
      })
    }
    

    getRewardsClaimedByQuery(filters,itemsPerPage,pageNumber) {
      this.rewardsEarned = [];
      this.pagination = null;
      this.filters['customerId'] = this.customerId;
      this.customerService.getLoyaltyRewardsByQuery(filters,itemsPerPage,pageNumber).subscribe((data) => {
        this.rewardsEarned = data.rewardsCollected;
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
      });
    }
    
    getLoyaltyProfileByCustomerId(customerId){
      this.customerService.getLoyaltyProfileById(customerId).subscribe((response) => {
        console.log("getLoyaltyProfileOfCustomer",response)
        this.loyaltyProfile = response["loyaltyProfile"];
        console.log(this.loyaltyProfile)
        this.accumulatedPoints = response["loyaltyProfile"]["currentAccumulatedPoints"];
        this.totalAccumulatedPoints = response["loyaltyProfile"]["totalAccumulatedPoints"];
        if (!('rewardsCollected' in response.loyaltyProfile)) {
          response.loyaltyProfile = [];
        }
        // this.rewardsEarned = response.loyaltyProfile.rewardsCollected;
        if(this.tierList.length > 0) {
          var perDiff;
          for(let i=0; i< this.tierList.length; i++) {
            if(this.tierList[i].tierId == this.loyaltyProfile.tierId) {
              this.currentTierIndex = i ;
              var abc = this.loyaltyProfile.totalAccumulatedPoints - this.tierList[i].minValue;
              var tierDiff;
              if( i+1 > this.tierList.length-1) {
                perDiff =  100;
              }else {
                
                tierDiff =this.tierList[i+1].minValue - this.tierList[i].minValue; 
                perDiff = (100/tierDiff)*abc;
              }
              
              this.tierLevel = this.tierList[i].name;
              
              break;
            }
          }
          for(let j= this.currentTierIndex; j >=0; j--) {
            if(j>0) {
              document.getElementById(this.tierList[j-1].tierId).classList.add("step--complete");
              document.getElementById(this.tierList[j-1].tierId).classList.remove("step--incomplete");
            }
            document.getElementById(this.tierList[j].tierId).classList.add("step--active");
            var checkActiveClass = document.getElementById(this.tierList[j].tierId).classList.contains('step--active') ;
            if(checkActiveClass) {
              var checkCompleteClass = document.getElementById(this.tierList[j].tierId).classList.contains("step--complete");
              var checkIncompleteClass =document.getElementById(this.tierList[j].tierId).classList.contains("step--incomplete");
              if(checkCompleteClass && checkActiveClass) {
                document.getElementById(this.tierList[j].tierId).children[3]['style']['width'] = "100%";
              }
              if(j != this.tierList.length-1) {
                if(checkActiveClass && checkIncompleteClass && !checkCompleteClass) {
                  {
                    document.getElementById(this.tierList[j].tierId).children[3]['style']['width'] = perDiff + "%";
                  }
                }
              }
              
              if(j < 0) {
                break;
              }
            }
          }
        }
      });
    }

     // -------  pagination methods ------ //
    
     onChangePage(event) {
      this.currentPage = event;
      this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
    }
    
    addItemsPerPage(event) {
      this.currentPage = 1;
      this.itemsPerPage = event;
      this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
    }
    
    // ------ search bar method ----------- //
    
    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['name'] = this.searchName;  
        this.currentPage = 1;
        this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
      }
    }
    hideCross(event) {
      this.filters['name'] = null;
      this.currentPage = 1;
      this.searchName = '';
      this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['couponSource'] = event.target.value
        this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
      }else {
        this.filters['couponSource'] = null;
        this.getRewardsClaimedByQuery(this.filters,this.itemsPerPage,this.currentPage);
      }
    }

 
  }
  