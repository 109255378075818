import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/directives/modal.sevice';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { StoreService } from '../../services/store.service';
import { StoreFilters } from 'src/app/shared/constants/filter-model/store-filters.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-all-stores',
  templateUrl: './get-all-stores.component.html',
  styleUrls: ['./get-all-stores.component.css']
})
export class GetAllStoresComponent implements OnInit {
  
  stores: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  myBucketUrl: any;
  subscription: Subscription;
  alternateImage: any = null;
  noDataShow: Boolean = false;
  breadCrumbs: any;
  searchName: any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  numberOfPages :number=1;
  filters: StoreFilters;
  pagination: any;
  types: any;
  // sort: Array<any> = sortConstant.STORE_SORT;
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  
  constructor(private storeService: StoreService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService,
    public translate : TranslateService) {
      this.filters = new StoreFilters();
    }
    
    ngOnInit() {
      this.storeService.ngOnInit();
      this.messageService.broadcast('primary',
      {key: "regions", labelName: "Regions", path: "regions",
      alternativeKey: 'regions',
      terminalOnly: false,
      afterBaseOnly: false,
      pathParamList: [],
      queryParams: undefined,
      fragment:undefined,
      child: []}
      )
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      this.getTypes();
    }
    
    getAllStoresByQuery(filters,itemsPerPage,pageNumber,sort) {
      this.stores = [];
      this.pagination = null;
      this.allCheck = false;
      this.storeService.getAllStoresByQuery(filters,itemsPerPage,pageNumber,sort).subscribe((data) => {
        this.stores = data.stores;
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.noDataShow = this.noDataService.noData(this.stores);
        this.backgroundImage(this.stores)
      })
    }
    
    backgroundImage(stores) {
      if(stores.length > 0) {
        this.checkboxItems = []
        for (let i = 0; i < stores.length; i++) {
          stores[i].imageRef = this.getBackground(stores[i].imageRef);
          this.checkboxItems[i] = false;
        }
      }
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.stores.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.stores.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    // multiple delete method
    delete() {
      
      this.openModal1("storedelete");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          for (let i = 0; i < this.stores.length; i++) {
            if (this.checkboxItems[i] == true) {
              this.storeService.deleteStoreByCodes(this.stores[i].code).subscribe((data) => {
                this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
              })
            }
          }
          this.closeModal1("storedelete");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal1("storedelete")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
    
    
    openModal1(id: string){
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal1(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    openModal(id: string) {
      this.modalService.open(id);
    }
    
    closeModal(id: string) {
      this.modalService.close(id);
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    
    // -------  pagination methods ------ //
    
    onChangePage(event) {
      this.currentPage = event;
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    // ------ search bar method ----------- //
    
    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['code'] = this.searchName;  
        this.currentPage = 1;
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }
    hideCross(event) {
      this.filters['code'] = null;
      this.currentPage = 1;
      this.searchName = '';
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    // ------- type method ---------// 
    
    
    getTypes() {
      this.storeService.getAllStoreTypes().subscribe((data) => {
        this.types = data.storeTypes;
      })
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }else {
        this.filters['type'] = null;
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='name'
        console.log(this.sort)
      } else {
        this.sort[0].sortDirection = await null
  
      }
  
      this.getAllStoresByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
    
  }