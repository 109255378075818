import { Injectable } from '@angular/core';
import { FieldRefService } from '../field-ref-service/field-ref.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FeatureFieldRefService implements FieldRefService{

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;

  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }


  // serverRootUrl = environment.BASE_URL;
  serverRootUrl =environment.PRODUCT_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20";
  productFeatureTypeRootUrl = this.serverRootUrl + "/api/v1/productfeaturetypes/";


  getAllFieldsByTypeCode(featureTypeCode): Observable<any> {
    return this.http.get<any>( this.productFeatureTypeRootUrl+featureTypeCode+"/fields", this.getHeaderTenant()); 
  }

  createFieldsByTypeCode(featureFields, featureTypeCode): Observable<any> {
    let request = {}
    request['field'] = featureFields ;
    return this.http.post<any>(this.productFeatureTypeRootUrl+featureTypeCode+ "/fields", request, this.getHeaderTenant());
  }

  deleteFieldsByFieldIds(fieldIds, featureTypeCode): Observable<any> {
    return this.http.delete<any>(this.productFeatureTypeRootUrl+featureTypeCode+"/fields?fieldIds="+fieldIds, this.getHeaderTenant());  }

  updateFieldByFieldId(fieldId,featureFields, featureTypeCode) {
    let request = {};
    request['field'] = featureFields;
    return this.http.put<any>(this.productFeatureTypeRootUrl+featureTypeCode+"/fields/"+fieldId, request, this.getHeaderTenant());
  }
}
