import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { environment } from 'src/environments/environment.prod';
import { StoreService } from '../../../services/store.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
const cleanDeep = require('src/app/shared/library/deep-clean');

@Component({
  selector: 'app-add-new-store-catalog',
  templateUrl: './add-new-store-catalog.component.html',
  styleUrls: ['./add-new-store-catalog.component.css']
})
export class AddNewStoreCatalogComponent implements OnInit {
  
  catalog: any;
  catalogTypes: any[];
  uid: any;
  user: any;
  fields: any;
  currentFields: any;
  catalogFields: any;
  showFields: boolean = false;
  selectedFiles: FileList;
  value: Array<any> = [];
  convertedTypeField: Array<any> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  catOptions: Array<Boolean> = [];
  catalogOptions: Array<string> = [];
  subscribe: Subscription;
  currentImageRef: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  
  storeCode: any;
  storeName: any;
  breadCrumbs: any;
  store: any;
  documentArray: Array<Boolean> = [];
  fieldImageRef: Array<any> = [];
  selectedFilesForFields: Array<FileList> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  restrictedFromDate:any
  restrictedThroughDate:any


  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private storeService: StoreService,
    private messageService: MessageSubjectService,
    public translate:TranslateService,
    private dateValidations: DateValidationsService) {
      
    }
    
    
    ngOnInit() {
      this.user = JSON.parse(sessionStorage.getItem("user"));
      this.route.paramMap.subscribe((map) => {
        this.storeCode = map.get('storecode');
        this.getAllCatalogTypes();
        this.model();
        this.getStoreName();
      })
      
    }
    
    
    addMultipleListValue(index) {
      this.multipleArray[index].push(({
        listValue: ''    
      }));
    }
    
    removeListValue(index,k) {
      this.multipleArray[index].splice(k,1)
    }
    
    model() {
      this.catalog = {
        name: '',
        description: '',
        imageRef: '',
        storeCode: this.storeCode,
        type: '',
        fromDate: '',
        throughDate: '',
        createdBy: this.user.name,
        fields: {}
      }
    }
    
    getStoreName(){
      this.storeService.getStoreByCode(this.storeCode).subscribe((data) => {
        this.store = data.stores[0];
        this.storeName = data.stores[0].name;
      })
    }
    
    resetForm() {
      this.openModal1("dialogBox");
      
      this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.closeModal1("dialogBox")
          
          this.ngOnInit();
          this.showFields = false;
          this.currentImageRef = "";
          this.selectedFiles = null;
          this.restrictedFromDate = null;
          this.restrictedThroughDate = null;
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal1("dialogBox")
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
    openModal1(id: string){
      let data = {};
      data = {
        value: 'reset'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal1(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    openModal(id: string) {
      this.modalService.open(id);
    }
    
    closeModal(id: string) {
      this.modalService.close(id);
      // //console.log(event.target)
    }
    
    getAllCatalogTypes() {
      this.adminService.getAllCatalogType().subscribe((data) => {
        this.catalogTypes = data.catalogTypes;
      })
    }
    selectFile(event, isField, indexOfField) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      if (isField) {
        this.selectedFilesForFields[indexOfField] = event.target.files;
        reader.onload = (_event) => {
          this.fieldImageRef[indexOfField] = reader.result;
        }
      } else {
        this.selectedFiles = event.target.files;
        reader.onload = (_event) => {
          this.currentImageRef = reader.result;
        }
      }
    }
    
    resetImage(isField, indexOfField) {
      if (isField) {
        this.fieldImageRef[indexOfField] = ""
        this.selectedFilesForFields[indexOfField] = null
      } else {
        this.currentImageRef = "";
        this.selectedFiles = null;
      }
    }
    
    optionFieldsCondition(catalogFields) {
      for (let i = 0; i < catalogFields.length; i++) {
        if (catalogFields[i].options) {
          if ((catalogFields[i].options.length == 1 || (catalogFields[i].options.length == 0))) {
            this.catOptions[i] = false;
          }
          else {
            this.catOptions[i] = true;
          }
        }
        if (this.catalogFields[i].list) {
          this.listFlag[i] = true;
        }
        if (catalogFields[i].type == "BOOLEAN") {
          this.value[i] = false;
        }
        let type = catalogFields[i].type;
        this.convertedTypeField[i] = (environment.typeConversion[type]);
      }
    }
    
    selectCode(event) {
      this.value = []
      this.catOptions = []
      this.currentFields = event.target.value;
      this.adminService.getCatalogFieldsByCatalogTypeCode(this.currentFields).subscribe((data) => {
        this.catalogFields = data.fields;
        for(let i=0; i< this.catalogFields.length;i++) {
          if(this.catalogFields[i].type == "DOCUMENT"){
            this.documentArray[i] = true;
          }else{
            this.documentArray[i] = false;
          }
          this.multipleArray[i] = new Array<any>();
          this.fieldImageRef[i] = null;
          this.selectedFilesForFields[i] = null;
          this.lastIndexOfMultipleArray[i] = new Array<number>();
          this.listArray[i] = new Array<any>()
          if(this.catalogFields[i].list) {
            this.lastIndexOfMultipleArray[i] = 0;
            this.multipleArray[i].push({
              listValue: ''
            })
          }
        }
        this.optionFieldsCondition(this.catalogFields);
      });
      
      this.showFields = true;
      
    }
    
    onCheck(index) {
      this.ck[index] = !this.ck[index];
      this.value[index] = this.ck[index];
    }

    validateFromThroughDate() {
      this.dateValidations.validateFromThroughDate(this.catalog)
    }
   
  
    
   async onSubmit() {
    this.validateFromThroughDate()
      if (this.catalogFields != null) {
        this.fields = {};
        for (let i = 0; i < this.catalogFields.length; i++) {
          let fieldName = this.catalogFields[i].key;
          if (this.catalogFields[i].type == "INTEGER") {
            this.catalog.fields[fieldName] = parseInt(this.value[i])
          } else if (this.catalogFields[i].type == "DECIMAL") {
            this.catalog.fields[fieldName] = parseFloat(this.value[i])
          }
          else {
            this.catalog.fields[fieldName] = this.value[i];
          }
          if (this.catalogFields[i].list) {
            for(let j=0; j< this.multipleArray.length; j++) {
              // //console.log(this.multipleArray[j].length)
              if(this.multipleArray[j].length > 0) {
                for(let k=0; k< this.multipleArray[j].length ; k++) {
                  //  //console.log(this.multipleArray[j].length)
                  //  //console.log( this.catalog.fields)
                  this.listArray[j][k] =(this.multipleArray[j][k].listValue)
                }
              }
            }
            if(this.listArray[i].length > 0) {
              if(this.catalogFields[i].type == "INTEGER") {
                this.catalog.fields[this.catalogFields[i].key] =   this.listArray[i].map(numStr => parseInt(numStr))
              } else if(this.catalogFields[i].type == "DECIMAL") {
                this.catalog.fields[this.catalogFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
              }else {
                this.catalog.fields[this.catalogFields[i].key] = this.listArray[i]
              }
            }
            else {
              this.multipleArray[i] = []
              this.catalog.fields[this.catalogFields[i].key] = []
            }
          }
        }
      }
      
      await this.uploadImageRef();
      
      if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
        for (let index = 0; index < this.selectedFilesForFields.length; index++) {
          if (this.selectedFilesForFields[index] != null) {
            const file = this.selectedFilesForFields[index].item(0);
            let data = await this.callServiceToUploadImage(file)
            this.catalog.fields[this.catalogFields[index].key] = data.documentId;
            if(index == this.selectedFilesForFields.length-1) {
              this.addCatalogServiceCall()
            }
          }else{
            if(index == this.selectedFilesForFields.length-1) {
              this.addCatalogServiceCall();
              
            }  
          }
        }
      }else{
        this.addCatalogServiceCall();
      }
    }

    async uploadImageRef() {
      if (this.selectedFiles != null) {
        const file = this.selectedFiles.item(0);
        let data = await this.callServiceToUploadImage(file);
        this.catalog.imageRef = data.documentId;
      } 
    }
    
    async callServiceToUploadImage(file: any): Promise<any>{
      return this.imageUploadService.uploadImage(file).toPromise();
    }
    
    addCatalogServiceCall() {
      let catalog = this.catalog
      catalog.fields = cleanDeep(this.catalog.fields)
      this.adminService.addCatalog(catalog).subscribe((data) => {
        if(!this.store['parentCode']) {
          this.router.navigate(["/regiondetails/" + this.storeCode + "/" + this.storeName + "/catalogs/" + this.storeCode]);
        }else {
          this.messageService.broadcast('splice', {
            key: 'addnewcatalog',
            alternativeKey: 'addnewcatalog'
          })
          this.router.navigate(["/subregiondetails/" + this.storeCode + "/" + this.storeName + "/catalogs/" + this.storeCode]);
          
        }
      });
    }

    onThroughDate(date) {
      this.restrictedFromDate = this.dateValidations.onThroughDate(date)
    }
  
    onFromDate(date) {
      this.restrictedThroughDate = this.dateValidations.onFromDate(date)
    }
    
    
  }
  
  