import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { StoreService } from '../../../services/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { StoreTypeRefService } from 'src/app/admin/api-configuration/api-configuration-services/type-services/store-type-ref-services/store-type-ref.service';
import { StoreFieldRefService } from 'src/app/admin/api-configuration/api-configuration-services/field-services/store-field-ref-service/store-field-ref.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { environment } from 'src/environments/environment.prod';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NgForm } from '@angular/forms';
import csc from 'country-state-city'
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenerateTimeslotService } from 'src/app/services/generate-timeslot.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';

@Component({
  selector: 'app-add-new-sub-store',
  templateUrl: './add-new-sub-store.component.html',
  styleUrls: ['./add-new-sub-store.component.css']
})
export class AddNewSubStoreComponent implements OnInit {

  @ViewChild('addNewStoreForm', { static: false }) myForm: NgForm;
  @ViewChild('addTimeSlots', { static: true }) public addTimeSlots: ElementRef;
  store: any;
  storeTypes: any[];
  selectedFiles: FileList;
  flag: boolean;
  uid: any;
  user: any;
  myBucketUrl: any;
  fields: any;
  value: Array<any> = [];
  storeTypeCode: any;
  storeFields: any;
  type: any;
  showFields: boolean = false;
  convertedTypeField: Array<string> = [];
  categoryOptions: Array<string> = []
  catOptions: Array<Boolean> = [];
  fieldCheckbox: Array<boolean> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  subscription: Subscription;
  currentImageRef: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  breadCrumbs: any;
  parentCode: any;
  parentName: any;
  currentStore: any;
  currencies: Array<any> = [];
  units: Array<any> = [];
  curbsideTimeslots: Array<any> = [];
  countries: Array<any> = [];
  states: Array<any> = [];
  cities: Array<any> = [];
  showCurbDetails: Boolean = false;
  bulkSlotRef: NgbModalRef;
  addTimeslotsRef: NgbModalRef;
  lastIndexOfMultipleArray: Array<any> = [];
  documentArray: Array<Boolean> = [];
  fieldImageRef: Array<any> = [];
  selectedFilesForFields: Array<FileList> = [];
  storeCodes: Array<any>= [];
  codeInvalid: Boolean = false;
  restrictedFromDate:any
  restrictedThroughDate:any


  constructor(private storeService: StoreService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private modalService: NgbModal,
    private storeTypeRefService: StoreTypeRefService,
    private storeTypeFieldRefService: StoreFieldRefService,
    private codeConversionService: AutoNameCodeGeneratorService,
    private route: ActivatedRoute,
    private messageService: MessageSubjectService,
    private generateTimeSlotService: GenerateTimeslotService,
    private imageUploadService: ImageUploadService,
    private dateValidations: DateValidationsService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.getAllCategoryTypes();
    this.route.paramMap.subscribe((map) => {
      this.parentCode = map.get('storecode');
      this.getParentName();
      this.model();
      this.getCurrency();
      this.getUnits();
      this.getAllCountries();
      this.curbsideTimeSlotModel();
      this.getAllStore();
    })

  }

  curbsideTimeSlotModel() {
    this.curbsideTimeslots = [
      {
        startTime: '',
        endTime: '',
        maxOrders: ''
      }
    ]
  }

  getCurrency() {
    this.storeService.getAllCurrency().subscribe((data) => {
      this.currencies = data;
    })
  }

  getUnits() {
    this.storeService.getAllUnits().subscribe((data) => {
      this.units = data;
    });
  }

  getAllStore() {
    this.storeService.getAllStores().subscribe((data) => {
      for(let i=0; i< data.stores.length;i++) {
        this.storeCodes[i] = data.stores[i].code;
      }
    })
  }

  model() {
    this.store = {
      name: '',
      code: '',
      imageRef: '',
      unit: '',
      currency: '',
      description: '',
      parentCode: this.parentCode,
      type: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,
      modifiedBy: this.user.name,
      fields: {},
      curbsideSupported: '',
      numberOfCurbsidePickupPoints: '',
      serviceDistance: '',
      curbsideTimeslots: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        country: '',
        landmark: '',
        latitude: '',
        longitude: '',
        postalCode: '',
        state: ''
      }
    };
  }


  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  resetForm() {
    this.openResetModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {

        this.closeResetModal("dialogBox");

        this.ngOnInit();
        this.myForm.resetForm();
        this.showFields = false;
        this.selectedFiles = null;
        this.myForm.resetForm();
        this.currentImageRef = "";
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  fillCode(event) {
    this.store.code = this.codeConversionService.generateCode(event.target.value);
    let value = '';
    value = this.store.code;
    this.invalidCode(value);
  }

  checkCode($event){
    let value = '';
    value = this.store.code;
    this.invalidCode(value);
  }

  invalidCode(value) {
    if(this.storeCodes.includes(value)) {
      this.codeInvalid = true;
    } else {
      this.codeInvalid = false;
    }
  }
  

  openResetModal(id: string) {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeResetModal(id: string) {
    this.deleteDialogService.closeDialog();
  }


  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
  }

  getAllCategoryTypes() {
    this.storeTypeRefService.getAllTypes().subscribe((data) => {
      this.storeTypes = data.storeTypes;
    })
  }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.fieldImageRef[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.fieldImageRef[indexOfField] = ""
      this.selectedFilesForFields[indexOfField] = null
    } else {
      this.currentImageRef = "";
      this.selectedFiles = null;
    }
  }

  optionFieldsCondition(storeFields) {
    for (let i = 0; i < storeFields.length; i++) {
      if (storeFields[i].options) {
        if ((storeFields[i].options.length == 1) || (storeFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      if (this.storeFields[i].list) {
        this.listFlag[i] = true;
      }
      if (storeFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = storeFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
    }
  }


  selectCode(event) {
    this.value = [];
    this.catOptions = [];
    this.type = event.target.value;
    this.storeTypeFieldRefService.getAllFieldsByTypeCode(this.type).subscribe((data) => {
      this.storeFields = data.fields;

      for (let i = 0; i < this.storeFields.length; i++) {
        if (this.storeFields[i].type == "DOCUMENT") {
          this.documentArray[i] = true;
        } else {
          this.documentArray[i] = false;
        }
        this.fieldImageRef[i] = null;
        this.selectedFilesForFields[i] = null;
        this.multipleArray[i] = new Array<any>();
        this.listArray[i] = new Array<any>();
        this.lastIndexOfMultipleArray[i] = new Array<number>();
        if (this.storeFields[i].list) {
          this.lastIndexOfMultipleArray[i] = 0;
          this.multipleArray[i].push({
            listValue: ''
          })
        }
      }

      this.optionFieldsCondition(this.storeFields)
    });

    this.showFields = true;
  }

  getParentName() {
    // //console.log(this.parentCode)
    this.storeService.getStoreByCode(this.parentCode).subscribe((data) => {
      this.currentStore = data['stores']
      this.parentName = data.stores[0].name;
      console.log(this.currentStore)
    })
  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.store)
  }
  async onSubmit() {
    this.validateFromThroughDate()
    this.store.curbsideTimeslots = this.curbsideTimeslots;
    if (!this.store.curbsideSupported) {
      delete this.store.numberOfCurbsidePickupPoints;
      delete this.store.serviceDistance;
      delete this.store.curbsideTimeslots;
    }
    if (this.store.unit == '') {
      delete this.store.unit;
    }
    if (this.storeFields != null) {
      this.fields = {};
      for (let i = 0; i < this.storeFields.length; i++) {
        let fieldName = this.storeFields[i].key;
        if(this.storeFields[i].type == "DOCUMENT"){
          this.store.fields[fieldName] = null;
        }
        if (this.storeFields[i].type == "INTEGER") {
          this.store.fields[fieldName] = parseInt(this.value[i])
        } else if (this.storeFields[i].type == "DECIMAL") {
          this.store.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.store.fields[fieldName] = this.value[i];
        }
        if (this.storeFields[i].list) {
          for (let j = 0; j < this.multipleArray.length; j++) {

            if (this.multipleArray[j].length > 0) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.storeFields[i].type == "INTEGER") {
              this.store.fields[this.storeFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.storeFields[i].type == "DECIMAL") {
              this.store.fields[this.storeFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.store.fields[this.storeFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.multipleArray[i] = []
            this.store.fields[this.storeFields[i].key] = []
          }
        }
      }
    }

    await this.uploadImageRef();
    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          let data = await this.callServiceToUploadImage(file);
          this.store.fields[this.storeFields[index].key] = data.documentId;
          if(index == this.selectedFilesForFields.length-1) {
            this.callServiceToCreateSubStore();
          }
        }else{
          if(index == this.selectedFilesForFields.length-1) {
            this.callServiceToCreateSubStore();
          }
        }
       
      }
    }else{
      this.callServiceToCreateSubStore();
    }
  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      let data = await this.callServiceToUploadImage(file);
      this.store.imageRef = data.documentId;
    } 
  }

  async callServiceToUploadImage(file: any): Promise<any>{
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  callServiceToCreateSubStore() {
    console.log(this.store)
    this.storeService.createStore(this.store).subscribe((data) => {
      if (this.currentStore['parentCode'] != null) {
        this.router.navigate(['/regiondetails/' + this.parentCode + '/' + this.parentName + '/subregions/' + this.parentCode]);
      } else {
        this.messageService.broadcast('splice', {
          key: 'addnewsubstore',
          alternativeKey: 'addnewsubstore' + this.parentCode
        })
        this.router.navigate(['/subregiondetails/' + this.parentCode + '/' + this.parentName + '/subregions/' + this.parentCode]);

      }
    })
  }

  getAllCountries() {
    this.countries = csc.getAllCountries();
  }

  getStateByCountry(event) {
    var selectCountry = event.target
    var countryId = selectCountry.options[selectCountry.selectedIndex].dataset.countryid
    this.states = csc.getStatesOfCountry(countryId);
    this.cities = [];
  }

  getCityByState(event) {
    var selectState = event.target;
    var stateId = selectState.options[selectState.selectedIndex].dataset.stateid;
    this.cities = csc.getCitiesOfState(stateId);
  }

  addTimeSlot() {
    this.curbsideTimeslots.push({
      startTime: '',
      endTime: '',
      maxOrders: ''
    })
  }

  removeTimeSlot(index) {
    this.curbsideTimeslots.splice(index, 1)
  }



  showCurbsideDetails() {
    this.showCurbDetails = !this.showCurbDetails
    if (!this.showCurbDetails) {
      this.store.numberOfCurbsidePickupPoints = '',
        this.store.serviceDistance = '',
        this.curbsideTimeslots = [{
          startTime: '',
          endTime: '',
          maxOrders: ''
        }
        ]

    }
  }

  openTimeSlotList() {
    this.addTimeslotsRef = this.modalService.open(this.addTimeSlots, { size: 'xl', windowClass: 'timeslot-modal' });
  }

  closeTimeSlotList() {
    this.addTimeslotsRef.dismiss();
  }

  addTimeslots() {
    this.store.curbsideTimeslots = this.curbsideTimeslots;
    this.closeTimeSlotList();
  }

  showBulkSlotDetails() {
    this.showBulkDetails = !this.showBulkDetails;
    this.bulkAdditionModel();
  }
  showBulkDetails: Boolean = false;
  selectedCondition: any;

  bulkAdditionModel() {
    this.bulkAddition = {
      startTime: '',
      endTime: '',
      condition: '',
      timeInterval: '',
      maxOrder: ''
    }
  }

  addInBulk() {
    var message;
   for(let i=0; i< this.curbsideTimeslots.length;i++) {
    if (this.curbsideTimeslots[i].startTime != "" || this.curbsideTimeslots[i].endTime != "" || this.curbsideTimeslots[i].maxOrders != "") {
      message = 'Your previous timeslots will be overwritten'
    }else {
      message = null;
    }
   }
    this.openWarningModal("dialogBox",message);
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.bulkSlot()
        this.closeWarningModal("dialogBox");
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeWarningModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });

  }


  bulkSlot() {
    var start = this.bulkAddition.startTime.split(":");
    var end = this.bulkAddition.endTime.split(":");
    if (start[0] == "00") {
      start[0] = "24"
    }
    if (end[0] == "00") {
      end[0] = "24"
    }
    this.bulkAddition.startTime = start[0] + ":" + start[1];
    this.bulkAddition.endTime = end[0] + ":" + end[1];
    let slotConfig;
    if (this.bulkAddition.timeInterval != '') {
      
      var start = this.bulkAddition.startTime.split(":");
      var end = this.bulkAddition.endTime.split(":");
      if (start[0] == "00") {
        start[0] = "24"
      }
      if (end[0] == "00") {
        end[0] = "24"
      }
      this.bulkAddition.startTime = start[0] + ":" + start[1];
      this.bulkAddition.endTime = end[0] + ":" + end[1];
      console.log(this.bulkAddition)
      slotConfig = {
        "configSlotHours": "0",
        "configSlotMinutes": this.bulkAddition.timeInterval,
        "configSlotPreparation": "0",
        "timeArr": [
          { "startTime": this.bulkAddition.startTime, "endTime": this.bulkAddition.endTime },
        ]
      }
    }

    var result = this.generateTimeSlotService.createSlots(slotConfig)
    console.log(result)
    for (let i = 0; i < result.length; i++) {
      this.curbsideTimeslots[i] = {
        startTime: '',
        endTime: '',
        maxOrders: ''
      }
      this.curbsideTimeslots[i].startTime = result[i].timeSlotStart;
      this.curbsideTimeslots[i].endTime = result[i].timeSlotEnd;
      this.curbsideTimeslots[i].maxOrders = this.bulkAddition.maxOrder;
    }
    console.log(this.curbsideTimeslots)
    this.showBulkDetails = false;
    this.bulkAdditionModel();
  }







  resetTimeSlots() {
    this.openResetModal("reset");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");
        this.curbsideTimeSlotModel();
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  resetBulk() {
    this.bulkAdditionModel();
  }
  bulkAddition: any;

  openWarningModal(id: string,message) {
    let data = {};
    data = {
      value: 'Add',
      responseMessage: message
    };
    this.deleteDialogService.openDialog(data);
  }

  closeWarningModal(id: string) {
    this.deleteDialogService.closeDialog();
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }
}


