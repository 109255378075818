import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '../../../services/store.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { Subscription } from 'rxjs';
import { NoDataService } from 'src/app/services/no-data.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { StoreCategoryProductFilters } from 'src/app/shared/constants/filter-model/product-store-category-product-filters.model';

@Component({
  selector: 'app-get-all-products',
  templateUrl: './get-all-products.component.html',
  styleUrls: ['./get-all-products.component.css']
})
export class GetAllProductsComponent implements OnInit {
  
    parentCategoryId: any;
    storeCode: any;
    products: Array<any> = []
    itemCheck: boolean = false;
    allCheck: boolean = false;
    checkboxItems: Array<boolean> = [];
    myValue: boolean = false;
    subscription: Subscription;
    pagination:any;
    searchName:any;
    showCross: boolean = false;
    totalItems: number;
    noDataShow: Boolean = false;
    numberOfPages:number=1;
    imageArray: Array<any> = [];
    totalProducts: any;
    currentPage : number =1;
    itemsPerPage :number = 10;
    filters:any = StoreCategoryProductFilters;
    sort: Array<any> = [];
    fields: any = {
  
      fieldName: null,
      sortDirection: null
    };

    constructor(private route: ActivatedRoute,
      private storeService: StoreService,
      private deleteDialogService: DeleteDialogService,
      private backgroundImageService: BackgroundImageService,
      private noDataService: NoDataService,
      private adminService: AdminService,
      private messageService: MessageSubjectService,
      private router: Router) {
        this.filters=new StoreCategoryProductFilters();
       }

    ngOnInit() {
      console.log('productcategoory')
      this.route.paramMap.subscribe((map) => {
        this.parentCategoryId = map.get('parentcategoryid')
        this.filters['categoryId']=this.parentCategoryId
        console.log(this.parentCategoryId)
        this.storeCode = map.get('storecode');
        console.log(this.storeCode)
        this.getCategoryById(this.parentCategoryId);
        // //console.log(this.parentCategoryId)
        // this.getProductByCategoryId()
        this.getProductsByCategoryIdFilter(this.storeCode,this.filters,this.itemsPerPage,this.currentPage,this.sort);   
      })
    
    }
    
    getCategoryById(categoryId) {
      this.adminService.getCategoryById(categoryId).subscribe((data) => {
        if(data['categories'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: 'regioncategorydetails',
            alternativeKey : 'regioncategorydetails',
            labelName: 'Category: '+data['categories'][0].name,
            child: [],
            path: decodeURI(this.router.url)
          });
        }
      });
      
    }
    
    getProductByCategoryId() {
      this.storeService.getProductByCategoryId(this.parentCategoryId, this.storeCode).subscribe((data) => {
        this.totalProducts = data.products.length;
        console.log(this.totalProducts)
      })
    }

    hideCross() {
      this.showCross = false;
      this.filters['name'] = null;
      this.searchName = '';
      this.currentPage = 1;
      
      this.getProductsByCategoryIdFilter(this.storeCode ,this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }



    // categoryId : string=null;
    name:string=null;
    findName(event) {
      if(this.searchName != '') {
        
        var fieldValue = null;
        this.name = this.searchName;
        fieldValue = document.getElementById('search-name')['value'];
        
        this.filters = {
         "categoryId":this.parentCategoryId,
         "name":this.name
        }
        this.currentPage = 1;
        this.showCross = true;
        this.getProductsByCategoryIdFilter(this.storeCode ,this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }

     
      }

    addItemsPerPage(event) {
      this.itemsPerPage = event.target.value;
      this.currentPage = 1;
      this.getProductsByCategoryIdFilter(this.storeCode,this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }


    getProductsByCategoryIdFilter(productStoreCode,filters,itemsPerPage,currentPage,sort) {
      this.products = [];
      this.pagination=null;
      // this.storeCode=null
      
      this.storeService.getProductsByFilter(productStoreCode,filters,itemsPerPage,currentPage,sort).subscribe((data) => {

        this.products = data.products;
        this.pagination=data.pagination
        this.totalItems=this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages
        console.log(this.products)
        console.log(this.totalProducts)
        console.log(this.numberOfPages)
        for(let i=0; i< this.products.length; i++) {
          this.imageArray[i] = this.getBackground(this.products[i].imageRef)
        }
        this.noDataShow = this.noDataService.noData(this.products);
      });
    }

    onChangePage(event) {
      this.currentPage = event;
      this.getProductsByCategoryIdFilter(this.storeCode,this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.products.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.products.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    delete() {
      this.openModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          for (let i = 0; i < this.products.length; i++) {
            if (this.checkboxItems[i] == true) {
              // this.storeService.deleteProductfromCategory(this.parentCategoryId,this.storeCode,this.products).subscribe((data) => {
              //   this.getProductByCategoryId();
              // })
            }
          }
          
          this.closeModal("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        } else if (res == "no") {
          this.closeModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
  
    
    openModal(id: string){
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    setUp(key,alternativeKey,labelName,path) {
      this.messageService.broadcast('bcLabelNew', {
        afterBaseOnly: false,
        key: key,
        alternativeKey: alternativeKey,
        labelName: labelName,
        path: path,
        pathParamList: [],
        terminalOnly: false,
        child: []
      });
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='name'
        console.log(this.sort)
      } else {
        this.sort[0].sortDirection = await null
  
      }
  
      this.getProductsByCategoryIdFilter(this.storeCode,this.filters,this.itemsPerPage, this.currentPage  , this.sort);
  
    }
  }
  