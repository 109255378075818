import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FieldRefService } from '../field-ref-service/field-ref.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CategoryFieldRefService  implements FieldRefService{
  
  constructor(private http: HttpClient) { 
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  user: any;
  tenant:any;
  headers: Headers;
  
  getHeaderTenant() {
    return { headers: {'X-TENANT':this.tenant} }
  }
  
  
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = environment.BASE_URL;
  serverRootUrl=environment.PRODUCT_CATEGORIES_BASE_URL
  // serverRootUrl = "35.175.79.20"
  productCategoryTypeRootUrl =  this.serverRootUrl+"/api/v1/productcategorytypes/";
  
  
  getAllFieldsByTypeCode(categoryTypeCode):Observable<any>{
    return this.http.get<any>(this.productCategoryTypeRootUrl+ categoryTypeCode+'/fields', this.getHeaderTenant());
  }
  
  createFieldsByTypeCode(field, categoryTypeCode):Observable<any> {
    let request = {};
    
    request['field'] = field;
    return this.http.post<any>(this.productCategoryTypeRootUrl+categoryTypeCode+'/fields', request, this.getHeaderTenant());
  }
  
  deleteFieldsByFieldIds(fieldIds,categoryTypeCode):Observable<any> {
    return this.http.delete<any>(this.productCategoryTypeRootUrl+ categoryTypeCode+"/fields/?fieldIds="+fieldIds, this.getHeaderTenant());
  }
  
  updateFieldByFieldId(fieldId, field, categoryTypeCode) {
    let request = {};
    request['field'] = field;
    return this.http.put<any>(this.productCategoryTypeRootUrl+categoryTypeCode+'/fields/'+fieldId, request, this.getHeaderTenant());
  }
}
