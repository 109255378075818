import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-marketing-details',
  templateUrl: './marketing-details.component.html',
  styleUrls: ['./marketing-details.component.css']
})
export class MarketingDetailsComponent implements OnInit {

  isTenantOO :Boolean=false

  constructor( private router: Router,
    private messageService: MessageSubjectService,public translate : TranslateService) { }

  ngOnInit() {
    let user= JSON.parse(sessionStorage.getItem('user'))

    if(user.tenant=="Order Orchestration"){
      this.isTenantOO=true
      this.messageService.broadcast('bcLabelOverwrite', {
        key: "marketingdetails",
        alternativeKey: 'marketingdetails',
        labelName: 'Rules',
        path: this.router.url,
        child: []
      });
    }else{
      this.messageService.broadcast('bcLabelOverwrite', {
        key: "marketingdetails",
        alternativeKey: 'marketingdetails',
        labelName: 'Marketing',
        path: this.router.url,
        child: []
      });
    }
    
  }


  setUp() {
    let user= JSON.parse(sessionStorage.getItem('user'))
    if(user.tenant=="Order Orchestration"){
      this.messageService.broadcast('bcLabelOverwrite', {
        key: "marketingdetails",
        alternativeKey: 'marketingdetails',
        labelName: 'Rules',
        path: this.router.url,
        child: []
      });
    }else{
      this.messageService.broadcast('bcLabelOverwrite', {
        key: "marketingdetails",
        alternativeKey: 'marketingdetails',
        labelName: 'Marketing',
        path: this.router.url,
        child: []
      });
    }
  }
}
