import { Injectable } from '@angular/core';
import { BackgroundImageService } from '../shared/background-image/background-image.service';

@Injectable({
  providedIn: 'root'
})
export class NestedCategoryService {
  
  constructor(private backgroundImageService: BackgroundImageService) { }
  
  dropdownElements : Array<any> = [];
  
  getProductCategoryTree(categories) {
    var idMap = {};
    var dropdownElements= []
    // Pass 1 collect all categories into a dictionary using the ID of the category as the key
    for (var i = 0; i < categories.length; i++) {
      categories[i]["children"] = [];
      idMap[categories[i].id] = categories[i];
      // categories[i].selected = false;
      // categories[i].disabled = false;
      categories[i].initial = "unchecked";
      categories[i].current = "unchecked";
      categories[i].changed = categories[i].current != categories[i].initial;
      categories[i].imageRef = this.getBackground(categories[i].imageRef);
      categories[i].open = false;
    }
    // Pass 2 link the children of a category with their parents
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].parentId != null) {
        try {
          idMap[categories[i].parentId].children.push(categories[i]);      
        }
        catch {
          
        }
      }
    }
    
    // Pass 3 generate the category dropdown list
    dropdownElements = [];
    for (var i = 0; i < categories.length; i++) {
      if (categories[i].parentId == null) {
        categories[i].parentId = null;
        dropdownElements.push(categories[i]);
      }
    }
    console.log(dropdownElements);
    return dropdownElements;
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }
}
