import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ModalService } from 'src/app/directives/modal.sevice';
import { TierService } from '../../tiers/tier.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { RewardService } from '../reward.service';
import { Action } from '../../promotions/promotion-model/promotion';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/admin/admin.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-reward',
  templateUrl: './edit-reward.component.html',
  styleUrls: ['./edit-reward.component.css']
})
export class EditRewardComponent implements OnInit {
  
  reward: any;
  actionClass: Action;
  actionTypeKeys: Array<any> = []
  actionTypeValues: Array<any> = []
  showOtherField: Boolean = false;
  showAmount: Boolean = false;
  selectedFiles: FileList;
  subscription: Subscription;
  conditionModelArray: Array<any> = []
  currentImageRef: any;
  previousImageRef: any;
  user: any;
  requestReward: Array<string> = [];
  dropdownList = [];
  dropdownSettings: IDropdownSettings;
  rewardId: any;
  selectedItems = [];
  selectedCouponSource: string;
  actionsForm: any;
  selectedProduct: any;
  selectCategory: any;
  products: Array<any> = [];
  catalogs: Array<any> = [];
  typeValue: any;
  modalReference: any;
  categories: Array<any> = [];
  productFlagArray: Array<boolean> = [];
  catalogFlagArray: Array<boolean> = [];
  noProductOnCategoryFlag: boolean = false;
  dropdownElements: Array<any> = [];
  actionTypeValue: any;
  actionProductId: any;
  actionCatalogId: any;
  rewardModel: any;
  breadCrumbs: any;
  restrictedFromDate:any
  restrictedThroughDate:any
  prodId: any

  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;
  
  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private tierService: TierService,
    private rewardService: RewardService,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private ngbModalService: NgbModal,
    private nestedCategoryService: NestedCategoriesService,
    private messageService: MessageSubjectService,
    public translate : TranslateService,
    private dateValidations: DateValidationsService
    ) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.reward = {
        imageRef: '',
        name: '',
        description: '',
        instructions: '',
        points: '',
        couponSource: '',
        couponPartnerId: '',
        couponCodeInternal: '',
        couponActionInternal: {
          type: '',
          quantity: 1,
          amount: '',
          productId: '',
          catalogId: ''
        },
        selectedItems: [],
        tierIds: this.requestReward,
        fromDate: '',
        throughDate: '',
        modifiedBy: this.user.name
      }
    }
    
    ngOnInit() {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.tierService.getAllTiers().subscribe(data => {
        this.dropdownList = data["loyaltyTiers"];
      })
      this.actionClass = new Action();
      this.actionTypeKeys = this.actionClass.getActionTypeKeys();
      this.actionTypeValues = this.actionClass.getActionTypeValues()
      this.actionModel();
      this.route.paramMap.subscribe((map) => {
        this.rewardId = map.get('rewardId');
        this.getRewardById(this.rewardId);
      })
      
      
      
      // this.tierService.getAllTiers().subscribe(data=>{
      //   this.dropdownList=data["loyaltyTier"];
      // })
      //this.rewardModel();
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'tierId',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 7,
        allowSearchFilter: true
      };
    }
    
    
    getTierBasedOnTierId(loyaltyReward) {
      this.requestReward = loyaltyReward['tierIds'];
      var tiers = new Array();
      for (let i = 0; i < loyaltyReward['tierIds'].length; i++) {
        for (let j = 0; j < this.dropdownList.length; j++) {
          if (loyaltyReward['tierIds'][i] == this.dropdownList[j]['tierId']) {
            tiers.push(this.dropdownList[j])
            break;
          }
        }
      }
      this.reward = loyaltyReward;
      this.reward['selectedItems'] = tiers;
      return tiers;
    }
    
    getRewardById(rewardId) {
      this.rewardService.getRewardByrewardId(rewardId).subscribe(data => {
        console.log("getRewardById",data)
        this.selectedItems = this.getTierBasedOnTierId(data["loyaltyRewards"][0]);
        this.reward = data["loyaltyRewards"][0];
        this.reward['selectedItems'] = this.selectedItems;
        this.previousImageRef = this.reward.imageRef;
        this.selectedCouponSource = this.reward.couponSource;
        console.log(this.reward);
        if (this.reward.couponSource == "INTERNAL") {
          this.reward['couponCodeInternal'] = data["loyaltyRewards"][0]['couponCodeInternal'];
          this.reward['couponActionInternal'] = data["loyaltyRewards"][0]['couponActionInternal'];
          this.reward['couponActionInternal'] = data["loyaltyRewards"][0]['couponActionInternal'];
          this.actionsForm = {
            type: this.reward.couponActionInternal.type,
            amount: this.reward.couponActionInternal.amount,
            catalogId: this.reward.couponActionInternal.catalogId,
            productId: this.reward.couponActionInternal.productId,
            quantity: this.reward.couponActionInternal.quantity
          }
          if (this.reward.couponActionInternal.type == "PROMO_ORDER_PERCENT") {
            this.showAmount = true;
            this.showOtherField = false;
          }
          else if (this.reward.couponActionInternal.type == "PROMO_GWP") {
            this.showAmount = false;
            this.showOtherField = true;
            if ((this.reward.couponActionInternal.productId != '' && this.reward.couponActionInternal.productId != null)) {
              this.getProductById(this.reward.couponActionInternal.productId)
            }
            else if ((this.reward.couponActionInternal.catalogId != '' && this.reward.couponActionInternal.catalogId != null)) {
              this.getCatalogById(this.reward.couponActionInternal.catalogId)
            }
          }
          else {
            this.showOtherField = false;
            this.showAmount = false;
          }
        } else if (this.reward.couponSource == "PARTNER") {
          this.reward['couponPartnerId'] = data["loyaltyRewards"][0]['couponPartnerId'];
        }
      })
    }
    
    pushInReqTier(item) {
      this.requestReward.push(item['tierId']);
      //console.log(this.requestReward)
    }
    
    removeInReqTier(item) {
      var newArray = new Array();
      console.log(this.requestReward)
      for (var i = 0; i < this.requestReward.length; i++) {
        if (item['tierId'] != this.requestReward[i]) {
          newArray.push(this.requestReward[i])
        }
      }
      this.requestReward = newArray;
      console.log(this.requestReward);
    }
    
    onItemSelect(item: any) {
      //console.log(item)
      this.pushInReqTier(item);
      
    }
    onSelectAll(items: any) {
      this.requestReward = new Array();
      for (let i = 0; i < items.length; i++) {
        this.requestReward.push(items[i]['id'])
      }
      //this.requestReward=idList;
      //console.log(this.requestReward)
    }
    
    
    onItemDeSelect(item: any) {
      console.log(item);
      this.removeInReqTier(item);
    }
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    
    selectFile(event) {
      this.selectedFiles = event.target.files;
      
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
    
    resetImage() {
      this.selectedFiles = null;
      this.currentImageRef = "";
    }
    
    
    
    resetForm(editRewardsForm: NgForm) {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.closeResetModal("dialogBox");

          editRewardsForm.resetForm()
          this.selectedProduct = null;
          this.showOtherField = false;
     
          this.conditionModelArray = []
          this.selectedFiles = null;
          this.restrictedFromDate = null;
          this.restrictedThroughDate = null;
          this.currentImageRef = "";
          this.getRewardById(this.rewardId);
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }
    validateFromThroughDate() {
      this.dateValidations.validateFromThroughDate(this.reward)
    }
    
    editReward() {
      this.validateFromThroughDate()
      
      if (this.selectedFiles != null) {
        const file = this.selectedFiles.item(0);
        
        if (this.reward.imageRef != undefined) {
          if((this.reward.imageRef != "")) {
            this.imageUploadService.editImage(file, this.reward.imageRef).subscribe((data) => {
              this.editRewardServiceCall();
            })
          }else {
            this.imageUploadService.uploadImage(file).subscribe((data) => {
              this.reward.imageRef = data.documentId;
              this.editRewardServiceCall();
            })
          }
        } else {
          this.imageUploadService.uploadImage(file).subscribe((data) => {
            this.reward.imageRef = data.documentId;
            this.editRewardServiceCall();
          })
        }
      } else {
        this.editRewardServiceCall();
      }
    }
    
    editRewardServiceCall() {
      let requestBody = new Object();
      requestBody["loyaltyReward"]=new Object();
      requestBody['loyaltyReward']['description'] = this.reward['description'];
      requestBody['loyaltyReward']['instructions'] = this.reward['instructions'];
      requestBody['loyaltyReward']['fromDate'] = this.reward['fromDate'];
      requestBody['loyaltyReward']['imageRef'] = this.reward['imageRef'];
      requestBody['loyaltyReward']['points'] = this.reward['points'];
      requestBody['loyaltyReward']['name'] = this.reward['name'];
      requestBody['loyaltyReward']['imageRef'] = this.reward['imageRef'];
      requestBody['loyaltyReward']['throughDate'] = this.reward['throughDate'];
      requestBody['loyaltyReward']['tierIds'] = this.requestReward;
      // requestBody['loyaltyReward']['createdBy'] = this.reward['createdBy'];
      requestBody['loyaltyReward']['modifiedBy'] = this.reward['modifiedBy'];
      requestBody['loyaltyReward']['couponSource'] = this.reward['couponSource']
      
      if (this.reward.couponSource == "INTERNAL") {
        if (this.actionsForm.type == "PROMO_ORDER_PERCENT") {
          requestBody['loyaltyReward']['couponActionInternal'] = {
            amount: this.actionsForm.amount,
            type: this.actionsForm.type
          }
        } else {
          requestBody['loyaltyReward']['couponActionInternal'] = {
            productId: this.actionsForm.productId,
            catalogId: this.actionsForm.catalogId,
            type: this.actionsForm.type,
            quantity: this.actionsForm.quantity
          }
        }
        
        requestBody['loyaltyReward']['couponCodeInternal'] = this.reward['couponCodeInternal'];
      }
      else {
        
        requestBody['loyaltyReward']['couponPartnerId'] = this.reward.couponPartnerId
      }
      console.log("requestBody")
      console.log(requestBody)
      var rewardId = this.reward['id']
      this.rewardService.updateReward(rewardId,requestBody).subscribe(data=>
        {
          if(data['success']) {
            if(this.selectedFiles != null) {
                let timestamp = new Date();
                sessionStorage.setItem('timeStamp',JSON.stringify(timestamp));
            }
        }
          this.messageService.broadcast('splice', {
            key: 'editreward',
            alternativeKey: 'editreward'
          })
          this.router.navigateByUrl('/marketingdetails/rewards')
        });
        
      }
      
      
      openResetModal(id) {
        let data = {};
        data = {
          value: 'reset'
        };
        if (id == 'delete') {
          data['value'] = id;
        }
        this.deleteDialogService.openDialog(data);
      }
      closeResetModal(id) {
        this.deleteDialogService.closeDialog();
      }
      
      
      onSelectCouponSource(event) {
        this.selectedCouponSource = event.target.value;
        if (this.selectedCouponSource == "INTERNAL") {
          this.reward.couponPartnerId = null
        } else if (this.selectedCouponSource == "PARTNER") {
          this.reward.couponCodeInternal = null;
          this.reward.couponActionInternal = '';
          this.selectedProduct = null;
          this.actionModel();
        }
      }
      
      actionModel() {
        this.actionsForm = {
          type: '',
          quantity: 1,
          amount: '',
          productId: '',
          catalogId: ' '
        }
      }
      
      getProductById(prodId) {
        this.adminService.getProductById(prodId).subscribe((data) => {
          this.selectedProduct = data.products[0];
          // //console.log(this.selectedProduct)
          
          this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
            this.selectCategory = data.categories[0];
          })
        })
      }
      
      getCatalogById(catalogId) {
        this.adminService.getCatalogById(catalogId).subscribe((data) => {
          this.selectedProduct = data.catalogs[0];
          // //console.log(this.selectedProduct)
        });
      }
      openModel(id) {
        // this.modalService.open(id);
        this.modalReference = this.ngbModalService.open(this.addProduct, { size: 'xl', windowClass: 'add-product-modal', backdropClass: 'dark-backdrop' });
        this.getAllCategory()
        this.getAllCatalogs()
      }
      
      closeModel(id) {
        // this.modalService.close(id)
        this.typeValue = ''
        this.catalogs = [];
        this.products = [];
        this.modalReference.dismiss();
      }
      
      getAllCatalogs() {
        this.adminService.getAllCatalog().subscribe((data) => {
          this.catalogs = data.catalogs
          this.filterCatlog()
        });
      }
      
      
      getAllCategory() {
        this.adminService.getAllCategory().subscribe((data) => {
          this.categories = data.categories;
          
          this.dropdownElements = this.nestedCategoryService.getNestedProductCategories(this.categories);
        });
      }
      
      filterCatlog() {
        for (let i = 0; i < this.catalogs.length; i++) {
          if (this.selectedProduct != null) {
            if (this.selectedProduct.id.includes(this.catalogs[i].id)) {
              this.catalogFlagArray[i] = true;
              // //console.log(this.productFlagArray)
            } else {
              this.catalogFlagArray[i] = false;
            }
          }
        }
      }
      
      actionType(event) {
        this.actionTypeValue = event.target.value;
        // this.actionTypeConditions(this.actionTypeValue)
        if (this.actionTypeValue == "PROMO_ORDER_PERCENT") {
          this.showAmount = true;
          this.showOtherField = false;
          this.selectedProduct = null;
          this.actionsForm.quantity = null;
          this.actionsForm.productId = null;
          this.actionsForm.catalogId ;
        }
        else if (this.actionTypeValue == "PROMO_GWP") {
          this.showAmount = false;
          this.showOtherField = true;
          this.actionsForm.amount = null
        }
        else {
          this.reward.LoyaltyRewardAction = {};
          this.selectedProduct = null;
          this.showAmount = false;
          this.showOtherField = false;
        }
      }
      
      onSelectCategory(event) {
        var selectElement = event.target;
        var categoryId = selectElement.value;
        
        this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
          this.products = data.products;
          
          if (this.products.length == 0) {
            this.noProductOnCategoryFlag = true;
          } else {
            this.noProductOnCategoryFlag = false;
          }
          this.filterProductInCategory()
          
        })
      }
      
      filterProductInCategory() {
        for (let i = 0; i < this.products.length; i++) {
          if (this.selectedProduct != null) {
            if (this.selectedProduct.id.includes(this.products[i].id)) {
              this.productFlagArray[i] = true;
            } else {
              this.productFlagArray[i] = false;
            }
          }
        }
      }
      
      onSelectType(event) {
        this.typeValue = event.target.value;
        if (this.typeValue == 'Product') {
          this.products = []
        }
        if (this.typeValue == "Catalog") {
          this.getAllCatalogs();
          this.catalogs = [];
        }
      }
      
      addProductInAction(productId, id) {
        if (this.typeValue == "Product") {
          
          this.actionProductId = productId;
          
          this.adminService.getProductById(this.actionProductId).subscribe((data) => {
            this.selectedProduct = data.products[0];
            
            this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
              this.selectCategory = data.categories[0];
              this.closeModel(id);
              this.filterProductInCategory()
            })
          })
          this.actionsForm.productId = this.actionProductId;
          this.actionsForm.catalogId ;
        } else if (this.typeValue == "Catalog") {
          
          this.actionCatalogId = productId;
          this.adminService.getCatalogById(this.actionCatalogId).subscribe((data) => {
            this.selectedProduct = data.catalogs[0];
            this.closeModel(id);
            this.filterCatlog();
            
          });
          this.actionsForm.catalogId = this.actionCatalogId;
          this.actionsForm.productId = null;
        }
        
      }

      onThroughDate(date) {
        this.restrictedFromDate = this.dateValidations.onThroughDate(date)
      }
    
      onFromDate(date) {
        this.restrictedThroughDate = this.dateValidations.onFromDate(date)
      }
      
      
    }
    
    