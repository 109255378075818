import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-catalog-details',
  templateUrl: './catalog-details.component.html',
  styleUrls: ['./catalog-details.component.css']
})
export class CatalogDetailsComponent implements OnInit {

    catalogId : string;
    catalog: any;
    products: any[];
    breadCrumbs: any;

    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                private sanitizer: DomSanitizer,
                private router: Router,
                private messageService: MessageSubjectService,
                public translate:TranslateService){}

    ngOnInit() {
       this.route.paramMap.subscribe((map) => {
            this.catalogId = map.get('catalogId');  
            
            this.adminService.getCatalogById(this.catalogId).subscribe((data) => {
                this.catalog = data.catalogs[0];
                this.breadcrumbSetUp(data);
      
            });
        });       
    }

    breadcrumbSetUp(data) {
        if(data['catalogs'][0].name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
                key: "catalogdetails",
                alternativeKey: 'catalogdetails',
                labelName: 'Catalog : '+data['catalogs'][0].name,
                path: this.router.url
            });
        }    
    }

    setup() {
       this.breadcrumbSetUp(this.catalog); 
    }
 
   
}
