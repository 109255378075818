import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FieldRefService } from '../field-ref-service/field-ref.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogFieldRefService implements FieldRefService{

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;

  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }


  // serverRootUrl = environment.BASE_URL;
  serverRootUrl=environment.PRODUCT_CATALOG_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  productCatalogTypeRootUrl = this.serverRootUrl + "/api/v1/productcatalogtypes/";


  getAllFieldsByTypeCode(catalogTypeCode): Observable<any> {
    return this.http.get<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "/fields", this.getHeaderTenant());
  }

  createFieldsByTypeCode(field, catalogTypeCode): Observable<any> {
    let request = {};
    request['field'] = field;
    return this.http.post<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "/fields", request, this.getHeaderTenant());
  }

  deleteFieldsByFieldIds(fieldIds, catalogTypeCode): Observable<any> {
    return this.http.delete<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "/fields/?fieldIds=" + fieldIds, this.getHeaderTenant());
  }

  updateFieldByFieldId(fieldId, field, catalogTypeCode) {
    let request = {};
    request['field'] = field;
    return this.http.put<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "/fields/" + fieldId, request, this.getHeaderTenant());
  }
}
