import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CasscadeToProductModalService } from 'src/app/shared/casscadetoproduct-modal/casscadetoproduct-modal.service';
const cleanDeep = require('clean-deep');

@Component({
  selector: 'app-addnew-feature-ref',
  templateUrl: './addnew-feature-ref.component.html',
  styleUrls: ['./addnew-feature-ref.component.css']
})
export class AddnewFeatureRefComponent implements OnInit {
  
  @ViewChild('addNewFeatureForm', { static: false }) myForm: NgForm;
  user: any;
  myBucketUrl: any;
  features: any;
  featureTypes: any[];
  selectedFiles: FileList;
  id: any;
  basePrice: any;
  price: any;
  uid: any;
  currentFields: any;
  showFields: boolean = false;
  convertedTypeField: Array<string> = [];
  fieldCheckbox: Array<boolean> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  featureFields: any;
  value: Array<any> = [];
  fields: any;
  featureOptions: Array<string> = []
  catOptions: Array<Boolean> = [];
  subscription: Subscription;
  featureRefService: any;
  typeRefService: any;
  fieldRefService: any;
  routerFeatureLink: any;
  currentImageRef: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  cascadeToProduct : boolean = false;
  lastIndexOfMultipleArray: Array<any> = [];
  featureFieldsOption: any[][][];
  arr: Array<any> = [];
  myArr: Array<any> = [];
  onChangeFlag: boolean = true;
  breadCrumbs: any;
  documentArray: Array<Boolean> = [];
  fieldImageRef: Array<any> = [];
  selectedFilesForFields: Array<FileList> = [];
  isFeatureCategory: Boolean 
  @ViewChild('isFeatureCategory',{ static: false}) isFeatureCategoryField:ElementRef; 
  
  constructor(
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public router: Router,
    public imageUploadService: ImageUploadService,
    public deleteDialogService: DeleteDialogService,
    public casscadeToProductService : CasscadeToProductModalService,
    public modalService: ModalService,
    public http: HttpClient,
    public messageService: MessageSubjectService,
    public translate:TranslateService) {
      
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.route.paramMap.subscribe((map) => {
        this.id = map.get('id');
      })
      
      
      
      this.features = {
        name: '',
        description: '',
        defaultUseCount: 0,
        maxUseCount: 0,
        minUseCount:0,
        imageRef: '',
        type: '',
        createdBy: this.user.name,
        options: [],
        fields: {}
      }
    }
    
    
    
    
    ngOnInit() {
      this.featureFieldsOption[0][0] = new Array<any>();
      console.log('addnewfeature')
    }
    
    
    
    resetForm() {
      this.openModal1("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.closeModal1("dialogBox");
          
          this.features = {}
          this.showFields = false;
          this.price = "";
          this.currentImageRef = '';
          this.selectedFiles = null;
          this.ngOnInit();
          this.myForm.resetForm();
          
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        } else if (res == "no") {
          this.closeModal1("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
    onClickSave(){
      if((this.router.url.includes('addnewcategoryfeature'))){
      this.openModal2("dialogBox");
      this.subscription = this.casscadeToProductService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.cascadeToProduct=true
           this.onSubmit()
           this.closeModal2("dialogBox")
        } else if (res == "no") {
          this.closeModal2("dialogBox")
          this.cascadeToProduct=false
        }
      })
     
    } else if(this.router.url.includes("addnewfeature")){
      this.onSubmit()
    }
    }

   

    openModal1(id: string) {
      let data = {};
      data = {
        value: 'reset'
      };
      this.deleteDialogService.openDialog(data);
    }

    openModal2(id: string){
      let data={}
      data ={
        value : 'casscade'
      }
      this.casscadeToProductService.openDialog(data);
    }
    
    closeModal1(id: string) {
      this.deleteDialogService.closeDialog();
    }
    closeModal2(id: string) {
      this.casscadeToProductService.closeDialog();
    }
    
    openModal(id: string) {
      this.modalService.open(id);
    }
    
    closeModal(id: string) {
      this.modalService.close(id);
      //console.log(event.target)
    }
    
    addMultipleListValue(index) {
      this.multipleArray[index].push({ listValue: '' });
      this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
    }
    
    removeListValue(index, k) {
      this.multipleArray[index].splice(k, 1)
      this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
    }
    
    
    getFeatureType() {
      this.typeRefService.getAllTypes().subscribe((data) => {
        this.featureTypes = data.featureTypes;
      })
    }
    
    // selectFile(event) {
    //   this.selectedFiles = event.target.files;
    
    //   var reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]);
    //   reader.onload = (_event) => {
    //     this.currentImageRef = reader.result;
    //   }
    // }
    
    selectFile(event, isField, indexOfField) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      if (isField) {
        this.selectedFilesForFields[indexOfField] = event.target.files;
        reader.onload = (_event) => {
          this.fieldImageRef[indexOfField] = reader.result;
        }
      } else {
        this.selectedFiles = event.target.files;
        reader.onload = (_event) => {
          this.currentImageRef = reader.result;
        }
      }
    }
    
    resetImage(isField, indexOfField) {
      if (isField) {
        this.fieldImageRef[indexOfField] = ""
        this.selectedFilesForFields[indexOfField] = null
      } else {
        this.currentImageRef = "";
        this.selectedFiles = null;
      }
    }
    
    onCheck(index) {
      this.ck[index] = !this.ck[index];
      this.value[index] = this.ck[index];
      //console.log(this.ck)
    }
    
    selectCode(event) {
      this.currentFields = event.target.value;
      
      this.fieldRefService.getAllFieldsByTypeCode(this.currentFields).subscribe((data) => {
        this.featureFields = data.fields;
        
        for (let i = 0; i < this.featureFields.length; i++) {
          if(this.featureFields[i].type == "DOCUMENT"){
            this.documentArray[i] = true;
          }else{
            this.documentArray[i] = false;
          }
          this.multipleArray[i] = new Array<any>();
          //console.log(this.featureFieldsOption)
          this.fieldImageRef[i] = null;
          this.selectedFilesForFields[i] = null;
          this.lastIndexOfMultipleArray[i] = new Array<number>();
          this.listArray[i] = new Array<any>()
          
          if (this.featureFields[i].list) {
            this.lastIndexOfMultipleArray[i] = 0;
            this.multipleArray[i].push({
              listValue: ''
            })
            this.lastIndexOfMultipleArray[i] = 0;
          }
        }
        this.optionFieldsCondition(this.featureFields);
      });
      this.showFields = true;
    }
    
    
    onSelectFeatureOption(event, i, k) {
      
    }
    
    optionFieldsCondition(featureFields) {
      this.value = []
      for (let i = 0; i < featureFields.length; i++) {
        if (featureFields[i].options) {
          if ((featureFields[i].options.length == 1) || (featureFields[i].options.length == 0)) {
            this.catOptions[i] = false;
          }
          else {
            this.catOptions[i] = true;
          }
        }
        //console.log(this.catOptions)
        if (featureFields[i].list) {
          this.listFlag[i] = true;
        } else {
          this.listFlag[i] = false;
        }
        if (featureFields[i].type == "BOOLEAN") {
          this.value[i] = false;
        }
        let type = featureFields[i].type;
        this.convertedTypeField[i] = (environment.typeConversion[type]);
      }
    }
    
    async onSubmit() {
      if (this.featureFields != null) {
        this.fields = {};
        for (let i = 0; i < this.featureFields.length; i++) {
          let fieldName = this.featureFields[i].key;
          if (this.featureFields[i].type == "INTEGER") {
            this.features.fields[fieldName] = parseInt(this.value[i]);
          }
          else if (this.featureFields[i].type == "DECIMAL") {
            this.features.fields[fieldName] = parseFloat(this.value[i]);
          }
          else {
            this.features.fields[fieldName] = this.value[i];
          }
          if (this.featureFields[i].list) {
            for (let j = 0; j < this.multipleArray.length; j++) {
              //console.log(this.multipleArray[j].length)
              if (this.multipleArray[j].length > 0) {
                for (let k = 0; k < this.multipleArray[j].length; k++) {
                  //console.log(this.multipleArray[j].length)
                  //console.log(this.features.fields)
                  this.listArray[j][k] = (this.multipleArray[j][k].listValue)
                }
              }
            }
            if (this.listArray[i].length > 0) {
              if (this.featureFields[i].type == "INTEGER") {
                this.features.fields[this.featureFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
              } else if (this.featureFields[i].type == "DECIMAL") {
                this.features.fields[this.featureFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
              } else {
                this.features.fields[this.featureFields[i].key] = this.listArray[i]
              }
            }
            else {
              this.multipleArray[i] = []
              this.features.fields[this.featureFields[i].key] = []
            }
          }
        }
        // this.features.fields = this.fields
      }
      
      
      // this.features.price["value"] = this.price;
      // this.features.price["currency"] = "USD";
      
      await this.uploadImageRef();

      if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
        for (let index = 0; index < this.selectedFilesForFields.length; index++) {
          if (this.selectedFilesForFields[index] != null) {
            const file = this.selectedFilesForFields[index].item(0);
            let data = await this.callServiceToUploadImage(file)
            this.features.fields[this.featureFields[index].key] = data.documentId;
            if(index == this.selectedFilesForFields.length-1) {
              this.addNewFeatureServiceCall()
            }
          }else{
            if(index == this.selectedFilesForFields.length-1) {
              this.addNewFeatureServiceCall();
              
            }  
          }
        }
      }else{
        this.addNewFeatureServiceCall();
      }
      
    }
  
    async uploadImageRef() {
      if (this.selectedFiles != null) {
        const file = this.selectedFiles.item(0);
        let data = await this.callServiceToUploadImage(file);
        this.features.imageRef = data.documentId;
      } 
    }
    
    async callServiceToUploadImage(file: any): Promise<any>{
      return this.imageUploadService.uploadImage(file).toPromise();
    }
    
    addNewFeatureServiceCall() {
      let feature = this.features
      feature.fields = cleanDeep(this.features.fields)
      console.log(feature)
      console.log(this.isFeatureCategoryField)
      if(!this.isFeatureCategoryField) {
        this.cascadeToProduct = null
      }
      this.featureRefService.createFeatureById(this.id, feature,this.cascadeToProduct).subscribe((data) => {
        if (this.routerFeatureLink.includes("subcategorydetails")) {
          this.router.navigate([this.routerFeatureLink])
          this.messageService.broadcast('splice', {
            key: 'addnewcategoryfeature',
            alternativeKey: "addnewcategoryfeature"
          })
        } else {
          this.router.navigate([this.routerFeatureLink])
        }
      });
    }
    // onClickCascade(event) {
    //   this.openCascade("dialogBox");
    //   this.subscription = this.casscadeToProductService.receiveFromDialog.subscribe((res) => {
    //     if (res == "yes") {
    //       this.cascadeToProduct=true
    //       this.onSubmit()
    //       this.closeCascade("dialogBox");
    //       this.subscription.unsubscribe();
    //       this.casscadeToProductService.clearResponse();
    //     } else if (res == "no") {
    //       this.cascadeToProduct=false
    //       this.onSubmit()
    //       this.closeCascade("dialogBox")
    //       this.subscription.unsubscribe();
    //       this.casscadeToProductService.clearResponse();
    //     }
    //   })
    // }

    // _clickHandler = this.onClickCascade.bind(this)

    // ngAfterViewInit() {
    //   this.isFeatureCategoryField.nativeElement.addEventListener('click', this._clickHandler)
    // }

    // closeCascade(id:string){
    //   this.casscadeToProductService.closeDialog()
    // }

    // openCascade(id: string){
    //   let data={}
    //   data ={
    //     value : 'casscade'
    //   }
    //   this.casscadeToProductService.openDialog(data);
    // }
  }
  