import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureDetailsRefComponent } from '../../feature-ref/feature-details-ref/feature-details-ref.component';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { HttpClient } from '@angular/common/http';
import { ProductFeatureRefService } from '../../../product-feature-service/product-feature-ref-service/product-feature-ref.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-product-feature-details-ref',
  templateUrl: '../../feature-ref/feature-details-ref/feature-details-ref.component.html',
  styleUrls: ['./product-feature-details-ref.component.css']
})
export class ProductFeatureDetailsRefComponent  extends FeatureDetailsRefComponent implements OnInit {

  constructor(public route: ActivatedRoute,
    public router: Router,
    public adminService: AdminService,
    public messageService: MessageSubjectService,
    public http: HttpClient,
    public dataService: MessageService)  {
    super(route,router,adminService,messageService,http,dataService)
    this.featureRefService = new ProductFeatureRefService(this.http);
    console.log("hello from product constructor")
    let type = JSON.parse(sessionStorage.getItem('currentType'))
    console.log('type',type)
      // for(let i=0; types.length;i++) {
        // console.log('(this.currentFeature.type == types[i].code',(this.currentFeature.type == types[i].code))
        // if(this.currentFeature.type == type.code) {
          let currentType = type
          console.log(currentType);
          if(currentType['hasOption'] != undefined) {
            if(currentType['hasOption']) {
              this.showAddOns = true;
              console.log("true")
            }else {
             this.showAddOns = false;
             console.log("false")
            }
          }
        // }
        console.log(this.showAddOns)
      // }

  }

  ngOnInit() {
    let types = JSON.parse(sessionStorage.getItem('types'))
    this.editFeature = "editproductfeature"
    this.featureOptionLink = "addproductfeatureoption"
    console.log("hello from product ng")

    // this.adminService.getAllProductFeatureType().subscribe((data) => {
      //  for(let i=0; types.length;i++) {
      //    if(this.currentFeature.type == types[i].code) {
      //      let currentType = types[i];
      //      console.log(currentType);
      //      if(currentType['hasOption'] != undefined) {
      //        if(currentType['hasOption']) {
      //          this.showAddOns = true;
      //          console.log("true")
      //        }else {
      //         this.showAddOns = false;
      //         console.log("false")
      //        }
      //      }
      //      console.log(this.showAddOns)
      //    }
      //  }
    // })
    // this.getFeature()
    
  }



}
