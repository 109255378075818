import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class JbpmUsersService implements OnInit {
  user: any;
  tenant: any;
  headers: Headers;
  private http: HttpClient;
//When injected, HttpBackend dispatches requests directly to the backend, without going through the interceptor chain.
  constructor( handler: HttpBackend) { 
     this.http = new HttpClient(handler);
     this.user = JSON.parse(sessionStorage.getItem("user"));
     this.tenant = this.user.tenant;
  }
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
    
  }

  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }
    productServerRootUrl = environment.PRODUCT_BASE_URL
    workflowRootUrl = this.productServerRootUrl + "/api/v1/workflow/";
    

    getTaskSummariesOfUser(): Observable<any> {
      return this.http.get<any>(this.workflowRootUrl + "tasks/" + this.user.name + "/all", this.getHeaderTenant());
   
     
  }
getCheckerList():Observable<any> {
  return this.http.get<any>("../../assets/checker/checker.json")
  }
 
}

