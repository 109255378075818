import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MatExpansionModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AdminModule } from 'src/app/admin/admin.module';
import { ModalService } from 'src/app/directives/modal.sevice';
import { ModalModule } from 'src/app/directives/modal/modal.module';
import { AddNewStoreComponent } from '../components/add-new-store/add-new-store.component';
import { EditStoreComponent } from '../components/edit-store/edit-store.component';
import { GetAllStoresComponent } from '../components/get-all-stores/get-all-stores.component';
import { PriceListComponent } from '../components/price-list/price-list.component';
import { AddNewStoreCatalogComponent } from '../components/store-catalog-components/add-new-store-catalog/add-new-store-catalog.component';
import { GetAllStoreCatalogsComponent } from '../components/store-catalog-components/get-all-store-catalogs/get-all-store-catalogs.component';
import { GetAllSubcategoriesComponent } from '../components/store-categories/get-all-subcategories/get-all-subcategories.component';
import { StoreCategoryDetailsComponent } from '../components/store-categories/store-category-details/store-category-details.component';
import { GetAllProductsComponent } from '../components/store-category-products/get-all-products/get-all-products.component';
import { StoreDetailsComponent } from '../components/store-details/store-details.component';
import { AddNewSubStoreComponent } from '../components/sub-store-components/add-new-sub-store/add-new-sub-store.component';
import { GetAllSubStoresComponent } from '../components/sub-store-components/get-all-sub-stores/get-all-sub-stores.component';
import { ViewStoreProductComponent } from '../components/view-store-product/view-store-product.component';
import { StoreRouterModule } from '../router-module/router.module';
import { StoreService } from '../services/store.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';



@NgModule({
  declarations: [
    GetAllStoresComponent,
    AddNewStoreComponent,
    StoreDetailsComponent,
    GetAllSubStoresComponent,
    AddNewSubStoreComponent,
    GetAllStoreCatalogsComponent,
    AddNewStoreCatalogComponent,
    EditStoreComponent,
    StoreCategoryDetailsComponent,
    GetAllSubcategoriesComponent ,
    PriceListComponent,
    GetAllProductsComponent,
    ViewStoreProductComponent

      ],
  imports: [
    CommonModule,
    StoreRouterModule,
    FormsModule,
    AdminModule,
    ModalModule,
    // NgxSortableModule.forRoot(),
    SortableModule,
    NgbModule,
    MatExpansionModule,
    SelectDropDownModule,
    NgxPaginationModule,
    // NgxSortableModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })

 
  ],
  exports:[
    GetAllStoresComponent,
    AddNewStoreComponent,
    StoreDetailsComponent,
    GetAllSubStoresComponent,
    AddNewSubStoreComponent,
    GetAllStoreCatalogsComponent,
    AddNewStoreCatalogComponent,
    EditStoreComponent,
    StoreCategoryDetailsComponent,
    GetAllSubcategoriesComponent    
  ],
  providers:[
    StoreService,
    ModalService,
    {provide: MatDialogRef, useValue: {}},
    
  ],
  schemas: [
  NO_ERRORS_SCHEMA],
})
export class StoreModule { }

 // required for AOT compilation
 export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);;
}