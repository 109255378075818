import { Component, OnInit } from '@angular/core';
import { FieldRefComponent } from '../field-ref/field-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { StoreFieldRefService } from '../../api-configuration-services/field-services/store-field-ref-service/store-field-ref.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-store-field-ref',
  templateUrl: '../field-ref/field-ref.component.html',
  styleUrls: ['./store-field-ref.component.css']
})
export class StoreFieldRefComponent extends FieldRefComponent implements OnInit {

  fieldRefService: any;
	constructor(
		public route: ActivatedRoute,
		public modalService: NgbModal,
		public router: Router,
		public http: HttpClient,
		public deleteDialogService: DeleteDialogService,
		public autoCodeGenerator: AutoNameCodeGeneratorService) {
			super(route, modalService, router, http,deleteDialogService,autoCodeGenerator)

		this.fieldRefService = new StoreFieldRefService(http);
	}
	ngOnInit() {
		this.getAllFields()
		this.formFieldModel()
		this.addOptionFields()
		this.fieldName = "region"
		this.fieldDescription = "List of region fields"
	}


	// getAllFields() {
	// 	this.fieldRefService.getAllFieldsByTypeCode(this.typeCode).subscribe((data) => {
	// 		this.fields = data.fields;
	// 		this.typeConversion(this.fields)
	// 		for(let i=0;i< this.fields.length; i++){
	// 			this.keys[i] = this.fields[i].key;
	// 		}
	// 	});
	// }


}
