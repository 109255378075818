import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { ProductAssociationFilters } from 'src/app/shared/constants/filter-model/product-association-filters.model';
import { NoDataService } from 'src/app/services/no-data.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-association-list',
  templateUrl: './association-list.component.html',
  styleUrls: ['./association-list.component.css']
})
export class AssociationListComponent implements OnInit {
  
  productAssociations: any[];
  productId: any;
  disableAll:Boolean= true
  user:any
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  associationProduct: Array<any> = []
  productIds: Array<any> = [];
  showNoData: boolean = false;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  numberOfPages :number=1;
  filters: ProductAssociationFilters;

  // filters:any;
  pagination: any;
  searchName: string;
  types: Array<any> = [];
  sort: Array<any> = [];
  fields: any = {

  fieldName: null,
  sortDirection: null
};

  // sort:Array<any> = sortConstant.PRODUCT_ASSOCIATION_SORT;
  
  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private backgroundImageService: BackgroundImageService,
    private router:Router,
    public messageService: MessageSubjectService,
    private noDataService: NoDataService) {
      this.filters = new ProductAssociationFilters()
    }
    
    ngOnInit() {
      
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.route.paramMap.subscribe((map) => {
        this.productId = map.get('productId');
        this.adminService.getProductById(this.productId).subscribe((data) => {
          if(environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant) && data.products[0].workflowState.history.length != 0){
            if (data.products[0].workflowState.status == 'REVIEW') {
              this.disableAll = false
            }
          }
          if(data['products'][0].name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              alternativeKey: 'productdetails',
              key: "productdetails",
              labelName: 'Product: '+data['products'][0].name,
              path: decodeURI(this.router.url)
            });
          }
        })
        console.log('associationlist')
        this.getTypes();
    
      });
      this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
    }

    
  
    
    getAssociationByQuery(filters,itemsPerPage,currentPage,sort) {
      this.allCheck = false;
      this.productAssociations = [];
      this.associationTypesName=[]
      this.pagination = null;
      this.adminService.getProductAssociationsByQuery(this.productId,filters,itemsPerPage,currentPage,sort).subscribe((data) => {
        this.productAssociations = data.associations;
        console.log(this.productAssociations)
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.getProductsByIds(this.productAssociations);
        // this.getAllAssociationsTypes()
        this.showNoData = this.noDataService.noData(this.productAssociations);
      });
    }

    type:string=null;
    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['type'] = this.searchName  
        this.currentPage = 1;
        console.log(this.searchName)
        this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
      }
    }
    
    hideCross(event) {
      // this.filters['type'] ;
      delete this.filters['type']
      this.searchName = '';
      this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
    }
    
    getProductsByIds(data) {
      var productIds = [];
      this.checkboxItems = [];
      if(data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          this.checkboxItems[i] = false;
          productIds[i] = data[i].productId;
        }
        this.getProductById(productIds)
      }
    }
    
    products: Array<any> = [];
    
    getProductById(productIds) {
      this.products = []
      this.adminService.getProductById(productIds).subscribe((data) => {
        this.associationProduct = data.products;
        for(let i=0; this.productAssociations.length;i++) {
          for(let j=0; j< this.associationProduct.length;j++) {
            if(this.productAssociations[i].productId == this.associationProduct[j].id) {
              this.products[i] = {
                id: '',
                name: '',
                imageRef: '',
                type: ''
              }
              this.products[i]['id'] = this.productAssociations[i].id;
              this.products[i]['name'] = this.associationProduct[j].name;
              this.products[i]['imageRef'] = this.getBackground(this.associationProduct[j].imageRef);
              this.products[i]['type'] = this.productAssociations[i].type;
            }
          }
          // this.getAllAssociationsTypes()
        }
      });
    }
    
    associationTypesName : Array<any> = [];

    getAllAssociationsTypes() {
      console.log('associationlist')
      this.associationTypesName=[]
      this.adminService.getAllProductAssociationType().subscribe((data) => {
        for(let i=0; i< this.productAssociations.length;i++) {
          for(let j=0 ; j< data.associationTypes.length;j++) {
            if(this.productAssociations[i].type ==   data.associationTypes[j].code) {
              this.products[i]['type'] = (data.associationTypes[j].name)
              // this.associationTypesName[i]=data.associationTypes[j].name
            }
          }
        }
      });
      console.log(this.associationTypesName)
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.productAssociations.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.productAssociations.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    delete() {
      if (true) {
        let associationCodes: Array<any> = [];
        for (let i = 0; i < this.productAssociations.length; i++) {
          if (this.checkboxItems[i] == true) {
            associationCodes.push(this.productAssociations[i].code)
            this.adminService.deleteProductAssociationById(this.productAssociations[i].id, this.productId).subscribe((data) => {
              this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
            })
          }
          
        }
      }
    }
    
    
    // -------  pagination methods ------ //
    
    onChangePage(event) {
      this.currentPage = event;
      this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
    }
    
    // ------- type method ---------// 
    
    
    getTypes() {
      this.types=[]
      this.adminService.getAllProductAssociationType().subscribe((data) => {
        this.types = data.associationTypes;
        console.log(this.types)
      });
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value;
        this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
      }else {
        // this.filters['type'] = null;
        delete this.filters.type
        this.getAssociationByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
      }
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      console.log("associationlist")
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='associations.type'
        console.log(this.sort)
      } else {
        // this.sort[0].sortDirection = await null
        delete this.sort[0].sortDirection

  
      }
  
      this.getAssociationByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
    
  }
  