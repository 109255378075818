import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-store-category-details',
  templateUrl: './store-category-details.component.html',
  styleUrls: ['./store-category-details.component.css']
})
export class StoreCategoryDetailsComponent implements OnInit {
  
  parentCategoryId: any;
  storeCode: any;
  breadCrumbs: any;
  
  constructor(private route: ActivatedRoute,
    private messageService: MessageSubjectService,
    private router: Router,
    private adminService: AdminService) { }
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.storeCode = map.get('storecode');
        this.parentCategoryId = map.get('parentcategoryid');
        this.getCategoryById(this.parentCategoryId);
      })
    }
    
    getCategoryById(categoryId) {
      this.adminService.getCategoryById(categoryId).subscribe((data) => {
        console.log(data)
        if(data['categories'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: 'regioncategorydetails',
            alternativeKey : 'regioncategorydetails',
            labelName: 'Category: '+data['categories'][0].name,
            child: [],
            path: this.router.url
          });
        }
      });
      
    }
  }