import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  
  BASE_URL= environment.BASE_URL;
  
  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  SERVER_ROOT_URL=environment.CUSTOMER_BASE_URL
  CUSTOMER_ROOT_URL =this.SERVER_ROOT_URL+"/api/v1/customers"
  // CUSTOMER_ROOT_URL = this.BASE_URL + "/customer/api/v1/customers/"
  
  ngOnInit(){
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  user: any;
  tenant: any;
  headers: Headers;
  
  
  getHeaderTenant() {
    return { headers: {'X-TENANT':this.tenant} }
  }
  
  filterName(filter,itemsPerPage,pageNumber,sort) {
    var requestBody = {
      filters: filter,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      // "sort": [
      //   {
      //     "fieldName": "firstName",
      //     "sortDirection": "ASCENDING"
      //   }
      // ]
      "sort":sort
      
    }
    console.log(requestBody)
    return this.http.post<any>(this.CUSTOMER_ROOT_URL+"/query",requestBody,this.getHeaderTenant());
  }


  getCustomerPagination(filters,itemsPerPage,pageNumber,sort) {
    var requestBody = {
      filters:filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber":pageNumber,
      "retrieveInactive": false,
      "sort":sort
    }
    console.log(requestBody,'service')
    return this.http.post<any>(this.CUSTOMER_ROOT_URL+"/query",requestBody,this.getHeaderTenant());
  }
  
}
