import { Injectable } from '@angular/core';
import { BreadcrumbItem } from '../shared/breadcrumb/breadcrumb.component';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  constructor() { }

  breadCrumbArray = new Array();

  resetAll()
  {
    this.breadCrumbArray= new Array();
    sessionStorage.setItem("breadcrumb",JSON.stringify(this.breadCrumbArray))
  }

deleteAfter(obj)
{
  // console.log("delhitted");
  let newArray=new Array();
 
  this.breadCrumbArray =  JSON.parse(sessionStorage.getItem("breadcrumb"));;
  for(let i=0;i<this.breadCrumbArray.length;i++)
  {
    if(this.breadCrumbArray[i]["url"]!=obj["url"])
    {
      newArray.push(this.breadCrumbArray[i])
      sessionStorage.setItem("breadcrumb",JSON.stringify(newArray))
    }
    else{
      newArray.push(this.breadCrumbArray[i]);
      sessionStorage.setItem("breadcrumb",JSON.stringify(newArray))
      break;
    }
  }
  //this.breadCrumbArray=new Array();
  this.breadCrumbArray=newArray;
}

  setBread(obj)
  {
    this.breadCrumbArray = JSON.parse(sessionStorage.getItem("breadcrumb"))
  this.breadCrumbArray.push(obj);
   sessionStorage.setItem("breadcrumb",JSON.stringify(this.breadCrumbArray))
  //  console.log(JSON.parse(sessionStorage.getItem("breadcrumb")))
  }

  breadcrumbList: BreadcrumbItem[] = [];

  getBread()
  {
    // console.log(JSON.parse(sessionStorage.getItem("breadcrumb")))
    return JSON.parse(sessionStorage.getItem("breadcrumb"));
  }

  // primaryRoute() {
  //   this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));    
  //   console.log(this.breadcrumbList)
  //   if(this.breadcrumbList.length > 1) {
  //       for(let i=0; i< this.breadcrumbList.length -2 ; i++) {
  //           this.breadcrumbList.splice(i)
  //           window.sessionStorage.setItem('breadcrumbList', JSON.stringify(this.breadcrumbList));  
  //           this.breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));    
  //           console.log(this.breadcrumbList)
  //           }
  //   }
  
// }
}
