import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { BucketUrl } from 'src/app/bucket';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { FiltersConstant } from 'src/app/shared/constants/filters-constant';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ProductFilters, ProductSort } from 'src/app/shared/constants/filter-model/product-filters.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.css']
})
export class ProductCategoryComponent implements OnInit {
  categoryId: any;
  products: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  myBucketUrl: any;
  currentProduct: any;
  bucketUrl: BucketUrl;
  subscription: Subscription;
  noDataShow: Boolean = false;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: number;
  numberOfPages: number = 1;
  filters: any = ProductFilters;
  pagination: any;
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  // sort:Array<any> = sortConstant.PRODUCT_SORT;
  searchName: string;
  categoryName: any;
  workflowEnabled = environment.WORKFLOW_ENABLED
  user: any
  workflowTenant: Boolean = false

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private deleteDialogService: DeleteDialogService,
    private router: Router,
    private noDataService: NoDataService,
    private backgroundImageService: BackgroundImageService,
    private messageService: MessageSubjectService,
    public translate: TranslateService) {
    this.bucketUrl = new BucketUrl();
    this.filters = new ProductFilters();

  }

  ngOnInit() {
    this.adminService.ngOnInit()
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');
      console.log('product-categorys')
      this.getCategory();
      this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    })
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if (environment.WORKFLOW_TENANT.includes(this.user.tenant)) {
      this.workflowTenant = true
    }

  }

  useLanguage(language: string) {
    console.log(language)
    this.translate.use(language);
  }


  getCategory() {
    this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
      this.categoryName = data.categories[0].name
      if (!data['categories'][0]['parentId']) {
        if (data['categories'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "categorydetails",
            alternativeKey: 'categorydetails',
            labelName: 'Category: ' + this.categoryName,
            path: this.router.url
          });
        }
      } else {
        if (data['categories'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "subcategorydetails",
            alternativeKey: 'subcategorydetails' + this.categoryId,
            labelName: 'Sub Category: ' + this.categoryName,
            path: this.router.url
          });
        }
      }
    });
  }

  async getAllProductByQuery(filters, itemsPerPage, currentPage, sort) {
    this.products = [];
    this.pagination = null;
    this.allCheck = false;
    this.filters['categoryId'] = this.categoryId; 
     //comment this when not using workflow
    this.filters['excludeWorkflowStatus']=['INACTIVE']
    console.log(this.filters)
    console.log(this.itemsPerPage)
    await this.adminService.getCategoryProductsByQuery(filters, itemsPerPage, currentPage, sort).subscribe(async(data) => {
      this.products = await data.products;
      console.log(this.products)
      console.log('product-category')
      this.pagination = data.pagination;
      this.totalItems = this.pagination.totalNumberOfRecords;
      this.numberOfPages = this.pagination.numberOfPages;
      this.noDataShow = this.noDataService.noData(this.products);
      console.log(this.noDataShow)
      this.getBackgroundImage(this.products);
    });
    console.log("all products",this.products)
  }

  getBackgroundImage(products) {
    this.checkboxItems = []
    if (products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        this.checkboxItems[i] = false;
        products[i].imageRef = this.getBackground(products[i].imageRef);
      }
    }
  }

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.products.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }



  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.products.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  delete() {
    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        for (let i = 0; i < this.products.length; i++) {
          if (this.checkboxItems[i] == true) {
            this.adminService.deleteProductById(this.products[i].id).subscribe((data) => {
              this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
            })
          }
        }

        this.closeModal("dialogBox");
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }




  openModal(id: string) {
    let data = {};
    data = {
      value: 'delete'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal(id: string) {
    this.deleteDialogService.closeDialog();
  }



  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  // ------ pagination method  ---------//

  onChangePage(event) {
    this.currentPage = event;
    this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  addItemsPerPage(event) {
    this.itemsPerPage = event;
    console.log(this.itemsPerPage)
    this.currentPage = 1;
    this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }


  // ------- search bar methods ------//

  findName(event) {
    this.searchName = event;
    if (this.searchName != ' ') {
      this.filters['name'] = this.searchName
      this.currentPage = 1;
      this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
    }
  }

  hideCross(event) {
    this.filters['name'];
    this.searchName = ' ';
    this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  async onSelectSort(event) {
    this.sort[0] = this.fields
    console.log(this.sort)
    if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING')) {
      this.sort[0].sortDirection = await event.target.value
      this.sort[0].fieldName = 'name'
      console.log(this.sort)
    } else {
      delete this.sort[0].sortDirection
      // this.sort[0].sortDirection = await null
      console.log(this.sort[0].sortDirection)
    }

    this.getAllProductByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);

  }


}
