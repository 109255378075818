import { Routes } from '@angular/router';
import { AdminModule } from './admin/admin.module';
import { UserAuthModule } from './user-auth/user-auth.module';
import { UserLoginComponent } from './user-auth/user-login/user-login.component';



export const appRoute : Routes = [
    // {path: '', component: UserAuthModule},
    {path: '', component: UserLoginComponent,
}
];