import { environment } from 'src/environments/environment';

export class BucketUrl{
    
    private user: any;

    constructor(){
        this.user = JSON.parse(sessionStorage.getItem("user"));
    }


    public getBucketUrl(){
        return environment.BASE_URL + "/documentstore/api/v1/documents/" + this.user.tenant + "/";
        // return "http://ec2-54-197-219-77.compute-1.amazonaws.com/documentstore/api/v1/documents/" + this.user.tenant + "/";;
        // return "http://ec2-3-80-226-208.compute-1.amazonaws.com:8080/api/v1/documents/" + this.user.tenant + "/";;

    }

    
}