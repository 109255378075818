import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionServiceService {
  
  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  user: any;
  tenant: any;
  headers: Headers;
  
  
  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }
  
  ngOnInit(){
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  // promotionsRootUrl = environment.BASE_URL+"/promotion/api/v1/promotions/"
  SERVER_ROOT_URL=environment.PROMOTION_BASE_URL
  promotionsRootUrl =this.SERVER_ROOT_URL+"/api/v1/promotions/"
  // promotionsRootUrl=this.SERVER_ROOT_URL+"/api/v1/promotions/"
  
  // promotionsRootUrl = this.baseUrl+"/v1/promotions/"
  // promotionRootUrl = this.baseUrl+"/v1/promotion/"
  
  getAllPromotionSummary(): Observable<any> {
    return this.http.get<any>( this.promotionsRootUrl+"summaries?retrieveInactive=true", this.getHeaderTenant());
  }
  
  createPromotion(promotion):Observable<any> {
    let request = {};
    request['promotion'] = promotion;
    return this.http.post<any>(this.promotionsRootUrl , request, this.getHeaderTenant());
  }
  
  deletePromotionById(promotionIds):Observable<any> {
    return this.http.delete<any>(this.promotionsRootUrl +"?promotionIds=" + promotionIds, this.getHeaderTenant());
  }
  
  getPromotionByIds(promotionIds):Observable<any> {
    return this.http.get<any>(this.promotionsRootUrl +"?promotionIds=" + promotionIds, this.getHeaderTenant());
  }
  
  updatePromotionById(promotionId,promotion):Observable<any> {
    let request = {};
    request['promotion'] = promotion;
    return this.http.put<any>(this.promotionsRootUrl + promotionId, request,this.getHeaderTenant());
  }
  
  
  getAllPromotionsByQuery(filters,itemsPerPage,pageNumber,sort):Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    return this.http.post<any>(this.promotionsRootUrl+"query",requestBody,this.getHeaderTenant());
  }
  
  // getAllConditions(promotionId):Observable<any> {
  //   return this.http.get<any>(this.promotionsRootUrl+promotionId+"/conditions/all",this.getHeaderTenant())
  // }
  
  //--------------------------------PromoConditions Services--------------------------------//
  createCondition(promotionId, condition):Observable<any> {
    let request = {};
    request['condition'] = condition;
    return this.http.post<any>(this.promotionsRootUrl + promotionId + "/conditions", request, this.getHeaderTenant());
  }
  
  deleteConditionById(promotionId, conditionIds):Observable<any>{
    return this.http.delete<any>(this.promotionsRootUrl + promotionId+"/conditions/?conditionIds=" + conditionIds, this.getHeaderTenant())
  } 
  
  // getConditionById(promotionId,conditionIds):Observable<any> {
  //   return this.http.get<any>(this.promotionsRootUrl+promotionId+"/conditions?ids="+conditionIds,this.getHeaderTenant());
  // }
  
  updateConditionById(promotionId,conditionId,condition):Observable<any> {
    let request = {};
    request['condition'] = condition;
    return this.http.put<any>(this.promotionsRootUrl + promotionId+"/conditions/" + conditionId , request ,this.getHeaderTenant());
  }
  
  
  // createAction(promotionId, action):Observable<any> {
  //   return this.http.post<any>(this.promotionsRootUrl+promotionId+"/action", action,this.getHeaderTenant());
  // }
}
