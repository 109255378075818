import { Component, OnInit } from '@angular/core';
import { FeatureRefComponent } from '../../feature-ref/feature-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { FeatureTypeRefServiceService } from 'src/app/admin/api-configuration/api-configuration-services/type-services/feature-type-ref-service/feature-type-ref-service.service';
import { FeatureFieldRefService } from 'src/app/admin/api-configuration/api-configuration-services/field-services/feature-field-ref-service/feature-field-ref.service';
import { EditFeatureRefComponent } from '../../feature-ref/edit-feature-ref/edit-feature-ref.component';
import { ProductFeatureRefService } from '../../../product-feature-service/product-feature-ref-service/product-feature-ref.service';
import { AdminService } from 'src/app/admin/admin.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { CasscadeToProductModalService } from 'src/app/shared/casscadetoproduct-modal/casscadetoproduct-modal.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-edit-product-feature-ref',
  templateUrl: '../../feature-ref/edit-feature-ref/edit-feature-ref.component.html',
  styleUrls: ['./edit-product-feature-ref.component.css']
})
export class EditProductFeatureRefComponent extends EditFeatureRefComponent implements OnInit {

  typeRefService: any;
  fieldRefService: any;
  featureRefService: any;
  productName: any;
  lastIndexOfMultipleArray: Array<any> = [];

  constructor(public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public router: Router,
    public imageUploadService: ImageUploadService,
    public deleteDialogService: DeleteDialogService,
    public modalService: ModalService,
    public http: HttpClient,
    private adminService: AdminService,
    public backgroundImageService: BackgroundImageService,
    public messageService: MessageSubjectService,
    public translate: TranslateService,
    public casscadeToProductService : CasscadeToProductModalService) {
    super(route, sanitizer, router, imageUploadService, deleteDialogService, modalService, http,
      backgroundImageService, messageService, translate,casscadeToProductService);

    this.typeRefService = new FeatureTypeRefServiceService(this.http);
    this.fieldRefService = new FeatureFieldRefService(this.http);
    this.featureRefService = new ProductFeatureRefService(this.http);
  }

  ngOnInit() {
    this.isFeatureCategory = false
    this.getFeature();
    this.getFeatureType();
  }

  getFeature() {
    this.featureRefService.getFeaturesByProductId(this.id).subscribe((data) => {
      this.allFeatures = data.features;
      console.log('this.allFeatures',this.allFeatures)
      console.log(this.featureId)
      for (let i = 0; i < this.allFeatures.length; i++) {
        if (this.featureId == this.allFeatures[i].id) {
          this.currentFeature = this.allFeatures[i]
          console.log('this.currentFeature', this.currentFeature)
          break;
        }
      }
      if (this.currentFeature.name != null) {
        this.messageService.broadcast('bcLabelOverwrite', {
          key: 'productfeaturedetails',
          alternativeKey: 'productfeaturedetails',
          labelName: 'Feature: ' + this.currentFeature.name,
          path: this.router.url
        })
      }
      this.editProductFeatureModel();
      this.previousImageRef = this.currentFeature.imageRef;

      this.getProductName();

      this.fieldRefService.getAllFieldsByTypeCode(this.editFeatures.type).subscribe((data) => {
        this.featureFields = data.fields;
        this.optionFieldsCondition(this.featureFields);
        for (let i = 0; i < this.featureFields.length; i++) {
          if (this.featureFields != null) {
            this.previousImageRefForField[i] = this.currentFeature.fields[this.featureFields[i].key];
            this.multipleArray[i] = new Array<any>()
            this.listArray[i] = new Array<any>()
            this.lastIndexOfMultipleArray[i] = new Array<any>();
            this.multipleArray[i].push({
              listValue: ''
            })
            if (this.featureFields[i].list) {
              for (const key of Object.keys(this.currentFeature.fields)) {
                if (this.featureFields[i].key == key) {
                  if ((this.currentFeature.fields[this.featureFields[i].key].length != 0)) {
                    for (let j = 0; j < this.currentFeature.fields[this.featureFields[i].key].length; j++) {
                      this.listFlag[i] = true;
                      this.multipleArray[i][j] = ({
                        listValue: this.currentFeature.fields[this.featureFields[i].key][j]
                      })
                      // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                    }
                  }
                }
              }
            }
            else {
              this.listFlag[i] = false;
              if (this.featureFields[i].type == "BOOLEAN") {
                if (this.currentFeature.fields[this.featureFields[i].key] != true) {
                  this.value[i] = false;
                } else {
                  this.value[i] = (this.currentFeature.fields[this.featureFields[i].key])
                }
              } else {
                this.value[i] = (this.currentFeature.fields[this.featureFields[i].key])
              }
            }
          }
          this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
        }
      });
    });
  }

  getProductName() {
    this.adminService.getProductsByProductIds(this.id).subscribe((data) => {
      if(environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant) && data.products[0].workflowState.history.length != 0){ 
      if(data['products'][0].workflowState.status=='REVIEW'){
        // this.dataSubscription=this.dataService.getMessage().subscribe((data)=>{
      
        //   this.disableAll=data.disable
        //   this.dataService.clearMessage()
        // })
        this.disableAll=true
        
      }
    
    }
      this.productName = data.products[0].name;
      this.editRouteLink = "/productdetails/" + this.id + '/' + this.productName + '/productfeatures/' + this.id;
    })
  }
}
