import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class Pagination implements OnInit {

  math = Math;
  @Input() currentPage: number;
  @Input() itemsPerPage: number;
  @Input() totalItems: number;
  @Input() totalData: number;
  @Input() numberOfPages :number;
  @Output() addItems = new EventEmitter();

  constructor() { 
 
  }


  ngOnInit() {
    console.log(this.itemsPerPage)
    console.log(this.currentPage)
    console.log(this.numberOfPages )
  }

  
  addItemsPerPage(event) {
    this.itemsPerPage = event.target.value;
    this.currentPage = 1;
    this.addItems.emit(this.itemsPerPage);
  }

 

}
