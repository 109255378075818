import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Type } from 'src/app/admin/admin';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/admin/admin.service';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
const cleanDeep = require('src/app/shared/library/deep-clean');

@Component({
	selector: 'app-field-ref',
	templateUrl: './field-ref.component.html',
	styleUrls: ['./field-ref.component.css']
})
export class FieldRefComponent implements OnInit {


	@ViewChild('addFieldsForm', { static: false }) addFieldsForm: NgForm;
	@ViewChild('editFieldsForm', { static: false }) editFieldsForm: NgForm;
	typeCode: any
	fields: any;
	modalOpen: boolean = false;
	formFields: any;
	formFields1: any[];
	currentValue: any;
	optionfields: boolean = false;
	adOptionfields: boolean = false;
	checkbtns: boolean = false;
	addCheckbtns: boolean = false;
	types: Array<any> = []
	user: any;
	typeKey: Array<any> = []
	typeValue: Array<any> = []
	typeData: Array<any> = []
	itemCheck: boolean = false;
	allCheck: boolean = false;
	checkboxItems: Array<boolean> = [];
	myValue: boolean = false;
	currentType: any;
	typeClass: Type;
	showOptionFields: boolean = false;
	showAddOptionFields: boolean = false;
	editFormFields: any;
	field: any;
	checked: Boolean = false;
	option: Array<any> = [];
	option1: Array<any> = [];
	type: any;
	fieldRefService: any;
	fieldName: any;
	fieldDescription: any;
	listChecked: Boolean = false;
	requiredChecked: Boolean = false;
	editFieldList: Boolean;
	editFieldRequired: Boolean;
	subscription: Subscription;

	keys: Array<any> = [];
	codeInvalid: boolean = false;
	addModelRef: any;
	editModalRef: any;
	showNoData: boolean = false;

	@ViewChild('addField', { static: true }) public addField: ElementRef;
	@ViewChild('editField', { static: true }) public editField: ElementRef;
	// @ViewChild('addField',{static:false}) public addField: NgForm;



	constructor(
		public route: ActivatedRoute,
		public modalService: NgbModal,
		public router: Router,
		public http: HttpClient,
		public deleteDialogService: DeleteDialogService,
		public autoCodeGenerator: AutoNameCodeGeneratorService) {
		this.typeClass = new Type()
		this.user = JSON.parse(sessionStorage.getItem('user'));

		this.route.paramMap.subscribe((map) => {
			this.typeCode = map.get('typeCode');
		});

		this.formFieldModel()
		this.typeKey = this.typeClass.getKeys()
		this.typeValue = this.typeClass.getValues();

	}

	formFieldModel() {
		this.addoptionsValue = []
		this.option1 = []
		this.formFields = {
			name: '',
			key: '',
			type: '',
			required: false,
			createdBy: this.user.name,
			options: [],
			list: ''
		}
	}

	ngOnInit() {
		// this.addoptionsValue = []
		// this.getAllFields()
		// this.formFieldModel()

	}

	checkCode(event) {

		let value: any;
		value = event.target.value;
		if (this.keys.includes(value)) {
			this.codeInvalid = true;
		} else {
			this.codeInvalid = false;
		}
		this.formFields.key = this.autoCodeGenerator.generateCode(value);
	}


	getAllFields() {
		this.keys = []
		this.fieldRefService.getAllFieldsByTypeCode(this.typeCode).subscribe((data) => {
			this.fields = data.fields;
			console.log("this.fields", this.fields)
			if (this.fields.length > 0) {
				this.showNoData = false;
			} else {
				this.showNoData = true;
			}
			this.typeConversion(this.fields)
			for (let i = 0; i < this.fields.length; i++) {
				this.keys[i] = this.fields[i].key;
			}
		});
	}

	openAddModal() {
		this.ngOnInit();
		this.addModelRef = this.modalService.open(this.addField, { size: 'lg', windowClass: 'addField-modal', scrollable: true });
		this.addoptionsValue = []
		this.option1 = []
		this.getAllFields()
		this.formFieldModel()

	}


	closeAddModal() {
		this.ngOnInit()
		this.addModelRef.dismiss();
		this.codeInvalid = false
		this.adOptionfields = false;
		this.addCheckbtns = false;
		this.showAddOptionFields = false
	}

	openDeleteModal(id: string) {
		let data = {};
		data = {
			value: 'delete'
		};
		this.deleteDialogService.openDialog(data);
	}

	closeDeleteModal(id: string) {
		this.deleteDialogService.closeDialog();
	}

	closeEditModal() {
		this.editModalRef.dismiss();
		this.field = {}
	}

	public addoptionsValue: any[] = [{
		option: ''
	}]

	addOptionFields() {
		this.addoptionsValue.push({
			option: ''
		});
	}

	selectOption(event) {
		this.currentValue = event.target;
		if (event.target.checked) {
			this.adOptionfields = true;
			this.addCheckbtns = true;
		}
		else {
			this.adOptionfields = false;
			this.addCheckbtns = false;
		}
	}

	checkAll() {
		this.allCheck = !this.allCheck;
		for (let a = 0; a < this.fields.length; a++) {
			this.checkboxItems[a] = this.allCheck;
		}
	}

	onChange(i: number) {
		this.checkboxItems[i] = !this.checkboxItems[i];
		this.myValue = true;
		for (let a = 0; a < this.fields.length; a++) {
			if (this.checkboxItems[a] == false) {
				this.myValue = false;
			}
		}
		this.allCheck = this.myValue;
	}

	typeSelect(event) {
		console.log(event)
		this.type = event.target.value;
		this.option1 = [];
		this.adOptionfields = false;
		this.addoptionsValue = [];
		this.addCheckbtns = false;
		this.currentType = environment.typeConversion[this.type]
		if (this.type == ("DOCUMENT") || this.type == ("BOOLEAN")) {
			this.showAddOptionFields = false;


		}
		else {
			this.showAddOptionFields = true;
		}
	}


	onSubmit() {
		console.log("this.addoptionsValue",this.addoptionsValue)
		if (this.addoptionsValue.length !== 0) {
			for (let i = 0; i < this.addoptionsValue.length; i++) {
				if (this.formFields.type == "INTEGER") {
					this.formFields.options[i] = parseInt(this.addoptionsValue[i].option);
				} else if (this.formFields.type == "DECIMAL") {
					this.formFields.options[i] = parseFloat(this.addoptionsValue[i].option);
				}


				else {
					this.formFields.options[i] = this.addoptionsValue[i].option
				}
			}
		}
		else {
			// this.formFields.options = []
			delete this.formFields.options
		}

		if ((this.formFields.type == "BOOLEAN") || (this.formFields.type == "DOCUMENT")) {

			delete this.formFields.list
			delete this.formFields.options
		}
		else {
			this.formFields.list = this.listChecked
		}
		// if(this.formFields.options.length===0){
		// 	delete this.formFields.options
		// }
		// this.formFields.list = this.listChecked
		// this.formFields.required=this.requiredChecked
		console.log("this.formFields", this.formFields)
		let formFields = cleanDeep(this.formFields)
		console.log(formFields)
		this.fieldRefService.createFieldsByTypeCode(formFields, this.typeCode).subscribe((data) => {
			this.closeAddModal();
			this.getAllFields();
			this.formFieldModel()
			this.listChecked = false;
			this.optionfields = false;
			this.adOptionfields = false;
			this.addCheckbtns = false;
			this.checkbtns = false;
			this.checked = false;
			this.showAddOptionFields = false;
			this.addoptionsValue = []
		});
	}

	delete() {

		this.openDeleteModal("dialogBox");
		this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
			if (res == "yes") {
				for (let i = 0; i < this.fields.length; i++) {
					if (this.checkboxItems[i] == true) {
						this.fieldRefService.deleteFieldsByFieldIds(this.fields[i].id, this.typeCode).subscribe((data) => {
							this.getAllFields();
							this.allCheck = false
						})
					}
				}
				this.closeDeleteModal("dialogBox");
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();

			} else if (res == "no") {
				this.closeDeleteModal("dialogBox")
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();
			}
		})
	}

	editTypeSelect(event) {
		let type;
		type = event.target.value;
		this.currentType = environment.typeConversion[type]

		if (type == ("DOCUMENT") || type == ("BOOLEAN")) {
			this.showOptionFields = false;
			this.checked = false;
			this.editFieldList = false;
			this.editFormFields.options = []
		}
		else {
			this.showOptionFields = true;
			// this.editFieldList = true;
		}
		if (this.field.type == type) {
			for (let i = 0; i < this.field.options.length; i++) {
				this.checked = true;
				this.optionfields = true
				this.checkbtns = true
				this.optionsValue.length = this.field.options.length
				this.option[i] = this.field.options[i];
			}
		}
		else {
			this.checked = false;
			this.optionfields = false;
			this.checkbtns = false;
			this.option = [];
			this.optionsValue.length = 1
		}

	}

	editSelectOption(event) {
		this.currentValue = event.target;
		if (event.target.checked) {
			this.optionfields = true;
			this.checkbtns = true;
			this.checked = true;
		}
		else {
			this.optionfields = false;
			this.checkbtns = false;
			this.checked = false;
			this.editFormFields.options = []
		}
	}

	editFormFieldModal() {
		this.checked = false;
		this.optionfields = false;
		this.optionsValue = []
		this.option = []
		this.editFormFields = {
			name: this.field.name,
			key: this.field.key,
			type: this.field.type,
			modifiedBy: this.user.name,
			options: this.field.options,
			list: this.field.list,
			required: this.field.required
		}
		this.currentType = environment.typeConversion[this.field.type]

		this.editFieldList = this.field.list;
		if (this.field.type == ("DOCUMENT") || this.field.type == ("BOOLEAN")) {
			this.showOptionFields = false;
			this.editFieldList = false;
		}
		else {
			this.showOptionFields = true;
			// this.editFieldList = true;

		}
		if (this.field.options) {
			if ((this.field.options.length == 0)) {
				this.checked = false;
				this.optionfields = false;
				this.checkbtns = false;
				this.option = []
			}
			else {
				this.checked = true;
				this.optionfields = true;
				this.checkbtns = true;
				this.option = []
				this.optionsValue = []
				if (this.editFormFields.type == this.field.type) {
					for (let i = 0; i < this.field.options.length; i++) {
						this.option[i] = this.field.options[i];
						this.editOptionFields();
						this.optionsValue[i].option = this.field.options[i];
					}
				}
			}
		}
	}

	openEditModal(id: string, field: {}) {
		console.log("field", field)
		this.field = {}
		this.optionsValue = []
		this.checked = false
		this.optionfields = false
		this.editModalRef = this.modalService.open(this.editField, { size: 'lg', windowClass: 'editField-modal', scrollable: true });
		this.field = field
		this.editFormFields = {}
		this.editFormFieldModal();

	}

	public optionsValue: any[] = [{

	}]

	editOptionFields() {
		console.log(this.optionsValue)
		console.log(this.option)
		this.optionsValue.push({
			option: ''
		});
		this.optionsValue
		console.log(this.optionsValue)
	}

	onEdit() {
		if (this.checked) {
			this.editFormFields.options = []
			for (let i = 0; i < this.optionsValue.length; i++) {
				if (this.editFormFields.type == "INTEGER") {
					this.editFormFields.options[i] = parseInt(this.optionsValue[i].option);
				} else if (this.editFormFields.type == "DECIMAL") {
					this.editFormFields.options[i] = parseFloat(this.optionsValue[i].option);
				} else {
					this.editFormFields.options[i] = this.optionsValue[i].option;
				}
			}
		}
		else {
			// this.editFormFields.options = []

		}
		if ((this.editFormFields.type == "BOOLEAN") || (this.editFormFields.type == "DOCUMENT")) {

			delete this.editFormFields.list
			delete this.editFormFields.options
		}
		else {
			this.editFormFields.list = this.editFieldList
		}
		let editFormFields = cleanDeep(this.editFormFields)
		this.fieldRefService.updateFieldByFieldId(this.field.id, editFormFields, this.typeCode).subscribe((data) => {
			this.closeEditModal();
			this.getAllFields()
		});

	}

	typeConversion(fields) {
		for (let i = 0; i < fields.length; i++) {
			this.checkboxItems[i] = false;
			for (let j = 0; j < this.typeKey.length; j++) {
				if (fields[i].type == this.typeKey[j]) {
					this.typeData[i] = (this.typeValue[j])
				}
			}
		}
	}

	removeOptionField(i, type) {
		console.log(type)
		if (type == "ADD") {
			this.addoptionsValue.splice(i, 1);
		} else {
			console.log(this.optionsValue)
			console.log(this.editFormFields)
			this.optionsValue.splice(i, 1);
			console.log(this.optionsValue)
			console.log(this.editFormFields)
		}
	}

	onTest() {
		console.log(this.addoptionsValue)
	}

	listc() {
		// this.editFieldList = this.field.list
		this.editFieldList = !this.editFieldList
		this.listChecked = !this.listChecked
	}

	requiredc(type) {
		if (type == "ADD") {
			this.formFields.required = !this.formFields.required
		} else if (type == "EDIT") {
			this.editFormFields.required = !this.editFormFields.required
		}
	}


	resetAddForm() {
		this.openResetModal("reset");
		this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
			if (res == "yes") {
				this.formFieldModel()
				this.closeResetModal("dialogBox");
				this.fieldName = ''
				this.showAddOptionFields = false;
				this.adOptionfields = false;

				this.listChecked = false;
				this.addCheckbtns = false;
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();


			} else if (res == "no") {
				this.closeResetModal("dialogBox")
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();
			}
		});
	}

	resetEditForm() {
		this.openResetModal("reset");
		this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
			if (res == "yes") {
				this.editFormFieldModal()
				this.closeResetModal("dialogBox");
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();

			} else if (res == "no") {
				this.closeResetModal("dialogBox")
				this.subscription.unsubscribe();
				this.deleteDialogService.clearResponse();
			}
		});
	}

	openResetModal(id) {
		let data = {};
		data = {
			value: 'reset'
		};
		// if (id == 'delete') {
		// 	data['value'] = id;
		// }
		this.deleteDialogService.openDialog(data);
	}
	closeResetModal(id) {
		this.deleteDialogService.closeDialog();
	}
}
