import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CustomerService } from '../../customer-service/customer.service';

@Component({
  selector: 'app-view-customer-details',
  templateUrl: './view-customer-details.component.html',
  styleUrls: ['./view-customer-details.component.css']
})
export class ViewCustomerDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private customerService: CustomerService) { }

  customerId: string;
  customer: any;
  // customerAddress:customerAddress;
  

  ngOnInit() {
    this.route.paramMap.subscribe((map) => {
      this.customerId = map.get('customerid');
      this.getCustomerDetailsById(this.customerId);
    });
  }

  getCustomerDetailsById(customerId) {
    this.customerService.getCustomerById(this.customerId).subscribe((response) => {
      this.customer = response.customers[0];
      console.log(this.customerId)
      console.log(this.customer)
      console.log(response)
    })
  }

   
  getRandomDate()
  {
    return new Date(+(new Date()) - Math.floor(Math.random()*10000000000));
  }


  getFiveMinsBrefore()
  {
    var dt = new Date();
    dt.setMinutes( dt.getMinutes() - 5 );
    return dt;
  }
 
}
