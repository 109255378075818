import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomAdapterService extends NgbDateAdapter<string>{

  readonly DELIMITER = '-';

  fromModel(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      let date = value.split(this.DELIMITER);
      result = {
        year : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        day : parseInt(date[2], 10),
      };
    }
    //console.log(result)
    return result;
  }

  toModel(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      // result = date.day + this.DELIMITER + date.month + this.DELIMITER + date.year;
            result = date.year + this.DELIMITER + date.month + this.DELIMITER + date.day;

    }
    //console.log(result)
    return result;
  }
}
