import { Component, OnInit } from '@angular/core';
import { FeatureDetailsRefComponent } from '../../feature-ref/feature-details-ref/feature-details-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { HttpClient } from '@angular/common/http';
import { CategoryFeatureRefService } from '../../../product-feature-service/category-feature-ref-service/category-feature-ref.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-category-feature-details-ref',
  templateUrl: '../../feature-ref/feature-details-ref/feature-details-ref.component.html',
  styleUrls: ['./category-feature-details-ref.component.css']
})
export class CategoryFeatureDetailsRefComponent extends FeatureDetailsRefComponent implements OnInit {
  
  constructor(public route: ActivatedRoute,
    public router: Router,
    public adminService: AdminService,
    public messageService: MessageSubjectService,
    public http: HttpClient,
    public dataService: MessageService)  {
      super(route,router,adminService,messageService,http,dataService)
      this.featureRefService = new CategoryFeatureRefService(this.http);
    }
    
    ngOnInit() {
      this.editFeature = "editcategoryfeature";
      this.featureOptionLink ="addcategoryfeatureoption";
      this.getFeature();
      this.showAddOns = false;
      this.adminService.getAllProductFeatureType().subscribe((data) => {
        console.log(data)
        for(let i=0; data.featureTypes.length;i++) {
          if(this.currentFeature.type == data.featureTypes[i].code) {
            let currentType = data.featureTypes[i];
            console.log(currentType);
            if(currentType['hasOption'] != undefined) {
              if(currentType['hasOption']) {
                this.showAddOns = true;
                console.log("true")
              }else {
                this.showAddOns = false;
                console.log("false")
              }
            }
            console.log(this.showAddOns)
          }
        }
      })
      //console.log(this.editFeature)
    }
    
  }
  