import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AutoNameCodeGeneratorService {

  constructor() { }

  public generateCode(name: string) {
    let code: string;
    let result: string = "";

    code = name.trim().toLowerCase();

    for (let i = 0; i < code.length; i++) {
      if (code.charAt(i) == " ") {
        result = result + code.charAt(i).replace(" ", "_");
      } else {
        result = result + code.charAt(i);
      }
      result = result.replace(/[^\w\s]/gi, '')
      result = result.replace(/_+/g, '_')
    };

    return result;
  }

  public generatePromotionCode(name: string) {
    let code: string;
    let result: string = "";
    code = name.trim().toUpperCase()
    for(let i=0; i< code.length;i++) {
      if(code.charAt(i) == " "){
        result = result + code.charAt(i).replace(" ", "");;
      }else {
        result = result + code.charAt(i);
      }

      result = result.replace(/[^\w\s]/gi, '')
      result = result.replace(/_+/g, '_')
    }
    return result;
  }
}

