import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionServiceService } from '../promotions-service/promotion-service.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { AdminService } from 'src/app/admin/admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { Action } from '../promotion-model/promotion';
import { v4 as uuid } from 'uuid';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-promotion',
  templateUrl: './edit-promotion.component.html',
  styleUrls: ['./edit-promotion.component.css']
})
export class EditPromotionComponent implements OnInit {

  promotionId: any;
  promotion: any;
  editPromotion: any;
  selectedFiles: FileList;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  conditions: Array<any>;
  conditionForm: any;
  catalogProductIds: Array<any> = [];
  noProductOnCategoryFlag: boolean = false;
  allCategories: Array<any> = [];
  dropdownElements: Array<any> = [];
  categories: Array<any> = [];
  products: any[];
  myBucketUrl: any;
  productFlagArray: Array<boolean> = [];
  actionProductId: any;
  selectedProduct: any;
  selectCategory: any;
  actionsForm: any;
  typeValue: any;
  catalogs: any;
  actionCatalogId: any;
  selectedCatalog: any;
  subscription: Subscription;
  condition: any;
  editConditionForm: any;
  catalogFlagArray: Array<boolean> = [];
  actionClass: Action;
  actionTypeKeys: Array<any> = []
  actionTypeValues: Array<any> = []
  promoParameterKeys: Array<any> = []
  promoParameterValues: Array<any> = []
  promoOperationKeys: Array<any> = [];
  promoOperationValues: Array<any> = [];
  promoParameterData: Array<any> = [];
  promoOperatorData: Array<any> = [];
  showOtherField: Boolean = false;
  showAmount: Boolean = false;
  showValue: Boolean = false;
  actionTypeValue: any;
  currentImageRef: any;
  previousImageRef: any;
  modalReference: any;
  breadCrumbs: any;
  restrictedFromDate: any
  restrictedThroughDate: any
  prodId: any;
  isTenantOO :Boolean=false
  user: any;

  @ViewChild('editPromotionForm', { static: false }) editPromotionForm: NgForm;
  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;
  @ViewChild('addFormConditions', { static: true }) public addFormConditions: NgForm;
 

  constructor(private route: ActivatedRoute,
    private promotionSevice: PromotionServiceService,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private modalService: ModalService,
    private adminService: AdminService,
    private sanitizer: DomSanitizer,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private nestedCategoryService: NestedCategoriesService,
    private codeConversionService: AutoNameCodeGeneratorService,
    private ngbModalService: NgbModal,
    private messageService: MessageSubjectService,
    public translate: TranslateService,
    private dateValidations: DateValidationsService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if(this.user.tenant==="Order Orchestration"){
      this.isTenantOO=true
    }
    this.getPromotionById()
    this.conditionModel()
    this.actionClass = new Action();
    this.actionTypeKeys = this.actionClass.getActionTypeKeys();
    this.actionTypeValues = this.actionClass.getActionTypeValues()
    this.promoParameterKeys = this.actionClass.getPromoParameterKeys()
    this.promoParameterValues = this.actionClass.getPromoParameterValues()
    this.promoOperationKeys = this.actionClass.getPromoConditionOperatorKeys();
    this.promoOperationValues = this.actionClass.getPromoConditionOperatorValues()
  }


  getPromotionById() {
    this.route.paramMap.subscribe((map) => {
      this.promotionId = map.get('promotionId');

      this.promotionSevice.getPromotionByIds(this.promotionId).subscribe((data) => {
        this.promotion = data.promotions[0];
        console.log(this.promotion)
        this.conditions = this.promotion.conditions;
        this.getAllConditions()
        this.editPromotionModel()
        if (this.promotion.action != null) {
      
          if (this.promotion.action.type == "PROMO_ORDER_PERCENT") {
         
            this.showAmount = true;
            this.showValue=false;
            this.showOtherField = false;
          }
          else if (this.promotion.action.type == "PROMO_ROUTE_TO") {
         
            this.showValue = true;
            this.showAmount = false;
            this.showOtherField = false;
          }
          else if (this.promotion.action.type == "PROMO_GWP") {
           
            this.showAmount = false;
            this.showOtherField = true;
            if ((this.promotion.action.productId != '' && this.promotion.action.productId != null)) {
              this.getProductById(this.promotion.action.productId)
            }
            else if ((this.promotion.action.catalogId != '' && this.promotion.action.catalogId != null)) {
              this.getCatalogById(this.promotion.action.catalogId)
            }
          }
          else {
            
            this.showOtherField = false;
            this.showAmount = false;
            this.showValue=false;
          }
        }
        this.previousImageRef = this.promotion.imageRef;
      });
    })
  }



  selectFile(event) {
    this.selectedFiles = event.target.files;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.currentImageRef = '';
    this.selectedFiles = null;
    this.previousImageRef = '';
    this.editPromotion.imageRef = "";
  }

  editPromotionModel() {
    this.editPromotion = {
      name: this.promotion.name,
      code: this.promotion.code,
      description: this.promotion.description,
      imageRef: this.promotion.imageRef,
      fromDate: this.promotion.fromDate,
      throughDate: this.promotion.throughDate,
      useLimitPerCustomer: this.promotion.useLimitPerCustomer,
      useLimitPerCode: this.promotion.useLimitPerCode,
      modifiedBy: this.user.name,
      // modifiedBy:this.user.name,
      action: {}
    }
    this.actionModel()
  }




  fillCode(event) {
    this.editPromotion.code = this.codeConversionService.generatePromotionCode(event.target.value);
  }

  actionModel() {
    if (this.promotion.action == undefined) {
      this.actionsForm = {
        type: '',
        quantity: '',
        amount: '',
        value:'',
        productId: '',
        catalogId: ''
      }
    } else {

      this.actionsForm = {
        type: this.promotion.action.type,
        quantity: this.promotion.action.quantity,
        amount: this.promotion.action.amount,
        value: this.promotion.action.value,
        productId: this.promotion.action.productId,
        catalogId: this.promotion.action.catalogId
      }
    }
    console.log(this.actionsForm)
  }

  conditionModel() {
    this.conditionForm = {
      parameter: '',
      conditionOperator: '',
      conditionValue: '',
      // modifiedBy: this.user.name,
      createdBy: this.user.name,
    }
  }

  openConditionModal(id) {
    this.addFormConditions.resetForm()
    this.modalService.open(id);
    // //console.log("hii")
    this.conditionModel();
    // //console.log(this.conditionForm)
  }
  closeConditionModal(id) {
    this.modalService.close(id);
  }

  addCondition(id) {
    this.promotionSevice.createCondition(this.promotionId, this.conditionForm).subscribe((data) => {
      this.modalService.close(id);
      this.getPromotionById()

    })
  }

  filterProductInCategory() {
    for (let i = 0; i < this.products.length; i++) {
      if (this.selectedProduct != null) {
        if (this.selectedProduct.id.includes(this.products[i].id)) {
          this.productFlagArray[i] = true;
          // //console.log(this.productFlagArray)
        } else {
          this.productFlagArray[i] = false;
        }
      }
    }
  }

  filterCatlog() {
    for (let i = 0; i < this.catalogs.length; i++) {
      if (this.selectedProduct != null) {
        if (this.selectedProduct.id.includes(this.catalogs[i].id)) {
          this.catalogFlagArray[i] = true;
          // //console.log(this.productFlagArray)
        } else {
          this.catalogFlagArray[i] = false;
        }
      }
    }
  }




  getAllConditions() {

    // //console.log(this.conditions)
    for (let i = 0; i < this.conditions.length; i++) {
      this.checkboxItems[i] = false;
      for (let j = 0; j < this.promoParameterKeys.length; j++) {
        if (this.conditions[i].parameter == this.promoParameterKeys[j]) {
          this.promoParameterData[i] = (this.promoParameterValues[j])
        }
      }
      // //console.log(this.promoParameterData)
      for (let k = 0; k < this.promoOperationKeys.length; k++) {
        if (this.conditions[i].conditionOperator == this.promoOperationKeys[k]) {
          this.promoOperatorData[i] = (this.promoOperationValues[k])
        }
      }
    }

  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.editPromotion)
  }

  editPromo() {
    console.log(this.editPromotionForm.form)
    console.log("hello", this.editPromotionForm.form.valid)
    this.validateFromThroughDate()
    if (this.actionsForm.type == "" && this.actionsForm.quantity == "" && this.actionsForm.amount == "" && this.actionsForm.value == ""&& this.actionsForm.productId == "" && this.actionsForm.catalogId == "") {
      this.editPromotion.action = {}
    }
    else {
      this.editPromotion.action.type = this.actionsForm.type;
      this.editPromotion.action = this.actionsForm;
    }
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);

      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.editPromotion.imageRef = data.documentId;
        this.editPromosServiceCall();
      })
    } else {
      this.editPromosServiceCall();
    }
  }

  editPromosServiceCall() {
    console.log(this.editPromotion)
    this.promotionSevice.updatePromotionById(this.promotionId, this.editPromotion).subscribe((data) => {
      this.messageService.broadcast('splice', {
        key: 'editpromotion',
        alternativeKey: 'editpromotion'
      })
      this.router.navigate(['/marketingdetails/promotions']);
    });
  }


  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.conditions.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.conditions.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }



  delete() {
    this.openResetModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");
        let conditionIds = []
        for (let i = 0; i < this.conditions.length; i++) {
          if (this.checkboxItems[i] == true) {
            conditionIds.push(this.conditions[i].id)
          }
        }
        this.promotionSevice.deleteConditionById(this.promotionId, conditionIds).subscribe((data) => {
          this.getPromotionById()
        })
        this.allCheck = false
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  deleteSingle(id) {
    this.openResetModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {

        this.closeResetModal("dialogBox");
        this.promotionSevice.deleteConditionById(this.promotion.id, id).subscribe((data) => {
          this.getPromotionById();
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        })
      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }



  openModel(id) {
    // this.modalService.open(id);
    this.modalReference = this.ngbModalService.open(this.addProduct, { size: 'xl', windowClass: 'add-product-modal', backdropClass: 'dark-backdrop' });
    this.getAllCategory()
    this.getAllCatalogs()
  }

  closeModel(id) {
    this.typeValue = ''
    this.catalogs = [];
    this.products = [];
    this.modalReference.dismiss();
    // this.modalService.close(id)
  }

  onSelectType(event) {
    this.typeValue = event.target.value;
    // //console.log(this.typeValue);
    if (this.typeValue == 'Product') {
      this.products = [];
    }
    if (this.typeValue == "Catalog") {
      this.getAllCatalogs();
    }
  }


  getAllCategory() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;

      this.dropdownElements = this.nestedCategoryService.getNestedProductCategories(this.categories);
    });
  }

  getAllCatalogs() {
    this.catalogs = [];
    this.adminService.getAllCatalog().subscribe((data) => {
      this.catalogs = data.catalogs
      this.filterCatlog()
    });
  }


  getBackground(image) {
    return this.backgroundImageService.getBackground(image)
  }


  onSelectCategory(event) {
    var selectElement = event.target;
    var categoryId = selectElement.value;
    this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
      this.products = data.products;
      // //console.log(this.products)
      if (this.products.length == 0) {
        this.noProductOnCategoryFlag = true;
      } else {
        this.noProductOnCategoryFlag = false;
      }
      this.filterProductInCategory()
    })
  }


  getProductById(prodId) {
    this.adminService.getProductById(prodId).subscribe((data) => {
      this.selectedProduct = data.products[0];
      // //console.log(this.selectedProduct)

      this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
        this.selectCategory = data.categories[0];
      })
    })
  }

  getCatalogById(catalogId) {
    this.adminService.getCatalogById(catalogId).subscribe((data) => {
      this.selectedProduct = data.catalogs[0];
      // //console.log(this.selectedProduct)
    });
  }

  addProductInAction(productId, id) {
    if (this.typeValue == "Product") {
      this.actionProductId = productId;
      this.getProductById(this.actionProductId);
      // this.modalService.close(id)
      this.closeModel(id);
      this.filterProductInCategory()
      this.actionsForm.productId = this.actionProductId;
      this.actionsForm.action.catalogId = ''
    } else if (this.typeValue == "Catalog") {

      this.actionCatalogId = productId;
      this.getCatalogById(this.actionCatalogId);
      // this.modalService.close(id);
      this.closeModel(id);
      this.filterCatlog();
      this.actionsForm.catalogId = this.actionCatalogId
      this.actionsForm.productId = ''
    }

  }


  // edit Condition

  openEditConditionModal(conditionId, id) {
    this.modalService.open(id);
    for (let i = 0; i < this.conditions.length; i++) {
      if (this.conditions[i].id == conditionId) {
        this.condition = this.conditions[i];
      }
    }
    this.editConditionModel()
  }

  editConditionModel() {
    this.editConditionForm = {
      parameter: this.condition.parameter,
      conditionOperator: this.condition.conditionOperator,
      conditionValue: this.condition.conditionValue,
      modifiedBy: this.user.name,
    }
  }

  editCondition(id) {
    this.promotionSevice.updateConditionById(this.promotionId, this.condition.id, this.editConditionForm).subscribe((data) => {
      this.closeEditConditionModal(id)
      this.getPromotionById();
    });
  }
  closeEditConditionModal(id) {
    this.modalService.close(id);
  }

  actionType(event) {
    this.actionTypeValue = event.target.value;
    console.log(this.actionTypeValue)
    if (this.actionTypeValue == "PROMO_ORDER_PERCENT") {
      this.showAmount = true;
      this.showValue = false;
      this.showOtherField = false;
      this.actionsForm = {
        type: this.actionsForm.type,
        
      }
      this.selectedProduct = null
    }else if (this.actionTypeValue == "PROMO_ROUTE_TO") {
      this.showValue = true;
      this.showOtherField = false;
      this.actionsForm = {
        type: this.actionsForm.type
      }
      this.selectedProduct = null
    }
    else if (this.actionTypeValue == "PROMO_GWP") {
      this.showAmount = false;
      this.showValue =false;
      this.showOtherField = true;
      this.actionsForm = {
        type: this.actionsForm.type
      }
    }else {
      this.selectedProduct = null
      this.showAmount = false;
      this.showOtherField = false;
    }

  }

  actionTypeConditions(actionTypeValue) {
    if (actionTypeValue == "PROMO_ORDER_PERCENT") {
      this.showAmount = true;
      this.showOtherField = false;
    }
    else if (actionTypeValue == "PROMO_GWP") {
      this.showAmount = false;
      this.showOtherField = true;
    }
  }

  resetForm() {
    this.openResetModal("reset");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");
        this.selectedProduct = '';
        this.showAmount = false;
        this.showOtherField = false;
        this.ngOnInit();
        this.selectedFiles = null;
        this.currentImageRef = "";
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.editPromotionForm.resetForm()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  openResetModal(id) {
    let data = {};
    data = {
      value: 'delete'
    };
    if (id == 'reset') {
      data['value'] = id;
    }
    this.deleteDialogService.openDialog(data);
  }

  closeResetModal(id) {
    this.deleteDialogService.closeDialog();
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }

}
