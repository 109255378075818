import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomDateParserFormatterService extends NgbDateParserFormatter{

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct = null;
    if (value) {
      //console.log(this.DELIMITER)
      let date = value.split(this.DELIMITER);
      result = {
        year : parseInt(date[2], 10),
        month : parseInt(date[1], 10),
        day : parseInt(date[0], 10),
      };
    }
    return result;
  }

  format(date: NgbDateStruct): string {
    let result: string = null;
    if (date) {
      //console.log(this.DELIMITER)
      // result = date.day +  this.DELIMITER + date.month + this.DELIMITER + date.year;
      result = date.year + this.DELIMITER + date.month + this.DELIMITER + date.day;
    }
    //console.log(result)
    return result;
  }
}
