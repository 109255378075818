import { Component, OnInit, AfterViewChecked, AfterViewInit, OnChanges } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';

import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { BucketUrl } from 'src/app/bucket';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import { environment } from 'src/environments/environment.prod';
import { JbpmUsersService } from 'src/app/services/jbpm-users.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import cleanDeep from 'clean-deep'

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  productId: string;
  product: any;
  editProductForm: any;
  selectedFiles: FileList;
  uid: any;
  productFields: any[];
  fields: any;
  value: Array<any> = [];
  user: any;
  price: any;
  fieldName: any;
  convertedTypeField: Array<any> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  prodOptions: Array<Boolean> = [];
  productOptions: Array<any> = [];
  subscription: Subscription;
  currentImageRef: any;
  previousImageRef: any;
  bucketUrl: BucketUrl;
  myBucketUrl: any;
  categoryName: any;
  futureFields: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  category: Array<any> = [];
  restrictedFromDate: any
  restrictedThroughDate: any
  notes: any;
  workflowEnabled = environment.WORKFLOW_ENABLED
  workflowTenant : Boolean=false;
  disableAll: Boolean = false
  ownerTaskList:any=[]
  checkerList: Array<any>=[]

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private backgroundImageService: BackgroundImageService,
    private messageService: MessageSubjectService,
    private dateValidations: DateValidationsService,
    private jbpmService: JbpmUsersService,
    public translate: TranslateService,
    private loaderService: LoaderService,

    ) {
    this.bucketUrl = new BucketUrl();
    
  }


  ngOnInit() {
    
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    this.getCheckerList()
    this.user = JSON.parse(sessionStorage.getItem("user"));
    if(environment.WORKFLOW_TENANT.includes(this.user.tenant)){
      this.workflowTenant=true
   }
    this.route.paramMap.subscribe((map) => {
      this.productId = map.get('productId');
      this.getProduct();
    });

    
    
  }

  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }


  getProduct() {


    this.adminService.getProductById(this.productId).subscribe((data) => {
      this.product = data.products[0];
      console.log(this.product)
      if (this.workflowEnabled && environment.WORKFLOW_TENANT.includes(this.user.tenant) && this.product.workflowState.history.length != 0) {
       
        if (this.product.workflowState.status == 'REVIEW') {
          this.disableAll = true
        }
        // if (this.product.workflowState.status == 'INACTIVE') {
        //   this.inactivateProduct()
        // }
      }
      if (this.product.name != null) {
        this.messageService.broadcast('bcLabelOverwrite', {
          key: "productdetails",
          alternativeKey: "productdetails",
          labelName: 'Product: ' + this.product.name,
          path: decodeURI(this.router.url)
        });
      }
      this.editProductFormModel();
      this.getCategory();
      //if value is absent in product.. from script data 
      try {
        this.price = this.product.basePrice.value;
      } catch (error) {

      }

      this.previousImageRef = this.product.imageRef;


      this.getProductFieldsByCategoryId();

    });
  }

  getCategory() {
    this.adminService.getCategoryById(this.product.categoryId).subscribe((data => {
      this.categoryName = data.categories[0].name;
      this.category = data.categories[0];
    }))
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
  }

  editProductFormModel() {
    this.editProductForm = {
      name: this.product.name,
      description: this.product.description,
      fromDate: this.product.fromDate,
      throughDate: this.product.throughDate,
      imageRef: this.product.imageRef,
      fields: this.product.fields,
      categoryId: this.product.categoryId,
      externalSystemRef: this.product.externalSystemRef
    }
    
    if (environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant) && this.product.workflowState.history.length != 0) {
        
      
        if (this.product.workflowState.status == 'PUBLISHED') {
          this.editProductForm["createdBy"] = this.user.name
        }else{
            this.editProductForm["modifiedBy"] = this.user.name
          }
      }else {
     
        this.editProductForm["modifiedBy"] = this.user.name
      }
   }


  resetForm() {

    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.closeModal("dialogBox");
        this.ngOnInit();
        try {
          this.price = this.product.basePrice.value;
        } catch (error) {

        }
        this.currentImageRef = '';
        this.selectedFiles = null;

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  openClaimModal(id: string){
    let data = {
      component: 'ClaimDialogComponent'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal(id: string) {
    this.deleteDialogService.closeDialog();
  }



  getProductFieldsByCategoryId() {
    this.adminService.getProductFieldsByCategoryId(this.product.categoryId).subscribe((data) => {
      this.productFields = data.fields;
      this.optionFieldsCondition(this.productFields)
      if (this.productFields != null) {
        for (let i = 0; i < this.productFields.length; i++) {
          this.multipleArray[i] = new Array<any>()
          this.lastIndexOfMultipleArray[i] = new Array<any>();
          this.listArray[i] = new Array<any>()
          this.multipleArray[i].push({
            listValue: ''
          })
          if (this.productFields[i].list) {
            for (const key of Object.keys(this.product.fields)) {
              if (this.productFields[i].key == key) {
                if ((this.product.fields[this.productFields[i].key].length != 0)) {
                  for (let j = 0; j < this.product.fields[this.productFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.product.fields[this.productFields[i].key][j]
                    })
                    // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                  }
                }
              }
            }
          }
          else {
            this.value[i] = (this.product.fields[this.productFields[i].key]);
            this.listFlag[i] = false;
          }
          this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
        }
      }
    });
  }

  optionFieldsCondition(productFields) {
    try {
      for (let i = 0; i < productFields.length; i++) {
        if (productFields[i].options) {
          if ((productFields[i].options.length == 1) || (productFields[i].options.length == 0)) {
            this.prodOptions[i] = false;
          }
          else {
            this.prodOptions[i] = true;
          }
        }
        if (this.productFields[i].list) {
          this.listFlag[i] = true;
        }
        if (productFields[i].type == "BOOLEAN") {
          this.value[i] = false;
        }
        let type = productFields[i].type;
        this.convertedTypeField[i] = (environment.typeConversion[type]);
      }
    } catch (error) {

    }
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.currentImageRef = '';
    this.selectedFiles = null;
    this.previousImageRef = '';
    this.editProductForm.imageRef = '';
  }
  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.editProductForm)
  }

  onSubmit() {
    this.validateFromThroughDate()
    if (this.productFields != null) {
      this.fields = {};
      for (let i = 0; i < this.productFields.length; i++) {

        let fieldName = this.productFields[i].key;

        if (this.productFields[i].type == "INTEGER") {
          this.editProductForm.fields[fieldName] = parseInt(this.value[i])
        } else if (this.productFields[i].type == "DECIMAL") {
          this.editProductForm.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editProductForm.fields[fieldName] = this.value[i]
        }
        if (this.productFields[i].list) {
          //console.log(this.multipleArray.length)
          for (let j = 0; j < this.multipleArray.length; j++) {
            //console.log(this.multipleArray)
            //console.log(this.multipleArray[j])
            if ((this.multipleArray[j] != null) && (this.multipleArray[j].length > 0)) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.productFields[i].type == "INTEGER") {
              this.editProductForm.fields[this.productFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.productFields[i].type == "DECIMAL") {
              this.editProductForm.fields[this.productFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.editProductForm.fields[this.productFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.editProductForm.fields[this.productFields[i].key] = []
          }
        }
      }

       let fields =  cleanDeep(this.editProductForm.fields)
           for (let [key, keyValue] of Object.entries(fields)) {
          if(Number.isNaN(keyValue)) {
            delete fields[key]
          }
        }
        this.editProductForm.fields = fields
    }

    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.product.imageRef != undefined) {
        if ((this.product.imageRef != "")) {
          this.imageUploadService.editImage(file, this.product.imageRef).subscribe((data) => {
            this.editProductServiceCall();
          })
        } else {
          this.imageUploadService.uploadImage(file).subscribe((data) => {
            this.editProductForm.imageRef = data.documentId;
            this.editProductServiceCall();
          })
        }
      } else {
        this.imageUploadService.uploadImage(file).subscribe((data) => {
          this.editProductForm.imageRef = data.documentId;

          this.editProductServiceCall();
        })
      }
    } else {
      this.editProductServiceCall();
    }
  }

  editProductServiceCall() {
    if (environment.WORKFLOW_ENABLED && environment.WORKFLOW_TENANT.includes(this.user.tenant) && this.product.workflowState.history.length != 0) {
        if (this.product.workflowState.status == 'PUBLISHED') {
          let updateProduct = {
            productId: this.productId,
            createdBy: this.product.createdBy
          }
          this.adminService.updatePublishedProduct(updateProduct, this.editProductForm).subscribe((data) => {
            if (!this.category['parentId']) {
              this.router.navigate(["/categorydetails/" + this.product.categoryId + '/' + this.categoryName + "/categoryproduct/" + this.product.categoryId])
            } else {
              this.messageService.broadcast('splice', {
                key: 'addnewproduct',
                alternativeKey: 'addnewproduct'
              })
              this.router.navigate(["/subcategorydetails/" + this.product.categoryId + '/' + this.categoryName + "/categoryproduct/" + this.product.categoryId])
            }
          });
        } else {
          this.adminService.updateProduct(this.productId, this.editProductForm).subscribe((data) => {
            if (data['success']) {
              if (this.selectedFiles != null) {
                let timestamp = new Date();
                sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
              }
            }
            if (!this.category['parentId']) {
              this.router.navigate(["/categorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])
            } else {
              this.messageService.broadcast('splice', {
                key: "productdetails",
                alternativeKey: "productdetails",
              })
              this.router.navigate(["/subcategorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])

            }
          })
        }
      
    } else {
      this.adminService.updateProduct(this.productId, this.editProductForm).subscribe((data) => {
        if (data['success']) {
          if (this.selectedFiles != null) {
            let timestamp = new Date();
            sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
          }
        }
        if (!this.category['parentId']) {
          this.router.navigate(["/categorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])
        } else {
          this.messageService.broadcast('splice', {
            key: "productdetails",
            alternativeKey: "productdetails",
          })
          this.router.navigate(["/subcategorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])

        }
      })
    }
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }

 

  sendProductToReview() {
    let parameters = { notes: this.product.workflowState.parameters.notes }
    console.log("Sent for approval/reject")
    this.adminService.sendProductToReview(this.productId, parameters).subscribe(() => {
      // this.getTaskDetails()
      if (!this.category['parentId']) {
        this.router.navigate(["/categorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])
      } else {
        this.messageService.broadcast('splice', {
          key: "productdetails",
          alternativeKey: "productdetails",
        })
        this.router.navigate(["/subcategorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])

      }
    })
  }
  approveProduct() {
  
    if(this.checkerList.includes(this.product.workflowState.owner)){
      let parameters = { notes: this.product.workflowState.parameters.notes }
      console.log("Sent for approval/reject")
      this.adminService.sendProductToPublish(this.productId, parameters).subscribe(() => {
        // this.getTaskDetails()
        this.inactivateProduct()
        this.loaderService.show() 
        if (!this.category['parentId']) {
          setTimeout(()=>{   
                                   // <<<---using ()=> syntax
            this.router.navigate(["/categorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])
        }, 2000); 
          
        } else {
          this.messageService.broadcast('splice', {
            key: "productdetails",
            alternativeKey: "productdetails",
          })
          this.loaderService.show() 
          setTimeout(()=>{   
                                   // <<<---using ()=> syntax
            this.router.navigate(["/subcategorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])
        }, 2000); 
          
  
        }
      })
    }else{
      this.openClaimModal("claimdialogBox");
    }
    

    
  }

  inactivateProduct(){
    if(this.product!=undefined){
      if(this.product.version.sourceIndexId!=undefined){
        let productToBeInactive=null
        this.adminService.getProductById(this.product.version.sourceIndexId).subscribe((data) => {
          productToBeInactive = data.products[0];
          this.adminService.abortPreviousProduct( productToBeInactive.workflowState.processInstanceId).subscribe(()=>{
            console.log("abort previous product")
          })
        })
      }
    }
  }

  rejectProduct() {
    let parameters = { notes: this.product.workflowState.parameters.notes }
    console.log("Sent for approval/reject")
    this.adminService.sendProductToDraft(this.productId, parameters).subscribe(() => {
    //   this.getTaskDetails()
      if (!this.category['parentId']) {
        this.router.navigate(["/categorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])
      } else {
        this.messageService.broadcast('splice', {
          key: "productdetails",
          alternativeKey: "productdetails",
        })
        this.router.navigate(["/subcategorydetails/" + this.product.categoryId + "/" + this.categoryName + "/categoryproduct/" + this.product.categoryId])

      }
    })

  }

  getCheckerList() {
    
    this.jbpmService.getCheckerList().subscribe((data) => {
      this.checkerList=data
    });
  }
}
