import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BucketUrl } from 'src/app/bucket';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
const cleanDeep = require('src/app/shared/library/deep-clean');

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css'],
})
export class EditCategoryComponent implements OnInit {

  categoryId: string;
  showFields: boolean;
  category: any;
  categories: any[];
  categoryTypes: any[];
  uid: any;
  editCategoryForm: any;
  selectedFiles: FileList;
  user: any;
  categoryFields: any[];
  value: Array<any> = [];
  convertedTypeField: Array<string> = [];
  categoryOptions: Array<string> = []
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  myBucketUrl: any;
  currentImageRef: any;
  previousImageRef: any;
  currentFields: any;
  catOptions: Array<Boolean> = [];
  fields: any;
  subscription: Subscription;
  parentCategoryName: any;
  bucketUrl: BucketUrl;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  currentImageRefForField: Array<any> = [];
  previousImageRefForField: Array<any> = [];
  selectedFilesForFields: Array<any> = [];
  documentArray: Array<Boolean> = [];
  restrictedFromDate: any
  restrictedThroughDate: any

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    public translate: TranslateService,
    private messageService: MessageSubjectService,
    private dateValidations: DateValidationsService) {
    this.bucketUrl = new BucketUrl();
  }




  ngOnInit() {

    this.myBucketUrl = this.bucketUrl.getBucketUrl();

    this.user = JSON.parse(sessionStorage.getItem("user"));

    this.getAllCategoryTypes();

    this.getCategory();



  }

  useLanguage(language: string) {
    console.log(language)
    this.translate.use(language);
  }

  catFields: Array<any> = []
  getCategory() {
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');

      this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
        this.categories = data.categories;
        this.category = this.categories[0];
        if (!this.category['parentId']) {
          if (this.category.name != null) {
            console.log(this.category.name)
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "categorydetails",
              alternativeKey: 'categorydetails',
              labelName: 'Category: ' + this.category.name
            });
          }
        }
        else {
          if (this.category.name != null) {
            console.log(this.category.name)
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "subcategorydetails",
              alternativeKey: 'subcategorydetails' + this.categoryId,
              labelName: 'Sub Category: ' + this.category.name
            });
          }
        }

        this.model();


        if (this.category.parentId) {
          this.getParentCategoryName();
        }

        this.previousImageRef = this.category.imageRef;
        this.getCategoryFieldsByCategoryType();
      });
    });
  }

  model() {
    this.editCategoryForm = {
      name: this.category.name,
      description: this.category.description,
      imageRef: this.category.imageRef,
      type: this.category.type,
      fromDate: this.category.fromDate,
      throughDate: this.category.throughDate,
      modifiedBy: this.user.name,
      fields: this.category.fields
    };
  }

  // selectFile(event) {
  //     this.selectedFiles = event.target.files;

  //     var reader = new FileReader();
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = (_event) => {
  //         this.currentImageRef = reader.result;
  //     }
  // }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRefForField[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.selectedFilesForFields[indexOfField] = null;
      this.currentImageRefForField[indexOfField] = '';
      this.previousImageRefForField[indexOfField] = '';
      this.editCategoryForm.fields[this.categoryFields[indexOfField].key] = '';
      this.category.fields[this.categoryFields[indexOfField].key] = ''
      this.value[indexOfField] = ''
    } else {
      this.currentImageRef = '';
      this.selectedFiles = null;
      this.previousImageRef = '';
      this.editCategoryForm.imageRef = '';
    }
  }


  getCategoryFieldsByCategoryType() {
    this.listArray = []
    this.adminService.getCategoryFieldsByCategoryTypeCode(this.category.type).subscribe((data) => {
      this.categoryFields = data.fields;
      //console.log(this.categoryFields)
      this.optionFieldsCondition(this.categoryFields);
      for (let i = 0; i < this.categoryFields.length; i++) {
        this.previousImageRefForField[i] = this.category.fields[this.categoryFields[i].key];
        this.multipleArray[i] = new Array<any>()
        this.listArray[i] = new Array<any>()
        this.multipleArray[i].push({
          listValue: ''
        })
        if (this.categoryFields != null) {
          if (this.categoryFields[i].list) {
            for (const key of Object.keys(this.category.fields)) {
              if (this.categoryFields[i].key == key) {
                if ((this.category.fields[this.categoryFields[i].key].length != 0)) {
                  for (let j = 0; j < this.category.fields[this.categoryFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.category.fields[this.categoryFields[i].key][j]
                    })
                  }
                }
              }
            }
          }
          else {
            this.listFlag[i] = false;
            //console.log(this.categoryFields[i].key)
            //console.log(this.category.fields[this.categoryFields[i].key])
            if (this.categoryFields[i].type == "BOOLEAN") {
              if (this.category.fields[this.categoryFields[i].key] != true) {
                this.value[i] = false
              } else {
                this.value[i] = (this.category.fields[this.categoryFields[i].key])
              }
            } else {
              this.value[i] = (this.category.fields[this.categoryFields[i].key])
              //console.log(this.value[i])
            }
          }
        }
      }
      //console.log(this.multipleArray)
    });
  }

  // optionFieldsCondition(categoryFields) {
  //     this.value= []
  //     if (categoryFields.length) {
  //         for (let i = 0; i < categoryFields.length; i++) {
  //             if (this.categoryFields[i].type == "DOCUMENT") {
  //                 this.documentArray[i] = true;
  //               } else {
  //                 this.documentArray[i] = false;
  //               }
  //               this.selectedFilesForFields[i] = null;
  //               this.currentImageRefForField[i] = null;
  //               this.previousImageRefForField[i] = null;
  //             if (categoryFields[i].options) {
  //                 if ((categoryFields[i].options.length == 1) || (categoryFields[i].options.length == 0)) {
  //                     this.catOptions[i] = false;
  //                 }
  //                 else {
  //                     this.catOptions[i] = true;
  //                 }
  //             }
  //             if (categoryFields[i].list) {
  //                 this.listFlag[i] = true;
  //             }else {
  //                 this.listFlag[i] = false
  //             }
  //             if (categoryFields[i].type == "BOOLEAN") {
  //                 this.value[i] = false;
  //                 //console.log(this.value[i])
  //             }
  //             let type = categoryFields[i].type;
  //             this.convertedTypeField[i] = (environment.typeConversion[type]);
  //         }
  //         //console.log(this.value)
  //     }
  // }

  optionFieldsCondition(categoryFields) {
    for (let i = 0; i < categoryFields.length; i++) {
      if (this.categoryFields[i].type == "DOCUMENT") {
        this.documentArray[i] = true;
      } else {
        this.documentArray[i] = false;
      }
      this.selectedFilesForFields[i] = null;
      this.currentImageRefForField[i] = null;
      this.previousImageRefForField[i] = null;
      if (categoryFields[i].options) {
        if ((categoryFields[i].options.length == 1) || (categoryFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      if (categoryFields[i].list) {
        this.listFlag[i] = true;
      } else {
        this.listFlag[i] = false;
      }
      if (categoryFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = categoryFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
    }
  }

  selectCode(event) {
    this.currentFields = event.target.value;
    this.adminService.getCategoryFieldsByCategoryTypeCode(this.currentFields).subscribe((data) => {
      this.categoryFields = data.fields;
      //console.log(this.categoryFields)
      this.optionFieldsCondition(this.categoryFields);
      for (let i = 0; i < this.categoryFields.length; i++) {
        this.multipleArray[i] = new Array<any>();
        this.lastIndexOfMultipleArray[i] = new Array<any>();
        this.listArray[i] = new Array<any>();
        this.multipleArray[i].push({
          listValue: ''
        })
        //console.log(this.category.type , this.currentFields)
        if (this.category.type == this.currentFields) {
          this.previousImageRefForField[i] = this.category.fields[this.categoryFields[i].key];
          if (this.categoryFields[i].list) {
            for (const key of Object.keys(this.category.fields)) {
              //console.log(this.categoryFields[i].key,key)
              if (this.categoryFields[i].key == key) {
                if ((this.category.fields[this.categoryFields[i].key].length != 0)) {
                  for (let j = 0; j < this.category.fields[this.categoryFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.category.fields[this.categoryFields[i].key][j]
                    })
                    // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                  }
                }
              } else {
                this.multipleArray[i].push({
                  listValue: ''
                })
              }
            }
          }
          else {
            this.value[i] = (this.category.fields[this.categoryFields[i].key])
            this.listFlag[i] = false;
          }

        }
        else {
          this.value[i] = ""
          this.editCategoryForm.fields = {}
          this.multipleArray[i].push({
            listValue: ''
          })
        }
        this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
      }
    });
    this.showFields = true;
  }

  resetForm() {

    this.openModal();
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal();
        this.ngOnInit();

        this.currentImageRef = '';
        this.selectedFiles = null;
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal() {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }
  closeModal() {
    this.deleteDialogService.closeDialog();
  }

  getAllCategoryTypes() {
    this.adminService.getAllCategoryTypes().subscribe((data) => {
      this.categoryTypes = data.categoryTypes;
    });
  }



  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image)
  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.editCategoryForm)
  }


  async onSubmit() {
    this.validateFromThroughDate()
    if (this.categoryFields != null) {
      for (let i = 0; i < this.categoryFields.length; i++) {
        let fieldName = this.categoryFields[i].key;

        if (this.categoryFields[i].type == "INTEGER") {
          this.editCategoryForm.fields[fieldName] = parseInt(this.value[i])
        } else if (this.categoryFields[i].type == "DECIMAL") {
          this.editCategoryForm.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editCategoryForm.fields[fieldName] = this.value[i]
        }

        if (this.categoryFields[i].list) {
          for (let j = 0; j < this.multipleArray.length; j++) {
            if ((this.multipleArray[j] != null) && (this.multipleArray[j].length > 0)) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.categoryFields[i].type == "INTEGER") {
              this.editCategoryForm.fields[this.categoryFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.categoryFields[i].type == "DECIMAL") {
              this.editCategoryForm.fields[this.categoryFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.editCategoryForm.fields[this.categoryFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.editCategoryForm.fields[this.categoryFields[i].key] = []
          }
        }

      }
    }
    await this.uploadImageRef();

    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          if (this.editCategoryForm.fields[this.categoryFields[index].key] != undefined) {
            if (this.editCategoryForm.fields[this.categoryFields[index].key] != "") {
              let data = await this.callServiceToEditImage(file, this.editCategoryForm.fields[this.categoryFields[index].key]);
              if (index == this.selectedFilesForFields.length - 1) {
                this.editCategoryServiceCall();
              }
            } else {
              let data = await this.callServiceToUploadImage(file);
              this.editCategoryForm.fields[this.categoryFields[index].key] = data.documentId;
              if (index == this.selectedFilesForFields.length - 1) {
                this.editCategoryServiceCall();
              }
            }
          } else {
            let data = await this.callServiceToUploadImage(file);
            this.editCategoryForm.fields[this.categoryFields[index].key] = data.documentId;
            if (index == this.selectedFilesForFields.length - 1) {
              this.editCategoryServiceCall();
            }
          }
        } else {
          if (index == this.selectedFilesForFields.length - 1) {
            this.editCategoryServiceCall();
          }
        }
      }
    } else {
      this.editCategoryServiceCall();
    }

  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.editCategoryForm.imageRef != undefined) {
        if ((this.editCategoryForm.imageRef != "")) {
          let data = await this.callServiceToEditImage(file, this.editCategoryForm.imageRef)
        } else {
          let data = await this.callServiceToUploadImage(file);
          this.editCategoryForm.imageRef = data.documentId;
        }
      } else {
        let data = await this.callServiceToUploadImage(file);
        this.editCategoryForm.imageRef = data.documentId;
      }
    } else {

    }
  }

  async callServiceToEditImage(file: any, imageRef: any): Promise<any> {
    return this.imageUploadService.editImage(file, imageRef).toPromise();
  }

  async callServiceToUploadImage(file: any): Promise<any> {
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  getParentCategoryName() {
    this.adminService.getCategoryById(this.category.parentId).subscribe((data) => {
      this.parentCategoryName = data.categories[0].name;
    })
  }

  editCategoryServiceCall() {
    if (this.category['parentId']) {
      this.editCategoryForm['parentId'] = this.category['parentId']
    }
    let category = this.editCategoryForm
    category.fields = cleanDeep(this.editCategoryForm.fields)
    console.log("category", this.categoryId)
    this.adminService.updateCategory(this.categoryId, category).subscribe((data) => {
      if (data['success']) {
        if (this.selectedFiles != null || this.selectedFilesForFields != null) {
          let timestamp = new Date();
          sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
        }
      }
      if (this.category.parentId != null) {
        let breadcrumbList = JSON.parse(window.sessionStorage.getItem('breadcrumbList'));
        if (breadcrumbList[breadcrumbList.length - 1].child.length > 1) {
          this.router.navigate(['/subcategorydetails/' + this.category.parentId + '/' + this.parentCategoryName + '/subcategory/' + this.category.parentId])

        } else {
          this.router.navigate(['/categorydetails/' + this.category.parentId + '/' + this.parentCategoryName + '/subcategory/' + this.category.parentId])

        }
        this.messageService.broadcast('childSplice', {
          key: 'subcategorydetails',
          alternativeKey: "subcategorydetails" + this.category.parentId
        })
      }
      else {
        this.router.navigate(["/productcategories"]);
      }
    })
  }

  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }
}
