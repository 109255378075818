import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  BASE_URL = environment.BASE_URL;

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;

  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;


  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }

  serverRootUrl = environment.BASE_URL;
  customerServerRootUrl = environment.CUSTOMER_BASE_URL;
  customerRootUrl = this.customerServerRootUrl + "/api/v1/customers/"
  orderHistoryRootUrl = this.serverRootUrl + "/orderhistory/api/v1/orderhistory/"
  orderRootUrl = this.serverRootUrl + "/order/api/v1/orders/"
  shipmentRootUrl = this.serverRootUrl + "/order/api/v1/shipments/"

  // loyaltyPointRootUrl = this.serverRootUrl + "/loyaltypoint/api/v1/loyaltypoints/"

  // loyaltyProfileRootUrl = this.serverRootUrl + "/loyaltyprofile/api/v1/loyaltyprofiles/"


  // loyaltyProfileRootUrl = "https://dcpecomm2.digitalcloudplatform.com/loyaltyprofile/api/v1/loyaltyprofiles/"
  // loyaltyProfileRootUrl = "https://1nluxz4k2c.execute-api.us-west-2.amazonaws.com/dev/api/v1/loyaltyprofiles/"
  loyaltyProfileRootUrl = environment.LOYALTY_PROFILE_BASE_URL + "/api/v1/loyaltyprofiles/"
  loyaltyTierRootUrl = environment.TIER_BASE_URL + "/api/v1/loyaltytiers/"
  // loyaltyTierRootUrl = "https://dcpecomm2.digitalcloudplatform.com/loyaltytier/api/v1/loyaltytiers/"

  getAllCustomers(): Observable<any> {

    var requestBody = {
      "retrieveInactive": true,
      "sort": [
        {
          "fieldName": "firstName",
          "sortDirection": "ASCENDING"
        }
      ]
    }


    return this.http.post<any>(this.customerRootUrl + "query", requestBody, this.getHeaderTenant());
  }

  deleteCustomerApi(customerId): Observable<any> {
    return this.http.delete<any>(this.customerRootUrl + "?customerId=" + customerId, this.getHeaderTenant());
  }

  getCustomerById(customerId): Observable<any> {
    return this.http.get<any>(this.customerRootUrl + "?customerIds=" + customerId, this.getHeaderTenant())
  }

  getLoyaltyPointById(customerId): Observable<any> {
    return this.http.get<any>(this.loyaltyProfileRootUrl + "customers/" + customerId + "/points", this.getHeaderTenant());
  }

  getLoyaltyPointsbyQuery(filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": false,
      // "sort": [
      //   {
      //     "fieldName": "earnDate",
      //     "sortDirection": "DESCENDING"
      //   }
      // ]
      sort: sort
    }
    return this.http.post<any>(this.loyaltyProfileRootUrl + "customers/points/query", requestBody, this.getHeaderTenant());
  }


  getAllTiers(): Observable<any> {
    // return this.http.get<any>(this.loyaltyProfileRootUrl + "tiers/all", this.getHeaderTenant())
    return this.http.get<any>(this.loyaltyTierRootUrl + "all", this.getHeaderTenant())
  }

  getLoyaltyProfileById(customerId): Observable<any> {
    return this.http.get<any>(this.loyaltyProfileRootUrl + "customers/" + customerId, this.getHeaderTenant());
  }

  getLoyaltyRewardsByQuery(filters, itemsPerPage, pageNumber): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": [{
        "fieldName": "rewardsCollected.earnDate",
        "sortDirection": "DESCENDING"

      }]
    }
    return this.http.post<any>(this.loyaltyProfileRootUrl + "customers/earnedrewards/query", requestBody, this.getHeaderTenant());
  }

  getAllOrderHistory(customerId) {
    return this.http.get<any>(this.orderHistoryRootUrl + "/customers/" + customerId + "/all", this.getHeaderTenant())
  }

  getAllOrderHistoryWithPagination(filters, customerId, itemsPerPage, pageNumber, sort) {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": false,
      // "sort": [
      //   {
      //     "fieldName": "createdDate",
      //     "sortDirection": "DESCENDING"
      //   }
      // ]
      "sort": sort
    }
    return this.http.post<any>(this.orderHistoryRootUrl + "/customers/" + customerId + "/query", requestBody, this.getHeaderTenant());
  }
  getOrderWithShipment(orderId) {
    return this.http.get<any>(this.orderHistoryRootUrl + "orders/" + orderId + "/shipments", this.getHeaderTenant())
  }

  getOrderWithId(orderId) {
    console.log('cafeservice')
    return this.http.get<any>(this.orderHistoryRootUrl + "orders/" + orderId, this.getHeaderTenant())

  }
  updateOrderWithShipment(orderId, status) {
    var requestBody = {

      "orderId": orderId,
      "status": status,

    }
    console.log(requestBody)
    return this.http.put<any>(this.orderRootUrl, requestBody, this.getHeaderTenant())
  }

  updateShipment(shipmentId, status) {
    var requestBody = {
      "shipmentId": shipmentId,
      "status": status
    }
    console.log(requestBody)
    return this.http.put<any>(this.shipmentRootUrl, requestBody, this.getHeaderTenant())
  }


}
