import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';


@Component({
  selector: 'app-addnew-category-features',
  templateUrl: './addnew-category-features.component.html',
  styleUrls: ['./addnew-category-features.component.css']
})
export class AddnewCategoryFeaturesComponent implements OnInit {

  user: any;
  myBucketUrl: any;
  categoryFeatures: any;
  featureTypes: any[];
  selectedFiles: FileList;
  categoryId: any;
  basePrice: any;
  price: any;
  uid: any;
  categoryFeature: any;
  currentFields: any;
  showFields: boolean = false;
  convertedTypeField: Array<string> = [];
  fieldCheckbox: Array<boolean> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  featureFields: any;
  value: Array<any> = [];
  fields: any;
  featureOptions: Array<string> = []
  catOptions: Array<Boolean> = [];
  subscription: Subscription;
  minUseCount:Number;
  maxUseCount:Number;
  defaultUseCount:Number;
  currentImageRef: any;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService) { }



  ngOnInit() {
    console.log('category-fetaures')
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.getFeatureType();

    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');
    })

    this.categoryFeatures = {
      name: '',
      description: '',
      defaultUseCount: 0,
      maxUseCount: 0,
      minUseCount: 0,
      price: {},
      imageRef: '',
      type: '',
      createdBy: this.user.name,
      options: [],
      fields: {}
    }

  }

  resetForm() {


    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal("dialogBox");

        this.ngOnInit();
        this.showFields = false;
        this.price = "";

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //console.log(event.target)
  }


  getFeatureType() {
    this.adminService.getAllProductFeatureType().subscribe((data) => {
      this.featureTypes = data.featureTypes;
    })
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;

   var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => { 
      this.currentImageRef = reader.result; 
    }
  }

  resetImage(){
    this.selectedFiles = null;
    this.currentImageRef = "";
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  selectCode(event) {
    this.currentFields = event.target.value;

    this.adminService.getAllFeatureFields(this.currentFields).subscribe((data) => {
      this.featureFields = data.productFeatureFields;
      //console.log(this.featureFields)
      this.optionFieldsCondition(this.featureFields);

    });

    this.showFields = true;
  }


  optionFieldsCondition(featureFields) {
    this.value = []
    for (let i = 0; i < featureFields.length; i++) {
      if (featureFields[i].options) {
        if (featureFields[i].options.length == 1) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      //console.log(this.catOptions)
      if (featureFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = featureFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
    }
  }

  onSubmit() {
    if (this.featureFields != null) {
      this.fields = {};
      for (let i = 0; i < this.featureFields.length; i++) {
        this.fields[this.featureFields[i].key] = this.value[i];
      }
      this.categoryFeatures.fields = this.fields
    }
    this.categoryFeatures.price["value"] = this.price;
    this.categoryFeatures.price["currency"] = "USD";


    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);

      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.categoryFeatures.imageRef = data.documentId;

        this.addNewCategoryFeatureServiceCall();
      })
    } else {
      this.addNewCategoryFeatureServiceCall();
    }
  }

  addNewCategoryFeatureServiceCall() {
    this.adminService.createCategoryFeatureById(this.categoryId, this.categoryFeatures).subscribe((data) => {
      this.router.navigate(['categorydetails/' + this.categoryId + '/categoryfeatures/' + this.categoryId])
    });
  }



}
