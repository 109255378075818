import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  user: any;
  tenant: any;
  headers: Headers;

  constructor(public http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
    //console.log(this.user.tenant)
  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  serverRootUrl = environment.STORE_BASE_URL;
  //services for build
  productStoreRootUrl = this.serverRootUrl + "/productstore/api/v1/productstores/";
  productStoreTypeRootUrl = this.serverRootUrl + "/productstore/api/v1/productstoretypes/"
  productPriceRootUrl = this.serverRootUrl + "/productstore/api/v1/productstoreprices/";
  productCatalogRootUrl = environment.PRODUCT_CATALOG_BASE_URL + "/api/v1/productcatalogs/"
  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }

  createStore(store) {
    let request = {};
    request['store'] = store;
    return this.http.post<any>(this.productStoreRootUrl, request, this.getHeaderTenant());
  }

  getChildStores(parentCode): Observable<any> {
    if (parentCode == null) {
      return this.http.get<any>(this.productStoreRootUrl + 'children?retrieveInactive=true', this.getHeaderTenant());
    } else {
      return this.http.get<any>(this.productStoreRootUrl + "/children?parentCode=" + parentCode + "&retrieveInactive=true", this.getHeaderTenant())
    }
  }

  getAllStoresByQuery(filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    return this.http.post<any>(this.productStoreRootUrl + "all/query", requestBody, this.getHeaderTenant());
  }

  getAllStores(): Observable<any> {
    return this.http.get<any>(this.productStoreRootUrl + 'all', this.getHeaderTenant());
  }

  getStoreByCode(storeCode): Observable<any> {
    return this.http.get<any>(this.productStoreRootUrl + "?productStoreCodes=" + storeCode, this.getHeaderTenant());
  }

  deleteStoreByCodes(storeCode) {
    return this.http.delete<any>(this.productStoreRootUrl + "?productStoreCodes=" + storeCode, this.getHeaderTenant());
  }

  updateStoreByCode(store, storeCode) {
    let request = {};
    request['store'] = store;
    return this.http.put<any>(this.productStoreRootUrl + storeCode, request, this.getHeaderTenant());
  }

  getAllStoreTypes(): Observable<any> {
    return this.http.get<any>(this.productStoreTypeRootUrl + "all?retrieveInactive=true", this.getHeaderTenant());
  }

  getStoreFieldByStoreTypeCode(storeTypeCode) {
    return this.http.get<any>(this.productStoreRootUrl + "types/" + storeTypeCode, this.getHeaderTenant());
  }

  getAllCatalogByStoreCode(storeCode) {
    return this.http.get<any>(this.serverRootUrl + "/productcatalog/api/v1/productcatalogs/stores/" + storeCode + "?retrieveInactive=true", this.getHeaderTenant());
  }

  getAllCatalogsByQuery(filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    return this.http.post<any>(this.productCatalogRootUrl + "query", requestBody, this.getHeaderTenant());
  }

  getAllChildrenCategories(parentId, storeCode) {
    if (parentId == null) {
      return this.http.get<any>(this.productStoreRootUrl + storeCode + "/categories/children", this.getHeaderTenant())
    } else {
      return this.http.get<any>(this.productStoreRootUrl + storeCode + "/categories/children?parentCategoryId=" + parentId, this.getHeaderTenant())
    }
  }

  getAllCategoryByStoreCode(storeCode) {
    //console.log(storeCode)
    return this.http.get<any>(this.productStoreRootUrl + storeCode + "/categories/all", this.getHeaderTenant())
  }

  getCategoriesByQuery(storeCode, filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    return this.http.post<any>(this.productStoreRootUrl + storeCode + "/categories/query", requestBody, this.getHeaderTenant());
  }

  deleteCategoyByIds(storeCode, categoryIds) {
    let request = {};
    request['categoryIds'] = categoryIds;
    let options = {
      headers: { 'X-TENANT': this.tenant },
      body: request
    }
    return this.http.delete<any>(this.productStoreRootUrl + storeCode + "/categories", options);
  }


  addCatgoryInStore(storeCode, categoryIds) {
    let request = {};
    request['categoryIds'] = categoryIds;
    return this.http.post<any>(this.productStoreRootUrl + storeCode + "/categories", request, this.getHeaderTenant())
  }


  getProductByCategoryId(categoryId, storeCode) {
    return this.http.get<any>(this.productStoreRootUrl + storeCode + "/categories/" + categoryId + "/products/all", this.getHeaderTenant())
  }


  getProductPriceByCategoryId(categoryId, storeCode) {
    return this.http.get<any>(this.productPriceRootUrl + storeCode + "/categories/" + categoryId, this.getHeaderTenant())
  }

  addProductByCategoryIdInStore(categoryId, storeCode, productIds) {
    let request = {};
    request['productIds'] = productIds;
    return this.http.post<any>(this.productStoreRootUrl + storeCode + "/categories/" + categoryId + "/products", request, this.getHeaderTenant())
  }

  deleteProductfromCategory(categoryId, storeCode, productIds) {
    let request = {};
    request['productIds'] = productIds;
    let options = {
      headers: { 'X-TENANT': this.tenant },
      body: request
    }
    return this.http.delete<any>(this.productStoreRootUrl + storeCode + "/categories/" + categoryId + "/products", options);
  }

  getPriceList(productId) {
    return this.http.get<any>('./assets/price-list-json/price-list.json');
  }


  getProductsByFilter(productStoreCode, filters, itemsPerPage, pageNumber, sort) {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }

    return this.http.post<any>(this.productStoreRootUrl + productStoreCode + "/products/query", requestBody, this.getHeaderTenant());
  }


  // ------ price service -------//

  // getProductsByStoreCode(storeCode) {
  //   return this.http.get<any>(this.productStoreRootUrl+storeCode+"/products/all",this.getHeaderTenant());
  // }

  // getProductPriceByProductId(productId,storeCode) {
  //   return this.http.get<any>(this.productPriceRootUrl+storeCode+"/productId/"+productId,this.getHeaderTenant())
  // }

  getProductsByStoreCode(productStoreCode) {
    return this.http.get<any>(this.productPriceRootUrl + productStoreCode, this.getHeaderTenant());
  }

  getProductsByQuery(productStoreCode, filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    return this.http.post<any>(this.productPriceRootUrl + productStoreCode + "/query", requestBody, this.getHeaderTenant());
  }

  getProductByCategoryIdAndStoreCode(categoryId, storeCode) {
    return this.http.get<any>(this.productPriceRootUrl + storeCode + "/categories/" + categoryId, this.getHeaderTenant());
  }

  getProductPriceListByPriceId(productId, storeCode) {
    return this.http.get<any>(this.productPriceRootUrl + storeCode + "/products/" + productId, this.getHeaderTenant());
  }

  updatepriceById(priceIdInList, productStorePriceId) {
    return this.http.put<any>(this.productPriceRootUrl + "/productStorePriceId/" + productStorePriceId + "?priceIdInList=" + priceIdInList, this.getHeaderTenant());
  }

  updatePriceByStorePriceId(price, productStorePriceId) {
    let request = {};
    request['price'] = price;
    return this.http.put<any>(this.productPriceRootUrl + productStorePriceId, request, this.getHeaderTenant());
  }

  manageProductPriceList(productId, storeCode, prices) {
    let request = {};
    request = prices;
    return this.http.put<any>(this.productPriceRootUrl + storeCode + "/products/" + productId, request, this.getHeaderTenant())
  }


  ManageProductStorePrice(storeCode, storePrices): Observable<any> {
    let request = {};
    request['storePrices'] = storePrices;
    console.log(request)
    return this.http.put<any>(this.productPriceRootUrl + storeCode + "/products", request, this.getHeaderTenant());
  }

  getAllCurrency() {
    return this.http.get<any>('./assets/json/currency/currency.json')
  }


  getAllUnits() {
    return this.http.get<any>('./assets/json/units/units.json')
  }
}

