import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/admin/admin.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { StoreCategoryFilters } from 'src/app/shared/constants/filter-model/store-category-filters.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-all-categories',
  templateUrl: './get-all-categories.component.html',
  styleUrls: ['./get-all-categories.component.css']
})
export class GetAllCategoriesComponent implements OnInit {
  
  productsArray: Array<any> =[];
  productsInCategory: Array<any> = [];
  checkboxItemsProduct: Array<boolean> = [];
  myValueProduct: boolean = false;
  categoryId : any;
  allCheckProduct: boolean = false;
  storeCode: any;
  categories: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  subscription: Subscription;
  parentCode: any;
  getAllNestedCategory: Array<any> = []
  category: any;
  categoryProducts: any;
  currentStore: any;
  currentCat: any;
  categoriesInStore: Array<any> =[]
  renderCategoryInStore : Array<any> = []
  categoriesAddedInStore: Array<any> = [];
  arrayOfCategoryIds: Array<any> = [];
  allCategories: Array<any> = [];
  getNestedCategoriesOfStore: Array<any> = [];
  noDataShow: Boolean = false;
  imageArray: Array<any> = [];
  allCheckCategory: Boolean = false;
  currentPage : number =1;
  itemsPerPage :number = 10;
  numberOfPages:number=1;
  totalItems: number;
  pagination: any;
  types: Array<any> = [];
  searchName: string;
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  filters: any = StoreCategoryFilters;
  @ViewChild('addCategory',{static: true}) public addCategory: ElementRef;
  
  
  
  constructor(private storeService: StoreService,
    private route: ActivatedRoute,
    private backgroundImageService: BackgroundImageService,
    private modalService: ModalService,
    private deleteDialogService: DeleteDialogService,
    private ngbModalService: NgbModal,
    private adminService: AdminService,
    private noDataService: NoDataService,
    private router: Router,
    private messageService: MessageSubjectService,
    private nestedCategory: NestedCategoryService,
    public translate:TranslateService) { 
      this.filters = new StoreCategoryFilters()
    }
    
    config = {
      hasSelect: false
    }
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.parentCode = map.get('storecode');
        this.storeService.getStoreByCode(this.parentCode).subscribe((data) => {
          this.currentStore = data.stores[0];
          this.getCategoriesAddedInStore()
          // this.getCategoriesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
          this.setBreadCrumbData();
        });
      });  
    }
    
    setBreadCrumbData() {
      if(!this.currentStore['parentCode']) {
        if(this.currentStore.name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "regiondetails",
            alternativeKey: 'regiondetails',
            labelName: 'Region: '+decodeURIComponent(this.currentStore.name),
            path: decodeURI(this.router.url)
          });
        }
      }
      else {
        if(this.currentStore.name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "subregiondetails",
            alternativeKey: 'subregiondetails'+this.storeCode,
            labelName: 'Subregion: '+decodeURIComponent(this.currentStore.name),
            path: decodeURI(this.router.url)
          });
        }
      }
    }
    
    getAllCategory() {
      this.getAllNestedCategory = []
      this.categories = []
      this.allCheckCategory = false;
      if(this.currentStore.parentCode != null) {
        this.storeService.getAllCategoryByStoreCode(this.currentStore.parentCode).subscribe((data) => {
          this.categories = data.categories;
          this.getAllNestedCategory = this.nestedCategory.getProductCategoryTree(this.categories);
          this.disabledAddedCategory(this.getAllNestedCategory).then(res => this.checkInitialForAllCategory(this.getAllNestedCategory));
        });    
      }else {
        this.adminService.getAllCategory().subscribe((data) => {
          this.categories = data.categories;    
          this.getAllNestedCategory = this.nestedCategory.getProductCategoryTree(this.categories);
          this.disabledAddedCategory(this.getAllNestedCategory).then(res => this.checkInitialForAllCategory(this.getAllNestedCategory));
        }); 
      }
    }

    
    
    
    getCategoriesAddedInStore() {
      this.arrayOfCategoryIds = [];
      this.categoriesInStore = [];
      this.getNestedCategoriesOfStore = []
      this.storeService.getAllCategoryByStoreCode(this.parentCode).subscribe((data) => {
        this.categoriesInStore = data.categories;
        for(let i=0; i< this.categoriesInStore.length; i++) {
          this.arrayOfCategoryIds[i] = this.categoriesInStore[i].id;
        }
        console.log(this.arrayOfCategoryIds)
        this.getNestedCategoriesOfStore = this.nestedCategory.getProductCategoryTree(this.categoriesInStore);
        console.log(this.getNestedCategoriesOfStore)
        this.noDataShow = this.noDataService.noData(this.getNestedCategoriesOfStore);
      });
    }

    categoriesResponse: Array<any> = []
    showNoData: Boolean = false;
    getCategoriesByQuery(filters,itemsPerPage,currentPage,sort) {
      this.categoriesResponse = []
      console.log(this.parentCode)
      this.storeService.getCategoriesByQuery(this.parentCode,filters,itemsPerPage,currentPage,sort).subscribe((data) => {
        console.log(data.categories)
        this.categoriesResponse = this.nestedCategory.getProductCategoryTree(data.categories)
        console.log(this.categoriesResponse)
        // this.categoriesResponse=data.cate
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.showNoData = this.noDataService.noData(data.categories);
        this.getBackgroundImage(data.categories)
      });
    }


    getBackgroundImage(categories) {
      if(categories.length > 0) {
        for (let i = 0; i < categories.length; i++) {
          categories[i].imageRef = this.getBackground(categories[i].imageRef);
        }
      }
    }

    
    disabledAddedCategory(tree) {
      return new Promise((resolve,reject) => {
        for(let i = 0;i< tree.length;i++){
          if(this.arrayOfCategoryIds.includes(tree[i].id)) {
            tree[i].current = "checked"
            tree[i].initial = "checked"
          }
          if(tree[i].children.length > 0) {
            this.disabledAddedCategory(tree[i].children)
          }
        }
        resolve();
      });
    }
    
    checkInitialForAllCategory(tree) {
      let checkAll = true;
      for(let i = 0;i< tree.length;i++){
        if(tree[i].initial == "unchecked") {
          checkAll = false;
        }
        if(tree[i].children.length > 0) {
          this.checkInitialForAllCategory(tree[i].children);
        }
      }
      this.allCheckCategory = checkAll
    }
    
    
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.categories.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.categories.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    selectCheckAll(categories) {
      let checkAll = true;
      for(let i=0; i< categories.length; i++) {
        if(categories[i].current == "unchecked") {
          checkAll = false;
        }
        if(categories[i].children.length > 0) {
          this.selectCheckAll(categories[i].children);
        }
      }
      this.allCheckCategory = checkAll;
    }
    
    getCategoryId(category,event) {
      if(event.target.checked) {
        if(category.parentId == null) {
          category.current = "checked";
          category.changed = category.current != category.initial;
          console.log(category)
          this.checkForChildCategory(event,category,this.getAllNestedCategory);
        }
        else {
          if(category.children != null) {
            category.current = "checked";
            category.changed = category.current != category.initial;
            console.log(category)
            this.checkForChildCategory(event,category,this.getAllNestedCategory);
            this.checkForParentCategory(event,category, this.getAllNestedCategory);
          }else {
            category.current = "checked";
            category.changed = category.current != category.initial;
            console.log(category)
            this.checkForParentCategory(event,category, this.getAllNestedCategory);
            
          }
        }
        this.selectCheckAll(this.getAllNestedCategory)
      }
      else {
        if(category.parentId == null ) {
          category.current = "unchecked"
          category.changed = category.current != category.initial;
          console.log(category)
          this.unCheckForChildCategory(event,category,this.getAllNestedCategory)
        }else{
          if(category.children != null) {
            category.current = "unchecked"
            category.changed = category.current != category.initial;
            console.log(category)
            this.unCheckForChildCategory(event,category,this.getAllNestedCategory)  
          }
          else {
            category.current = "unchecked"
            category.changed = category.current != category.initial;
            console.log(category)
          }
        }  
        this.allCheckCategory = false;     
      } 
    }
    
    
    checkForChildCategory(event,currentCategory1,tree) {
      var currentCategory = currentCategory1
      for(let i=0; i<tree.length; i++) {
        var cat = tree[i];
        if(currentCategory1.id == tree[i].parentId) {
          tree[i].current = "checked";
          tree[i].changed = tree[i].current != tree[i].initial
          currentCategory = cat;
          this.checkForChildCategory(event,cat,this.getAllNestedCategory);
        }
        if(cat.children.length > 0 ){
          this.checkForChildCategory(event,currentCategory,cat.children)
        }        
      }
    }
    
    unCheckForChildCategory(event, currentCategory1,tree) {
      var currentCategory = currentCategory1;
      for(let i=0; i<tree.length; i++) {
        var cat = tree[i]
        if(cat.parentId != null ) {
          if(currentCategory1.id == tree[i].parentId) {
            tree[i].current = "unchecked"
            tree[i].changed =  tree[i].current !=  tree[i].initial;
            currentCategory = cat;
            this.unCheckForChildCategory(event,cat,this.getAllNestedCategory)
          }
        }        
        if(cat.children.length > 0 ){
          this.unCheckForChildCategory(event,currentCategory,cat.children)
        }
      }
    }
    
    checkForParentCategory(event,currentCategory1,tree) {
      var currentCategory = currentCategory1;
      for(let i=0 ; i<tree.length; i++) {
        var cat = tree[i];
        if(currentCategory.parentId == cat.id) {
          tree[i].current = "checked";
          tree[i].changed = tree[i].current != tree[i].initial
          currentCategory = cat
          this.checkForParentCategory(event,cat, this.getAllNestedCategory)
        }
        if( cat.children.length > 0) {
          this.checkForParentCategory(event,currentCategory,cat.children)
        }
      }
    }
    
    
    
    
    unCheckForParentCategory(event,currentCategory1,tree) {
      var currentCategory = currentCategory1;
      for(let i=0 ; i< tree.length; i++) {
        var cat = tree[i];
        if(currentCategory.parentId == cat.id) {
          tree[i].current = "unchecked"
          tree[i].changed =  tree[i].current !=  tree[i].initial;
          console.log( tree[i]);
          currentCategory = cat
        }
        if( cat.children.length > 0) {
          this.unCheckForParentCategory(event,currentCategory,cat.children)
        } 
      }
    }
    
    openCategoryModal() {
      this.getAllCategory()
      this.ngbModalService.open(this.addCategory, { size: 'lg', windowClass: 'update-modal', scrollable: true });
    }
    
    
    closeCategoryModal() {
      this.ngbModalService.dismissAll(this.addCategory);
    }
    
    hideShowMethod(event,item) {
      item.childVisible =! item.childVisible;
    } 
    
    deleteArray : Array<any> = [];
    addArray: Array<any> = [];
    onCategorySubmit(){
      this.deleteArray = [];
      this.addArray = [];
      this.checkForAddAndDeleteCategory(this.getAllNestedCategory).then(
        res => this.callApiForAddAndDeleteCategory().then(res => {
          this.ngbModalService.dismissAll(this.addCategory);
          this.ngOnInit();
        }));
        // this.callApiForAddAndDeleteCategory().then(res =>this.getCategoriesAddedInStore())
        // this.callApiForAddAndDeleteCategory().then(res => this.callngOnit())
        
      }
      
      
      
      checkForAddAndDeleteCategory(tree) {
        return new Promise((resolve,reject) => {
          for(let i=0; i< tree.length; i++) {
            if((tree[i].changed == true) && (tree[i].current == "unchecked") && (tree[i].initial == "checked")) {
              this.deleteArray.push(tree[i].id)
            }
            else if((tree[i].changed == true) && (tree[i].current == "checked") && (tree[i].initial == "unchecked")) {
              this.addArray.push(tree[i].id)
            }
            
            if(tree[i].children.length > 0) {
              this.checkForAddAndDeleteCategory(tree[i].children)
            }
          }
          resolve();
        });
      }
      
      
      async callApiForAddAndDeleteCategory() {
        console.log(this.deleteArray)
        console.log(this.addArray)
        if(this.deleteArray.length > 0) {
          await this.storeService.deleteCategoyByIds(this.parentCode,this.deleteArray).subscribe((data) => {
            console.log("delete")
          });
        }
        
        if(this.addArray.length > 0) {
          await  this.storeService.addCatgoryInStore(this.parentCode,this.addArray).subscribe((data) => {
            console.log("add")
          })   
        }
      }
      
      checkAllCategory() {
        this.allCheckCategory = !this.allCheckCategory;
        let status = '';
        if(this.allCheckCategory) {
          status = "checked"
          this.checkAllNestedCategories(this.getAllNestedCategory,status);
        }else {
          status = "unchecked";
          this.checkAllNestedCategories(this.getAllNestedCategory,status);
        }
      }
      
      checkAllNestedCategories(categories,status) {
        for(let i=0; i< categories.length; i++) {
          if(categories.current != "checked") {
            categories[i]['current'] = status;
            categories[i]['changed'] = categories[i]['current'] != categories[i]['initial'];
          }
          if(categories[i].children.length > 0) {
            this.checkAllNestedCategories(categories[i].children,status);
          }
        }
      }
      
      resetCategories() {
        this.openResetModal("dialogBox");
        this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
          if (res == "yes") {
            this.allCheckCategory = false;
            this.getAllNestedCategory = this.nestedCategory.getProductCategoryTree(this.categories);
            this.disabledAddedCategory(this.getAllNestedCategory).then(res => this.checkInitialForAllCategory(this.getAllNestedCategory));
            this.closeResetModal("dialogBox");
            this.subscription.unsubscribe();
            this.deleteDialogService.clearResponse();
            
          } else if (res == "no") {
            this.closeResetModal("dialogBox")
            this.subscription.unsubscribe();
            this.deleteDialogService.clearResponse();
          }
        });
      }
      
      openResetModal(id: string){
        let data = {};
        data = {
          value: 'reset'
        };
        this.deleteDialogService.openDialog(data);
      }
      
      closeResetModal(id: string){
        this.deleteDialogService.closeDialog();
      }
      
      /**mange products */
      
      getAllCategoryInStore() {
        var allCategories = this.getAllNestedCategory;
        this.storeService.getAllCategoryByStoreCode(this.parentCode).subscribe((data) => {
          this.categoriesInStore = data.categories;
          this.renderCategoryInStore = this.nestedCategory.getProductCategoryTree(this.categoriesInStore)
        });
      }
      
      openProductModal(content) {
        this.getAllCategoryInStore()
        this.ngbModalService.open(content, { size: 'lg', windowClass: 'update-modal'});
      }
      
      closeProductModal(content) {
        this.ngbModalService.dismissAll(content);
        this.productsArray = []
        this.renderCategoryInStore = [];
        this.productsInCategory = [];
      }
      
      checkAllProduct() {
        this.allCheckProduct = !this.allCheckProduct
        for (let a = 0; a < this.productsInCategory.length; a++) {
          this.checkboxItemsProduct[a] = this.allCheckProduct;
          if(this.checkboxItemsProduct[a]) {
            this.productsInCategory[a]['current'] = "checked"
            this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['initial'];
            this.productsArray[a] = this.productsInCategory[a].id;
          }
          else {
            this.productsArray = [];
            this.productsInCategory[a]['current'] = "unchecked"
            this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['initial'];
            
          }
          
        }
      }
      
      onSelectCategory(event) {
        this.imageArray = []
        this.categoryId = event.id;
        this.allCheckProduct = false;
        this.productsInCategory = [];
        this.adminService.getAllCategoryProduct(this.categoryId).subscribe((data) => {
          this.productsInCategory = data.products;
          for(let i=0;i< this.productsInCategory.length; i++) {
            this.productsInCategory[i].imageRef = this.getBackground(this.productsInCategory[i].imageRef);
            this.checkboxItemsProduct[i] = false; 
            this.productsInCategory[i]['initial'] = "unchecked";
            this.productsInCategory[i]['current'] = "unchecked";
            this.productsInCategory[i]['changed'] =  this.productsInCategory[i]['current'] !=   this.productsInCategory[i]['initial'];
          }
          this.getProductAddedInCategory(this.categoryId);
        });
      }
      
      getProductAddedInCategory(categoryId) {
        this.storeService.getProductByCategoryId(categoryId, this.parentCode).subscribe((data) => {
          this.categoryProducts = data.products;
          let checkAll = true;
          for(let i=0; i< this.productsInCategory.length; i++) {
            for(let j=0; j< this.categoryProducts.length; j++) {
              if(this.categoryProducts[j].id == this.productsInCategory[i].id) {
                this.checkboxItemsProduct[i] = true;
                this.productsInCategory[i]['initial'] = "checked";
                this.productsInCategory[i]['current'] = "checked";
                this.productsInCategory[i]['changed'] =  this.productsInCategory[i]['current'] !=   this.productsInCategory[i]['initial'];
              }
              
            }
            if(this.productsInCategory[i].initial == "unchecked") {
              checkAll = false;
            }
          }
          this.allCheckProduct = checkAll;
        })
      }
      
      onChangeProduct(i,product,event) {
        this.checkboxItemsProduct[i] = !this.checkboxItemsProduct[i];
        this.myValueProduct = true;
        for (let a = 0; a < this.productsInCategory.length; a++) {
          if (this.checkboxItemsProduct[a] == false) {
            this.myValueProduct = false;
            this.productsInCategory[a]['current'] = "unchecked" 
            this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['initial'];
          }
        }
        if(this.checkboxItemsProduct[i]) {
          this.productsArray.push(this.productsInCategory[i].id);
          this.productsInCategory[i]['current'] = "checked"
          this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['initial'];
          
        }else {
          this.productsInCategory[i]['current'] = "unchecked"
          this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['initial'];
          
          this.productsArray.splice(this.productsArray.indexOf(product.id),1);
        }
        this.allCheckProduct = this.myValueProduct;
        
      }
      
      deleteProductArray : Array<any> = [];
      addProductsArray:  Array<any> = [];
      addProductInCategory(id){
        this.deleteProductArray = [];
        this.addProductsArray = []
        this.checkForAddAndDeleteProduct(this.productsInCategory).then(res => this.callApiForAddAndDeleteProduct(id))
      }
      
      
      checkForAddAndDeleteProduct(productsInCategory) {
        return new Promise((resolve,reject) => {
          for(let i=0; i< this.productsInCategory.length; i++) {
            if((this.productsInCategory[i].changed == true) && (this.productsInCategory[i].current == "unchecked") && (this.productsInCategory[i].initial == "checked")) {
              this.deleteProductArray.push(this.productsInCategory[i].id)
            }
            else if((this.productsInCategory[i].changed == true) && (this.productsInCategory[i].current == "checked") && (this.productsInCategory[i].initial == "unchecked")) {
              this.addProductsArray.push(this.productsInCategory[i].id)
            }
          }
          resolve()
        });
      }
      
      callApiForAddAndDeleteProduct(id) {
        if(this.addProductsArray.length > 0) {
          this.storeService.addProductByCategoryIdInStore(this.categoryId,this.parentCode,this.addProductsArray).subscribe((data) => {
            
          });
        }
        if(this.deleteProductArray.length > 0) {
          this.storeService.deleteProductfromCategory(this.categoryId,this.parentCode,this.deleteProductArray).subscribe((data) => {
            
          });
        }
        this.closeProductModal(id);
        this.ngOnInit();
      }

      // async onSelectSort(event) {
      //   this.sort[0] = this.fields
      //   console.log(this.sort)
      //   if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
      //     this.sort[0].sortDirection = await event.target.value
      //     this.sort[0].fieldName='categories.name'
      //     console.log(this.sort)
      //   } else {
      //     this.sort[0].sortDirection = await null
    
      //   }
    
      //   this.getCategoriesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    
      // }



    //   // ------- pagination method --------- //
    
    // onChangePage(event) {
    //   this.currentPage = event;
    //   this.getCategoriesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    // }
    
    // addItemsPerPage(event) {
    //   this.itemsPerPage = event.target.value;
    //   this.currentPage = 1;
    //   this.getCategoriesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    // }
    
    
    // // // ------ search bar method ----------- //
    
    // findName(event) {
    //   // this.searchName = event;
    //   if(this.searchName != '') {
    //     this.filters['name'] = this.searchName;  
    //     this.currentPage = 1;
    //     console.log(this.filters)
    //     this.getCategoriesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    //   }
    // }
    // hideCross(event) {
    //   this.filters['name'] = null;
    //   this.searchName = '';
    //   this.getCategoriesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    // }
    
      
    }
    