import { Component, OnInit } from '@angular/core';
import { TypeRefComponentComponent } from '../type-ref-component/type-ref-component.component';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AssociationTypeRefServiceService } from '../../api-configuration-services/type-services/association-type-ref-service/association-type-ref-service.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageSubjectService } from 'src/app/services/message-subject.service';

@Component({
  selector: 'app-association-type-ref',
  templateUrl: '../type-ref-component/type-ref-component.component.html',
  styleUrls: ['./association-type-ref.component.css']
})
export class AssociationTypeRefComponent extends TypeRefComponentComponent implements OnInit {
  
  typeRefService: any;
  constructor(
    public formBuilder: FormBuilder, 
    public http: HttpClient,
    public deleteDialogService: DeleteDialogService,
    public modalService: NgbModal,
    public autoCodeGenerator: AutoNameCodeGeneratorService,
    public messageService: MessageSubjectService) { 
      super(formBuilder, http, deleteDialogService, modalService,autoCodeGenerator,messageService)
      this.typeRefService = new AssociationTypeRefServiceService(this.http);
    }
    
    ngOnInit() {
      this.typeName = "association";
      this.configureFieldLink = "associationfields"
      this.getAllType()
    }
    
    
    
    
    getAllType() {
      this.typeRefService.getAllTypes().subscribe((data) => {
        this.types = data.associationTypes;
        console.log(data)
        console.log(this.types)
        console.log(data.associationTypes)
        if(this.types.length > 0) {
          this.showNoData = false;
        }else {
          this.showNoData = true;
        }
        for (let i = 0; i < this.types.length; i++) {
          this.checkboxItems[i] = false;
          this.codes[i] = this.types[i].code;
        }
        console.log(this.types);
      });
    }
  }
  