import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NgForm } from '@angular/forms';
import { NoDataService } from 'src/app/services/no-data.service';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { CasscadeToProductModalService } from 'src/app/shared/casscadetoproduct-modal/casscadetoproduct-modal.service';
const cleanDeep = require('clean-deep');

@Component({
  selector: 'app-addnew-category-product-associatons',
  templateUrl: './addnew-category-product-associatons.component.html',
  styleUrls: ['./addnew-category-product-associatons.component.css']
})
export class AddnewCategoryProductAssociatonsComponent implements OnInit {


  categoryProductAssociations: any;
  categoryId: any = null;
  myBucketUrl: any;
  productAssociationsTypes: any[];
  user: any;
  categories: any[];
  products: any[];
  productFlagArray: Array<boolean> = [];
  productIds: Array<any> = [];
  availableProductsIds: any[]
  availableProducts: any[]
  categoryIds: Array<any> = []
  availableCategory: Array<any> = [];
  associationProduct: any;
  catOptions: Array<Boolean> = [];
  convertedTypeField: Array<string> = [];
  checkbox = "checkbox"
  ck: Array<Boolean> = [];
  value: Array<any> = [];
  currentField: any;
  associationFields: Array<any>;
  showFields: boolean = false;
  fields: any;
  subscription:Subscription;
  dropdownElements: Array<any> = [];
  currentCategory: any;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  lastIndexOfMultipleArray: Array<any> = [];
  selectedCategory: any = null;
  breadCrumbs: any;
  selectedFiles: FileList;
  currentImageRef: any;
  noProducts : Boolean = false;
  documentArray: Array<Boolean> = [];
  fieldImageRef: Array<any> = [];
  selectedFilesForFields: Array<FileList> = [];
  cascadeToProduct : boolean = false;
  @ViewChild('addProduct',{static: true}) public addProduct: ElementRef;
  @ViewChild('addNewAssociationForm',{static: true}) public myForm: NgForm;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private sanitizer: DomSanitizer,
    private deleteDialogService: DeleteDialogService,
    public casscadeToProductService : CasscadeToProductModalService,
    private nestedCategory: NestedCategoryService,
    private noDataService: NoDataService,
    private backgroundImageService: BackgroundImageService,
    private ngbModalService: NgbModal,
    private messageService: MessageSubjectService,
    public imageUploadService: ImageUploadService) { }



    
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    this.myBucketUrl = environment.myBucketUrl;
    this.getAllCategory();
    this.getAllAssociationTypes();

    this.categoryProductAssociations = {
      productId: '',
      type: '',
      createdBy: this.user.name,
      fields: {}
    }
  }


  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''    
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index,k) {
    this.multipleArray[index].splice(k,1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  resetForm() {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");

        this.ngOnInit();
        this.myForm.resetForm();
        this.showFields = false;
        this.associationProduct = null;
        for (let i = 0; i < this.productFlagArray.length; i++) {
          this.productFlagArray[i] = false;
        }

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }




  getAllAssociationTypes() {
    this.adminService.getAllProductAssociationType().subscribe((data) => {
      this.productAssociationsTypes = data.associationTypes;
      //console.log(this.productAssociationsTypes);
    });
  }

  onClickCasscade(){
      this.openModal2("dialogBox");
      this.subscription = this.casscadeToProductService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.cascadeToProduct=true
          this.onSubmit()
          this.closeModal2("dialogBox");
          this.subscription.unsubscribe();
          this.casscadeToProductService.clearResponse();
        } else if (res == "no") {
          this.cascadeToProduct=false
          this.onSubmit()
          this.closeModal2("dialogBox")
          this.subscription.unsubscribe();
          this.casscadeToProductService.clearResponse();
        }
      })
    }

    closeModal2(id:string){
      this.casscadeToProductService.closeDialog()
    }

    openModal2(id: string){
      let data={}
      data ={
        value : 'casscade'
      }
      this.casscadeToProductService.openDialog(data);
    }

  getAllCategory() {
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId');
      this.adminService.getAllCategory().subscribe((data) => {
        this.categories = data.categories;

        for (let i = 0; i < this.categories.length; i++) {
          if (this.categoryId != this.categories[i].id) {
            this.availableCategory.push(this.categories[i]);
          }
        }
        //console.log(this.nestedCategoresService.getNestedProductCategories(this.availableCategory))
        this.dropdownElements = this.nestedCategory.getProductCategoryTree(this.categories)
      });
    });
    this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
      this.currentCategory = data.categories[0];
    });

  }

  optionFieldsCondition(associationFields) {
    this.value = []
    for (let i = 0; i < associationFields.length; i++) {
      if (associationFields[i].options) {
        if ((associationFields[i].options.length == 1) || (associationFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      //console.log(this.catOptions)
      if (associationFields[i].list) {
        this.listFlag[i] = true;
      }else {
        this.listFlag[i] = false;
      }
      if (associationFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = associationFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
    }
  }

  selectCode(event) {
    this.currentField = event.target.value;
    this.adminService.getAllAssociationFields(this.currentField).subscribe((data) => {
      this.associationFields = data.fields;
      //console.log(this.associationFields)
      for(let i=0; i< this.associationFields.length;i++) {
        if(this.associationFields[i].type == "DOCUMENT"){
          this.documentArray[i] = true;
        }else{
          this.documentArray[i] = false;
        }

        this.fieldImageRef[i] = null;
        this.selectedFilesForFields[i] = null;
        this.multipleArray[i] = new Array<any>();
       this.listArray[i] = new Array<any>();
       this.lastIndexOfMultipleArray[i] = new Array<number>();

       if(this.associationFields[i].list) {
        this.lastIndexOfMultipleArray[i] = 0;
         this.multipleArray[i].push({
           listValue: ''
         })
       }
     }
      this.optionFieldsCondition(this.associationFields)
    });

    this.showFields = true;
  }

   config = {
    hasSelect: false
  }
  
  removeCurrentProduct(products, productId) {
    return products.filter((ele) => {
      return ele != productId
    });
  }

  removeCurrentCategory(categories, categoryId) {
    return categories.filter((ele) => {
      return ele != categoryId;
    });
  }


  onSelectCategory(event) {
    this.selectedCategory = event.id;
    this.getAllCategoryProduct(this.selectedCategory);
    // this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
    //   this.products = data.products;

    //   for (let i = 0; i < this.products.length; i++) {
    //     this.productFlagArray[i] = false;
    //     this.productIds[i] = this.products[i].id;
    //     if(this.associationProduct != null) {
    //       if (this.associationProduct.id.includes(this.products[i].id)) {
    //         this.productFlagArray[i] = true;
    //         //console.log(this.productFlagArray)
    //     }
    //     else {
    //       this.productFlagArray[i] = false;
    //     }
    //     }
    //   }

    // });
  }

  getAllCategoryProduct(categoryId) {
    this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
      this.products = data.products;
      this.noProducts =  this.noDataService.noData(this.products);
      for (let i = 0; i < this.products.length; i++) {
        this.productFlagArray[i] = false;
        this.productIds[i] = this.products[i].id;
        if(this.associationProduct != null) {
          if (this.associationProduct.id.includes(this.products[i].id)) {
            this.productFlagArray[i] = true;
            //console.log(this.productFlagArray)
        }
        else {
          this.productFlagArray[i] = false;
        }
        }
      }

    });
  }

  addAssociationProduct(selectedProduct, id) {
    this.associationProduct = selectedProduct;
    //console.log(this.associationProduct)
    this.categoryProductAssociations.productId = this.associationProduct.id;
    //console.log(this.categoryProductAssociations.productId)
    for (let i = 0; i < this.products.length; i++) {
      this.productFlagArray[i] = false;
      if (this.products[i].id == this.categoryProductAssociations.productId) {
        this.productFlagArray[i] = true;
      }
      this.closeNgbModal(id);
    }
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  openModal1(id: string){
    let data = {};
    data = {
        value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal1(id: string){
    this.deleteDialogService.closeDialog();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //console.log(event.target)
  }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.fieldImageRef[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.fieldImageRef[indexOfField] = ""
      this.selectedFilesForFields[indexOfField] = null
    } else {
      this.currentImageRef = "";
      this.selectedFiles = null;
    }
  }

 async onSubmit() {
    if (this.associationFields != null) {
      this.fields = {};
      for (let i = 0; i < this.associationFields.length; i++) {
        let fieldName = this.associationFields[i].key;

        if (this.associationFields[i].type == "INTEGER") {
          this.categoryProductAssociations.fields[fieldName] = parseInt(this.value[i])
        } else if (this.associationFields[i].type == "DECIMAL") {
          this.categoryProductAssociations.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.categoryProductAssociations.fields[fieldName] = this.value[i]
        }
        if (this.associationFields[i].list) {
          for(let j=0; j< this.multipleArray.length; j++) {
            //console.log(this.multipleArray[j].length)
            if(this.multipleArray[j].length > 0) {
               for(let k=0; k< this.multipleArray[j].length ; k++) {
                 //console.log(this.multipleArray[j].length)
                 //console.log( this.categoryProductAssociations.fields)
                 this.listArray[j][k] =(this.multipleArray[j][k].listValue)
                }
              }
            }
            if(this.listArray[i].length > 0) {
              if(this.associationFields[i].type == "INTEGER") {
                this.categoryProductAssociations.fields[this.associationFields[i].key] =   this.listArray[i].map(numStr => parseInt(numStr))
              } else if(this.associationFields[i].type == "DECIMAL") {
                this.categoryProductAssociations.fields[this.associationFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
              }else {
                this.categoryProductAssociations.fields[this.associationFields[i].key] = this.listArray[i]
              }
            }
            else {
              this.multipleArray[i] = []
              this.categoryProductAssociations.fields[this.associationFields[i].key] = []
            }
        }
      }
    }
    
    await this.uploadImageRef();

    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          let data = await this.callServiceToUploadImage(file)
          this.categoryProductAssociations.fields[this.associationFields[index].key] = data.documentId;
          if(index == this.selectedFilesForFields.length-1) {
            this.addNewAssociationServiceCall(0)
          }
        }else{
          if(index == this.selectedFilesForFields.length-1) {
            this.addNewAssociationServiceCall(0);
            
          }  
        }
      }
    }else{
      this.addNewAssociationServiceCall(0);
    }
    
    
  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      let data = await this.callServiceToUploadImage(file);
      this.categoryProductAssociations.imageRef = data.documentId;
    } 
  }
  
  async callServiceToUploadImage(file: any): Promise<any>{
    return this.imageUploadService.uploadImage(file).toPromise();
  }

 

  addNewAssociationServiceCall(id){
    let association = this.categoryProductAssociations
    association.fields = cleanDeep(this.categoryProductAssociations.fields)
    this.adminService.createCategoryAssociations(this.categoryId, association,this.cascadeToProduct).subscribe((data) => {
      
      this.closeNgbModal(id);
      if(!this.currentCategory['parentId']) {
        this.router.navigate(['/categorydetails/' + this.categoryId +'/'+this.currentCategory.name+'/categoryassociations/' + this.categoryId])
      }else {
        this.messageService.broadcast('splice', {
          key: 'addnewcategoryassociation',
          alternativeKey: 'addnewcategoryassociation'
        })
        this.router.navigate(['/subcategorydetails/' + this.categoryId +'/'+this.currentCategory.name+'/categoryassociations/' + this.categoryId])
      }
    });
  }


  openNgbModal(content) {
    this.products = [];
    this.selectedCategory = null;
    this.ngbModalService.open(content, { size: 'lg', windowClass: 'add-product' });
   
  }

  closeNgbModal(content) {
    this.ngbModalService.dismissAll(content);
    this.products = [];
    this.selectedCategory = null;
  }
}
