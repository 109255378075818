import { Injectable } from '@angular/core';
import { FeatureRefService } from '../feature-ref-service/feature-ref.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryFeatureRefService implements FeatureRefService {

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
    //console.log(this.tenant)
  }

  user: any;
  tenant: any;
  headers: Headers;


  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }

  // serverRootUrl = environment.BASE_URL;
  serverRootUrl = environment.PRODUCT_CATEGORIES_BASE_URL
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  productCategoryRootUrl = this.serverRootUrl + "/api/v1/productcategories/";

  getAllFeatures(id): Observable<any> {
    return this.http.get<any>(this.productCategoryRootUrl + id + "/products/features", this.getHeaderTenant())

  }

  getAllFeaturesByQuery(id, filters, itemsPerPage, pageNumber, sort): Observable<any> {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": [
        {
          "fieldName": "name"
        }
      ]
    }
    return this.http.post<any>(this.productCategoryRootUrl + id + "/products/features/query", requestBody, this.getHeaderTenant());
  }

  createFeatureById(id, feature, cascadeToProduct): Observable<any> {
    let request = {}
    request['feature'] = feature;
    console.log('request',request)
    if (cascadeToProduct) {
      return this.http.post<any>(this.productCategoryRootUrl + id + "/products/features?cascadeToProduct=" + cascadeToProduct, request, this.getHeaderTenant())
    } else {
      return this.http.post<any>(this.productCategoryRootUrl + id + "/products/features", request, this.getHeaderTenant())
    }
  }

  deleteFeatureByFeatureId(categoryId: any, featureIds: any[], cascadeToProduct) {
    console.log(categoryId, 'service')
    return this.http.delete<any>(this.productCategoryRootUrl + categoryId + "/products/features?productFeatureIds=" + featureIds, this.getHeaderTenant())
  }

  updateFeatureByFeatureId(id, featureId, feature, cascadeToProduct) {
    let request = {};
    request['feature'] = feature;
    console.log("request",request)
    if(cascadeToProduct) {
      return this.http.put<any>(this.productCategoryRootUrl + id + "/products/features/" + featureId + "?cascadeToProduct=" + cascadeToProduct, request, this.getHeaderTenant());
    } else {
      return this.http.put<any>(this.productCategoryRootUrl + id + "/products/features/" + featureId, request, this.getHeaderTenant());

    }
  }

  getFeaturesByProductId(id) {
    let requestBody = {}
    return this.http.post<any>(this.productCategoryRootUrl + id + "/products/features/query", requestBody, this.getHeaderTenant());
  }
}
