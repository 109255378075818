import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplitUrlService {

  constructor() { }

  splitUrl(url) {
    var str = url;
    return  str.split("/api",1);
  }

}
