import { Routes } from '@angular/router';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { CategoriesComponent } from './manage-products/categories/categories.component';
import { AddnewCategoryComponent } from './manage-products/categories/addnew-category/addnew-category.component';
import { CategoryDetailsComponent } from './manage-products/categories/category-details/category-details.component';
import { ProductCategoryComponent } from './manage-products/categories/category-details/product-category/product-category.component';
import { EditCategoryComponent } from './manage-products/categories/category-details/edit-category/edit-category.component';
import { AddnewProductComponent } from './manage-products/categories/category-details/addnew-product/addnew-product.component';
import { ProductDetailsComponent } from './manage-products/categories/category-details/product-details/product-details.component';
import { EditProductComponent } from './manage-products/categories/category-details/edit-product/edit-product.component';
import { CatalogComponent } from './manage-products/catalog/catalog.component';
import { AddnewCatalogComponent } from './manage-products/catalog/addnew-catalog/addnew-catalog.component';
import { CatalogDetailsComponent } from './manage-products/catalog/catalog-details/catalog-details.component';
import { CatalogProductComponent } from './manage-products/catalog/catalog-details/catalog-product/catalog-product.component';
import { EditCatalogComponent } from './manage-products/catalog/catalog-details/edit-catalog/edit-catalog.component';
import { AddnewCatalogProductComponent } from './manage-products/catalog/catalog-details/addnew-catalog-product/addnew-catalog-product.component';
import { AssociationListComponent } from './manage-products/categories/category-details/association-list/association-list.component';
import { AddnewProductAssociationComponent } from './manage-products/categories/category-details/addnew-product-association/addnew-product-association.component';
import { EditProductAssociationComponent } from './manage-products/categories/category-details/edit-product-association/edit-product-association.component';
import { UserAuthModule } from '../user-auth/user-auth.module';
import { UserLoginComponent } from '../user-auth/user-login/user-login.component';
import { CategoryProductAssociationsComponent } from './manage-products/categories/category-details/category-product-associations/category-product-associations.component';
import { AddnewCategoryProductAssociatonsComponent } from './manage-products/categories/category-details/category-product-associations/addnew-category-product-associatons/addnew-category-product-associatons.component';
import { EditCategoryProductAssociationsComponent } from './manage-products/categories/category-details/category-product-associations/edit-category-product-associations/edit-category-product-associations.component';
import { CategoryTypeRefComponentComponent } from './api-configuration/type-component/category-type-ref-component/category-type-ref-component.component';
import { CatalogTypeRefComponent } from './api-configuration/type-component/catalog-type-ref/catalog-type-ref.component';
import { AssociationTypeRefComponent } from './api-configuration/type-component/association-type-ref/association-type-ref.component';
import { FeatureTypeRefComponent } from './api-configuration/type-component/feature-type-ref/feature-type-ref.component';
import { CategoryFieldRefComponent } from './api-configuration/field-component/category-field-ref/category-field-ref.component';
import { CatalogFieldRefComponent } from './api-configuration/field-component/catalog-field-ref/catalog-field-ref.component';
import { AssociationFieldRefComponent } from './api-configuration/field-component/association-field-ref/association-field-ref.component';
import { FeatureFieldRefComponent } from './api-configuration/field-component/feature-field-ref/feature-field-ref.component';
import { GetAllSubCategoriesComponent } from './manage-products/categories/category-details/sub-category/get-all-sub-categories/get-all-sub-categories.component';
import { AddNewSubCategoryComponent } from './manage-products/categories/category-details/sub-category/add-new-sub-category/add-new-sub-category.component';
import { ProductFieldRefComponent } from './api-configuration/field-component/product-field-ref/product-field-ref.component';
import { CategoryFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/category-feature-ref.component';
import { ProductFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/product-feature-ref.component';
import { AddnewCategoryFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/addnew-category-feature-ref/addnew-category-feature-ref.component';
import { AddnewProductFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/addnew-product-feature-ref/addnew-product-feature-ref.component';
import { CategoryFeatureDetailsRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/category-feature-details-ref/category-feature-details-ref.component';
import { ProductFeatureDetailsRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/product-feature-details-ref/product-feature-details-ref.component';
import { EditCategoryFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/edit-category-feature-ref/edit-category-feature-ref.component';
import { EditProductFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/edit-product-feature-ref/edit-product-feature-ref.component';
import { CategoryFeatureOptionRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/category-feature-option-ref/category-feature-option-ref.component';
import { ProductFeatureOptionRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/product-feature-option-ref/product-feature-option-ref.component';
import { PromotionsComponent } from './marketing/promotions/promotions.component';
import { AddPromotionsComponent } from './marketing/promotions/add-promotions/add-promotions.component';
import { EditPromotionComponent } from './marketing/promotions/edit-promotion/edit-promotion.component';
import { StoreTypeRefComponent } from './api-configuration/type-component/store-type-ref/store-type-ref.component';
import { StoreFieldRefComponent } from './api-configuration/field-component/store-field-ref/store-field-ref.component';
import { ItemResolver } from '../resolvers/item.resolver';
import { MarketingDetailsComponent } from './marketing/marketing-details/marketing-details.component';
import { TiersComponent } from './marketing/tiers/tiers.component';
import { AddTiersComponent } from './marketing/tiers/add-tiers/add-tiers.component';
import { Component } from '@angular/core';
import { RewardsComponent } from './marketing/rewards/rewards.component';
import { AddRewardComponent } from './marketing/rewards/add-reward/add-reward.component';
import { EditRewardComponent } from './marketing/rewards/edit-reward/edit-reward.component';
import { RewarddetailsComponent } from './marketing/rewards/rewarddetails/rewarddetails.component';
import { EditTierComponent } from './marketing/tiers/edit-tier/edit-tier.component';
import { LoyaltyPointRulesComponent } from './marketing/loyalty-point-rules/loyalty-point-rules.component';
// import { ItemResolver } from './resolvers/item.resolver';

let user= sessionStorage.getItem('user')
console.log("shuruat me",user)

export const adminRoute: Routes = [
    // {path: 'adminhome', component: AdminHomeComponent,
    // children: [
    //     {path: 'categories', component: CategoriesComponent},
    // ]},
    { path: '', component: UserLoginComponent },
    {
        path: '', component: AdminHomeComponent,
        children: [
          
            // {path: '', component: CategoriesComponent},
            { path: 'productcategories', component: CategoriesComponent} ,
            // data: { breadcrumbItem: { key: 'productcategories', labelName: 'productcategories'} }}
            
            { path: 'addnewcategory', component: AddnewCategoryComponent ,
            data: { breadcrumbItem: { key: 'addnewcategory', labelName: 'Add Category'} }},
            {
                path: 'categorydetails/:categoryId/:categoryName', component: CategoryDetailsComponent,
                data: { breadcrumbItem: { key: 'categorydetails', labelName: 'Category',alternativeKey: 'categorydetails' , child: []}},
                children: [
                    { path: 'categoryproduct/:categoryId', component: ProductCategoryComponent},
                    { path: 'subcategory/:categoryId', component: GetAllSubCategoriesComponent},
                    { path: 'editcategory/:categoryId', component: EditCategoryComponent , data: {breadcrumbs:'edit '}},
                    { path: 'addcustomfields/:typeCode', component: ProductFieldRefComponent },
                    // { path: 'addcustomfields/:typeCode', component: CustomFieldsComponent },
                    { path: 'categoryfeatures/:id', component: CategoryFeatureRefComponent },
                    {
                        path: 'categoryassociations/:categoryId', component: CategoryProductAssociationsComponent
                    }
                ]
            },
            {
                path: 'subcategorydetails/:categoryId/:categoryName', component: CategoryDetailsComponent,
                // data: { breadcrumbItem: { key: 'subcategorydetails', labelName: 'Sub Category' }},
                children: [
                    { path: 'categoryproduct/:categoryId', component: ProductCategoryComponent },
                    { path: 'subcategory/:categoryId', component: GetAllSubCategoriesComponent },
                    { path: 'editcategory/:categoryId', component: EditCategoryComponent },
                    { path: 'addcustomfields/:typeCode', component: ProductFieldRefComponent },
                    // { path: 'addcustomfields/:categoryId', component: CustomFieldsComponent },
                    { path: 'categoryfeatures/:id', component: CategoryFeatureRefComponent },
                    {
                        path: 'categoryassociations/:categoryId', component: CategoryProductAssociationsComponent
                    }
                ]
            },
            
            { path: 'addnewsubcategory/:parentId', component: AddNewSubCategoryComponent,
        },
            {
                path: "addnewcategoryfeature/:id", component: AddnewCategoryFeatureRefComponent,
                data: { breadcrumbItem: { key: 'addnewcategoryfeature', labelName: 'Add Feature'} },
            },
            {
                path: 'categoryfeaturedetails/:id/:featureId/:index/:featureName', component: CategoryFeatureDetailsRefComponent,
                data: { breadcrumbItem: { key: 'categoryfeaturedetails', labelName: 'Feature',alternativeKey: 'categoryfeaturedetails'} },
                children: [
                    {
                        path: 'editcategoryfeature/:id/:featureId/:index', component: EditCategoryFeatureRefComponent
                    },
                    {
                        path: 'addcategoryfeatureoption/:id/:featureId/:index', component: CategoryFeatureOptionRefComponent
                    }
                ]
            },
            { path: 'addnewproduct/:categoryId', component: AddnewProductComponent ,
            data: { breadcrumbItem: { key: 'addnewproduct', labelName: 'Add Product'} }},
            {
                path: 'productdetails/:productId/:productName', component: ProductDetailsComponent,
                data: { breadcrumbItem: { key: 'productdetails', labelName: 'Product',alternativeKey: 'productdetails' }},
                children: [
                    { path: 'editproduct/:productId', component: EditProductComponent , data: {breadcrumbs: '{{product.name}}'}, resolve: { item: ItemResolver }},
                    { path: 'productfeatures/:id', component: ProductFeatureRefComponent },
                    { path: 'productassociation/:productId', component: AssociationListComponent },
                ]
            },
            {
                path: 'productid/:productId/editproductassociation/:associationIndex', component: EditProductAssociationComponent,
                data: { breadcrumbItem: { key: 'editproductassociation', labelName: 'Association',alternativeKey: 'editproductassociation'} }
            },
            {
                path: 'addnewfeature/:id', component: AddnewProductFeatureRefComponent,
                data: { breadcrumbItem: { key: 'addnewfeature', labelName: 'Add Feature' }},
            },
            {
                path: 'productfeaturedetails/:id/:featureId/:index/:featureName', component: ProductFeatureDetailsRefComponent,
                data: { breadcrumbItem: { key: 'productfeaturedetails', labelName: 'Feature', alternativeKey: 'productfeaturedetails'} },
                children: [
                    {
                        path: 'editproductfeature/:id/:featureId/:index', component: EditProductFeatureRefComponent
                    },
                    {
                        path: 'addproductfeatureoption/:id/:featureId/:index', component: ProductFeatureOptionRefComponent
                    }
                ]
            },
            {
                path: 'addnewassociation/:productId', component: AddnewProductAssociationComponent,
                data: { breadcrumbItem: { key: 'addnewassociation', labelName: 'Add Association',alternativeKey: 'addnewassociation' }},
            },
            {
                path: 'addnewcategoryassociation/:categoryId', component: AddnewCategoryProductAssociatonsComponent,
                data: { breadcrumbItem: { key: 'addnewcategoryassociation', labelName: 'Add Association' ,alternativeKey: 'addnewcategoryassociation'}},
            },
            {
                path: 'categoryassociation/:categoryId/editcategoryassociation/:associationId/:index', component: EditCategoryProductAssociationsComponent,
                data: { breadcrumbItem: { key: 'editcategoryassociation', labelName: 'Association' ,alternativeKey: 'editcategoryassociation'}},
            },
            { path: 'productcatalogs', component: CatalogComponent },
            { path: 'addnewcatalog', component: AddnewCatalogComponent },
            {
                path: 'catalogdetails/:catalogId', component: CatalogDetailsComponent,
                data: { breadcrumbItem: { key: 'catalogdetails', labelName: 'Catalog',alternativeKey: 'catalogdetails' }},

                children: [
                    { path: 'catalogproducts/:catalogId', component: CatalogProductComponent },
                    { path: 'editcatalog/:catalogId', component: EditCatalogComponent }
                ],
            },
            { path: 'addnewcatalogproduct/:catalogId', component: AddnewCatalogProductComponent ,
            data: { breadcrumbItem: { key: 'addnewcatalogproduct', labelName: 'Add Catalog',alternativeKey: 'addnewcatalogproduct' }},},

            // { path: 'categorytype', component: CategoryTypeComponent} ,
            { path: 'categorytype', component: CategoryTypeRefComponentComponent} ,
            // {path: 'categoryfields/:categoryTypeCode', component: CategoryFieldsComponent},
            {path: 'categoryfields/:typeCode', component: CategoryFieldRefComponent},
            { path: 'catalogtype', component: CatalogTypeRefComponent },
            {path: 'catalogfields/:typeCode', component: CatalogFieldRefComponent},
            // { path: 'catalogtype', component: CatalogTypeComponent },
            // {path: 'catalogfields/:catalogTypeCode', component: CatalogFieldsComponent},
            // { path: 'associationtype', component: AssociationTypeComponent },
              { path: 'associationtype', component: AssociationTypeRefComponent },
              {path: "associationfields/:typeCode", component: AssociationFieldRefComponent},

            // {path: "associationfields/:associationTypeCode", component: CustomAssociationFieldsComponent},
            // { path: 'featuretype', component: FeatureTypeComponent },
            { path: 'featuretype', component: FeatureTypeRefComponent },
            {path: 'featurefields/:typeCode', component: FeatureFieldRefComponent},
            
            { path: 'regiontype', component: StoreTypeRefComponent },
            { path: 'regionfields/:typeCode', component: StoreFieldRefComponent },
            
            {path: "addpromotion", component: AddPromotionsComponent,
            data: { breadcrumbItem: { key: 'addpromotion', labelName: 'Add Promotion',alternativeKey: 'addpromotion'} }
        },
            {path:"editpromotion/:promotionId", component: EditPromotionComponent},

            {path:"addreward",component:AddRewardComponent,
            data: { breadcrumbItem: { key: 'addreward', labelName: 'Add Reward',alternativeKey: 'addreward'}}},
            {path:"editreward/:rewardId",component:EditRewardComponent},
         
            {path:"rewarddetails/:rewardId",component:RewarddetailsComponent},
            {path: "addnewtier", component: AddTiersComponent,
            data: { breadcrumbItem: { key: 'addnewtier', labelName: 'Add Tier',alternativeKey: 'addnewtier'}}},
            {path:"edittier/:tierId",component:EditTierComponent},
            {path:"marketingdetails", component: MarketingDetailsComponent,
            // data: { breadcrumbItem: { key: 'marketing', labelName: 'Marketing'} },
            children: [     
                {path: "promotions", component: PromotionsComponent},
                {path: 'loyaltypointrule', component: LoyaltyPointRulesComponent},
                {path: "tiers", component: TiersComponent},
                {path:"rewards",component:RewardsComponent}
            ]}
            
        ],
    }
]