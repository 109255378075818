import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MessageSubjectService } from './services/message-subject.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'dcp-ppp-admin';
  // user:any;
  // tenant:any;
  breadcrumbArray: Array<any> = [];
  isAuthenticated : boolean;
  user:any

  constructor(location: PlatformLocation,
    private messageService: MessageSubjectService){
    location.onPopState(() => {
     
      console.log('pressed back!');
      // this.breadcrumbArray = JSON.parse(sessionStorage.getItem("breadcrumb"));
      
  });
  
  }

  async ngOnInit(){
    //this.tenant = environment.tenant;

    // this.user = {"name": "John",
    //             "userId":"123",
    //             "imageRef" : "",
    //             "tenant" : this.tenant}

   

  }


  
  isShow: boolean;
  topPosToStartShowing = 100;

  @HostListener('window:scroll')
  checkScroll() {
      
    // window의 scroll top
    // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  
  
}
