import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomersComponent } from './customers.component';
import { AdminHomeComponent } from '../admin-home/admin-home.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { ViewCustomerDetailsComponent } from './customer-details/view-customer-details/view-customer-details.component';
import { LoyaltyPointsComponent } from './customer-details/loyalty-points/loyalty-points.component';
import { LoyaltyProfileComponent } from './customer-details/loyalty-profile/loyalty-profile.component';
import { OrderHistoryComponent } from './customer-details/order-history/order-history.component';
import { OrderHistoryDetailsComponent } from './customer-details/order-history-details/order-history-details.component';


const routes: Routes = [
  {path: '', component: AdminHomeComponent,
  children: [
    { path: 'customers', component: CustomersComponent},
    {path: 'orderhistorydetails/:orderid', component: OrderHistoryDetailsComponent},
    {path: 'customerdetails/:customerid', component: CustomerDetailsComponent,
    children: [
      {path: 'viewcustomerdetails/:customerid', component: ViewCustomerDetailsComponent},
      {path: 'loyaltypoints/:customerid', component: LoyaltyPointsComponent},
      {path: 'loyaltyprofile/:customerid', component: LoyaltyProfileComponent},
      {path: 'orderhistory/:customerid', component: OrderHistoryComponent},
      
    ]}

  ]},
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
