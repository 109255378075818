import { NgModule, CUSTOM_ELEMENTS_SCHEMA ,LOCALE_ID} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { RouterModule } from '@angular/router';
import { adminRoute } from './admin.route';
import { CategoriesComponent } from './manage-products/categories/categories.component';
import { AdminService } from './admin.service';
import { AddnewCategoryComponent } from './manage-products/categories/addnew-category/addnew-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CategoryDetailsComponent } from './manage-products/categories/category-details/category-details.component';
import { ProductCategoryComponent } from './manage-products/categories/category-details/product-category/product-category.component';
import { EditCategoryComponent } from './manage-products/categories/category-details/edit-category/edit-category.component';
import { AddnewProductComponent } from './manage-products/categories/category-details/addnew-product/addnew-product.component';
import { ProductDetailsComponent } from './manage-products/categories/category-details/product-details/product-details.component';
import { EditProductComponent } from './manage-products/categories/category-details/edit-product/edit-product.component';
import { CatalogComponent } from './manage-products/catalog/catalog.component';
import { AddnewCatalogComponent } from './manage-products/catalog/addnew-catalog/addnew-catalog.component';
import { CatalogDetailsComponent } from './manage-products/catalog/catalog-details/catalog-details.component';
import { EditCatalogComponent } from './manage-products/catalog/catalog-details/edit-catalog/edit-catalog.component';
import { CatalogProductComponent } from './manage-products/catalog/catalog-details/catalog-product/catalog-product.component';
import { AddnewCatalogProductComponent } from './manage-products/catalog/catalog-details/addnew-catalog-product/addnew-catalog-product.component';
import { AssociationListComponent } from './manage-products/categories/category-details/association-list/association-list.component';
import { AddnewProductAssociationComponent } from './manage-products/categories/category-details/addnew-product-association/addnew-product-association.component';
import { EditProductAssociationComponent } from './manage-products/categories/category-details/edit-product-association/edit-product-association.component';
import { AddnewProductFeatureComponent } from './manage-products/categories/category-details/addnew-product-feature/addnew-product-feature.component';
import { ProductFeatureDetailsComponent } from './manage-products/categories/category-details/product-feature-details/product-feature-details.component';
import { EditProductFeatureComponent } from './manage-products/categories/category-details/product-feature-details/edit-product-feature/edit-product-feature.component';
import { AddFeatureOptionsComponent } from './manage-products/categories/category-details/product-feature-details/add-feature-options/add-feature-options.component';
import { CategoryFeaturesComponent } from './manage-products/categories/category-details/category-features/category-features.component';
import { AddnewCategoryFeaturesComponent } from './manage-products/categories/category-details/category-features/addnew-category-features/addnew-category-features.component';
import { CategoryFeatureDetailsComponent } from './manage-products/categories/category-details/category-features/category-feature-details/category-feature-details.component';
import { EditCategoryFeaturesComponent } from './manage-products/categories/category-details/category-features/edit-category-features/edit-category-features.component';
import { CategoryFeaturesOptionsComponent } from './manage-products/categories/category-details/category-features/category-features-options/category-features-options.component';
import { CategoryProductAssociationsComponent } from './manage-products/categories/category-details/category-product-associations/category-product-associations.component';
import { AddnewCategoryProductAssociatonsComponent } from './manage-products/categories/category-details/category-product-associations/addnew-category-product-associatons/addnew-category-product-associatons.component';
import { EditCategoryProductAssociationsComponent } from './manage-products/categories/category-details/category-product-associations/edit-category-product-associations/edit-category-product-associations.component';
import { EditFieldModalComponent } from '../shared/popup-modal/edit-field-modal/edit-field-modal.component';
import { TypeRefComponentComponent } from './api-configuration/type-component/type-ref-component/type-ref-component.component';
import { CategoryTypeRefComponentComponent } from './api-configuration/type-component/category-type-ref-component/category-type-ref-component.component';
import { CategoryTypeRefServiceService } from './api-configuration/api-configuration-services/type-services/category-type-ref-service/category-type-ref-service.service';
import { CatalogTypeRefServiceService } from './api-configuration/api-configuration-services/type-services/catalog-type-ref-service/catalog-type-ref-service.service';
import { FeatureTypeRefServiceService } from './api-configuration/api-configuration-services/type-services/feature-type-ref-service/feature-type-ref-service.service';
import { AssociationTypeRefServiceService } from './api-configuration/api-configuration-services/type-services/association-type-ref-service/association-type-ref-service.service';
import { CatalogTypeRefComponent } from './api-configuration/type-component/catalog-type-ref/catalog-type-ref.component';
import { AssociationTypeRefComponent } from './api-configuration/type-component/association-type-ref/association-type-ref.component';
import { FeatureTypeRefComponent } from './api-configuration/type-component/feature-type-ref/feature-type-ref.component';
import { CategoryFieldRefService } from './api-configuration/api-configuration-services/field-services/category-field-ref-service/category-field-ref.service';
import { FieldRefComponent } from './api-configuration/field-component/field-ref/field-ref.component';
import { CategoryFieldRefComponent } from './api-configuration/field-component/category-field-ref/category-field-ref.component';
import { CatalogFieldRefComponent } from './api-configuration/field-component/catalog-field-ref/catalog-field-ref.component';
import { FeatureFieldRefComponent } from './api-configuration/field-component/feature-field-ref/feature-field-ref.component';
import { AssociationFieldRefComponent } from './api-configuration/field-component/association-field-ref/association-field-ref.component';
import { CatalogFieldRefService } from './api-configuration/api-configuration-services/field-services/catalog-field-ref-service/catalog-field-ref.service';
import { FeatureFieldRefService } from './api-configuration/api-configuration-services/field-services/feature-field-ref-service/feature-field-ref.service';
import { AssociationFieldRefService } from './api-configuration/api-configuration-services/field-services/association-field-ref-service/association-field-ref.service';
import { GetAllSubCategoriesComponent } from './manage-products/categories/category-details/sub-category/get-all-sub-categories/get-all-sub-categories.component';
import { AddNewSubCategoryComponent } from './manage-products/categories/category-details/sub-category/add-new-sub-category/add-new-sub-category.component';
import { ProductFieldRefComponent } from './api-configuration/field-component/product-field-ref/product-field-ref.component';
import { ProductFieldRefService } from './api-configuration/api-configuration-services/field-services/product-field-ref-service/product-field-ref.service';
import { ProductFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/product-feature-ref.component';
import { CategoryFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/category-feature-ref.component';
import { CategoryFeatureRefService } from './manage-products/categories/category-details/product-features/product-feature-service/category-feature-ref-service/category-feature-ref.service';
import { ProductFeatureRefService } from './manage-products/categories/category-details/product-features/product-feature-service/product-feature-ref-service/product-feature-ref.service';
import { FeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/feature-ref/feature-ref.component';
import { AddnewFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/feature-ref/addnew-feature-ref/addnew-feature-ref.component';
import { AddnewCategoryFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/addnew-category-feature-ref/addnew-category-feature-ref.component';
import { AddnewProductFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/addnew-product-feature-ref/addnew-product-feature-ref.component';
import { FeatureDetailsRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/feature-ref/feature-details-ref/feature-details-ref.component';
import { CategoryFeatureDetailsRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/category-feature-details-ref/category-feature-details-ref.component';
import { ProductFeatureDetailsRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/product-feature-details-ref/product-feature-details-ref.component';
import { EditFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/feature-ref/edit-feature-ref/edit-feature-ref.component';
import { EditCategoryFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/edit-category-feature-ref/edit-category-feature-ref.component';
import { EditProductFeatureRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/edit-product-feature-ref/edit-product-feature-ref.component';
import { FeatureOptionRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/feature-ref/feature-option-ref/feature-option-ref.component';
import { CategoryFeatureOptionRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/category-feature-ref/category-feature-option-ref/category-feature-option-ref.component';
import { ProductFeatureOptionRefComponent } from './manage-products/categories/category-details/product-features/product-feature-component/product-feature-ref/product-feature-option-ref/product-feature-option-ref.component';
import { PromotionsComponent } from './marketing/promotions/promotions.component';
import { PromotionServiceService } from './marketing/promotions/promotions-service/promotion-service.service';
import { AddPromotionsComponent } from './marketing/promotions/add-promotions/add-promotions.component';
import { EditPromotionComponent } from './marketing/promotions/edit-promotion/edit-promotion.component';
import { MatMenuModule, MatButtonModule, MatDialogModule, MatDialogRef } from '@angular/material';
import { GetAllStoresComponent } from './product-stores/stores/components/get-all-stores/get-all-stores.component';
import { StoreTypeRefComponent } from './api-configuration/type-component/store-type-ref/store-type-ref.component';
import { StoreFieldRefComponent } from './api-configuration/field-component/store-field-ref/store-field-ref.component';
import { DeleteDialogService } from '../shared/delete-dialog/delete-dialog.service';
import { GetAllCategoriesComponent } from './product-stores/stores/components/store-categories/get-all-categories/get-all-categories.component';
import { ModalService } from '../directives/modal.sevice';
import { ModalModule } from '../directives/modal/modal.module';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { AnotherItemResolver } from '../resolvers/another-item.resolver';
import { ItemResolver } from '../resolvers/item.resolver';
import { MarketingDetailsComponent } from './marketing/marketing-details/marketing-details.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { TiersComponent } from './marketing/tiers/tiers.component';
import { AddTiersComponent } from './marketing/tiers/add-tiers/add-tiers.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { RewardsComponent } from './marketing/rewards/rewards.component';
import { AddRewardComponent } from './marketing/rewards/add-reward/add-reward.component';
import { EditRewardComponent } from './marketing/rewards/edit-reward/edit-reward.component';
import { RewarddetailsComponent } from './marketing/rewards/rewarddetails/rewarddetails.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EditTierComponent } from './marketing/tiers/edit-tier/edit-tier.component';
import { LoyaltyPointRulesComponent } from './marketing/loyalty-point-rules/loyalty-point-rules.component';
import { HeaderComponent } from '../shared/layout/header/header.component';
import { BreadcrumbComponent } from '../shared/breadcrumb/breadcrumb.component';
import { MessageSubjectService } from '../services/message-subject.service';
import {ClickOutsideDirective} from '../directives/click-outside/click-outside.directive'
import { ClickOutsideModule } from 'ng-click-outside';
import { TreeviewDropdownComponent } from '../shared/treeview-dropdown/treeview-dropdown.component';
import { SearchBarComponent } from '../shared/search-bar/search-bar.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RequiredIfDirective } from '../shared/custom-validators/required-if-directive';
import { Pagination } from '../shared/pagination/pagination.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ClickOutsideDirective,
    AdminHomeComponent,
    CategoriesComponent,
    AddnewCategoryComponent,
    CategoryDetailsComponent,
    ProductCategoryComponent,
    EditCategoryComponent,
    AddnewProductComponent,
    ProductDetailsComponent,
    EditProductComponent,
    CatalogComponent,
    AddnewCatalogComponent,
    CatalogDetailsComponent,
    EditCatalogComponent,
    CatalogProductComponent,
    AddnewCatalogProductComponent,
    AssociationListComponent,
    AddnewProductAssociationComponent,
    EditProductAssociationComponent,
    AddnewProductFeatureComponent,
    ProductFeatureDetailsComponent,
    EditProductFeatureComponent,
    AddFeatureOptionsComponent,
    CategoryFeaturesComponent,
    AddnewCategoryFeaturesComponent,
    CategoryFeatureDetailsComponent,
    EditCategoryFeaturesComponent,
    CategoryFeaturesOptionsComponent,
    CategoryProductAssociationsComponent,
    AddnewCategoryProductAssociatonsComponent,
    EditCategoryProductAssociationsComponent,
    EditFieldModalComponent,
    TypeRefComponentComponent,
    CategoryTypeRefComponentComponent,
    CatalogTypeRefComponent,
    AssociationTypeRefComponent,
    FeatureTypeRefComponent,
    FieldRefComponent,
    CategoryFieldRefComponent,
    CatalogFieldRefComponent,
    FeatureFieldRefComponent,
    AssociationFieldRefComponent,
    GetAllSubCategoriesComponent,
    AddNewSubCategoryComponent,
    ProductFieldRefComponent,
    ProductFeatureRefComponent,
    CategoryFeatureRefComponent,
    FeatureRefComponent,
    AddnewFeatureRefComponent,
    AddnewCategoryFeatureRefComponent,
    AddnewProductFeatureRefComponent,
    FeatureDetailsRefComponent,
    CategoryFeatureDetailsRefComponent,
    ProductFeatureDetailsRefComponent,
    EditFeatureRefComponent,
    EditCategoryFeatureRefComponent,
    EditProductFeatureRefComponent,
    FeatureOptionRefComponent,
    CategoryFeatureOptionRefComponent,
    ProductFeatureOptionRefComponent,
    PromotionsComponent,
    AddPromotionsComponent,
    EditPromotionComponent,
    StoreTypeRefComponent,
    StoreFieldRefComponent, GetAllCategoriesComponent,
    MarketingDetailsComponent,
    TiersComponent,
    AddTiersComponent,
    RewardsComponent,
    AddRewardComponent,
    EditRewardComponent,
    RewarddetailsComponent,
    EditTierComponent,
    LoyaltyPointRulesComponent,
    HeaderComponent,
    BreadcrumbComponent,
    TreeviewDropdownComponent,
    RequiredIfDirective,
    SearchBarComponent,
    Pagination

    ],
  imports: [
    BrowserModule,
    CommonModule,
    ClickOutsideModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    ModalModule,
    PpBreadcrumbsModule,
    NgxPaginationModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forChild(adminRoute),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [
    AdminHomeComponent,
    HeaderComponent,
    BreadcrumbComponent,
    TreeviewDropdownComponent ,
    RequiredIfDirective,
    SearchBarComponent,
    Pagination

   ],
  providers: [
    AdminService,
    CategoryTypeRefServiceService,
    CatalogTypeRefServiceService,
    FeatureTypeRefServiceService,
    AssociationTypeRefServiceService,
    CategoryFieldRefService,
    CatalogFieldRefService,
    FeatureFieldRefService,
    AssociationFieldRefService,
    ProductFieldRefService,
    CategoryFeatureRefService,
    ProductFeatureRefService,
    PromotionServiceService,
    DeleteDialogService,
    ModalService,
    NgbActiveModal,
    ItemResolver, AnotherItemResolver,
    MessageSubjectService,
    
    DatePipe,
    {provide: MatDialogRef, useValue: {}},
    { provide: LOCALE_ID, useValue: navigator.languages }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  // entryComponents: [
  //   ErrorHandlerModalComponent
  // ]
  
})
export class AdminModule { }
 // required for AOT compilation
 export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);;
}