import { Component, OnInit } from '@angular/core';
import { FieldRefComponent } from '../field-ref/field-ref.component';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { FieldRefService } from '../../api-configuration-services/field-services/field-ref-service/field-ref.service';
import { CategoryFieldRefService } from '../../api-configuration-services/field-services/category-field-ref-service/category-field-ref.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-category-field-ref',
	templateUrl: '../field-ref/field-ref.component.html',
	styleUrls: ['./category-field-ref.component.css']
})
export class CategoryFieldRefComponent extends FieldRefComponent implements OnInit {

	fieldRefService: any;
	constructor(
		public route: ActivatedRoute,
		public modalService: NgbModal,
		public router: Router,
		public http: HttpClient,
		public deleteDialogService: DeleteDialogService,
		public autoCodeGenerator: AutoNameCodeGeneratorService) {
			super(route, modalService, router, http,deleteDialogService,autoCodeGenerator)
		this.fieldRefService = new CategoryFieldRefService(http)
	}
	ngOnInit() {
		this.getAllFields()
		this.formFieldModel()
		this.addOptionFields()
		this.fieldName = "category"
		this.fieldDescription = "List of category fields"
	}


	// getAllFields() {
	// 	this.fieldRefService.getAllFieldsByTypeCode(this.typeCode).subscribe((data) => {
	// 		this.fields = data.fields;
	// 		this.typeConversion(this.fields);
	// 		for(let i=0;i< this.fields.length; i++){
	// 			this.keys[i] = this.fields[i].key;
	// 		}
	// 	});
	// }
}
