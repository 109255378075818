import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription } from 'rxjs';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { RewardFilters } from 'src/app/shared/constants/filter-model/RewardFilters';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { TierService } from '../tiers/tier.service';
import { RewardService } from './reward.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {
  
  constructor(private tierService: TierService,
    private rewardService: RewardService,
    private deleteDialogService: DeleteDialogService,
     private router: Router,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService,
    public translate:TranslateService) { 
      this.filters = new RewardFilters()
    }
    rewardList: any[];
    itemCheck: boolean = false;
    allCheck: boolean = false;
    checkboxItems: Array<boolean> = [];
    myValue: boolean = false;
    subscription: Subscription;
    dropdownList = [];
    dropdownSettings: IDropdownSettings;
    selectedItems = [];
    noDataShow: Boolean = false;
    breadCrumbs: any;
    currentPage : number =1;
    itemsPerPage :number = 10;
    numberOfPages :number=1;
    totalItems: number;
    filters: RewardFilters;
    pagination: any;
    searchName: string;
    requestTier: Array<string> = [];
    // sort:Array<any> =sortConstant.REWARD_SORT
    sort: Array<any> = [];
    fields: any = {

    fieldName: null,
    sortDirection: null
  };

    
    ngOnInit() {
      this.rewardService.ngOnInit();
      this.tierService.ngOnInit()
      this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      this.tierService.getAllTiers().subscribe(data => {
        this.dropdownList = data["loyaltyTiers"];
      })
      
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'tierId',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 2,
        allowSearchFilter: true
      };
    }
    
    
    getRewardsSummaryByQuery(filters,itemsPerPage,pageNumber,sort) {
      this.rewardList = [];
      this.pagination = null;
      this.allCheck = false;
      this.rewardService.getRewardsByQuery(filters,itemsPerPage,pageNumber,sort).subscribe((data) => {
        this.rewardList = data.loyaltyRewards;
        console.log(this.rewardList)
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.getCheckBoxes(this.rewardList);
        this.noDataShow = this.noDataService.noData(this.rewardList);
      });  
    }
    
    getCheckBoxes(rewardList) {
     if(rewardList.length > 0) {
      for (let i = 0; i < rewardList.length; i++) {
        this.checkboxItems[i] = false;
        rewardList[i].imageRef = this.getBackground(rewardList[i].imageRef);
      }
     }
    }
    
    getTierNameById(id: []) {
      var nameField = "";
      if (this.dropdownList.length == id.length || id.length == 0) {
        return 'All';
      }
      else {
        
        
        for (var i = 0; i < id.length; i++) {
          for (var j = 0; j < this.dropdownList.length; j++) {
            if (id[i] == this.dropdownList[j]['tierId']) {
              nameField = nameField + this.dropdownList[j]['name'];
              if (i + 1 < id.length) {
                nameField = nameField + ", "
              }
              break;
            }
          }
        }
      }
      return nameField;
    }
    
    
    pushInReqTier(item) {
      this.requestTier.push(item);
      this.getRewardForFilters();
    }
    
    removeInReqTier(item) {
      var newArray = new Array();
      for (var i = 0; i < this.requestTier.length; i++) {
        if (item['tierId'] != this.requestTier[i]['tierId']) {
          newArray.push(this.requestTier[i])
        }
      }
      this.requestTier = newArray;
      this.getRewardForFilters();
    }
    
    onItemSelect(item: any) {
      this.pushInReqTier(item);
      
    }
    onSelectAll(items: any) {
      this.requestTier = items;
      this.getRewardForFilters();
    }

    onItemDeSelectAll(item:any){
      // this.requestTier=items;
      // this.removeInReqTier(item);
      // this.onItemDeSelect(item);
      // this.getRewardForFilters();
      // this.onItemSelect(item)
      this.requestTier=[];
      // this.pushInReqTier(item);
    }

    onItemDeSelect(item: any) {
      this.removeInReqTier(item);
      this.getRewardForFilters();
    }
    
    
    getRewardForFilters() {
      var tierIds = [];
      for(let i=0; i< this.requestTier.length;i++) {
        tierIds.push(this.requestTier[i]['tierId'])
      }
      this.filters['tierIds'] = tierIds;
      this.currentPage = 1;
      this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    
  
    // ---------- delete methods -------------//
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.rewardList.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.rewardList.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    // multiple delete method
    delete() {
      this.openModal1("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          // var deleteRewardsList = new Array();
          let rewardIds = []
          this.closeModal1("dialogBox");
          for (let i = 0; i < this.rewardList.length; i++) {
            if (this.checkboxItems[i] == true) {
              rewardIds.push(this.rewardList[i]['id'])
            }
          }
          
          this.rewardService.deleteReward(rewardIds).subscribe(data => {
            this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
            this.allCheck = false
          })
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal1("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
    
    // single delete method
    
    setEditedreward(reward) {
      // this.rewardService.setEdit(reward);
      this.router.navigateByUrl("/rewarddetails/" + reward.id)
    }
    openModal1(id: string) {
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    closeModal1(id: string) {
      this.deleteDialogService.closeDialog();
    }
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    setUpBreadCrumb(key,labelName,path) {
      this.messageService.broadcast('bcLabelNew', {
        key: key,
        alternativeKey: key,
        labelName: labelName,
        path: path,
        child: []
      })
    }
    
    onChangePage(event) {
      this.currentPage = event;
      this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }

    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['name'] = this.searchName;  
        this.currentPage = 1;
        this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
  }

    hideCross(event) {
      // this.filters['name'] = null;
      delete this.filters.name
      this.searchName = '';
      this.getRewardsSummaryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='name'
        console.log(this.sort)
      } else {
        // this.sort[0].sortDirection = await null
        delete this.sort[0].sortDirection
  
      }
  
      this.getRewardsSummaryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
  }