
export class Action {
    public actionType = new Map();
    public promoParameter = new Map();
    public promoConditionOperator = new Map();
    user: any
    constructor() {
        let user = JSON.parse(sessionStorage.getItem('user'))

        if (user.tenant === 'Order Orchestration') {
            this.actionType.set("PROMO_ROUTE_TO", "Route To");
            this.promoParameter.set("PPIP_ORDER_TYPE", "LOB");
            this.promoConditionOperator.set("PPC_EQ", "Equal To");
            this.promoConditionOperator.set("PPC_NEQ", "Is Not Equal To");
        } else {
            this.actionType.set("PROMO_GWP", "Gift With Purchase");
            this.actionType.set("PROMO_ORDER_PERCENT","Order Percent Discount");
            this.promoParameter.set("PPIP_ORDER_TOTAL", "Cart Total");
            this.promoParameter.set("PPIP_ORDER_CART_ITEM_COUNT", "Number of Items in Cart")

            this.promoConditionOperator.set("PPC_EQ", "Equal To");
            this.promoConditionOperator.set("PPC_GT", "Is Greater Than");
            this.promoConditionOperator.set("PPC_GTE", "Is Greater Than or Equal To");
            this.promoConditionOperator.set("PPC_LT", "Is Less Than");
            this.promoConditionOperator.set("PPC_LTE", "Is Less Than or Equal To");
            this.promoConditionOperator.set("PPC_NEQ", "Is Not");
        }



    


    }

    public getActionTypeKeys(): any {
        let actionTypeKey: Array<any> = [];
        for (let key of this.actionType.keys()) {
            actionTypeKey.push(key);
        }
        return actionTypeKey;
    }

    public getActionTypeValues() {
        let actionTypeValue: Array<any> = [];
        for (let value of this.actionType.values()) {
            actionTypeValue.push(value);
        }
        return actionTypeValue;
    }

    public getPromoParameterKeys(): any {
        let promoParameterKey: Array<any> = [];
        for (let key of this.promoParameter.keys()) {
            promoParameterKey.push(key);
        }
        return promoParameterKey;
    }

    public getPromoParameterValues() {
        let promoParameterValue: Array<any> = [];
        for (let value of this.promoParameter.values()) {
            promoParameterValue.push(value);
        }
        return promoParameterValue;
    }

    public getPromoConditionOperatorKeys(): any {
        let promoConditionOperatorKey: Array<any> = [];
        for (let key of this.promoConditionOperator.keys()) {
            promoConditionOperatorKey.push(key);
        }
        return promoConditionOperatorKey;
    }

    public getPromoConditionOperatorValues() {
        let promoConditionOperatorValue: Array<any> = [];
        for (let value of this.promoConditionOperator.values()) {
            promoConditionOperatorValue.push(value);
        }
        return promoConditionOperatorValue;
    }
}