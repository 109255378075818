import { Component, OnInit } from '@angular/core';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { TierService } from '../tier.service';
import { Subscription } from 'rxjs';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
const cleanDeep = require('clean-deep');

@Component({
  selector: 'app-edit-tier',
  templateUrl: './edit-tier.component.html',
  styleUrls: ['./edit-tier.component.css']
})
export class EditTierComponent implements OnInit {
  tier: any;
  user: any;
  tierId: any;
  selectedFiles: FileList;
  currentImageRef: any;
  subscription: Subscription;
  breadCrumbs: any;
  editTier: any
  previousImageRef: any;

  constructor(private tierService: TierService,
    private route: ActivatedRoute,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private messageService: MessageSubjectService,
    public translate: TranslateService) {

    this.user = JSON.parse(sessionStorage.getItem('user'));

  }

  editTierModel() {
    this.editTier = {
      // tierId:this.tier.tierId ,
      modifiedBy: this.user.name,
      name: this.tier.name,
      description: this.tier.description,
      imageRef: this.tier.imageRef,
      minValue: this.tier.minValue
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe((map) => {
      this.tierId = decodeURIComponent(map.get('tierId'))
      this.getTierById();
    })

  }


  getTierById() {

    this.tierService.getTierById(this.tierId).subscribe(data => {
      this.tier = data["loyaltyTiers"][0];
      console.log(this.tier)
      this.currentImageRef = this.getBackground(this.tier.imageRef)
      this.editTierModel()
    })
  }


  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
      console.log(this.currentImageRef)
    }
  }

  resetImage() {
    this.selectedFiles = null;
    this.currentImageRef = "";
  }



  resetForm(edittiersForm:NgForm) {
    this.openResetModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");
        this.ngOnInit();
        this.selectedFiles = null;
        this.currentImageRef = "";
        edittiersForm.resetForm()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }


  editTiers() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.editTier.imageRef != undefined) {
        if ((this.editTier.imageRef != "")) {
          console.log("upload")
          console.log(this.editTier.imageRef)
          this.imageUploadService.editImage(file, this.editTier.imageRef).subscribe((data) => {
            this.editTierServiceCall();
          })
        } else {
          this.imageUploadService.uploadImage(file).subscribe((data) => {
            this.editTier.imageRef = data.documentId;
            this.editTierServiceCall();
          })
        }
      } else {
        this.imageUploadService.uploadImage(file).subscribe((data) => {
          this.editTier.imageRef = data.documentId;

          this.editTierServiceCall();
        })
      }
      // this.imageUploadService.uploadImage(file).subscribe((data) => {
      //   this.tier.imageRef = data.documentId;
      //   console.log("tierImage",this.tier.imageRef)
      //   this.editTierServiceCall();
      // })
    } else {
      this.editTierServiceCall();
    }
  }

  editTierServiceCall() {
    var requestBody = new Object();
    requestBody['loyaltyTier'] = this.editTier;
    // console.log(requestBody)
    // var requestBodyObject = cleanDeep(requestBody)
    console.log(this.tier)
    console.log(requestBody)
    this.tierService.editTier(decodeURIComponent(this.tierId), requestBody).subscribe(data => {
      if (data['success']) {
        if (this.selectedFiles != null) {
          let timestamp = new Date();
          sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
        }
      }
      this.messageService.broadcast('splice', {
        key: 'edittier',
        alternativeKey: 'edittier'
      })
      this.router.navigateByUrl('/marketingdetails/tiers')
    });
  }


  openResetModal(id) {
    let data = {};
    data = {
      value: 'reset'
    };
    if (id == 'delete') {
      data['value'] = id;
    }
    this.deleteDialogService.openDialog(data);
  }
  closeResetModal(id) {
    this.deleteDialogService.closeDialog();
  }

}
