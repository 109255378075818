import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoDataService } from 'src/app/services/no-data.service';
import { OrderHistoryFilters } from 'src/app/shared/constants/filter-model/order-history-filters.model';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { CustomerService } from '../../customer-service/customer.service';

@Component({
  selector: 'app-order-history-details',
  templateUrl: './order-history-details.component.html',
  styleUrls: ['./order-history-details.component.css']
})
export class OrderHistoryDetailsComponent implements OnInit {

  constructor(private customerService: CustomerService  ,private route :ActivatedRoute ,  private noDataService: NoDataService,   private deleteDialogService: DeleteDialogService, private  loaderService: LoaderService) {  
    this.user = JSON.parse(sessionStorage.getItem("user"));
  this.tenant = this.user.tenant;
 }

  customerId: any;
  subscription: Subscription;
  orderId:any;
  showForm:boolean;
  trackingTime: any;
  showData: boolean =false;
  searchOrder: any
  orderData: any;
  status: any;
  cardNumber: any;
  newOrderId: any;
 timer:any
shipmentId:any;
public hideRuleContent:boolean[] = [];
public buttonName:any = 'Expand';
isShipment:boolean;
public isCollapsed: boolean = true;
tenant:any;
filters: any =  OrderHistoryFilters;
// noDataShow: Boolean = false;
currentPage : number =1;
itemsPerPage :number = 10;
pagination: any;
numberOfPages:number=1;
sort: Array<any> = [];
totalItems: number;
tenants:any;
user: any;
totalQuantity: any;
shipments: Array<any> = [];;
DELIVERED: boolean = false;
isSelected:boolean = true;
    
  ngOnInit() {

    this.route.paramMap.subscribe((map) => {
      this.customerId = map.get('customerid');
      this.orderId=map.get('orderid');
      this.user = JSON.parse(sessionStorage.getItem("user"));
      this.tenant = this.user.tenant;
      // this.onSelectOrderStatus(event)
      // this.showOrderHistory()
      this.getOrderWithShipment();
  
    });
    // 
   
    // this.getOrderHistoryWithPagination(this.filters,this.customerId,this.itemsPerPage,this.currentPage,this.sort)
  }

  getOrderWithShipment()
{
  
  this.customerService.getOrderWithShipment(this.orderId).subscribe((data) => {
   this.shipments=[]
    for(let i=0;i<data.orderHistory.length;i++){
          this.orderData = data.orderHistory;
      if(this.orderData[i].shipments==null){
              console.log('in if')
              this.isShipment=false
              this.getOrderWithOrderId()
            }else {
              console.log('in else')
              console.log('getOrderWithShipment',data)
              this.isShipment=true;
              this.showData = true;
              this.orderData = data.orderHistory;
            }
      console.log(this.orderData)
    }
   
        })
}
order:any

getOrderWithOrderId() {
  this.isShipment=false;
  this.customerService.getOrderWithId(this.orderId).subscribe(data=>{
    console.log('getOrderWithId',data)
    this.showData=true;
    this.orderData=data.orderHistory;
   
    
  })
  
}

// showOrderHistory(){

//   for(let i=0;i<this.shipment.length;i++){
// if(this.shipment[i]>0){
//   this.isPhones=true
//   console.log('in showorder' , this.tenant)
//   this.getOrderWithShipment()
//   console.log('with shipment')
//   console.log(this.isPhones)
// } else{
// // this.isPhones=false
// console.log('in showorder' , this.tenant)
// this.getOrderWithOrderId()
// console.log(this.isPhones)
// }
//   }
// }


visibleIndex = -1;
toggle1: boolean = false;
toggle2: boolean = false;

showSubItem(ind  ) {
  if (this.visibleIndex === ind) {
    
    this.visibleIndex = -1;
    
  } else {
    this.visibleIndex = ind;
  }

  
}

public icon = 'highlight_off'; 

public toggleIcon() {
  // if(num == 1)
  this.toggle1 = !this.toggle1;
// else
//   this.toggle2 = !this.toggle2;
}

onSelectStatus(event,shipmentId){

  if(event.target.value){
    this.status=event.target.value;
    // this.loaderService.show()
    this.customerService.updateShipment(shipmentId,this.status).subscribe(data=>{
    this.getOrderWithShipment()  
    })

    console.log(this.status)
  }
}

onSelectOrderStatus(event){
  if(event.target.value){
    this.status=event.target.value;
    
    this.customerService.updateOrderWithShipment(this.orderId,this.status).subscribe(data=>{
      this.getOrderWithShipment()
    })
    console.log(this.status)
  }

}

getImage(imageRef) {
    
  return "https://dcp-ui-all-image.s3.amazonaws.com/phones/img/" + imageRef;
}



}
