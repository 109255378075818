export class ProductFilters {
    public name:  string ;
    public categoryId:  string ;
    public includeWorkflowStatus:Array<any>
    public excludeWorkflowStatus:Array<any>
}

export class ProductSort{


    public sorts:SortParameters;
}

export class SortParameters{

    public fieldName:string=null;
    public sortDirection:string=null;
}