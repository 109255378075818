import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class RewardService {
  
  user:any;
  tenant:any;
  headers: Headers;
  serverRootUrl = environment.REWARD_BASE_URL;
  // rewardRootUrl = this.serverRootUrl + "/loyaltyreward/api/v1/loyaltyrewards/"
  rewardRootUrl = this.serverRootUrl+"/api/v1/loyaltyrewards/"
  constructor(private http:HttpClient) { 
    
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  ngOnInit(){
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  public deleteReward(rewardIds):Observable<any>
  {
    
    const options = {
      headers:this.getHeaderTenant()  
    }
    console.log(this.rewardRootUrl+ "?loyaltyRewardIds="+rewardIds)
    return this.http.delete(this.rewardRootUrl+"?loyaltyRewardIds="+rewardIds,options);
  }
  getHeaderTenant() {
    //return { headers: {'X-TENANT':this.tenant} 
    
    return  new HttpHeaders({
      'X-TENANT': this.tenant
    });
  }
  public getAllRewards():Observable<any>
  {
    const options = {
      headers:this.getHeaderTenant()  
    }
    return  this.http.get(this.rewardRootUrl+'all?retrieveInactive=false',options)
  }
  public  getRewardsByTierIds(queryString):Observable<any>
  {
    const options = {
      headers:this.getHeaderTenant()  
    }
    
    
    return  this.http.get(this.rewardRootUrl+'tiers/all?retrieveInactive=false'+queryString,options)
  }
  
  public getRewardById(rewardid):Observable<any>
  {
    const options = {
      headers:this.getHeaderTenant()  
    }
    //  return  this.http.get(this.rewardRootUrl,options)
    
    return this.http.get<any>('./assets/admin-json/rewardbyid.json')
  }
  
  
  public addReward(requestBody):Observable<any>
  {
    const options = {
      headers:this.getHeaderTenant() 
    }
    
    return this.http.post(this.rewardRootUrl,requestBody,options);
  }
  
  
  public getRewardByrewardId(rewardId)
  {    
    const options = {
      headers:this.getHeaderTenant() 
      
    }
    return this.http.get(this.rewardRootUrl+"?loyaltyRewardIds="+rewardId,options) ;
    
  }
  
  public updateReward(rewardId,requestBody)
  {
    const options = {
      headers:this.getHeaderTenant() 
      
    }
    return this.http.put(this.rewardRootUrl+rewardId,requestBody,options);
  }

  public getRewardsByQuery(filters,itemsPerPage,pageNumber,sort):Observable<any> {
    const options = {
      headers:this.getHeaderTenant() 
    }
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    console.log(requestBody)
    return this.http.post<any>(this.rewardRootUrl+"query",requestBody,options);
  }
  
}
