import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Type } from 'src/app/admin/admin';
import { environment } from 'src/environments/environment.prod';
import { ModalService } from 'src/app/directives/modal.sevice';
import { EditFieldModalService } from './edit-field-modal.service';

@Component({
  selector: 'app-edit-field-modal',
  templateUrl: './edit-field-modal.component.html',
  styleUrls: ['./edit-field-modal.component.css']
})
export class EditFieldModalComponent implements OnInit {

	modalOpen: boolean = false;
	categoryFormFields: any;
	optionForm: FormGroup
	optionForm1: FormGroup
	currentValue: any;
	optionfields: boolean = false;
	checkbtns: boolean = false;
	types: Array<any> = []
	user: any;
	typeKey: Array<any> = []
	typeValue: Array<any> = []
	typeData: Array<any> = []
	itemCheck: boolean = false;
	allCheck: boolean = false;
	checkboxItems: Array<boolean> = [];
	myValue: boolean = false;
	currentType: any;
	typeClass: Type;
	showOptionFields: boolean = false;
	array: Array<any>;
	checked: Boolean = false;
	optionValue: any;
	opt: any;
	optn: FormGroup
	optionArray: Array<any> = [];
	option: Array<any> = [];
  type : any;
  editModel: any;
  fieldsValue: any;
  constructor(  private modalService: ModalService,
    private editFieldsModalService: EditFieldModalService) { }

  ngOnInit() {

    this.editFieldsModalService.fieldType.subscribe((res) => {
      this.typeValue = res;
    //   //console.log(this.typeValue)
    });

    this.editFieldsModalService.data.subscribe((res) => {
      alert("hii")
    //   //console.log(res)
      this.fieldsValue = res;
    //   //console.log(this.fieldsValue);
      
      this.editModel = {
        name: this.fieldsValue.name,
        key: this.fieldsValue.key,
        type: this.fieldsValue.type,
        createdBy: this.fieldsValue.createdBy,
        modifiedBy: this.user.name,
        options: this.fieldsValue.options
      }
  
      if (this.fieldsValue.type == ("DOCUMENT") || this.fieldsValue.type == ("BOOLEAN")) {
        this.showOptionFields = false;
      }
      else {
        this.showOptionFields = true;
      }
      if (this.fieldsValue.options) {
        if ((this.fieldsValue.options.length == 1) || (this.fieldsValue.options.length == 0)) {
          this.checked = false;
          this.optionfields = false;
          this.checkbtns = false;
        }
        else {
          this.checked = true;
          this.optionfields = true;
          this.checkbtns = true;
          if( this.editModel.type == this.fieldsValue.type) {
            for (let i = 0; i <this.editModel.options.length; i++) {
            //   //console.log(this.fieldsValue.options)
              this.option[i] = this.editModel.options[i];
            //   //console.log(this.option)
              this.editOptionFields();
            }
          }
        }
      }
    })
  }


  editTypeSelect(event) {
		let type
		type = event.target.value;
		this.currentType = environment.typeConversion[type]
		// //console.log(type)
		if (type == ("DOCUMENT") || type == ("BOOLEAN")) {
			this.showOptionFields = false;
			this.checked = false;
			this.editModel.options = []
		}
		else {
			this.showOptionFields = true;
		}
		if (this.fieldsValue.type == type) {
		for (let i = 0; i < this.fieldsValue.options.length; i++) {
				this.checked = true;
				this.optionfields = true
				this.checkbtns = true				
				// //console.log(this.fieldsValue.options)
				this.optionsValue.length  = this.fieldsValue.options.length
				this.option[i] = this.fieldsValue.options[i];
			}
		}
				else {
					this.checked = false;
					this.optionfields = false;
					this.checkbtns = false;
					this.option= [];
					this.optionsValue.length = 1
				}

	}

	editSelectOption(event) {
		this.currentValue = event.target;

		if (event.target.checked) {
			this.optionfields = true;
			this.checkbtns = true;
			this.checked = true;
		}
		else {
			this.optionfields = false;
			this.checkbtns = false;
			this.checked = false;
			this.editModel.options = []
		}
	}

	public optionsValue: any[] = [{
		option: ''
	}]

	editOptionFields() {
		this.optionsValue.push({
			option: ''
		});
	}

	onEdit(id) {
		// //console.log(this.checked)
		// //console.log(this.optionsValue)
		// //console.log(this.showOptionFields)
		if(this.checked) {
			for (let i = 0; i < this.optionsValue.length; i++) {
				this.editModel.options[i] = this.option[i]
			}}
			else {
				this.editModel.options = []
			}
		// //console.log(this.editModel)
		// this.adminService.updatefieldsValueByFieldId(this.categoryTypeCode, this.fieldsValue.id, this.editCategoryFormFields).subscribe((data) => {
		// 	this.router.navigate[("/fieldsValues/"+this.categoryTypeCode)]
		// 	this.closeModal(id);
		// });
		
}

	removeOptionField(i) {
		this.optionsValue.splice(i, 1);
  }
  closeModal(id) {
    this.modalService.close(id)
  }
}
