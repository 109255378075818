import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.prod';
import { v4 as uuid } from 'uuid';
import { BucketUrl } from 'src/app/bucket';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';

@Component({
  selector: 'app-category-features',
  templateUrl: './category-features.component.html',
  styleUrls: ['./category-features.component.css']
})
export class CategoryFeaturesComponent implements OnInit {

  categoryId: any;
  categoryFeatures: Array<any> = [];
  myBucketUrl: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  bucketUrl: BucketUrl;
  subscription: Subscription;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService) {
    this.bucketUrl = new BucketUrl();

    
  }

  ngOnInit() {

    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('categoryId')
      this.getAllCategoryFeatures()
    });
  }


  getAllCategoryFeatures() {
    this.adminService.getAllCategoryFeaturesById(this.categoryId).subscribe((data) => {
      this.categoryFeatures = data.features;
      //console.log(this.categoryFeatures)
      for (let i = 0; i < this.categoryFeatures.length; i++) {
        this.checkboxItems[i] = false;
      }
    });
  }

  getBackground(image) {
    let uid = uuid();
    if (image == "") {
      image = "noImage.jpg";
      return this.sanitizer.bypassSecurityTrustStyle('url(/assets/images/' + image + ')');
    }
    else {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.myBucketUrl + image + "?" + uid + ')');
    }
  }
  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.categoryFeatures.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.categoryFeatures.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  // multiple delete method
  delete() {



    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal("dialogBox");

        let len = this.categoryFeatures.length;
        let featureIds: Array<any> = [];
        for (let i = 0; i < len; i++) {
          if (this.checkboxItems[i] == true) {
            featureIds.push(this.categoryFeatures[i].id)
          }
        }

        this.adminService.deleteCategoryFeatureById(this.categoryId, featureIds).subscribe((data) => {
          this.getAllCategoryFeatures()
        })

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })


  }

  // single delete method
  deleteSingle(categoryFeature) {
    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal("dialogBox");

        this.adminService.deleteCategoryFeatureById(this.categoryId, categoryFeature.id).subscribe((data) => {
          this.getAllCategoryFeatures()
        })

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //console.log(event.target)
  }
}
