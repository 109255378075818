import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { request } from 'http';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {

  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  ngOnInit(){
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  user: any;
  tenant: any;
  headers: Headers;
  

  serverRootUrl = environment.LOYALTYPOINT_BASE_URL;
  // loyaltyRuleRootUrl = this.serverRootUrl+"/api/v1/loyaltypoints/rules/"
  loyaltyRuleRootUrl = this.serverRootUrl+"/api/v1/loyaltypoints/rules/";

  // loyaltyRuleRootUrl = this.serverRootUrl+ "/loyaltypoint/api/v1/loyaltypoints/rules/"
  // loyaltyRuleRootUrl = this.serverRootUrl+"/api/v1/loyaltypoints/rules/"
  getHeaderTenant() {
    return { headers: {'X-TENANT':this.tenant} }
  }


  getLoyaltyRuleSummaryByType(RuleType):Observable<any> {
    return this.http.get<any>(this.loyaltyRuleRootUrl+"types/"+RuleType+"/summaries?retrieveInactive=true",this.getHeaderTenant());
  }

  getAllLoyaltyRuleSummary():Observable<any> {
    return this.http.get<any>(this.loyaltyRuleRootUrl+"summaries/all?retrieveInactive=true",this.getHeaderTenant());
  }

  getAllLoyaltyRuleSummaryByQuery(filters,itemsPerPage,pageNumber,sort) {
    var requestBody = {
      filters: filters,
      "itemsPerPage": itemsPerPage,
      "pageNumber": pageNumber,
      "retrieveInactive": true,
      "sort": sort
    }
    return this.http.post<any>(this.loyaltyRuleRootUrl+"query",requestBody,this.getHeaderTenant());
  }

  getLoyaltyRuleTypes():Observable<any> {
    return this.http.get<any>('./assets/loyalty-rules/loyaltyPointRuleType.json')
  }

  addNewloyaltyRule(loyaltyPointRule):Observable<any> {
    let request = {};
    request['loyaltyPointRule'] = loyaltyPointRule;
    return this.http.post<any>(this.loyaltyRuleRootUrl,request,this.getHeaderTenant());
  }

  editLoyaltyRule(ruleId,loyatyPointRule):Observable<any> {
    let request = {};
    request['loyaltyPointRule'] = loyatyPointRule;
    return this.http.put<any>(this.loyaltyRuleRootUrl+ruleId,request,this.getHeaderTenant())
  }
  getLoyaltyRuleById(ruleId):Observable<any> {
    console.log(ruleId , 'service')
    return this.http.get<any>(this.loyaltyRuleRootUrl+"?ruleIds="+ruleId,this.getHeaderTenant());
  }


  deleteLoyaltyRuleById(ruleIds):Observable<any> {
    return this.http.delete<any>(this.loyaltyRuleRootUrl+"?ruleIds="+ruleIds,this.getHeaderTenant());
  }


  
}
