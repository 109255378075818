export const environment = {
  production: true,
  BASE_URL: "https://dcpecomm.digitalcloudplatform.com",
  IMAGE_VIEW_URL: "https://dcp-ui-all-image.s3.amazonaws.com/{tenant}/img/{documentId}",
  ECOMM2_BASE_URL: "https://dcpecomm2.digitalcloudplatform.com",
  PRODUCT_BASE_URL: "https://productapi.digitalcloudplatform.com",
  //PRODUCT_BASE_URL: "http://localhost:8088",
  PRODUCT_CATALOG_BASE_URL: "https://productcatalogapi.digitalcloudplatform.com",
  LOYALTYPOINT_BASE_URL: "https://loyaltypointapi.digitalcloudplatform.com",
  PRODUCT_CATEGORIES_BASE_URL: "https://productcategoryapi.digitalcloudplatform.com",
  CUSTOMER_BASE_URL: "https://customerapi.digitalcloudplatform.com",
  PROMOTION_BASE_URL: "https://promotionapi.digitalcloudplatform.com",
  TIER_BASE_URL: 'https://loyaltytierapi.digitalcloudplatform.com',
  REWARD_BASE_URL: 'https://loyaltyrewardapi.digitalcloudplatform.com',
  LOYALTY_PROFILE_BASE_URL: 'https://loyaltyprofileapi.digitalcloudplatform.com',
  STORE_BASE_URL : 'https://dcpecomm.digitalcloudplatform.com',
  WORKFLOW_ENABLED: true,
  USER_KEY_PASSWORD: 'dcpworkflow',
  WORKFLOW_TENANT:['cafe','abc'],
   //please add tenant in myBucketUrl also 
  // myBucketUrl: "https://dcp-ui-images.s3.ap-south-1.amazonaws.com/product-images/" + "mm/",//change this tenant also if tenant changes
  myBucketUrl: "https://dcp-ui-all-image.s3.amazonaws.com/",//change this tenant also if tenant changes
  //tenant:"mm",
  typeConversion: {
    STRING: "text",
    BOOLEAN: "checkbox",
    DOCUMENT: "file",
    INTEGER: "number",
    DECIMAL: "number",
    DATE: "date",
    TIME: "time",
    DATETIME: "datetime-local"
  },
  typeArray:[
    {"STRING": "Text"},
    {"BOOLEAN": "Boolean"},
    {"DOCUMENT": "Image"},
    {"NUMBER": "Number"},
    {"DECIMAL": "Decimal"}
  ],


};



