import { Injectable } from '@angular/core';
import { TypeRefServiceService } from '../type-ref-service/type-ref-service.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryTypeRefServiceService implements TypeRefServiceService {

  constructor(public http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;

  serverRootUrl = environment.BASE_URL;
  productServerUrl=environment.PRODUCT_CATEGORIES_BASE_URL
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"

  //services for build
  productCategoryServerRootUrl =  this.productServerUrl+"/api/v1/productcategorytypes/"
  // productCategoryTypeRootUrl = this.serverRootUrl + "/productcategory/api/v1/productcategorytypes/";

  getHeaderTenant() {
    return { headers: {'X-TENANT':this.tenant} }
}

  createType(categoryType) {
    let request = {};
        request['categoryType'] = categoryType;
        return this.http.post<any>(this.productCategoryServerRootUrl, request ,  this.getHeaderTenant());
  }

  getAllTypes():Observable<any> {
    return this.http.get<any>(this.productCategoryServerRootUrl ,  this.getHeaderTenant());
  }

  deleteTypeByTypeCodes(categoryTypeCodes) {
    return this.http.delete<any>(this.productCategoryServerRootUrl + "?productCategoryTypeCodes=" +categoryTypeCodes ,  this.getHeaderTenant());
  }

  updateTypeByTypeCode(categoryType, categoryTypeCode) {
    let request = {};
    request['categoryType'] = categoryType;
    return this.http.put<any>(this.productCategoryServerRootUrl + categoryTypeCode, request ,  this.getHeaderTenant());
  }
}
