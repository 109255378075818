import { Component, OnInit } from '@angular/core';
import { BucketUrl } from 'src/app/bucket';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { v4 as uuid } from 'uuid';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { AdminService } from 'src/app/admin/admin.service';
import { HttpClient } from '@angular/common/http';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { FiltersConstant } from 'src/app/shared/constants/filters-constant';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { NoDataService } from 'src/app/services/no-data.service';
import { FeatureFilters } from 'src/app/shared/constants/filter-model/feature-filters.model';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-feature-ref',
  templateUrl: './feature-ref.component.html',
  styleUrls: ['./feature-ref.component.css']
})
export class FeatureRefComponent implements OnInit {

  categoryId: any;
  features: Array<any> = [];
  myBucketUrl: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  dataSubscription: Subscription;
  flag: boolean = false;
  bucketUrl: BucketUrl;
  subscription: Subscription;
  featureRefService: any;
  featureLink: any;
  featureDetails: any;
  editFeature: any;
  showNoData: boolean = false;
  searchName: any;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  numberOfPages: number = 1;
  totalItems: number;
  filters: any = FeatureFilters;
  pagination: any;
  types: Array<any> = [];
  sort: Array<any> = [];
  fields: any = {
    fieldName: null,
    sortDirection: null,

  };
  disableAll: Boolean = true
  // sort: Array<any> = sortConstant.FEATURE_SORT;


  constructor(public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public deleteDialogService: DeleteDialogService,
    public modalService: ModalService,
    public http: HttpClient,
    public backgroundImageService: BackgroundImageService,
    public router: Router,
    public adminService: AdminService,
    public messageService: MessageSubjectService,
    public noDataService: NoDataService,
    public translate: TranslateService,
    public dataService: MessageService) {

    this.filters = new FeatureFilters()
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('id')
      console.log(this.categoryId)
      this.featureRefService.ngOnInit()
      this.getTypes()
      this.adminService.getAllProductFeatureType().subscribe((data) => {
        this.types = data.featureTypes;
        console.log(this.types)
      });
      console.log(this.types)

    });

    console.log("disableAll", this.disableAll)
  }
  getAllFeatureTypes() {
    this.featureTypesName = []
    this.adminService.getAllProductFeatureType().subscribe((data) => {
      for (let i = 0; i < this.features.length; i++) {
        for (let j = 0; j < data.featureTypes.length; j++) {
          if (this.features[i].type == data.featureTypes[j].code) {
            this.featureTypesName[i] = data.featureTypes[j].name
          }
        }
      }
    });
    console.log(this.featureTypesName)
  }

  ngOnInit() {
    console.log('features2')
    this.getTypes()
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get('id')

      this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
    });
  }


  // getAllFeatures() {

  // }

  getAllFeaturesByQuery(filters, itemsPerPage, currentPage, sort) {
    this.features = [];
    this.pagination = null;
    this.featureTypesName = []
    this.allCheck = false;
    this.featureRefService.getAllFeaturesByQuery(this.categoryId, filters, itemsPerPage, currentPage, sort).subscribe((data) => {
      this.features = data.features;
      console.log(this.features)
      this.pagination = data.pagination;
      this.totalItems = this.pagination.totalNumberOfRecords;
      this.numberOfPages = this.pagination.numberOfPages;
      // await this.getTypes()
      this.getAllFeatureTypes()
      this.showNoData = this.noDataService.noData(this.features);
      this.backgroundImage(this.features)
    });
  }

  backgroundImage(features) {
    if (features.length > 0) {
      this.checkboxItems = []
      for (let i = 0; i < features.length; i++) {
        console.log(features[i].imageRef)
        features[i].imageRef = this.getBackground(features[i].imageRef);
        this.checkboxItems[i] = false;
        console.log(features)
      }
    }
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }
  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.features.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  featureTypesName: Array<any> = [];



  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.features.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  // multiple delete method
  delete() {
    this.openModal1("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal1("dialogBox");

        let len = this.features.length;
        let featureIds: Array<any> = [];
        for (let i = 0; i < len; i++) {
          if (this.checkboxItems[i] == true) {
            featureIds.push(this.features[i].id)
            this.featureRefService.deleteFeatureByFeatureId(this.categoryId, featureIds).subscribe((data) => {
              this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
            })
          }
        }



        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal1("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })


  }



  openModal1(id: string) {
    let data = {};
    data = {
      value: 'delete'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeModal1(id: string) {
    this.deleteDialogService.closeDialog();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //console.log(event.target)
  }


  // -------  pagination methods ------ //

  onChangePage(event) {
    this.currentPage = event;
    this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
  }

  addItemsPerPage(event) {
    this.itemsPerPage = event;
    this.currentPage = 1;
    this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
  }

  // ------ search bar method ----------- //

  findName(event) {
    this.searchName = event;
    if (this.searchName != '') {
      this.filters['name'] = this.searchName;
      this.currentPage = 1;
      this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
    }
  }
  hideCross(event) {
    // this.filters['name'] ;
    delete this.filters.name
    this.searchName = '';
    this.currentPage = 1
    this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)

  }


  // ------- type method ---------// 


  getTypes() {
    this.types = [];
    this.adminService.getAllProductFeatureType().subscribe((data) => {
      this.types = data.featureTypes;
      console.log(this.types)
      sessionStorage.setItem('types',JSON.stringify(this.types))
    });
    console.log(this.types)
  }

  onSelectType(event) {
    this.currentPage = 1;
    if (event.target.value != '') {
      this.filters['type'] = event.target.value
      this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
    } else {
      // this.filters['type'] = null;
      delete this.filters.type
      this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort)
    }
  }

  async onSelectSort(event) {
    this.sort[0] = this.fields
    console.log(this.sort)

    if ((this.router.url.includes('categoryfeatures'))) {
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING')) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName = "productFeatures.name"
        console.log(this.sort[0].fieldName)
        console.log(this.sort)
      } else {
        // this.sort[0].sortDirection = await null
        delete this.sort[0].sortDirection

      }

    }
    else if (this.router.url.includes("productfeatures")) {
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING')) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName = "features.name"
        console.log(this.sort[0].fieldName)
        console.log(this.sort)
      } else {
        // this.sort[0].sortDirection = await null
        delete this.sort[0].sortDirection

      }
    }

    this.getAllFeaturesByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  }

  onClickFeature(feature, j) {
    for (let i = 0; i < this.types.length; i++) {
      if (this.types[i].code == feature.type) {
        sessionStorage.setItem('currentType',JSON.stringify(this.types[i]))
      }
    }
    this.router.navigateByUrl('/' + this.featureDetails + '/' + this.categoryId + '/' + feature.id + '/' + j + '/' + feature.name + '/' + this.editFeature + '/' + this.categoryId + '/' + feature.id + '/' + j)
  }


}
