import { Routes } from '@angular/router';
import { UserLoginComponent } from './user-login/user-login.component';
import { AdminModule } from '../admin/admin.module';
import { AdminHomeComponent } from '../admin/admin-home/admin-home.component';
import { ProductCategoryComponent } from '../admin/manage-products/categories/category-details/product-category/product-category.component';
import { CategoriesComponent } from '../admin/manage-products/categories/categories.component';

export const userAuthRoute : Routes = [
    {path: 'userlogin', component: UserLoginComponent},
    {path: 'adminhome', component: AdminHomeComponent,
        children:[
            {path: "productcategories", component: CategoriesComponent}
        ]},
];