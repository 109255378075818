import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BucketUrl } from 'src/app/bucket';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { AdminService } from '../../admin.service';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { FiltersConstant } from 'src/app/shared/constants/filters-constant';
import { CategoryFilters } from 'src/app/shared/constants/filter-model/category-filters.model';
import { TranslateService } from '@ngx-translate/core';
import { async } from '@angular/core/testing';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit{
  
  categories: Array<any> = [];
  cato : Observable<any[]>;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  myBucketUrl: any;
  currentCategory: any;
  bucketUrl: BucketUrl;
  subscription: Subscription;
  alternateImage: any = null;
  forImage : boolean = false;
  showNoData: Boolean = false;
  breadCrumbs:any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  numberOfPages :number=1;
  pagination: any;
  types: Array<any> = [];
  searchName: string;
  filters: any = CategoryFilters;
  sort: Array<any> = [];
  fields: any = {

  fieldName: '',
  sortDirection: ''
};
  // sort:Array<any> = sortConstant.CATEGORY_SORT;
  
  constructor(private sanitizer: DomSanitizer,
    private adminService: AdminService,
    private router: Router,
    private noDataService: NoDataService,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private messageService: MessageSubjectService) {
      this.bucketUrl = new BucketUrl();
      this.filters = new CategoryFilters();
    }
    
    
    ngOnInit() {
      this.adminService.ngOnInit();
      this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      this.messageService.broadcast('primary',
      {key: "Products", labelName: "Categories", path: "productcategories",
      alternativeKey: 'Products',
      terminalOnly: false,
      afterBaseOnly: false,
      pathParamList: [],
      queryParams: undefined,
      fragment:undefined,
      child: []}
      )
      let parentId = null;
      this.myBucketUrl = this.bucketUrl.getBucketUrl();
      this.getAllCategory();
      
      this.getCategoryTypes()
    }
    // 
    getAllCategory() {
      let parentId = null;
      this.adminService.getChildCategories(parentId).subscribe((data) => {
        this.totalItems = data.categories.length;
      });
    }
    
    getAllCategoryByQuery(filters,itemsPerPage,currentPage,sort) {
      // this.categories = [];
      this.allCheck = false;
      // this.pagination = null;
      console.log(this.categories)
      this.adminService.getCategoriesByQuery(filters,itemsPerPage,currentPage,sort).subscribe((data) =>{
        console.log(data);
        console.log('catgeories')
        this.categories = data.categories;
        console.log(data.categories)
        this.pagination = data.pagination;
        console.log(this.categories)
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages =this.pagination.numberOfPages ;
        this.showNoData = this.noDataService.noData(this.categories);
        // this.getAllCategory()
        this.getBackgroundImage(this.categories)
      });
    }

   
    
    getBackgroundImage(categories) {
      if(categories.length > 0) {
        for (let i = 0; i < categories.length; i++) {
          categories[i].imageRef = this.getBackground(categories[i].imageRef);
          this.checkboxItems[i] = false;
        }
      }
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.categories.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.categories.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    // multiple delete method
   async delete() {
      
      this.openModal();
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe(async(res) => {
        if (res == "yes") {
          this.closeModal();
          let categoryIds = []
          for (let i = 0; i < this.categories.length; i++) {
            if (this.checkboxItems[i] == true) {
            await categoryIds.push(this.categories[i].id)
             
            }
          }
          await this.adminService.deleteCategoryById(categoryIds).subscribe((data) => {
            this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
          })
         
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal()
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
    
    // single delete method
    deleteSingle(id) {
      
      this.openModal();
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.closeModal();
          this.adminService.deleteCategoryById(id).subscribe((data) => {
            
            // this.getAllCategory();
            this.subscription.unsubscribe();
            this.deleteDialogService.clearResponse();
          })
        } else if (res == "no") {
          this.closeModal()
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
    openModal() {
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    closeModal() {
      this.deleteDialogService.closeDialog();
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    
    setup(i) {
      // this.router.navigate(['/categorydetails/'+this.categories[i].id+'/'+this.categories[i].name+'/categoryproduct/'+this.categories[i].id])
      //  this.anotherMethod(i);
      this.getAllProducts(this.categories[i]);
    }
    
    getAllProducts(category) {
      let categoryId = category.id;
      let categoryName = category.name
      this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
        if(data.products.length == 0){
          if(category['parentId'] != null) {
            this.router.navigate(['/subcategorydetails/'+ categoryId + '/' + categoryName + '/subcategory/' + categoryId ]);
            
          }else {
            this.router.navigate(['/categorydetails/'+ categoryId + '/' + categoryName + '/subcategory/' + categoryId ]);
          }
        }else {
          if(category['parentId'] != null) {
            this.router.navigate(['/subcategorydetails/'+ categoryId + '/' + categoryName + '/categoryproduct/' + categoryId ]);
            
          }else {
            this.router.navigate(['/categorydetails/'+ categoryId + '/' + categoryName + '/categoryproduct/' + categoryId ]);
          }
        }
      });
    }
    
    // ------- pagination method --------- //
    
    onChangePage(event) {
      this.currentPage = event;
      this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    
    // ------ search bar method ----------- //
    
    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['name'] = this.searchName;  
        this.currentPage = 1;
        console.log(this.filters)
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }
    hideCross(event) {
      // this.filters['name'] ;
      delete this.filters.name;
      this.searchName = '';
      this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    
    // ------- type method ---------// 
    
    
    getCategoryTypes() {
      this.adminService.getAllCategoryTypes().subscribe((data) => {
        this.types = data.categoryTypes;
      })
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);   
      }else {
        // this.filters['type'] = '';
        delete  this.filters.type;
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);   
      }
      console.log(this.filters)
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='name'
        console.log(this.sort)
      } else{
       delete this.sort
      }
  
      this.getAllCategoryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
    
    
  }