import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup } from '@angular/forms';
import { v4 as uuid } from 'uuid';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BucketUrl } from 'src/app/bucket';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { StoreService } from 'src/app/admin/product-stores/stores/services/store.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
const cleanDeep = require('src/app/shared/library/deep-clean');

@Component({
  selector: 'app-edit-catalog',
  templateUrl: './edit-catalog.component.html',
  styleUrls: ['./edit-catalog.component.css']
})
export class EditCatalogComponent implements OnInit {
  catalogId: string;
  catalog: any;
  catalogTypes: any[];
  uid: any;
  user: any;
  editCatalogForm: any;
  catalogFields: any[];
  value: Array<any> = [];
  convertedTypeField: Array<any> = [];
  catalogType: any;
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  myBucketUrl: any;
  currentFields: any;
  catOptions: Array<Boolean> = [];
  catalogOptions: Array<any> = []
  fields: any;
  subscribe: Subscription;
  currentImageRef: any;
  previousImageRef: any;
  bucketUrl: BucketUrl;
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  storeDetails: any;
  lastIndexOfMultipleArray: Array<any> = [];
  documentArray: Array<Boolean> = [];
  currentImageRefForField: Array<any> = [];
  previousImageRefForField: Array<any> = [];
  selectedFilesForFields: Array<any> = [];
  restrictedFromDate:any
  restrictedThroughDate:any

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private backgroundImageService: BackgroundImageService,
    private storeService: StoreService,
    private messageService: MessageSubjectService,
    private dateValidations: DateValidationsService) {
    this.bucketUrl = new BucketUrl();
  }


  selectedFiles: FileList;

  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem("user"));

    this.myBucketUrl = this.bucketUrl.getBucketUrl();

    this.getAllCatalogTypes();

    this.getCatalog();
    // this.getCatalogFieldByType()
  }



  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }


  getCatalog() {
    this.route.paramMap.subscribe((map) => {
      this.catalogId = map.get('catalogId');
      this.adminService.getCatalogById(this.catalogId).subscribe((data) => {
        this.catalog = data.catalogs[0];
        if (data['catalogs'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "catalogdetails",
            alternativeKey: 'catalogdetails',
            labelName: 'Catalog : ' + data['catalogs'][0].name,
            path: this.router.url
          });
        }
        // console.log(this.catalog)
        this.catalogType = this.catalog.type;

        this.model();
        if (this.catalog['storeCode']) {
          this.storeService.getStoreByCode(this.catalog.storeCode).subscribe((data) => {
            this.storeDetails = data.stores[0];
            // console.log(this.storeDetails)
          })
        }
        this.previousImageRef = this.catalog.imageRef;
        this.getCatalogFieldByType()
      });
    });

  }

  model() {
    this.editCatalogForm = {
      name: this.catalog.name,
      description: this.catalog.description,
      imageRef: this.catalog.imageRef,
      type: this.catalog.type,
      storeCode: this.catalog.storeCode,
      fromDate: this.catalog.fromDate,
      throughDate: this.catalog.throughDate,
      modifiedBy: this.user.name,
      fields: this.catalog.fields
    };
  }

  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  resetForm() {
    this.openResetModal("dialogBox");
    this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.currentImageRef = '';
        this.selectedFiles = null;
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.getAllCatalogTypes();
        this.getCatalog();
        this.closeResetModal("dialogBox")
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  openResetModal(id: string) {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }

  closeResetModal(id: string) {
    this.deleteDialogService.closeDialog();
  }

  optionFieldsCondition(catalogFields) {
    for (let i = 0; i < catalogFields.length; i++) {
      if (this.catalogFields[i].type == "DOCUMENT") {
        this.documentArray[i] = true;
      } else {
        this.documentArray[i] = false;
      }
      this.selectedFilesForFields[i] = null;
      this.currentImageRefForField[i] = null;
      this.previousImageRefForField[i] = null;
      if (catalogFields[i].options) {
        if ((catalogFields[i].options.length == 1) || (catalogFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      if (catalogFields[i].list) {
        this.listFlag[i] = true;
      } else {
        this.listFlag[i] = false;
      }
      //console.log(this.catOptions)
      if (catalogFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = catalogFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
      //console.log(environment.typeConversion[type])
      //console.log(this.convertedTypeField)

    }
  }

  selectCode(event) {
    this.currentFields = event.target.value;
    this.adminService.getCatalogFieldsByCatalogTypeCode(this.currentFields).subscribe((data) => {
      this.catalogFields = data.fields;

      this.optionFieldsCondition(this.catalogFields);
      for (let i = 0; i < this.catalogFields.length; i++) {
        this.multipleArray[i] = new Array<any>();
        this.listArray[i] = new Array<any>();
        this.lastIndexOfMultipleArray[i] = new Array<any>();
        this.multipleArray[i].push({
          listValue: ''
        })
        if (this.catalog.type == this.currentFields) {
          this.previousImageRefForField[i] = this.catalog.fields[this.catalogFields[i].key];
          if (this.catalogFields[i].list) {
            for (const key of Object.keys(this.catalog.fields)) {
              if (this.catalogFields[i].key == key) {
                if ((this.catalog.fields[this.catalogFields[i].key].length != 0)) {
                  for (let j = 0; j < this.catalog.fields[this.catalogFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.catalog.fields[this.catalogFields[i].key][j]
                    })
                  }
                }
              }
            }
          }
          else {
            this.value[i] = (this.catalog.fields[this.catalogFields[i].key])
            this.listFlag[i] = false;
          }
        }
        else {
          this.value[i] = ""
          this.editCatalogForm.fields = {}
          this.multipleArray[i].push({
            listValue: ''
          })
        }
        this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
      }

    });
  }

  getAllCatalogTypes() {
    this.adminService.getAllCatalogType().subscribe((data) => {
      this.catalogTypes = data.catalogTypes;
    })
  }

  getCatalogFieldByType() {
    //console.log(this.catalogType)
    this.adminService.getCatalogFieldsByCatalogTypeCode(this.catalogType).subscribe((data) => {
      //console.log(data);
      this.catalogFields = data.fields;

      this.optionFieldsCondition(this.catalogFields);

      if (this.catalogFields != null) {
        for (let i = 0; i < this.catalogFields.length; i++) {
          this.previousImageRefForField[i] = this.catalog.fields[this.catalogFields[i].key];
          this.multipleArray[i] = new Array<any>()
          this.listArray[i] = new Array<any>()
          this.multipleArray[i].push({
            listValue: ''
          })
          if (this.catalogFields[i].list) {
            for (const key of Object.keys(this.catalog.fields)) {
              if (this.catalogFields[i].key == key) {
                if ((this.catalog.fields[this.catalogFields[i].key].length != 0)) {
                  for (let j = 0; j < this.catalog.fields[this.catalogFields[i].key].length; j++) {
                    this.listFlag[i] = true;
                    this.multipleArray[i][j] = ({
                      listValue: this.catalog.fields[this.catalogFields[i].key][j]
                    })
                    // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                  }
                }
              }
            }
          }
          else {
            this.listFlag[i] = false;
            if (this.catalogFields[i].type == "BOOLEAN") {
              if (this.catalog.fields[this.catalogFields[i].key] != true) {
                this.value[i] = false;
              } else {
                this.value[i] = (this.catalog.fields[this.catalogFields[i].key])
              }
            } else {
              this.value[i] = (this.catalog.fields[this.catalogFields[i].key])
            }
          }
        }
      }
    });
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }

  selectFile(event, isField, indexOfField) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    if (isField) {
      this.selectedFilesForFields[indexOfField] = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRefForField[indexOfField] = reader.result;
      }
    } else {
      this.selectedFiles = event.target.files;
      reader.onload = (_event) => {
        this.currentImageRef = reader.result;
      }
    }
  }

  resetImage(isField, indexOfField) {
    if (isField) {
      this.selectedFilesForFields[indexOfField] = null;
      this.currentImageRefForField[indexOfField] = '';
      this.previousImageRefForField[indexOfField] = '';
      this.editCatalogForm.fields[this.catalogFields[indexOfField].key] = '';
      this.catalog.fields[this.catalogFields[indexOfField].key] = ''
      this.value[indexOfField] = ''
    } else {
      this.currentImageRef = '';
      this.selectedFiles = null;
      this.previousImageRef = '';
      this.editCatalogForm.imageRef = '';
    }
  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.editCatalogForm)
  }

  async onSubmit() {
    this.validateFromThroughDate()
    if (this.catalogFields != null) {
      this.fields = {};
      for (let i = 0; i < this.catalogFields.length; i++) {
        let fieldName = this.catalogFields[i].key;
        if (this.catalogFields[i].type == "INTEGER") {
          this.editCatalogForm.fields[fieldName] = parseInt(this.value[i])
        } else if (this.catalogFields[i].type == "DECIMAL") {
          this.editCatalogForm.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editCatalogForm.fields[fieldName] = this.value[i];
        }
        if (this.catalogFields[i].list) {
          for (let j = 0; j < this.multipleArray.length; j++) {
            if ((this.multipleArray[j] != null) && (this.multipleArray[j].length > 0)) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.catalogFields[i].type == "INTEGER") {
              this.editCatalogForm.fields[this.catalogFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.catalogFields[i].type == "DECIMAL") {
              this.editCatalogForm.fields[this.catalogFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.editCatalogForm.fields[this.catalogFields[i].key] = this.listArray[i]
            }
          }
          else {
            this.editCatalogForm.fields[this.catalogFields[i].key] = []
          }
        }
      }
    }

    await this.uploadImageRef();
    if (this.selectedFilesForFields != null && this.selectedFilesForFields.length > 0) {
      for (let index = 0; index < this.selectedFilesForFields.length; index++) {
        if (this.selectedFilesForFields[index] != null) {
          const file = this.selectedFilesForFields[index].item(0);
          if (this.editCatalogForm.fields[this.catalogFields[index].key] != undefined) {
            if (this.editCatalogForm.fields[this.catalogFields[index].key] != "") {
              let data = await this.callServiceToEditImage(file, this.editCatalogForm.fields[this.catalogFields[index].key]);
              if (index == this.selectedFilesForFields.length - 1) {
                this.updateCatalog();
              }
            } else {
              let data = await this.callServiceToUploadImage(file);
              this.editCatalogForm.fields[this.catalogFields[index].key] = data.documentId;
              if (index == this.selectedFilesForFields.length - 1) {
                this.updateCatalog();
              }
            }
          } else {
            let data = await this.callServiceToUploadImage(file);
            this.editCatalogForm.fields[this.catalogFields[index].key] = data.documentId;
            if (index == this.selectedFilesForFields.length - 1) {
              this.updateCatalog();
            }
          }
        } else {
          if (index == this.selectedFilesForFields.length - 1) {
            this.updateCatalog();
          }
        }
      }
    } else {
      this.updateCatalog();
    }

  }

  async uploadImageRef() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.editCatalogForm.imageRef != undefined) {
        if ((this.editCatalogForm.imageRef != "")) {
          let data = await this.callServiceToEditImage(file, this.editCatalogForm.imageRef)
        } else {
          let data = await this.callServiceToUploadImage(file);
          this.editCatalogForm.imageRef = data.documentId;
        }
      } else {
        let data = await this.callServiceToUploadImage(file);
        this.editCatalogForm.imageRef = data.documentId;
      }
    }
  }

  async callServiceToEditImage(file: any, imageRef: any): Promise<any> {
    return this.imageUploadService.editImage(file, imageRef).toPromise();
  }

  async callServiceToUploadImage(file: any): Promise<any> {
    return this.imageUploadService.uploadImage(file).toPromise();
  }

  updateCatalog() {
    let catalog = this.editCatalogForm
    catalog.fields = cleanDeep(this.editCatalogForm.fields)
    this.adminService.updateCatalog(this.catalogId, catalog).subscribe((data) => {
      if (data['success']) {
        if (this.selectedFiles != null || this.selectedFilesForFields != null) {
          let timestamp = new Date();
          sessionStorage.setItem('timeStamp', JSON.stringify(timestamp));
        }
      }
      if (this.storeDetails['parentCode'] == null) {
        this.router.navigate(["/regiondetails/" + this.catalog.storeCode + "/" + this.catalog.storeCode + "/catalogs/" + this.catalog.storeCode]);
      } else {
        this.messageService.broadcast('splice', {
          key: 'catalogdetails',
          alternativeKey: 'catalogdetails'
        })
        this.router.navigate(["/subregiondetails/" + this.catalog.storeCode + "/" + this.catalog.storeCode + "/catalogs/" + this.catalog.storeCode]);
      }
    });
  }

  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }

}
