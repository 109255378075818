import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/directives/modal.sevice';
import { AdminService } from 'src/app/admin/admin.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, NgForm } from '@angular/forms';
import { PromotionServiceService } from '../promotions-service/promotion-service.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { Action } from '../promotion-model/promotion';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import { LoaderService } from 'src/app/shared/loader/loader.service';
const cleanDeep = require('clean-deep');
@Component({
  selector: 'app-add-promotions',
  templateUrl: './add-promotions.component.html',
  styleUrls: ['./add-promotions.component.css']
})
export class AddPromotionsComponent implements OnInit {

  products: any[];
  catalogId: string;
  catalog: any;
  categories: Array<any> = [];
  catalogProducts: any[];
  productFlagArray: Array<boolean> = [];
  catalogFlagArray: Array<boolean> = [];
  myBucketUrl: any;
  catalogProductIds: Array<any> = [];
  noProductOnCategoryFlag: boolean = false;
  allCategories: Array<any> = [];
  dropdownElements: Array<any> = [];
  value: any;
  promotions: any;
  selectedFiles: FileList;
  actionsForm: any;
  actionProductId: any;
  selectedProduct: any;
  selectCategory: any;
  promotionResponse: any;
  typeValue: any;
  catalogs: any;
  actionCatalogId: any;
  selectedCatalog: any;
  actionClass: Action;
  actionTypeKeys: Array<any> = []
  actionTypeValues: Array<any> = []
  actionTypeValue: any;
  showOtherField: Boolean = false;
  showAmount: Boolean = false;
  showValue: Boolean = false;
  conditionForm: any;
  conditions: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  promoParameterKeys: Array<any> = []
  promoParameterValues: Array<any> = []
  promoOperationKeys: Array<any> = [];
  promoOperationValues: Array<any> = [];
  promoParameterData: Array<any> = [];
  promoOperatorData: Array<any> = [];
  subscription: Subscription;
  condition: any;
  editConditionForm: any;
  conditionModelArray: Array<any> = []
  currentImageRef: any;
  restrictedFromDate: any
  restrictedThroughDate: any

  user: any;
  editConditionValue: any;
  index: any;
  modalReference: any;
  breadCrumbs: any;
  prodId: any
  isTenantOO :Boolean=false

  @ViewChild('addProduct', { static: true }) public addProduct: ElementRef;
  @ViewChild('addFormConditions', { static: true }) public addFormConditions: NgForm;


  constructor(private modalService: ModalService,
    private promotionService: PromotionServiceService,
    private adminService: AdminService,
    private imageUploadService: ImageUploadService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private nestedCategoryService: NestedCategoriesService,
    private codeConversionService: AutoNameCodeGeneratorService,
    private ngbModalService: NgbModal,
    private messageService: MessageSubjectService,
    public translate: TranslateService,
    private dateValidations: DateValidationsService,
    private loaderService: LoaderService,) { }

  @ViewChild('addPromotionsForm', { static: false }) addPromotionsForm: NgForm;

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if(this.user.tenant=="Order Orchestration"){
      this.isTenantOO=true
      this.messageService.broadcast('bcLabelOverwrite', {
        key: "addpromotion",
        alternativeKey: 'addpromotion',
        labelName: 'Add Order Rule',
        path: this.router.url,
        child: []
      });
    }else{
      this.messageService.broadcast('bcLabelOverwrite', {
        key: "addpromotion",
        alternativeKey: 'addpromotion',
        labelName: 'Add Promotion',
        path: this.router.url,
        child: []
      });
    }
    this.promotionModel()
    this.actionModel()
    this.conditionModel()
    this.actionClass = new Action();
    this.actionTypeKeys = this.actionClass.getActionTypeKeys();
    this.actionTypeValues = this.actionClass.getActionTypeValues();
    this.promoParameterKeys = this.actionClass.getPromoParameterKeys()
    this.promoParameterValues = this.actionClass.getPromoParameterValues()
    this.promoOperationKeys = this.actionClass.getPromoConditionOperatorKeys();
    this.promoOperationValues = this.actionClass.getPromoConditionOperatorValues()

    this.editConditionValueModal();
  }


  /*
   * in ngModal we have to declared before use so for editCondition this mehtod requires to avoid errors
   */
  editConditionValueModal() {
    this.editConditionValue = { 'parameter': "" };
    this.editConditionModel();
  }

  fillCode(event) {
    this.promotions.code = this.codeConversionService.generatePromotionCode(event.target.value);
  }

  promotionModel() {
    this.promotions = {
      name: '',
      code: '',
      description: '',
      imageRef: '',
      fromDate: '',
      throughDate: '',
      createdBy: this.user.name,
      useLimitPerCustomer: 0,
      useLimitPerCode: 0,
      conditions: [],
      action: {}

    }
  }

  openModal(id) {
    this.modalService.open(id);
  }

  closeModal(id) {
    this.modalService.close(id);
  }

  public conditionsArray: any[] = [{
    parameter: '',
    conditionOperator: '',
    conditionValue: '',
    createdBy: '',
  }]

  addMoreConditions() {
    this.conditionsArray.push({
      parameter: '',
      conditionOperator: '',
      conditionValue: '',
      createdBy: this.user.name,
    });
  }

  removeConditions(i) {
    this.conditionsArray.splice(i, 1);
  }






  openModel(id) {
    // this.modalService.open(id);
    this.modalReference = this.ngbModalService.open(this.addProduct, { size: 'xl', windowClass: 'add-product-modal', backdropClass: 'dark-backdrop' });
    this.getAllCategory()
    this.getAllCatalogs()
  }

  closeModel(id) {
    // this.modalService.close(id)
    this.typeValue = ''
    this.catalogs = [];
    this.products = [];
    this.modalReference.dismiss();
  }

  onSelectType(event) {
    this.typeValue = event.target.value;
    // //console.log(this.typeValue);
    if (this.typeValue == 'Product') {
      this.products = []
    }
    if (this.typeValue == "Catalog") {
      this.getAllCatalogs();
    }
  }

  actionModel() {
    this.actionsForm = {
      type: '',
      quantity: '',
      amount: '',
      value:'',
      productId: '',
      catalogId: ''
    }
  }

  getAllCategory() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;

      this.dropdownElements = this.nestedCategoryService.getNestedProductCategories(this.categories);
    });
  }

  getAllCatalogs() {
    this.adminService.getAllCatalog().subscribe((data) => {
      this.catalogs = data.catalogs
      this.filterCatlog()
    });
  }



  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  onSelectCategory(event) {
    var selectElement = event.target;
    var categoryId = selectElement.value;

    this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
      this.products = data.products;

      if (this.products.length == 0) {
        this.noProductOnCategoryFlag = true;
      } else {
        this.noProductOnCategoryFlag = false;
      }
      this.filterProductInCategory()

    })
  }

  filterProductInCategory() {
    for (let i = 0; i < this.products.length; i++) {
      if (this.selectedProduct != null) {
        if (this.selectedProduct.id.includes(this.products[i].id)) {
          this.productFlagArray[i] = true;
        } else {
          this.productFlagArray[i] = false;
        }
      }
    }
  }

  filterCatlog() {
    for (let i = 0; i < this.catalogs.length; i++) {
      if (this.selectedProduct != null) {
        if (this.selectedProduct.id.includes(this.catalogs[i].id)) {
          this.catalogFlagArray[i] = true;
          // //console.log(this.productFlagArray)
        } else {
          this.catalogFlagArray[i] = false;
        }
      }
    }
  }

  addProductInAction(productId, id) {
    if (this.typeValue == "Product") {

      this.actionProductId = productId;

      this.adminService.getProductById(this.actionProductId).subscribe((data) => {
        this.selectedProduct = data.products[0];

        this.adminService.getCategoryById(this.selectedProduct.categoryId).subscribe((data) => {
          this.selectCategory = data.categories[0];
          this.closeModel(id);
          this.filterProductInCategory()
        })
      })
      this.actionsForm.productId = this.actionProductId;
    } else if (this.typeValue == "Catalog") {

      this.actionCatalogId = productId;
      this.adminService.getCatalogById(this.actionCatalogId).subscribe((data) => {
        this.selectedProduct = data.catalogs[0];
        this.closeModel(id);
        this.filterCatlog();

      });
      this.actionsForm.catalogId = this.actionCatalogId;
    }

  }

  actionType(event) {
    this.actionTypeValue = event.target.value;
    // this.actionTypeConditions(this.actionTypeValue)
    if (this.actionTypeValue == "PROMO_ORDER_PERCENT") {
      this.showAmount = true;
      this.showOtherField = false;
      this.actionsForm = {
        type: this.actionsForm.type
      }
      this.selectedProduct = null
    }
    else if (this.actionTypeValue == "PROMO_GWP") {
      this.showAmount = false;
      this.showOtherField = true;
      this.actionsForm = {
        type: this.actionsForm.type
      }
    }
    else if (this.actionTypeValue == "PROMO_ROUTE_TO") {
      this.showValue = true;
      this.showOtherField = false;
      this.actionsForm = {
        type: this.actionsForm.type
      }
    }
    else {
      this.actionsForm = {}
      this.showAmount = false
      this.showOtherField = false;
      this.selectedProduct = null

    }
  }



  conditionModel() {
    this.conditionForm = {
      parameter: '',
      conditionOperator: '',
      conditionValue: '',
      createdBy: this.user.name
    }
  }

  openConditionModal(id) {
    this.addFormConditions.resetForm()
    this.modalService.open(id);
    this.conditionModel();
  }
  closeConditionModal(id) {
    this.modalService.close(id);
  }

  addCondition(id) {
    this.conditionModelArray.push(this.conditionForm);
    this.modalService.close(id);
    this.conditionModel();
    this.getAllConditions()
  }

  getAllConditions() {
    for (let i = 0; i < this.conditionModelArray.length; i++) {
      this.checkboxItems[i] = false;
      for (let j = 0; j < this.promoParameterKeys.length; j++) {
        if (this.conditionModelArray[i].parameter == this.promoParameterKeys[j]) {
          this.promoParameterData[i] = (this.promoParameterValues[j])
        }
      }

      for (let k = 0; k < this.promoOperationKeys.length; k++) {
        if (this.conditionModelArray[i].conditionOperator == this.promoOperationKeys[k]) {
          this.promoOperatorData[i] = (this.promoOperationValues[k])
        }
      }
    }
  }

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.conditions.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }

  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.conditions.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }

  deleteSingle(id) {
    this.openResetModal("delete");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");

        this.conditionModelArray.splice(id, 1)

        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  // opendeleteModal(id) {
  //   this.modalService.open(id);
  // }

  // delete() {
  //   let conditionIds: Array<any> = [];
  //   this.opendeleteModal("dialogBox");
  //   this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
  //     if (res == "yes") {
  //       for (let i = 0; i < this.conditions.length; i++) {
  //         if (this.checkboxItems[i] == true) {
  //           conditionIds.push(this.conditions[i].id);
  //         }
  //       }
  //       this.promotionService.deleteConditionById(this.promotionResponse.promotionId, conditionIds).subscribe((data) => {
  //         this.closeModel("dialogBox");
  //         this.getAllConditions()
  //       })
  //       this.subscription.unsubscribe();
  //       this.deleteDialogService.clearResponse();

  //     } else if (res == "no") {
  //       this.closeModel("dialogBox")
  //       this.subscription.unsubscribe();
  //       this.deleteDialogService.clearResponse();
  //     }
  //   })
  // }

  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.selectedFiles = null;
    this.currentImageRef = "";
  }



  openEditConditionModal(conditionIndex, id) {
    this.index = conditionIndex;
    this.modalService.open(id);

    this.editConditionValue = this.conditionModelArray[conditionIndex];
    this.editConditionModel();
  }

  closeEditConditionModal(id) {
    this.modalService.close(id);
  }



  editConditionModel() {
    this.editConditionForm = {
      parameter: this.editConditionValue.parameter,
      conditionOperator: this.editConditionValue.conditionOperator,
      conditionValue: this.editConditionValue.conditionValue,
      createdBy: this.user.name
    }
  }

  editCondition(id) {

    this.conditionModelArray[this.index] = this.editConditionForm;
    // //console.log(this.conditionModelArray)
    this.getAllConditions();

    this.modalService.close(id)
  }



  resetForm() {
    this.openResetModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");
        this.selectedProduct = '';
        this.showAmount = false;
        this.showOtherField = false;
        this.ngOnInit();
        this.conditionModelArray = []
        this.selectedFiles = null;
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.currentImageRef = "";
        this.addPromotionsForm.resetForm()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.promotions)
  }
  addPromos() {
    
    this.validateFromThroughDate()
    if (this.actionsForm.type == "" && this.actionsForm.quantity == "" && this.actionsForm.amount == "" && this.actionsForm.value == "" && this.actionsForm.productId == "" && this.actionsForm.catalogId == "") {
      this.promotions.action = {}
    }
    else {
      this.promotions.action.type = this.actionsForm.type;
      this.promotions.action = this.actionsForm;
    }

    this.promotions.conditions = this.conditionModelArray;

    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.promotions.imageRef = data.documentId;
        
        this.addPromosServiceCall();
        
      })
    } else {
      this.addPromosServiceCall();
    }
  }

  addPromosServiceCall() {
    this.promotionService.createPromotion(this.promotions).subscribe((data) => {
      this.promotionResponse = data;
      this.messageService.broadcast('splice', {
        key: 'addpromotion',
        alternativeKey: 'addpromotion'
      });
      this.router.navigate(['/marketingdetails/promotions']);
    });
  }


  openResetModal(id) {
    let data = {};
    data = {
      value: 'reset'
    };
    if (id == 'delete') {
      data['value'] = id;
    }
    this.deleteDialogService.openDialog(data);
  }
  closeResetModal(id) {
    this.deleteDialogService.closeDialog();
  }
  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }

}
