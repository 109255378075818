import { Injectable } from '@angular/core';
import { Observable,Subject, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from './delete-dialog.component';
import { ClaimDialogComponent } from '../claim-dialog/claim-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DeleteDialogService {

  public isDialogOpen: Boolean = false;
  constructor(public dialog: MatDialog) { }
  openDialog(data): any {
    console.log("data",data)
      if (this.isDialogOpen) {

          return false;
      }
      this.isDialogOpen = true;
      let dialogRef;
      if(data.component=='ClaimDialogComponent'){
         dialogRef = this.dialog.open(ClaimDialogComponent, {
          width: '400px',
          data: data
      });

      }else{
         dialogRef = this.dialog.open(DeleteDialogComponent, {
          width: '400px',
          data: data
      });
      }
     
      dialogRef.afterClosed().subscribe(result => {
          // //console.log('The dialog was closed');
          this.isDialogOpen = false;
          let animal;
          animal = result;
      });
  }

  closeDialog(){
    this.dialog.closeAll();
  }



  public customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();

  public sentToDialog:BehaviorSubject<any> = new BehaviorSubject<any>(""); 
  public receiveFromDialog:BehaviorSubject<any> = new BehaviorSubject<any>("");


  // Service message commands
  callComponentMethod(value:any) {
    this.sentToDialog.next(value);
  }

  getResponseComponentMethod(value:any){
    this.receiveFromDialog.next(value);
  }

  clearResponse(){
    this.receiveFromDialog.next("");
  }
}
