import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { BucketUrl } from 'src/app/bucket';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';


@Component({
  selector: 'app-feature-option-ref',
  templateUrl: './feature-option-ref.component.html',
  styleUrls: ['./feature-option-ref.component.css']
})
export class FeatureOptionRefComponent implements OnInit {

  featureId: any;
  featureOption: any;
  selectedFiles: FileList;
  features: any[];
  id: any;
  price: any;
  currentFeature: any;
  uid: any;
  featureOptions: Array<any> = [];
  myBucketUrl: any;
  currentFeatureOption: any;
  updateFeatureOption: any;
  basePrice: any;
  featureOptionIndex: any;
  cIndex: any;
  featureRefService: any;
  optionFeatureLink: any;

  currentImageRef: any;
  previousImageRef: any;
  bucketUrl: BucketUrl;
  productIds: Array<any> = [];
  featureOptionProducts: Array<any> = [];
  user: any;
  editFeature: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public imageUploadService: ImageUploadService,
    public ngbModalService: NgbModal,
    public sanitizer: DomSanitizer,
    public http: HttpClient,
    public backgroundImageService: BackgroundImageService,
    public adminService: AdminService,
    public nestedCategory: NestedCategoryService,
    public messageService: MessageSubjectService) {
    this.bucketUrl = new BucketUrl();
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    this.route.paramMap.subscribe((map) => {
      this.featureId = map.get('featureId');
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.id = map.get('id');
      this.cIndex = map.get('index');
    });
  }

  ngOnInit() {

  }

  getFeature() {

  }

  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.selectedFiles = null;
    this.currentImageRef = "";
    this.previousImageRef = '';
  }

  editFeatureModel: any;

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  // new code

  openProductModal(content) {
    this.getAllCategoryInStore()
    this.ngbModalService.open(content, { size: 'lg', windowClass: 'add-ons-modal' });
  }

  closeProductModal(content) {
    this.ngbModalService.dismissAll(content);
    this.renderCategoryInStore = [];
    this.productsInCategory = []
  }
  categories: Array<any> = [];
  renderCategoryInStore: Array<any> = [];
  categoryId: any;
  productsInCategory: Array<any> = [];
  checkboxItemsProduct: Array<boolean> = [];
  allCheckProduct: boolean = false;
  myValueProduct: boolean = false;
  deleteProductArray: Array<any> = [];
  addProductsArray: Array<any> = [];

  getAllCategoryInStore() {
    this.adminService.getAllCategory().subscribe((data) => {
      this.categories = data.categories;
      this.renderCategoryInStore = this.nestedCategory.getProductCategoryTree(this.categories);
      console.log(this.renderCategoryInStore);
    });
  }

  onSelectCategory(event) {
    this.categoryId = event.id;
    this.allCheckProduct = false;
    this.productsInCategory = []
    this.checkboxItemsProduct = []
    this.adminService.getAllCategoryProduct(this.categoryId).subscribe((data) => {
      this.productsInCategory = data.products;
      console.log('this.productsInCategory', this.productsInCategory)
      for (let i = 0; i < this.productsInCategory.length; i++) {
        this.productsInCategory[i].imageRef = this.getBackground(this.productsInCategory[i].imageRef);
        this.checkboxItemsProduct[i] = false;
        this.productsInCategory[i]['initial'] = "unchecked";
        this.productsInCategory[i]['current'] = "unchecked";
        this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['initial'];
      }
      this.getProductsAddedInFeature();
    });
  }

  getProductsAddedInFeature() {
    let checkAll = true;
    for (let i = 0; i < this.productsInCategory.length; i++) {
      for (let j = 0; j < this.currentFeature.options.length; j++) {
        if (this.productsInCategory[i].id == this.currentFeature.options[j].productId) {
          this.checkboxItemsProduct[i] = true;
          this.productsInCategory[i]['initial'] = "checked";
          this.productsInCategory[i]['current'] = "checked";
          this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['initial'];
        }
      }
      if (this.productsInCategory[i].initial == "unchecked") {
        checkAll = false;
      }
    }
    this.allCheckProduct = checkAll;
  }

  onChangeProduct(i, product, event) {
    this.checkboxItemsProduct[i] = !this.checkboxItemsProduct[i];
    console.log(this.checkboxItemsProduct[i])
    this.myValueProduct = true;
    for (let a = 0; a < this.productsInCategory.length; a++) {
      if (this.checkboxItemsProduct[a] == false) {
        this.myValueProduct = false;
        this.productsInCategory[a]['current'] = "unchecked"
        this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['inital'];
      }
    }
    if (this.checkboxItemsProduct[i]) {
      this.productsInCategory[i]['current'] = "checked"
      this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['inital'];

    } else {
      this.productsInCategory[i]['current'] = "unchecked"
      this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['inital'];
    }
    this.allCheckProduct = this.myValueProduct;

  }

  checkAllProduct() {
    this.allCheckProduct = !this.allCheckProduct
    for (let a = 0; a < this.productsInCategory.length; a++) {
      this.checkboxItemsProduct[a] = this.allCheckProduct;
      if (this.checkboxItemsProduct[a]) {
        this.productsInCategory[a]['current'] = "checked"
        this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['inital'];
      }
      else {
        this.productsInCategory[a]['current'] = "unchecked"
        this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['inital'];

      }

    }
  }

  addProductsInFeature(id) {
    this.deleteProductArray = [];
    this.addProductsArray = []
    console.log(this.productsInCategory)
    this.checkForAddAndDeleteOption(this.productsInCategory).then(res => this.callApiForAddAndDeleteOption(id))
  }

  checkForAddAndDeleteOption(productsInCategory) {
    console.log(productsInCategory)
    return new Promise((resolve, reject) => {
      for (let i = 0; i < this.productsInCategory.length; i++) {
        if ((this.productsInCategory[i].changed == true) && (this.productsInCategory[i].current == "unchecked") && (this.productsInCategory[i].initial == "checked")) {
          console.log("delete", i)
          this.deleteProductArray.push(this.productsInCategory[i].id)
        }
        else if ((this.productsInCategory[i].changed == true) && (this.productsInCategory[i].current == "checked") && (this.productsInCategory[i].initial == "unchecked")) {
          this.addProductsArray.push(this.productsInCategory[i].id);
          console.log("add", i)
        }
      }
      console.log(this.editFeatureModel);
      for (let i = 0; i < this.editFeatureModel.options.length; i++) {
        if (this.deleteProductArray.length > 0) {
          for (let j = 0; j < this.deleteProductArray.length; j++) {
            if (this.editFeatureModel.options[i].productId == this.deleteProductArray[j]) {
              this.editFeatureModel.options.splice(i, 1);
            }
          }
        }
      }
      console.log(this.editFeatureModel.options);

      resolve()
    });
  }

  callApiForAddAndDeleteOption(id) {
    for (let i = 0; i < this.productsInCategory.length; i++) {
      if (this.addProductsArray.includes(this.productsInCategory[i].id))
        // for(let i=0; i< this.addProductsArray.length; i++){
        this.editFeatureModel.options.push(
          {
            productId: this.productsInCategory[i].id,
            name: this.productsInCategory[i].name,
            modifiedBy: this.user.name,
            description: ' '
          }
        )
    }
    console.log('this.editFeatureModel',this.editFeatureModel)
    this.featureRefService.updateFeatureByFeatureId(this.id, this.featureId, this.editFeatureModel).subscribe((data) => {
      console.log(data)
      this.closeProductModal(id);
      this.getFeature();
    })
  }

  config = {
    hasSelect: false
  }
}
