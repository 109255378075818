import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PopupDeleteResetDialogService } from './popup-delete-reset-dialog.service';

@Component({
  selector: 'app-popup-delete-reset-dialog',
  templateUrl: './popup-delete-reset-dialog.component.html',
  styleUrls: ['./popup-delete-reset-dialog.component.css']
})
export class PopupDeleteResetDialogComponent {

  reason: any;
  status: any;
  title = 'Angular-Interceptor';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
  private popupService: PopupDeleteResetDialogService) {
    // //console.log(data)
    this.reason = 'delete';
    this.status = data;
  }

  onClickYes(){
    this.popupService.getResponseComponentMethod("yes");
  }

  onClickNo(){
    this.popupService.getResponseComponentMethod("no");
  }

 

  closeModal(id: string) {
    // this.modalService.close(id);
    this.popupService.closeDialog();
  }
}
