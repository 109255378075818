import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { DomSanitizer } from '@angular/platform-browser';
import { BucketUrl } from '../bucket';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {

  tenant: string;
  myBucketUrl: any;
  user: any;
  bucketUrl: BucketUrl;


  
  documentServiceBaseUrl = environment.ECOMM2_BASE_URL+"/documentstore/api/v1/documents/";

  // documentServiceBaseUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com/documentstore/api/v1/documents/";
  
  //personal poojaip for testing
  // documentServiceBaseUrl = "http://ec2-3-80-226-208.compute-1.amazonaws.com:8080/api/v1/documents/";
 
 

  
  
  constructor(private sanitizer: DomSanitizer,
    private http: HttpClient) {
    this.bucketUrl = new BucketUrl();
    this.user = JSON.parse(sessionStorage.getItem("user"))
    this.tenant = this.user.tenant;
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
  }

  getHeaderContentType() {
    return {
      headers: {
        'content-type': 'application/octet-stream',
        'X-TENANT': this.tenant
      }
    }
  }


  uploadImage(image: any): Observable<any> {
    return this.http.post<any>(this.documentServiceBaseUrl, image, this.getHeaderContentType());
  }

  getImage(documentId: any): Observable<any> {
    return this.http.get<any>(this.documentServiceBaseUrl + this.tenant + "/" + documentId);
  }

  editImage(image: any, documentId: any): Observable<any> {
    return this.http.put<any>(this.documentServiceBaseUrl + documentId, image, this.getHeaderContentType())
  }


  



  



  //----------------S3 upload code---------------------------------//

  //  FOLDER = 'product-images/';
  //  FOLDER = 'assets/img';


  // uploadFile(file, uuid) {

  //       const contentType = file.type;
  //       const bucket = new S3(
  //         {
  //           accessKeyId: 'AKIATBQJOBRO45LVV77T',
  //           secretAccessKey: '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',
  //           // region: 'ap-south-1'
  //           region: 'us-east-1'
  //         }
  //       );
  //     }
  //   const params = {
  //     // Bucket: 'dcp-ui-images',
  //     Bucket: 'restaurant-facial',
  //     // Key: this.FOLDER + this.tenant + "/" + uuid,
  //     Key: this.FOLDER + "/" + uuid,
  //     Body: file,
  //     ACL: 'public-read',
  //     ContentType: contentType
  //   };
  //   bucket.upload(params, function (err, data) {
  //     if (err) {
  //       //console.log('There was an error uploading your file: ', err);
  //       return false;
  //     }
  //     //console.log('Successfully uploaded file.', data);
  //     return true;
  //   });


  //for upload progress   
  /*bucket.upload(params).on('httpUploadProgress', function (evt) {
            //console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
        }).send(function (err, data) {
            if (err) {
                //console.log('There was an error uploading your file: ', err);
                return false;
            }
            //console.log('Successfully uploaded file.', data);
            return true;
        });*/

  // }
  //-----------------------S3 upload code end----------------------------//
}
