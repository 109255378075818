import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateValidationsService {

  constructor(private datePipe: DatePipe) { }

  onThroughDate(date) {
    var throughDate = new Date(date)
    return this.datePipe.transform(throughDate.setDate(throughDate.getDate() - 1), 'yyyy-MM-dd')

  }

  onFromDate(date) {
    var fromDate = new Date(date)
    return this.datePipe.transform(fromDate.setDate(fromDate.getDate() + 1), 'yyyy-MM-dd')
  }

  validateFromThroughDate(data) {
    if (data.fromDate == '' && data.throughDate == '') {
      delete data.fromDate
      delete data.throughDate
    } else if(data.fromDate !== '' && data.throughDate == '') {
      delete data.throughDate
    }
    return data
  }
}
