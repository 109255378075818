import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { BucketUrl } from 'src/app/bucket';
import { ModalService } from 'src/app/directives/modal.sevice';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { FiltersConstant } from 'src/app/shared/constants/filters-constant';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { CategoryFilters } from 'src/app/shared/constants/filter-model/category-filters.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-get-all-sub-categories',
  templateUrl: './get-all-sub-categories.component.html',
  styleUrls: ['./get-all-sub-categories.component.css']
})
export class GetAllSubCategoriesComponent implements OnInit {
  
  categories: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  flag: boolean = false;
  currentCategory: any;
  bucketUrl: BucketUrl;
  subscription: Subscription;
  parentId: any;
  categoryId: any;
  showNoData: Boolean = false;
  breadCrumbs: any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  totalItems: number;
  numberOfPages :number=1;
  filters: any = CategoryFilters;
  pagination: any;
  types: Array<any> = [];
  searchName: string;
  requestTier: Array<string> = [];
  sort: Array<any> = [];
  fields: any = {

  fieldName: null,
  sortDirection: null
};
  // sort:Array<any> = sortConstant.SUBCATEGORY_SORT;
  
  constructor(private adminService: AdminService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private backgroundImageService: BackgroundImageService,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService,
    private router:Router,public translate :TranslateService) {
      this.filters = new CategoryFilters();
    }
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.parentId = map.get("categoryId");
        this.categoryId = this.parentId;
        this.adminService.ngOnInit();
        this.getCategory();
        this.getCategoryTypes();
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      });
      
    }
    
    useLanguage(language: string) {
      console.log(language)
      this.translate.use(language);
  }

    getCategory() {    
      this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
        this.currentCategory = data.categories[0];
        if(!this.currentCategory['parentId']) {
          if(this.currentCategory.name != null) {
            console.log(this.currentCategory.name)
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "categorydetails",
              alternativeKey: 'categorydetails',
              labelName: 'Category: '+this.currentCategory.name,
              path: this.router.url
            });
          }
        }
      });
    }
    
    
    setUp(i,key,labelName,path) {
      if(key == "addnewsubcategory") {
        this.messageService.broadcast('bcLabelNew', {
          afterBaseOnly: false,
          key: key,
          alternativeKey:key+this.currentCategory.id,
          labelName: labelName,
          path: path,
          pathParamList: [],
          terminalOnly: false,
          child: []
        });
      }else {
        this.messageService.broadcast('nestedLabel', {
          afterBaseOnly: false,
          key: key,
          alternativeKey:key+this.currentCategory.id,
          labelName: labelName,
          path: path,
          pathParamList: [],
          terminalOnly: false,
          child: []
        });
        this.getAllProducts(this.categories[i]);
      }
    }
    
    getAllProducts(category) {
      let categoryId = category.id;
      let categoryName = category.name
      this.adminService.getAllCategoryProduct(categoryId).subscribe((data) => {
        if(data.products.length == 0){
          this.router.navigate(['/subcategorydetails/'+ categoryId + '/' + categoryName + '/subcategory/' + categoryId ]);
        }else {
          this.router.navigate(['/subcategorydetails/'+ categoryId + '/' + categoryName + '/categoryproduct/' + categoryId ]);
        }
      });
    }
        
    getAllCategoryByQuery(filters,itemsPerPage,currentPage,sort) {
      // this.categories = [];
      this.allCheck = false;
      // this.pagination = null;
      console.log(this.categories)
      this.filters['parentId'] = this.parentId;
      this.adminService.getCategoriesByQuery(filters,itemsPerPage,currentPage,sort).subscribe((data) =>{
        console.log(data);
        this.categories = data.categories;
        this.pagination = data.pagination;
        console.log(this.categories)
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.showNoData = this.noDataService.noData(this.categories);
        this.getBackgroundImage(this.categories)
      });
    }
    
    getBackgroundImage(categories) {
      if(categories.length > 0) {
        for (let i = 0; i < categories.length; i++) {
          categories[i].imageRef = this.getBackground(categories[i].imageRef);
          this.checkboxItems[i] = false;
        }
      }
    }
    
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.categories.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.categories.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    // multiple delete method
    delete() {
      
      this.openModal1("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          let categoryIds = []
          for (let i = 0; i < this.categories.length; i++) {
            if (this.checkboxItems[i] == true) {
              categoryIds.push(this.categories[i].id)
            }
          }
          this.adminService.deleteCategoryById(categoryIds).subscribe((data) => {
            this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
            this.allCheck = false
          })
          this.closeModal1("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal1("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
    
    
    openModal1(id: string){
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal1(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    openModal(id: string) {
      this.modalService.open(id);
    }
    
    closeModal(id: string) {
      this.modalService.close(id);
      //console.log(event.target)
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    
    onChangePage(event) {
      this.currentPage = event;
      this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
      // ------ search bar method ----------- //
    
      findName(event) {
        this.searchName = event;
        if(this.searchName != '') {
          this.filters['name'] = this.searchName;  
          this.currentPage = 1;
          console.log(this.filters)
          this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        }
      }
      hideCross(event) {
        this.filters['name'] = null;
        this.searchName = '';
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    
          // ------- type method ---------// 
    
    
    getCategoryTypes() {
      this.adminService.getAllCategoryTypes().subscribe((data) => {
        this.types = data.categoryTypes;
      })
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);   
      }else {
        // this.filters['type'] = null;
        delete this.filters.type
        this.getAllCategoryByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);   
      }
      console.log(this.filters)
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='name'
        console.log(this.sort)
      } else {
        
        delete this.sort[0].sortDirection
        // this.sort[0].sortDirection = await null
  
      }
  
      this.getAllCategoryByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
  }
  