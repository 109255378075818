import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-feature-details',
  templateUrl: './category-feature-details.component.html',
  styleUrls: ['./category-feature-details.component.css']
})
export class CategoryFeatureDetailsComponent implements OnInit {

  featureId: any;
  index: any;
  categoryId: any;
  constructor(private route: ActivatedRoute,public translate :TranslateService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((map) => {
      this.featureId = map.get('featureId');
      this.index = map.get('index')
      this.categoryId = map.get('categoryId');
    })
  }

}
