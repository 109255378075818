import { Component, OnInit } from '@angular/core';
import { EditFeatureRefComponent } from '../../feature-ref/edit-feature-ref/edit-feature-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { FeatureTypeRefServiceService } from 'src/app/admin/api-configuration/api-configuration-services/type-services/feature-type-ref-service/feature-type-ref-service.service';
import { FeatureFieldRefService } from 'src/app/admin/api-configuration/api-configuration-services/field-services/feature-field-ref-service/feature-field-ref.service';
import { CategoryFeatureRefService } from '../../../product-feature-service/category-feature-ref-service/category-feature-ref.service';
import { AdminService } from 'src/app/admin/admin.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { CasscadeToProductModalService } from 'src/app/shared/casscadetoproduct-modal/casscadetoproduct-modal.service';

@Component({
  selector: 'app-edit-category-feature-ref',
  templateUrl: '../../feature-ref/edit-feature-ref/edit-feature-ref.component.html',
  styleUrls: ['./edit-category-feature-ref.component.css']
})
export class EditCategoryFeatureRefComponent extends EditFeatureRefComponent implements OnInit {
  
  typeRefService: any;
  fieldRefService: any;
  featureRefService: any;
  categoryName: any;
  
  constructor(public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public router: Router,
    public imageUploadService: ImageUploadService,
    public deleteDialogService: DeleteDialogService,
    public modalService: ModalService,
    public http: HttpClient,
    private adminService: AdminService,
    public backgroundImageService: BackgroundImageService,
    public messageService: MessageSubjectService,
    public translate : TranslateService,public casscadeToProductService : CasscadeToProductModalService) {
      super(route, sanitizer, router, imageUploadService, deleteDialogService, modalService, http,
        backgroundImageService,messageService,translate,casscadeToProductService);
        
        
        this.typeRefService = new FeatureTypeRefServiceService(this.http);
        this.fieldRefService = new FeatureFieldRefService(this.http);
        this.featureRefService = new CategoryFeatureRefService(this.http);
      }
      
      ngOnInit() {
        this.isFeatureCategory = true
        this.getFeature();
        this.getFeatureType();
        this.getCategory();
      }
      
      
      getFeature() {
        this.featureRefService.getFeaturesByProductId(this.id).subscribe((data) => {
          this.allFeatures = data.features;
          for(let i=0;i<this.allFeatures.length;i++){
            if(this.featureId==this.allFeatures[i].id){
              this.currentFeature=this.allFeatures[i]
              break;
            }
          }
          // this.currentFeature = this.allFeatures[this.featureIndex];
          if(this.currentFeature.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: 'categoryfeaturedetails',
              alternativeKey: 'categoryfeaturedetails',
              labelName: 'Feature: '+this.currentFeature.name,
              path: this.router.url
            })
          }
          this.editProductFeatureModel();
          
          try {
            this.price = this.currentFeature.price.value;
          } catch (error) {
            
          }
          
          this.previousImageRef = this.currentFeature.imageRef;
          
          this.fieldRefService.getAllFieldsByTypeCode(this.editFeatures.type).subscribe((data) => {
            this.featureFields = data.fields;
            //console.log(this.featureFields)
            this.optionFieldsCondition(this.featureFields);
            for (let i = 0; i < this.featureFields.length; i++) {
              this.previousImageRefForField[i] = this.currentFeature.fields[this.featureFields[i].key];
              this.multipleArray[i] = new Array<any>()
              this.listArray[i] = new Array<any>()
              this.lastIndexOfMultipleArray[i] = new Array<any>();
              this.multipleArray[i].push({
                listValue: ''
              })
              if (this.featureFields != null) {
                if (this.featureFields[i].list) {
                  for (const key of Object.keys(this.currentFeature.fields)) {
                    if (this.featureFields[i].key == key) {
                      if ((this.currentFeature.fields[this.featureFields[i].key].length != 0)) {
                        // this.lastIndexOfMultipleArray[i] = this.currentFeature.fields[this.featureFields[i].key].length - 1;
                        for (let j = 0; j < this.currentFeature.fields[this.featureFields[i].key].length; j++) {
                          this.listFlag[i] = true;
                          this.multipleArray[i][j] = ({
                            listValue: this.currentFeature.fields[this.featureFields[i].key][j]
                          })
                          // this.multipleArray[i][j].listValue = this.category.fields[this.categoryFields[i].key][j]
                        }
                        //console.log(this.multipleArray[i])
                      }
                    }
                  }
                }
                else {
                  this.listFlag[i] = false;
                  if (this.featureFields[i].type == "BOOLEAN") {
                    if (this.currentFeature.fields[this.featureFields[i].key] != true) {
                      this.value[i] = false;
                    } else {
                      this.value[i] = (this.currentFeature.fields[this.featureFields[i].key])
                    }
                  } else {
                    this.value[i] = (this.currentFeature.fields[this.featureFields[i].key])
                  }
                }
              }
              this.lastIndexOfMultipleArray[i] = this.multipleArray[i].length - 1;
            }
          });
        });
      }

      
      
      getCategory() {
        this.adminService.getCategoryById(this.id).subscribe((data) => {
          this.categoryName = data.categories[0].name;
          if(!data.categories[0]['parentId']) {
            this.editRouteLink = '/categorydetails/' + this.id + '/' + this.categoryName + '/categoryfeatures/' + this.id;
          }else {
            
            this.editRouteLink = '/subcategorydetails/' + this.id + '/' + this.categoryName + '/categoryfeatures/' + this.id;
          }
        })
      }
      
    }
    