import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../../services/store.service';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/admin/admin.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';

@Component({
  selector: 'app-get-all-subcategories',
  templateUrl: './get-all-subcategories.component.html',
  styleUrls: ['./get-all-subcategories.component.css']
})
export class GetAllSubcategoriesComponent implements OnInit {
  
  parentCategoryId: any;
  storeCode: any;
  categories: any;
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  subscription: Subscription;
 
  
  constructor(private route: ActivatedRoute,
    private storeService: StoreService,
    private backgroundImageService: BackgroundImageService,
    private modalService: ModalService,
    private deleteDialogService: DeleteDialogService,
    private ngbModalService: NgbModal,
    private adminService: AdminService,
    private nestedCategoryService: NestedCategoriesService){}
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.parentCategoryId = map.get('parentcategoryid');
        this.storeCode = map.get('storecode')
        // //console.log(this.storeCode)
        this.getAllSubCategories();
      })
    }
    
    
    
    getAllSubCategories() {
      this.storeService.getAllChildrenCategories(this.parentCategoryId,this.storeCode).subscribe((data) => {
        this.categories = data.categories;
      })
    }
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.categories.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.categories.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    // multiple delete method
    delete() {
      
      this.openModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          for (let i = 0; i < this.categories.length; i++) {
            if (this.checkboxItems[i] == true) {
              this.storeService.deleteCategoyByIds(this.storeCode,this.categories[i].id).subscribe((data) => {
                // this.getAllChildrenCategories();
              })
            }
          }
          this.closeModal("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
    
    
    
    // single delete method
    deleteSingle(id) {
      
      this.openModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          
          this.storeService.deleteCategoyByIds(this.storeCode,id).subscribe((data) => {
            this.closeModal("dialogBox");
            // this.getAllChildrenCategories();
            this.subscription.unsubscribe();
            this.deleteDialogService.clearResponse();
          })
        } else if (res == "no") {
          this.closeModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
    }
    
    openModal(id: string) {
      this.modalService.open(id);
    }
    
    closeModal(id: string) {
      this.modalService.close(id);
    }
    
    
  }
  