import { Component, OnInit, ElementRef, Inject, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AdminService } from '../../admin.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { v4 as uuid } from 'uuid';
import { ModalService } from 'src/app/directives/modal.sevice';
import { MAT_DIALOG_DATA } from '@angular/material';
import { BucketUrl } from 'src/app/bucket';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { Subscription } from 'rxjs';
import { ImageUploadService } from '../../image-upload.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

  catalogs: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  checkboxItems: Array<boolean> = [];
  myValue: boolean = false;
  myBucketUrl: any;
  bucketUrl: BucketUrl;
  subscribe: Subscription;

  image: Array<any> = []

  constructor(private sanitizer: DomSanitizer,
    private adminService: AdminService,
    private router: Router,
    private modalService: ModalService,
    private deleteDialogService: DeleteDialogService,
    private imageUploadService: ImageUploadService,
    private BackgroundImageService: BackgroundImageService) {
    this.bucketUrl = new BucketUrl();
  }

  ngOnInit() {
    this.myBucketUrl = this.bucketUrl.getBucketUrl();
    console.log('catalogs')
    this.getAllCatalog();

  }

  getAllCatalog() {
    this.adminService.getAllCatalog().subscribe((data) => {
      this.catalogs = data.catalogs;
      for (let i = 0; i < this.catalogs.length; i++) {
        this.checkboxItems[i] = false;
      
       
      }
    });
  }

  checkAll() {
    this.allCheck = !this.allCheck
    for (let a = 0; a < this.catalogs.length; a++) {
      this.checkboxItems[a] = this.allCheck;
    }
  }



  onChange(i: number) {
    this.checkboxItems[i] = !this.checkboxItems[i];
    this.myValue = true;
    for (let a = 0; a < this.catalogs.length; a++) {
      if (this.checkboxItems[a] == false) {
        this.myValue = false;
      }
    }
    this.allCheck = this.myValue;
  }



  getBackground(image) {
    return this.BackgroundImageService.getBackground(image);
  }



  delete() {
    this.openModal("dialogBox");
    this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        let catalogIds: Array<any> = [];
        for (let i = 0; i < this.catalogs.length; i++) {
          if (this.checkboxItems[i] == true) {
            catalogIds.push(this.catalogs[i].id)
          }
        }
        this.adminService.deleteCatalogById(catalogIds).subscribe((data) => {
          this.closeModal("dialogBox")
          this.getAllCatalog();
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
        })
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }


  deleteSingle(id) {
    this.openModal("dialogBox");

    this.subscribe = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.adminService.deleteCatalogById(id).subscribe((data) => {
          this.closeModal("dialogBox")
          this.getAllCatalog();
          this.subscribe.unsubscribe();
          this.deleteDialogService.clearResponse();
        })
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscribe.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })

  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}





// closeModal(id: string) {
//   this.modalService.close(id);
//   //console.log(event.target)
// }
