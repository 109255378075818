import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/shared/loader/loader.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit{

  

  color = 'primary';
  mode = 'indeterminate';
  value = 100;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
 
  ngOnInit(){
    if(this.loaderService.isLoading){
      this.spinner.hide();
    }
    else{
      this.spinner.show()
    }
    
  }
 
  constructor(private loaderService: LoaderService,
    private spinner: NgxSpinnerService){
   
      
    }

    onchange(){
     
    }
    
}