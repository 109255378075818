import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/directives/modal.sevice';
import { TierService } from '../tier.service';
import { AdminService } from 'src/app/admin/admin.service';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { Router } from '@angular/router';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NestedCategoriesService } from 'src/app/shared/nested-categories-service/nested-categories.service';
import { AutoNameCodeGeneratorService } from 'src/app/shared/auto-name-code-generator/auto-name-code-generator.service';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
const cleanDeep = require('clean-deep');

@Component({
  selector: 'app-add-tiers',
  templateUrl: './add-tiers.component.html',
  styleUrls: ['./add-tiers.component.css']
})
export class AddTiersComponent implements OnInit {





  subscription: Subscription;
  currentImageRef: any;
  selectedFiles: FileList;
  user: any;
  tiers: any;
  breadCrumbs: any;

  constructor(private modalService: ModalService,
    private tierService: TierService,

    private imageUploadService: ImageUploadService,
    private router: Router,
    private deleteDialogService: DeleteDialogService,
    private backgroundImageService: BackgroundImageService,
    private messageService:MessageSubjectService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.tierModel()


  }
  tierModel() {
    this.tiers = {
      description: '',
      imageRef: '',
      minValue: 0,
      name: '',
      createdBy: this.user.name
    }
  }

  getBackground(image) {
    return this.backgroundImageService.getBackground(image);
  }


  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.selectedFiles = null;
    this.currentImageRef = "";
  }

  resetForm(addtiersForm:NgForm) {
    this.openResetModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeResetModal("dialogBox");
        this.ngOnInit();
        this.selectedFiles = null;
        this.currentImageRef = "";
        addtiersForm.resetForm()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeResetModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    });
  }

  addTiers() {
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      this.imageUploadService.uploadImage(file).subscribe((data) => {
        this.tiers.imageRef = data.documentId;
        this.addPromosServiceCall();
      })
    } else {
      this.addPromosServiceCall();
    }
  }

  addPromosServiceCall() {
    var requestBody = new Object();
    requestBody['loyaltyTier'] = this.tiers;
    var requestBodyObject = cleanDeep(requestBody)
    console.log("demo",cleanDeep(requestBody))
    console.log(requestBody)
    this.tierService.addTier(requestBodyObject).subscribe(data => {
      this.messageService.broadcast('splice', {
        key: 'addnewtier',
        alternativeKey: 'addnewtier'
      })
      this.router.navigateByUrl('/marketingdetails/tiers')
    });
  }


  openResetModal(id) {
    let data = {};
    data = {
      value: 'reset'
    };
    if (id == 'delete') {
      data['value'] = id;
    }
    this.deleteDialogService.openDialog(data);
  }
  closeResetModal(id) {
    this.deleteDialogService.closeDialog();
  }


}
