import { Injectable } from '@angular/core';
import { TypeRefServiceService } from '../type-ref-service/type-ref-service.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreTypeRefService implements TypeRefServiceService {

  constructor(public http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }

  user: any;
  tenant: any;
  headers: Headers;

  serverRootUrl = environment.STORE_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  //services for build
  storeTypeRootUrl = this.serverRootUrl + "/productstore/api/v1/productstoretypes/";

  getHeaderTenant() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant
    return { headers: {'X-TENANT':this.tenant} }
}

  createType(categoryType) {
    let request = {};
        request['storeType'] = categoryType;
        return this.http.post<any>(this.storeTypeRootUrl, request ,  this.getHeaderTenant());
  }

  getAllTypes():Observable<any> {
    return this.http.get<any>(this.storeTypeRootUrl + '/all' ,  this.getHeaderTenant());
  }

  deleteTypeByTypeCodes(storeTypeCodes) {
    return this.http.delete<any>(this.storeTypeRootUrl + "?productStoreTypeCodes=" +storeTypeCodes ,  this.getHeaderTenant());
  }

  updateTypeByTypeCode(storeType, storeTypeCode) {
    let request = {};
    request['storeType'] = storeType;
    return this.http.put<any>(this.storeTypeRootUrl + storeTypeCode, request ,  this.getHeaderTenant());
  }
}

