import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { CasscadeToProductModalService } from './casscadetoproduct-modal.service';

@Component({
  selector: 'app-casscadetoproduct-modal',
  templateUrl: './casscadetoproduct-modal.component.html',
  styleUrls: ['./casscadetoproduct-modal.component.css']
})
export class CasscadetoproductModalComponent implements OnInit {

  value: any;
  status: any;
  responseMessage: any;
  title = 'Angular-Interceptor';
  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
  private casscadeToProductModalService: CasscadeToProductModalService){
    console.log(data);
  this.value = data;
  this.status = data;
  if(data['responseMessage'] != null) {
    this.responseMessage = data['responseMessage'];
  }
  console.log(this.responseMessage)
}

 ngOnInit(){
  
  }
  

  onClickYes(){
    this.casscadeToProductModalService.getResponseComponentMethod("yes");
  }

  onClickNo(){
    this.casscadeToProductModalService.getResponseComponentMethod("no");
  }

  closeModal(id: string) {
    // this.modalService.close(id);
    this.casscadeToProductModalService.closeDialog();
  }

}
