import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';

@Component({
  selector: 'app-edit-product-feature',
  templateUrl: './edit-product-feature.component.html',
  styleUrls: ['./edit-product-feature.component.css']
})
export class EditProductFeatureComponent implements OnInit {


  user: any;
  myBucketUrl: any;
  productFeatures: any;
  featureTypes: any[];
  selectedFiles: FileList;
  productId: any;
  basePrice: any;
  price: any;
  uid: any;
  editProductFeatures: any;
  featureIndex: any;
  currentProductFeature: any;
  featureId: any;
  featureFields: Array<any> = []
  featureOptions: Array<string> = [];
  value: Array<any> = [];
  convertedTypeField: Array<string> = [];
  checkbox = "checkbox";
  ck: Array<Boolean> = [];
  currentFields: any;
  showFields: boolean;
  catOptions: Array<Boolean> = [];

  subscription: Subscription;

  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.myBucketUrl = environment.myBucketUrl;
    this.user = JSON.parse(sessionStorage.getItem('user'));

    this.getFeatureType();

    this.route.paramMap.subscribe((map) => {
      this.productId = map.get('productId');
      this.featureId = map.get('featureId');
      this.featureIndex = map.get('index');
    })
   
    this.getProductFeature();

  }

  resetForm() {
    this.openModal("dialogBox");
    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal("dialogBox");
        this.ngOnInit();
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      } else if (res == "no") {
        this.closeModal("dialogBox")
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  getFeatureType() {
    this.adminService.getAllProductFeatureType().subscribe((data) => {
      this.featureTypes = data.featureTypes;
    })
  }

  getProductFeature() {
    this.adminService.getProductFeatureByProductId(this.productId).subscribe((data) => {
      this.productFeatures = data.productWithFeatures.features;
      this.currentProductFeature = this.productFeatures[this.featureIndex]
      //console.log(this.currentProductFeature)

      this.editProductFeatures = {
        name: this.currentProductFeature.name,
        description: this.currentProductFeature.description,
        defaultUseCount: this.currentProductFeature.defaultUseCount,
        maxUseCount: this.currentProductFeature.maxUseCount,
        minUseCount: this.currentProductFeature.minUseCount,
        price: this.currentProductFeature.price,
        imageRef: this.currentProductFeature.imageRef,
        type: this.currentProductFeature.type,
        createdBy: this.currentProductFeature.createdBy,
        modifiedBy: this.user.name,
        options: this.currentProductFeature.options,
        fields: this.currentProductFeature.fields
      }

      this.price = this.currentProductFeature.price.value;
      //console.log(this.currentProductFeature.price.value)

      this.adminService.getAllFeatureFields(this.editProductFeatures.type).subscribe((data) => {
        this.featureFields = data.productFeatureFields;
        //console.log(this.featureFields)
        this.optionFieldsCondition(this.featureFields);
        for (let i = 0; i < this.featureFields.length; i++) {
          if (this.featureFields != null) {
            this.value[i] = (this.currentProductFeature.fields[this.featureFields[i].key])
            //console.log(this.value)
          }
        }
        //console.log(this.featureFields)

      });
    });
  }


  optionFieldsCondition(featureFields) {
    for (let i = 0; i < featureFields.length; i++) {
      if (featureFields[i].options) {
        if ((featureFields[i].options.length == 1) || (featureFields[i].options.length == 0)) {
          this.catOptions[i] = false;
        }
        else {
          this.catOptions[i] = true;
        }
      }
      //console.log(this.catOptions)
      if (featureFields[i].type == "BOOLEAN") {
        this.value[i] = false;
      }
      let type = featureFields[i].type;
      this.convertedTypeField[i] = (environment.typeConversion[type]);
      //console.log(environment.typeConversion[type])
      //console.log(this.convertedTypeField)
    }
  }

  selectCode(event) {
    // this.value = []
    this.currentFields = event.target.value;
    //console.log(this.currentFields)
    //console.log(this.currentFields);
    this.adminService.getAllFeatureFields(this.currentFields).subscribe((data) => {
      this.featureFields = data.productFeatureFields;
      //console.log(this.featureFields)
      this.optionFieldsCondition(this.featureFields);
      //console.log(this.currentFields.type)
      //console.log(this.currentProductFeature.type)
      for (let i = 0; i < this.featureFields.length; i++) {
        if (this.currentProductFeature.type == this.currentFields) {
          this.value[i] = (this.currentProductFeature.fields[this.featureFields[i].key])
          //console.log(this.value)

        }
        else {
          this.value[i] = ""
          this.editProductFeatures.fields = {}
        }

      }
    });

    this.showFields = true;
  }


  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  getBackground(image) {
    if (image == "") {
      image = "noImage.jpg";
      return this.sanitizer.bypassSecurityTrustStyle('url(/assets/images/' + image + ')');
    }
    else {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + this.myBucketUrl + image + ')');
    }
  }

  onSubmit() {
    //console.log(this.editProductFeatures)
    if (this.featureFields != null) {
      for (let i = 0; i < this.featureFields.length; i++) {
        let fieldName = this.featureFields[i].key;

        if (this.featureFields[i].type == "INTEGER") {
          this.editProductFeatures.fields[fieldName] = parseInt(this.value[i])
        } else if (this.featureFields[i].type == "DECIMAL") {
          this.editProductFeatures.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.editProductFeatures.fields[fieldName] = this.value[i]
        }

      }
    }
    this.editProductFeatures.price["value"] = this.price;
    this.editProductFeatures.price["currency"] = "USD";
    
    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);
      if (this.currentProductFeature.imageRef != "") {
        this.imageUploadService.editImage(file, this.currentProductFeature.imageRef).subscribe((data) => {
          this.editProductFeatureServiceCall();
        })
      } else {
        this.imageUploadService.uploadImage(file).subscribe((data) => {
          this.editProductFeatures.imageRef = data.documentId;

          this.editProductFeatureServiceCall();
        })
      }
    } else {
      this.editProductFeatureServiceCall();
    }
      
  }

  editProductFeatureServiceCall(){
    this.adminService.updateProductFeatureById(this.productId, this.featureId, this.editProductFeatures).subscribe((data) => {
      this.router.navigate(['/productdetails/' + this.productId + '/productfeatures/' + this.productId]);
    });
  }
}
