import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-feature-details-ref',
  templateUrl: './feature-details-ref.component.html',
  styleUrls: ['./feature-details-ref.component.css']
})
export class FeatureDetailsRefComponent implements OnInit {
  
  featureId: any;
  index: any;
  id: any;
  editFeature: any;
  featureOptionLink: any;
  featureName: any;
  breadCrumbs: any;
  currentFeature: any;
  featureRefService: any;
  showAddOns: Boolean = false;
  constructor(public route: ActivatedRoute,
    public router: Router,
    public adminService: AdminService,
    public messageService: MessageSubjectService,
    public http: HttpClient,
    public dataService: MessageService) { 
      let types = JSON.parse(sessionStorage.getItem('types'))
      this.route.paramMap.subscribe((map) => {
        this.featureId = map.get('featureId');
        this.index = map.get('index')
        this.id = map.get('id');
        this.featureName = map.get('featureName');
        console.log("types",types)
        this.getFeature();
      });
    }
    
    
    ngOnInit() {
    //   // this.dataService.getMessage().subscribe((res) => {
    //   //   this.showAddOns = res
    //   // })
    //   // this.getFeature();
    }
    
    async getFeature() {
      if(this.editFeature == "editcategoryfeature") {
        this.featureRefService.getAllFeatures(this.id).subscribe((data) => {
          this.currentFeature = data.features[this.index];
          if(this.currentFeature.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: 'productfeaturedetails',
              alternativeKey: 'productfeaturedetails',
              labelName: 'Feature: '+this.currentFeature.name,
              path: this.router.url
            })
          }
        });
      }else if(this.editFeature == "editproductfeature") {
        this.featureRefService.getAllFeatures(this.id).subscribe((data) => {
          this.currentFeature = data.productWithFeatures.features[this.index];
          if(this.currentFeature.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: 'productfeaturedetails',
              alternativeKey: 'productfeaturedetails',
              labelName: 'Feature: '+this.currentFeature.name,
              path: this.router.url
            })
          }
        });
      }

    }
    
    setup() {
      if(this.editFeature == "editcategoryfeature") {
        if(this.featureName != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "categoryfeaturedetails",
            alternativeKey: 'categoryfeaturedetails',
            labelName: 'Feature: '+decodeURIComponent(this.featureName),
            path: decodeURI(this.router.url)
          });
        }
      }else if(this.editFeature == "editproductfeature") {
        if(this.featureName != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "productfeaturedetails",
            alternativeKey: 'productfeaturedetails',
            labelName: 'Feature: '+decodeURIComponent(this.featureName),
            path: decodeURI(this.router.url)
          });
        }
      }
    }
    
  }
  
  
  