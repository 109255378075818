import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { PopupDeleteResetDialogService } from 'src/app/shared/popup-delete-reset-dialog/popup-delete-reset-dialog.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import * as glob from '../../services/globals';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
  hideShow1 = false;
  hideShow2 = false;
  hideShow3 = false;
  hideShow4 = false;
  dropdownhideshow = false;

  user:any;

  constructor(private elementRef: ElementRef,
              private router: Router,
              private deleteDialogService: DeleteDialogService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));

   this.deleteDialogService.closeDialog();
   this.user = JSON.parse(sessionStorage.getItem("user"));
   
  }

  onMenuItemVisited($event) {
    glob.caches.isMenuAction = true;
}

  hideShowMethod1() {
    this.hideShow1 = !this.hideShow1;
    if (this.hideShow1) {
      this.hideShow2 = false;
      this.hideShow3 = false;
      this.hideShow4 = false;
    }
  }

  hideShowMethod2() {
    this.hideShow2 = !this.hideShow2;
    if (this.hideShow2) {
      this.hideShow1 = false;
      this.hideShow3 = false;
      this.hideShow4 = false;
    }
  }
  hideShowMethod3() {
    this.hideShow3 = !this.hideShow3;
    if (this.hideShow3) {
      this.hideShow2 = false;
      this.hideShow1 = false;
      this.hideShow4 = false;
    }
  }
  hideShowMethod4() {
    this.hideShow4 = !this.hideShow4;
    if (this.hideShow4) {
      this.hideShow2 = false;
      this.hideShow3 = false;
      this.hideShow1 = false;
    }
  }

  dropdownhideshowMethod() {
    this.dropdownhideshow = ! this.dropdownhideshow;
  }
 
  
  closeDropDown(){
    sessionStorage.removeItem("user")
    this.dropdownhideshow = false;
    this.router.navigate(["/userlogin"]);
  }


}





