import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditFieldModalService{

  constructor() { }

  public id: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public data: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public fieldType:BehaviorSubject<any> = new BehaviorSubject<any>("");

  getDataResponseFromComponent(id:any, data:any, fieldType: any) {
    this.id.next(id);
    this.data.next(data);
    this.fieldType.next(fieldType);
  }
}
