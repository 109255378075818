import { Injectable, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// import { Headers } from '@angular/http';

@Injectable()
export class AdminService implements OnInit {

    constructor(private http: HttpClient) {
        this.user = JSON.parse(sessionStorage.getItem("user"));
        this.tenant = this.user.tenant;
    }

    ngOnInit() {
        this.user = JSON.parse(sessionStorage.getItem("user"));
        this.tenant = this.user.tenant;
    }
    user: any;
    tenant: any;
    headers: Headers;


    getHeaderTenant() {
        return { headers: { 'X-TENANT': this.tenant } }
    }

    serverRootUrl = environment.BASE_URL;
    productCategoryServerRootUrl = environment.PRODUCT_CATEGORIES_BASE_URL
    productServerRootUrl = environment.PRODUCT_BASE_URL
    productCatalogServerRootUrl = environment.PRODUCT_CATALOG_BASE_URL
    // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
    // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"

    //services for build
    productCatalogRootUrl = this.productCatalogServerRootUrl + "/api/v1/productcatalogs/";
    productCatalogTypeRootUrl = this.productCatalogServerRootUrl + "/api/v1/productcatalogtypes/";

    productRootUrl = this.productServerRootUrl + "/api/v1/products/";
    // productRootUrl = this.serverRootUrl + "/product/api/v1/products/";
    workflowRootUrl = this.productServerRootUrl + "/api/v1/workflow/";
    productCategoryRootUrl = this.productCategoryServerRootUrl + "/api/v1/productcategories/";
    productCategoryTypeRootUrl = this.productCategoryServerRootUrl + "/api/v1/productcategorytypes/";

    productAssociationTypeRootUrl = this.productServerRootUrl + "/api/v1/productassociationtypes/";

    productFeatureTypeRootUrl = this.productServerRootUrl + "/api/v1/productfeaturetypes/";
    // forProxyProductService="/api/v1/products/"

    // productCatalogRootUrl = "/productcatalogs/";
    // productCatalogTypeRootUrl = "/productcatalogtypes/";

    // productRootUrl = "/products/";

    // productCategoryRootUrl = "/productcategories/";
    // productCategoryTypeRootUrl = "/productcategorytypes/";


    // --proxy-config proxy.conf.js



    //services for category
    addCategory(category: any): Observable<any> {
        let request = {};
        request["category"] = category;
        return this.http.post<any>(this.productCategoryRootUrl, request, this.getHeaderTenant());
    }

    getAllCategory(): Observable<any> {
        return this.http.get<any>(this.productCategoryRootUrl + "all/?retrieveInactive=true", this.getHeaderTenant())
    }

    //parentId is null so getting categories whose parentId is null
    // getChildCategories(parentId): Observable<any> {
    //     if (parentId == null) {
    //         return this.http.get<any>(this.productCategoryRootUrl + "children?retrieveInactive=true", this.getHeaderTenant());
    //     } else {
    //         return this.http.get<any>(this.productCategoryRootUrl + "children?parentId=" + parentId + "&retrieveInactive=true", this.getHeaderTenant())
    //     }
    // }

    getChildCategories(parentId): Observable<any> {
        if (parentId == null) {
            return this.http.get<any>(this.productCategoryRootUrl + "children", this.getHeaderTenant());
        } else {
            return this.http.get<any>(this.productCategoryRootUrl + "children?parentId=" + parentId + "&retrieveInactive=true", this.getHeaderTenant())
        }
    }

    getCategoriesByQuery(filters, itemsPerPage, pageNumber, sort): Observable<any> {
        var requestBody = {
            filters: filters,
            "itemsPerPage": itemsPerPage,
            "pageNumber": pageNumber,
            "retrieveInactive": false,
            "sort": sort
        }
        return this.http.post<any>(this.productCategoryRootUrl + "query", requestBody, this.getHeaderTenant());
    }

    getCategoryById(categoryId: any): Observable<any> {
        return this.http.get<any>(this.productCategoryRootUrl + "?productCategoryIds=" + categoryId, this.getHeaderTenant());
    }

    getAllCategoryProduct(categoryId: any): Observable<any> {
        return this.http.get<any>(this.productRootUrl + "categories/" + categoryId + "?retrieveInactive=true", this.getHeaderTenant());
    }

    getCategoryProductsByQuery(filters, itemsPerPage, pageNumber, sort): Observable<any> {
        var requestBody = {
            filters: filters,
            "itemsPerPage": itemsPerPage,
            "pageNumber": pageNumber,
            "retrieveInactive": true,
            "sort": sort
        }
        return this.http.post<any>(this.productRootUrl + "query", requestBody, this.getHeaderTenant())
    }

    updateCategory(categoryId: any, category: any): Observable<any> {
        let request = {};
        request["category"] = category;
        //console.log(request)
        return this.http.put<any>(this.productCategoryRootUrl + categoryId, request, this.getHeaderTenant());
    }

    deleteCategoryById(categoryId: any[]): Observable<any> {
        return this.http.delete<any>(this.productCategoryRootUrl + "?categoryIds=" + categoryId, this.getHeaderTenant());
    }

    getAllCategoryTypes(): Observable<any> {
        return this.http.get<any>(this.productCategoryTypeRootUrl, this.getHeaderTenant());
    }


    updateProductCategoryType(categoryType, categoryTypeCode): Observable<any> {
        let request = {};
        request['categoryType'] = categoryType;
        return this.http.put<any>(this.productCategoryTypeRootUrl + categoryTypeCode, request, this.getHeaderTenant());
    }

    createProductCategoryType(categoryType): Observable<any> {
        let request = {};
        request['categoryType'] = categoryType;
        return this.http.post<any>(this.productCategoryTypeRootUrl, request, this.getHeaderTenant());
    }

    deleteCategoryTypeByCode(productCategoryTypeCodes: any): Observable<any> {
        return this.http.delete<any>(this.productCategoryTypeRootUrl + "?productCategoryTypeCodes=" + productCategoryTypeCodes, this.getHeaderTenant());
    }






    // product Fields service start

    updateProductFieldsByCategoryId(productFields, categoryId): Observable<any> {
        let request = {};
        request['productFields'] = [productFields];
        return this.http.post<any>(this.productCategoryTypeRootUrl + 'products/' + 'fields/' + categoryId, request, this.getHeaderTenant());
    }

    getProductFieldsByCategoryId(categoryId): Observable<any> {
        return this.http.get<any>(this.productCategoryRootUrl + categoryId + "/products/" + "fields", this.getHeaderTenant())
    }

    addProductFieldsByCategoryId(field, categoryId): Observable<any> {
        let request = {};

        request['field'] = field;
        return this.http.post<any>(this.productCategoryRootUrl + categoryId + "/products/fields", request, this.getHeaderTenant());
    }

    deleteProductFieldsByCategoryId(fieldsId: any[], categoryId): Observable<any> {
        return this.http.delete<any>(this.productCategoryRootUrl + categoryId + "/products/fields?productFieldIds=" + fieldsId, this.getHeaderTenant())
    }

    // product Fields service end





    // service for category Fields start

    getCategoryFieldsByCategoryTypeCode(categoryTypeCode: any): Observable<any> {
        return this.http.get<any>(this.productCategoryTypeRootUrl + categoryTypeCode + '/fields', this.getHeaderTenant());
    }

    createCategoryFieldsByCategoryTypeCode(field, categoryTypeCode): Observable<any> {
        let request = {};

        request['field'] = field;
        return this.http.post<any>(this.productCategoryTypeRootUrl + categoryTypeCode + '/fields', request, this.getHeaderTenant());
    }

    updateCategoryFieldByFieldId(categoryTypeCode: any, fieldId: any, field: any): Observable<any> {
        let request = {};
        request['field'] = field;
        return this.http.put<any>(this.productCategoryTypeRootUrl + categoryTypeCode + '/fields/' + fieldId, request, this.getHeaderTenant());
    }

    deleteCategoryFieldsByFieldsId(fieldsId, categoryTypeCode): Observable<any> {
        return this.http.delete<any>(this.productCategoryTypeRootUrl + categoryTypeCode + "?fieldIds=" + fieldsId, this.getHeaderTenant());
    }


    // service for category Fields end



    // category features service start

    getAllCategoryFeaturesById(categoryId: any): Observable<any> {
        return this.http.get<any>(this.productCategoryRootUrl + categoryId + "/products/features", this.getHeaderTenant())
    }

    deleteCategoryFeatureById(categoryId: any, featureIds: any[]): Observable<any> {
        return this.http.delete<any>(this.productCategoryRootUrl + categoryId + "/products/features?productFeatureIds=" + featureIds, this.getHeaderTenant())
    }

    createCategoryFeatureById(categoryId: any, feature): Observable<any> {
        let request = {}
        request['feature'] = feature;
        return this.http.post<any>(this.productCategoryRootUrl + categoryId + "/products/features", request, this.getHeaderTenant())
    }

    updateCategoryFeatureById(categoryId: any, featureId: any, feature): Observable<any> {
        let request = {};
        request['feature'] = feature;
        return this.http.put<any>(this.productCategoryRootUrl + categoryId + "/products/features/" + featureId, request, this.getHeaderTenant());
    }

    // category features service end 

    // category associations service start
    getAllCategoryProductAssociations(categoryId: any): Observable<any> {
        return this.http.get<any>(this.productCategoryRootUrl + categoryId + "/products/associations", this.getHeaderTenant())
    }

    getAllCategoryProductAssociationByQuery(categoryId, filters, itemsPerPage, pageNumber, sort): Observable<any> {
        var requestBody = {
            filters: filters,
            "itemsPerPage": itemsPerPage,
            "pageNumber": pageNumber,
            "retrieveInactive": true,
            // "sort":sort
            "sort": [
                {
                    "fieldName": "name"
                }
            ]
        }

        return this.http.post<any>(this.productCategoryRootUrl + categoryId + "/products/associations/query", requestBody, this.getHeaderTenant());
    }

    deleteCategoryProductAssociations(categoryId: any, associationIds: any[]): Observable<any> {
        return this.http.delete<any>(this.productCategoryRootUrl + categoryId + "/products/associations?productAssociationIds=" + associationIds, this.getHeaderTenant())
    }

    createCategoryAssociations(categoryId: any, association: any, cascadeToProduct): Observable<any> {
        let request = {};
        request['association'] = association
        if (cascadeToProduct) {
            return this.http.post<any>(this.productCategoryRootUrl + categoryId + "/products/associations?cascadeToProduct=" + cascadeToProduct, request, this.getHeaderTenant());

        } else {
            return this.http.post<any>(this.productCategoryRootUrl + categoryId + "/products/associations", request, this.getHeaderTenant());
        }
    }

    updateCategoryAssociation(categoryId: any, associationId: any, association: any, cascadeToProduct): Observable<any> {
        let request = {};
        request['association'] = association
        if (cascadeToProduct) {
            return this.http.put<any>(this.productCategoryRootUrl + categoryId + "/products/associations/" + associationId + '?cascadeToProduct=' + cascadeToProduct, request, this.getHeaderTenant())

        } else {
            return this.http.put<any>(this.productCategoryRootUrl + categoryId + "/products/associations/" + associationId, request, this.getHeaderTenant())
        }
    }
    // category associations service end

    //services for product
    addProduct(product: any): Observable<any> {
        let request = {};
        request["product"] = product;
        return this.http.post<any>(this.productRootUrl, request, this.getHeaderTenant());

    }

    addWorkflowProduct(product: any): Observable<any> {
        let request = {};
        request["product"] = product;
        return this.http.post<any>(this.productRootUrl, request, this.getHeaderTenant());

    }

    getAllProducts(): Observable<any[]> {
        return this.http.get<any[]>(this.productRootUrl + "/all?retrieveInactive=true", this.getHeaderTenant());
    }

    getProductById(productId: any): Observable<any> {
        return this.http.get<any>(this.productRootUrl + "?productIds=" + productId, this.getHeaderTenant());
    }

    updateProduct(productId: any, product: any): Observable<any> {
        let request = {};
        request["product"] = product;
        return this.http.put<any>(this.productRootUrl + productId, request, this.getHeaderTenant());
    }

    updatePublishedProduct(updateProduct: any, product: any): Observable<any> {
        let request = {};
        request["updateProduct"] = updateProduct
        request["product"] = product

        console.log("request of updated product when published", request)
        return this.http.post<any>(this.productRootUrl, request, this.getHeaderTenant());
    }

    deleteProductById(productId: any[]): Observable<any> {
        return this.http.delete<any>(this.productRootUrl + "?productIds=" + productId, this.getHeaderTenant());
    }


    getProductsByProductIds(productIds: any[]): Observable<any> {
        console.log('service')
        // for (let i = 0; i < productIds.length; i++) {
        return this.http.get<any>(this.productRootUrl + "?productIds=" + productIds, this.getHeaderTenant())
        // }

    }


    //Product Workflow Service start
    sendProductToReview(productId: any, parameters: any): Observable<any> {
        let request = {
            "signalName": "REVIEW",
            "parameters": parameters
        }

        return this.http.post<any>(this.workflowRootUrl + productId + "/signal", request, this.getHeaderTenant());
    }

    sendProductToPublish(productId: any, parameters: any): Observable<any> {
        let request = {
            signalName: "APPROVE",
            parameters: parameters
        }

        return this.http.post<any>(this.workflowRootUrl + productId + "/signal", request, this.getHeaderTenant());
    }

    abortPreviousProduct(processInstanceId: any): Observable<any> {
        return this.http.delete<any>(this.workflowRootUrl + "process/" + processInstanceId + "/abort", this.getHeaderTenant());
    }

    sendProductToDraft(productId: any, parameters: any): Observable<any> {
        let request = {
            signalName: "REJECT",
            parameters: parameters
        }

        return this.http.post<any>(this.workflowRootUrl + productId + "/signal", request, this.getHeaderTenant());
    }

    claimWorkflowProduct(processInstanceId: any, taskId: any, owner: any): Observable<any> {
        let request = {
            taskId: taskId,
            owner: owner,
            userName: owner
        }
        console.log("request claim", request)
        return this.http.put<any>(this.workflowRootUrl + "process/" + processInstanceId + "/claim", request, this.getHeaderTenant());
    }



    //Product Workflow service end

    // product Association service start
    getProductAssociationsByType(productId: any): Observable<any> {
        return this.http.get<any>(this.productRootUrl + productId + "/associations", this.getHeaderTenant());
    }

    getProductAssociationsByQuery(productId, filters, itemsPerPage, pageNumber, sort): Observable<any> {
        var requestBody = {
            filters: filters,
            "itemsPerPage": itemsPerPage,
            "pageNumber": pageNumber,
            "retrieveInactive": true,
            // "sort": sort
            "sort": [
                {
                    "fieldName": "name"
                }
            ]
        }
        console.log(productId, 'associationservice')
        return this.http.post<any>(this.productRootUrl + productId + "/associations/query", requestBody, this.getHeaderTenant());
    }

    createProductAssociation(productId: any, productAssociation: any): Observable<any> {
        let request = {};
        request['association'] = productAssociation;
        return this.http.post<any>(this.productRootUrl + productId + "/associations", request, this.getHeaderTenant())
    }

    updateProductAssociation(productId: any, associationId: any, association: any): Observable<any> {
        let request = {};
        request['association'] = association;
        return this.http.put<any>(this.productRootUrl + productId + "/associations/" + associationId, request, this.getHeaderTenant())
    }

    deleteProductAssociationById(associationId: any[], productId: any): Observable<any> {
        return this.http.delete<any>(this.productRootUrl + productId + "/associations?associationIds=" + associationId, this.getHeaderTenant())
    }
    // product Association service end

    // Product Association Type Service start

    getAllProductAssociationType(): Observable<any> {
        return this.http.get<any>(this.productAssociationTypeRootUrl, this.getHeaderTenant());
    }

    createProductAssociationType(associationType): Observable<any> {
        let request = {};
        request['associationType'] = associationType
        return this.http.post<any>(this.productAssociationTypeRootUrl, request, this.getHeaderTenant())
    }

    updateProductAssociationType(associationType: any, associationTypeCode: any): Observable<any> {
        let request = {};
        request['associationType'] = associationType
        return this.http.put<any>(this.productAssociationTypeRootUrl + associationTypeCode, request, this.getHeaderTenant());
    }

    deleteProductAssociationType(associationTypeCode: any[]): Observable<any> {
        return this.http.delete<any>(this.productAssociationTypeRootUrl + "?productAssociationTypeCodes=" + associationTypeCode, this.getHeaderTenant())
    }
    // Product Association Type Service end

    getAllAssociationFields(associationTypeCode: any): Observable<any> {
        return this.http.get<any>(this.productAssociationTypeRootUrl + associationTypeCode + "/fields", this.getHeaderTenant());
    }

    createAssociationField(associationTypeCode: any, associationFields: any): Observable<any> {
        let request = {};
        request['associationFields'] = associationFields;
        return this.http.post(this.productAssociationTypeRootUrl + associationTypeCode + "/fields", request, this.getHeaderTenant());
    }

    // product Association fields Service start


    // product Association fields Service end

    // product Feature Type Service start

    getAllProductFeatureType(): Observable<any> {
        return this.http.get<any>(this.productFeatureTypeRootUrl, this.getHeaderTenant());
    }

    createProductFeatureType(featureType): Observable<any> {
        let request = {};
        request['featureType'] = featureType
        return this.http.post<any>(this.productFeatureTypeRootUrl, request, this.getHeaderTenant());
    }

    updateProductFeatureType(featureType, productFeatureTypeCode): Observable<any> {
        let request = {};
        request['featureType'] = featureType
        return this.http.put<any>(this.productFeatureTypeRootUrl + productFeatureTypeCode, request, this.getHeaderTenant())
    }

    deleteProductFeatureTypeByCode(typeCodes: any[]): Observable<any> {
        return this.http.delete<any>(this.productFeatureTypeRootUrl + "?productFeatureTypeCodes=" + typeCodes, this.getHeaderTenant());
    }

    // product Feature Type Service end


    // product Feature service start

    getProductFeatureByProductId(productId: any): Observable<any> {
        return this.http.get<any>(this.productRootUrl + productId + "/features", this.getHeaderTenant())
    }

    createProductFeatureByProductId(productId: any, productFeature: any): Observable<any> {
        let request = {};
        request['productFeature'] = productFeature
        return this.http.post<any>(this.productRootUrl + productId + "/features", request, this.getHeaderTenant());
    }


    deleteProductFeatureById(productId: any, featureId: any[]): Observable<any> {
        return this.http.delete<any>(this.productRootUrl + productId + "/features?featureIds=" + featureId, this.getHeaderTenant());
    }

    updateProductFeatureById(productId: any, featureId: any, feature): Observable<any> {
        let request = {};
        request['feature'] = feature
        return this.http.put<any>(this.productRootUrl + productId + "/features/" + featureId, request, this.getHeaderTenant());
    }

    // product Feature service end

    // product Feature Fields Service start

    getAllFeatureFields(featureTypeCode: any): Observable<any> {
        return this.http.get<any>(this.productFeatureTypeRootUrl + featureTypeCode + "/fields", this.getHeaderTenant());
    }

    createFeatureFields(featureTypeCode: any, featureFields: any): Observable<any> {
        let request = {}
        request['featureFields'] = featureFields;
        return this.http.post<any>(this.productFeatureTypeRootUrl + featureTypeCode + "/fields", request, this.getHeaderTenant());
    }

    deleteFeatureFields(featureTypeCode: any, featureIds: any[]): Observable<any> {
        return this.http.delete<any>(this.productFeatureTypeRootUrl + featureTypeCode + "/fields?fieldIds=" + featureIds, this.getHeaderTenant());
    }
    // product Feature Fields Service end

    //services for catalog-------------
    addCatalog(catalog: any): Observable<any> {

        let request = {}
        request["catalog"] = catalog;
        return this.http.post<any>(this.productCatalogRootUrl, request, this.getHeaderTenant());
    }

    getAllCatalog(): Observable<any> {
        return this.http.get<any>(this.productCatalogRootUrl + "all", this.getHeaderTenant());
    }

    getCatalogById(catalogId: string): Observable<any> {
        return this.http.get<any>(this.productCatalogRootUrl + "?productCatalogIds=" + catalogId, this.getHeaderTenant());
    }

    deleteCatalogById(catalogIds: string[]): Observable<any> {
        return this.http.delete<any>(this.productCatalogRootUrl + "?productCatalogIds=" + catalogIds, this.getHeaderTenant());
    }

    //all products of catalog
    getAllCatalogProductById(catalogId: any): Observable<any> {
        return this.http.get<any>(this.productCatalogRootUrl + catalogId + "/products", this.getHeaderTenant());
    }

    getAllCatalogProductByQuery(catalogId, filters, itemsPerPage, pageNumber, sort): Observable<any> {
        var requestBody = {
            filters: filters,
            "itemsPerPage": itemsPerPage,
            "pageNumber": pageNumber,
            "retrieveInactive": true,
            "sort": sort

        }
        return this.http.post<any>(this.productCatalogRootUrl + catalogId + "/products/query", requestBody, this.getHeaderTenant());
    }

    deleteProductFromCatalog(catalogId: any, productId: any[]) {
        return this.http.delete<any>(this.productCatalogRootUrl + catalogId + "/products?productIds=" + productId, this.getHeaderTenant())
    }


    updateCatalog(catalogId: any, catalog: any): Observable<any> {
        let request = {};
        request["catalog"] = catalog;
        //console.log(request)
        return this.http.put<any>(this.productCatalogRootUrl + catalogId, request, this.getHeaderTenant());
    }




    getAllCatalogType(): Observable<any> {
        return this.http.get<any>(this.productCatalogTypeRootUrl, this.getHeaderTenant())
    }


    updateProductCatalogType(catalogType: any, catalogTypeCode: any): Observable<any> {
        let request = {};
        request['catalogType'] = catalogType;
        return this.http.put<any>(this.productCatalogTypeRootUrl + catalogTypeCode, request, this.getHeaderTenant());
    }



    createProductCatalogType(productCatalogType): Observable<any> {
        let request = {};
        request['catalogType'] = productCatalogType;
        return this.http.post<any>(this.productCatalogTypeRootUrl, request, this.getHeaderTenant());
    }

    addNewProductToCatalog(productId: any, catalogId: any): Observable<any> {
        let emptyBody;
        return this.http.put<any>(this.productCatalogRootUrl + catalogId + "/products/" + productId, emptyBody, this.getHeaderTenant());

    }

    deleteCatalogTypeByCode(productCatalogTypeCode): Observable<any> {
        return this.http.delete<any>(this.productCatalogTypeRootUrl + "?productCatalogTypeCodes=" + productCatalogTypeCode, this.getHeaderTenant());
    }


    // service for product Catalog fields start

    getCatalogFieldsByCatalogTypeCode(catalogTypeCode): Observable<any> {
        return this.http.get<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "/fields", this.getHeaderTenant());
    }

    updateCatalogFieldsByCatalogTypeCode(catalogFields): Observable<any> {
        let request = {};
        request['catalogFields'] = catalogFields;
        return this.http.put<any>(this.productCatalogTypeRootUrl + "{productCatalogTypeCode}/fields", request);
    }

    createCatalogFieldsByCatalogTypeCode(catalogTypeCode, field): Observable<any> {
        let request = {};
        request['field'] = field;
        return this.http.post<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "/fields", request, this.getHeaderTenant());
    }

    deleteCatalogFieldsByFieldsId(fieldsId, catalogTypeCode): Observable<any> {
        return this.http.delete<any>(this.productCatalogTypeRootUrl + catalogTypeCode + "?fieldIds=" + fieldsId, this.getHeaderTenant());
    }

    // service for product Catalog fields end




    handleError(error) {
        alert()
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}
