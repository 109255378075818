import { Component, OnInit, HostListener, Input } from '@angular/core';
import { CustomerService } from './customer-service/customer.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { SearchService } from 'src/app/services/search.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';



@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
 
  
  constructor(private customerService: CustomerService,
    private backgroundImageService: BackgroundImageService,
    private searchService: SearchService,
    private noDataService: NoDataService,
    private messageService: MessageSubjectService
   
) {
      this.customerService.ngOnInit();

    }
    

    // page : string =app
    customerList:  any[];
    memberStatusList: Array<any> = [];
    randomDate: Array<any> = [];
    randomSpend: Array<any> = [];
    searchName: any;
    filters:any;
    showCross: boolean = false;
    numberOfPages:number=1;
    noDataShow: Boolean = false;
    // pagination: Array<any> = [];
    pagination:any;
    totalItems:number;
    totalCustomer: any;
    currentPage : number =1;
    itemsPerPage :number = 10;
    sort: Array<any> = [];
    fields: any = {

    fieldName: null,
    sortDirection: null
  };
  



    ngOnInit() {
     
      this.customerService.ngOnInit();
      this.searchService.ngOnInit();
      // this.getCustomerList();
      // this.customerList = []
      // this.memberStatusList= []
      // this.getAllCustomerData();
    this.getCustomerPagination(this.filters,this.itemsPerPage,this.currentPage,this.sort);
  
    }
    

    
    getAllCustomerData() {
      this.customerList=[]
      this.customerService.getAllCustomers().subscribe((response) => {
        this.customerList= response.customer;
        console.log(response,'getallcustomerdata')
        this.totalCustomer = response.customers.length;
        this.noDataShow = this.noDataService.noData(this.customerList);
      });
    }

   
      
      getCustomerPagination(filters,itemsPerPage,pageNumber,sort) {
        this.memberStatusList = []
        this.customerList=[]
        this.pagination=null;
        this.searchService.getCustomerPagination(filters,itemsPerPage,pageNumber,sort).subscribe((response) => {
          console.log(response)
          console.log('getCustomerPagination')
          this.customerList=response.customers;
          console.log(response.customers,'pagination')
            this.pagination = response.pagination;
            this.totalItems = this.pagination.totalNumberOfRecords
            console.log(this.totalCustomer)
            this.numberOfPages=this.pagination.numberOfPages;
            console.log(this.numberOfPages)
            console.log(this.pagination.numberOfPages)
            this.getRandomDate(this.customerList);
            this.getRandomSpend(this.customerList)
          for(let i=0;i<response.customers.length;i++)
          {
           
            this.customerService.getLoyaltyProfileById(response.customers[i].id).subscribe(data=>
              {
                if(data.statusCode==200 && data.loyaltyProfile!==null ){
                this.memberStatusList[i] = true 
                }else{
                  this.memberStatusList[i] = false 
                }
              });
            }   
            this.noDataShow = this.noDataService.noData(this.customerList);
          });
        }
   
        
        hideCross() {
          this.showCross = false;
          // this.currentPage = 1;
          // this.filters['name'] = null;
          delete this.filters.name
          this.searchName = '';
          this.currentPage = 1;
          // this.getAllCustomerData()
          this.getCustomerPagination(this.filters,this.itemsPerPage,this.currentPage,this.sort);
          // this.searchName = '';
          // this.userName = '';

          // this.searchName = '';
          // this.filters[''] = null;
          // this.currentPage = 1;
          // this.searchName = '';
          
         
      
        }

        userName: string ;
        name:string;
        address:string;
        city:string;
        email:string;
        gender:string;
        landmark:string;
        postalCode:string;
        state:string;
 
        findName(event) {
          if(this.searchName != '') {
            // this.memberStatusList = []
            // this.customerList = [];
            // var fieldValue = null;
            // this.userName = this.searchName;
            // fieldValue = document.getElementById('search-name')['value'];

        var fieldValue = null;
        this.name = this.searchName;
        fieldValue = document.getElementById('search-name')['value'];
            
            this.filters = {
              
              "name":this.name,
              "address":this.address,
              "city":this.city,
              "email":this.email,
              "gender":this.gender,
              "landmark":this.landmark,
              "postalCode":this.postalCode,
              "state":this.state,
              "userName":this.userName
            }
            this.filters['name'] = this.searchName;  
            this.currentPage = 1;
            
            this.showCross = true;
            this.getCustomerPagination(this.filters,this.itemsPerPage,this.currentPage,this.sort);
          }

         
          }
        
        
       
          onChangePage(event) {
            this.currentPage = event;
            this.getCustomerPagination(this.filters,this.itemsPerPage,this.currentPage,this.sort);
           
          }
      
          addItemsPerPage(event) {
            this.itemsPerPage = event.target.value;
            this.currentPage = 1;
            console.log(this.itemsPerPage);
            this.getCustomerPagination(this.filters,this.itemsPerPage,this.currentPage,this.sort);
           
          }
          
          
          
          getCustomerList() {
            this.customerList = []
            this.memberStatusList = [];
            this.noDataShow = null;
            this.customerService.getAllCustomers().subscribe((response) => {
              this.noDataShow = this.noDataService.noData(response.customers);
              for(let i=0;i<response.customers.length;i++)
              {
                this.customerList[i] = response.customers[i];
                this.customerService.getLoyaltyProfileById(response.customers[i].id).subscribe(data=>
                  {
                    this.memberStatusList[i] = (data.registered) 
                  }
                  )                 
                }
              });
              
            }
            
            
            
            getRandomDate(customers){
              
              for(let i=0; i< customers.length; i++) {
                this.randomDate[i] = new Date(+(new Date()) - Math.floor(Math.random()*10000000000))
              }
              // return new Date(+(new Date()) - Math.floor(Math.random()*10000000000));
            }
            
            
            getFiveMinsBrefore()
            {
              var dt = new Date();
              dt.setMinutes( dt.getMinutes() - 5 );
              return dt;
            }
            
            getMemberOfLoyaltyStatus(customerId)
            {
              this.customerService.getLoyaltyProfileById(customerId).subscribe((data)=>
              {
                return data['registered']
                
              })
            }
            
            getRandomSpend(customers) {
              for(let i=0; i< customers.length; i++) {
                var min = Math.ceil(0);
                var max = Math.floor(15);
                this.randomSpend[i] = Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
              }
            }
            
            setUpBreadCrumb(key,labelName,path) {
              this.messageService.broadcast('bcLabelNew', {
                key: key,
                alternativeKey: key,
                labelName: labelName,
                path: path,
                child: []
              })
            }
            // getBackground(image) {
            //   return this.backgroundImageService.getBackground(image);
            // }

            async onSelectSort(event) {
              this.sort[0] = this.fields
              console.log(this.sort)
              
              if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
                
                this.sort[0].sortDirection = await event.target.value
                this.sort[0].fieldName="firstName"
                console.log(this.sort)
              } else {
                this.sort[0].sortDirection = null;
               
              }
          
              this.getCustomerPagination(this.filters,this.itemsPerPage,this.currentPage,this.sort);
              // this.getCustomerByFilter(this.filtersArray,this.itemsPerPage,this.currentPage,this.sort);
          
            }

      
            
          }
          