import { Injectable } from '@angular/core';
import { FieldRefService } from '../field-ref-service/field-ref.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProductFieldRefService implements FieldRefService{
  
  
  constructor(private http: HttpClient) {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.tenant = this.user.tenant;
  }
  
  user: any;
  tenant: any;
  headers: Headers;
  
  getHeaderTenant() {
    return { headers: { 'X-TENANT': this.tenant } }
  }
  
  // serverRootUrl = environment.BASE_URL;
  serverRootUrl = environment.PRODUCT_CATEGORIES_BASE_URL;
  // serverRootUrl = "http://ec2-54-197-219-77.compute-1.amazonaws.com"
  // serverRootUrl = "http://ec2-35-175-79-20.compute-1.amazonaws.com"
  // serverRootUrl = "35.175.79.20"
  productCategoryRootUrl = this.serverRootUrl + "/api/v1/productcategories/";
  
  
  getAllFieldsByTypeCode(categoryId): Observable<any> {
    return this.http.get<any>(this.productCategoryRootUrl+categoryId+"/products/"+"fields", this.getHeaderTenant())
  }
  
  createFieldsByTypeCode(field,categoryId): Observable<any> {
    let request = {};
    request['field'] = field;
    return this.http.post<any>(this.productCategoryRootUrl+categoryId + "/products/fields", request, this.getHeaderTenant());
  }
  
  deleteFieldsByFieldIds(fieldIds, categoryId): Observable<any> {
    return this.http.delete<any>(this.productCategoryRootUrl+categoryId+"/products/fields?fieldIds="+fieldIds, this.getHeaderTenant())
  }
  
  updateFieldByFieldId(fieldId,field, categoryId) {
    let request = {};
    request['field'] = field;
    return this.http.put<any>(this.productCategoryRootUrl+categoryId+"/products/fields/"+fieldId, request, this.getHeaderTenant());
  }
}
