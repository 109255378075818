export class loyaltyRules {
 public EventRuleType = new Map();


 constructor() {
    this.EventRuleType.set('LOYALTY_FIRST_LOGIN', "Customer earned points after logging into the loyalty program");
    this.EventRuleType.set("LOYALTY_USER_REGISTERED","Customer earned points after registration to loyalty program");
    this.EventRuleType.set("CUSTOMER_REFERRAL","Customer earns points for referral");
 }



 public getEventRuleTypeKeys():any {
   let eventRuleTypeKey:Array<any> = [];
   for (let key of this.EventRuleType.keys()) {
      eventRuleTypeKey.push(key);
   }
   //console.log(eventRuleTypeKey)
   return eventRuleTypeKey;
}

public getEventRuleTypeValues(){
   let eventRuleTypeValue: Array<any> = [];
   for(let value of this.EventRuleType.values()) {
      eventRuleTypeValue.push(value);
   }
   return eventRuleTypeValue;
}


}