import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NestedCategoriesService {

  dropdownElements: Array<any> = [];

  constructor() { }

  getNestedProductCategories(categories: any[]) {
    this.dropdownElements = [];    
    var idMap = {};
    this.dropdownElements = [];
    for (var i = 0; i < categories.length; i++) {
        categories[i]["children"] = [];
        idMap[categories[i].id] = categories[i];
        // //console.log(idMap)
    }

    // Pass 2 link the children of a category with their parents
    for (var i = 0; i < categories.length; i++) {
        if (categories[i].parentId != null) {
            try {
                idMap[categories[i].parentId].children.push(categories[i]);
            } catch (error) {
                // //console.log(error)
            }
        }
    }


    // Pass 3 generate the category dropdown list

    for (var i = 0; i < categories.length; i++) {
        if (categories[i].parentId == null) {
            this.recurse(0, this.dropdownElements, categories[i]);
        }
    }
    // //console.log(this.dropdownElements)
    return this.dropdownElements;
}

recurse(depth, dropdownElements, currentCategory) {
    var line = "";
    let dept: Array<any> = [];
    for (var i = 1; i <= depth; i++) {
        dept.push(line);
    }

    // line += currentCategory.name;

    dropdownElements.push({ value: currentCategory.id, text: currentCategory.name, indentation: dept });
    for (var i = 0; i < currentCategory.children.length; i++) {
        this.recurse(depth + 1, dropdownElements, currentCategory.children[i]);
    }
}
}
