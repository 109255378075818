import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PopupDeleteResetDialogComponent } from './popup-delete-reset-dialog.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupDeleteResetDialogService {
  public isDialogOpen: Boolean = false;
  constructor(public dialog: MatDialog) { }
  openDialog(data): any {
      if (this.isDialogOpen) {
          return false;
      }
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(PopupDeleteResetDialogComponent, {
          width: '400px',
          data: data
      });

      dialogRef.afterClosed().subscribe(result => {
          // //console.log('The dialog was closed');
          this.isDialogOpen = false;
          let animal;
          animal = result;
      });
  }

  closeDialog(){
    this.dialog.closeAll();
  }


  public receiveFromDialog:BehaviorSubject<any> = new BehaviorSubject<any>("");


  // Service message commands
 

  getResponseComponentMethod(value:any){
    this.receiveFromDialog.next(value);
  }

  clearResponse(){
    this.receiveFromDialog.next("");
  }
}
