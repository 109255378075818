import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogRef, MatExpansionModule, MatMenuModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'src/app/directives/modal/modal.module';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { AdminModule } from '../admin.module';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { LoyaltyPointsComponent } from './customer-details/loyalty-points/loyalty-points.component';
import { LoyaltyProfileComponent } from './customer-details/loyalty-profile/loyalty-profile.component';
import { OrderHistoryComponent } from './customer-details/order-history/order-history.component';
import { ViewCustomerDetailsComponent } from './customer-details/view-customer-details/view-customer-details.component';
import { CustomerService } from './customer-service/customer.service';
import { CustomersRoutingModule } from './customers-routing.module';
import { CustomersComponent } from './customers.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OrderHistoryDetailsComponent } from './customer-details/order-history-details/order-history-details.component';




@NgModule({
  declarations: [
    CustomersComponent,
    CustomerDetailsComponent,
    ViewCustomerDetailsComponent,
    LoyaltyPointsComponent,
    OrderHistoryComponent,
    OrderHistoryDetailsComponent,
    LoyaltyProfileComponent
  ],
  imports: [
    CommonModule,
    CustomersRoutingModule,
    MatMenuModule,
    NgbModule,
    AdminModule,
    MatButtonModule,
    ModalModule,
    MatExpansionModule,
    FormsModule,
    PipesModule,
    NgxPaginationModule,

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
    
   ],
  providers: [
    CustomerService,
    DeleteDialogService,
    DatePipe,
    {provide: MatDialogRef, useValue: {}}
   
    
  ],
  schemas: [
    NO_ERRORS_SCHEMA]
})

export class CustomersModule { 
  
}
  // required for AOT compilation
  export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);;
  }