import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormGroupDirective, NgForm } from '@angular/forms';
import { AdminService } from 'src/app/admin/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { v4 as uuid } from 'uuid';
import { environment } from 'src/environments/environment.prod';
import { Subscription } from 'rxjs';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { DateValidationsService } from 'src/app/shared/date-validations/date-validations.service';
import cleanDeep from 'clean-deep'


@Component({
  selector: 'app-addnew-product',
  templateUrl: './addnew-product.component.html',
  styleUrls: ['./addnew-product.component.css']
})
export class AddnewProductComponent implements OnInit {

  @ViewChild('addNewProductForm', { static: false }) myForm: NgForm;
  addProductForm: NgForm;
  formDirective: FormGroupDirective;
  product: any;
  selectedFiles: FileList;
  categoryId: any;
  uid: any;
  productFields: any[];
  fields: any;
  value: Array<any> = [];
  user: any;
  basePrice: any;
  price: any;
  convertedTypeField: Array<any> = [];
  ck: Array<Boolean> = [];
  checkbox = "checkbox";
  prodOptions: Array<Boolean> = []
  productOptions: Array<any> = []
  currentFields: any;
  currentImageRef: any;
  subscription: Subscription;
  categoryName: any;
  futurePriceArray: any;
  featurePriceFields: Array<any> = []
  listFlag: Array<Boolean> = [];
  multipleArray: Array<any> = [];
  listArray: Array<any> = [];
  breadCrumbs: any;
  lastIndexOfMultipleArray: Array<any> = [];
  category: any;
  restrictedFromDate: any
  restrictedThroughDate: any
  workflowEnabled = environment.WORKFLOW_ENABLED
  workflowTenant : Boolean=false;
  constructor(private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private imageUploadService: ImageUploadService,
    private deleteDialogService: DeleteDialogService,
    private modalService: ModalService,
    private breadService: BreadcrumbService,
    private messageService: MessageSubjectService,
    private dateValidations: DateValidationsService) { }



  private newMethod() {
    return 'addProductForm';
  }

  ngOnInit() {

    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.breadCrumbs = this.breadService.getBread();
    if(environment.WORKFLOW_TENANT.includes(this.user.tenant)){
      this.workflowTenant=true
    }
    this.getProductFieldsByCategoryId();
    console.log('addproduct')
    this.model();

    this.getCategory();

    //console.log(this.featurePriceFields)

    // this.futurePriceArray = {
    //   price: '',
    //   type: '',
    //   date: ''
    // }
  }

  deleteAfter(name, url) {
    var obj = new Object();
    obj["name"] = name;
    obj["url"] = url;
    this.breadService.deleteAfter(obj);
    this.router.navigateByUrl(url);
  }

  addMultipleListValue(index) {
    this.multipleArray[index].push(({
      listValue: ''
    }));
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }

  removeListValue(index, k) {
    this.multipleArray[index].splice(k, 1);
    this.lastIndexOfMultipleArray[index] = this.multipleArray[index].length - 1;
  }


  model() {
    this.product = {
      name: '',
      description: '',
      fromDate: '',
      throughDate: '',
      imageRef: '',
      createdBy: this.user.name,
      // modifiedBy: this.user.name,
      categoryId: this.categoryId,
      fields: {},
      externalSystemRef: '',
    }
  }

  // resetForm(formDirective: FormGroupDirective) {
  //   if (this.addProductForm.valid) {
  //     formDirective.resetForm();
  //     this.addProductForm.reset();
  //   }
  // }


  resetForm() {

    this.openModal();

    this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
      if (res == "yes") {
        this.closeModal();
        this.ngOnInit();
        this.myForm.resetForm();
        // this.price = "";
        this.value = [];
        this.currentImageRef = "";
        this.selectedFiles = null;
        this.categoryName = "";
        this.restrictedFromDate = null;
        this.restrictedThroughDate = null;
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();

      } else if (res == "no") {
        this.closeModal()
        this.subscription.unsubscribe();
        this.deleteDialogService.clearResponse();
      }
    })
  }

  openModal() {
    let data = {};
    data = {
      value: 'reset'
    };
    this.deleteDialogService.openDialog(data);
  }
  closeModal() {
    this.deleteDialogService.closeDialog();
  }

  onCheck(index) {
    this.ck[index] = !this.ck[index];
    this.value[index] = this.ck[index];
    //console.log(this.ck)
  }


  futurePriceFlag: Boolean = false;

  getProductFieldsByCategoryId() {
    this.route.paramMap.subscribe((map) => {
      this.categoryId = map.get("categoryId");
      this.adminService.getProductFieldsByCategoryId(this.categoryId).subscribe((data) => {
        this.productFields = data.fields;
        //console.log(this.productFields);
        for (let i = 0; i < this.productFields.length; i++) {
          this.multipleArray[i] = new Array<any>();
          this.lastIndexOfMultipleArray[i] = new Array<number>();
          this.listArray[i] = new Array<any>()
          if (this.productFields[i].list) {
            this.lastIndexOfMultipleArray[i] = 0;
            this.multipleArray[i].push({
              listValue: ''
            })
            this.lastIndexOfMultipleArray[i] = 0;
          }
        }
        this.optionFieldsCondition(this.productFields);
      });
    });
  }

  getCategory() {
    this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
      this.category = data.categories[0];
      this.categoryName = data.categories[0].name;
    })
  }


  optionFieldsCondition(productFields) {
    if (productFields != null) {
      for (let i = 0; i < productFields.length; i++) {

        if (productFields[i].options) {
          if ((productFields[i].options.length == 1) || (productFields[i].options.length == 0)) {
            this.prodOptions[i] = false;
            // this.productOptions = productFields[i].options;
          }
          else {
            this.prodOptions[i] = true;
          }
        }
        if (this.productFields[i].list) {
          this.listFlag[i] = true;
        }
        if (productFields[i].type == "BOOLEAN") {
          this.value[i] = false;
        }
        let type = productFields[i].type;
        this.convertedTypeField[i] = (environment.typeConversion[type]);
      }
    }
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.currentImageRef = reader.result;
    }
  }

  resetImage() {
    this.selectedFiles = null;
    this.currentImageRef = "";
  }

  validateFromThroughDate() {
    this.dateValidations.validateFromThroughDate(this.product)
  }


  async onSubmit() {
    this.validateFromThroughDate()
    if (this.productFields != null) {
      this.fields = {};
      for (let i = 0; i < this.productFields.length; i++) {
        let fieldName = this.productFields[i].key;

        if (this.productFields[i].type == "INTEGER") {
          this.product.fields[fieldName] = parseInt(this.value[i])
        } else if (this.productFields[i].type == "DECIMAL") {
          this.product.fields[fieldName] = parseFloat(this.value[i])
        }
        else {
          this.product.fields[fieldName] = this.value[i];
        }
        if (this.productFields[i].list) {
          for (let j = 0; j < this.multipleArray.length; j++) {
            //console.log(this.multipleArray[j].length)
            if (this.multipleArray[j].length > 0) {
              for (let k = 0; k < this.multipleArray[j].length; k++) {
                //console.log(this.multipleArray[j].length)
                this.listArray[j][k] = (this.multipleArray[j][k].listValue)
              }
            }
          }
          if (this.listArray[i].length > 0) {
            if (this.productFields[i].type == "INTEGER") {
              this.product.fields[this.productFields[i].key] = this.listArray[i].map(numStr => parseInt(numStr))
            } else if (this.productFields[i].type == "DECIMAL") {
              this.product.fields[this.productFields[i].key] = this.listArray[i].map(numStr => parseFloat(numStr))
            } else {
              this.product.fields[this.productFields[i].key] = this.listArray[i]
            }

          }
          else {
            this.multipleArray[i] = []
            this.product.fields[this.productFields[i].key] = []
          }
        }
      }
     
      // console.log("this.fields", this.product.fields)
      // for (const [key, value] of Object.entries(this.product.fields)) {
      //   console.log(`${key}: ${value}`);
      //   if(value == null || value == NaN || value == 'undefind') {
      //     console.log("this.product.fields[key]",this.product.fields[key])
      //     delete this.product.fields[key]
      //   }
      // }
      //  //console.log(this.futurePriceArray)
      
      //   this.product.fields['future_price'] = this.futurePriceArray.price
      //   this.product.fields['future_type'] = this.futurePriceArray.type
      //   this.product.fields['change_from'] = this.futurePriceArray.date
      
      //console.log(this.product.fields)
    } 
   let fields = await cleanDeep(this.product.fields)
         for (let [key, value] of Object.entries(fields)) {
        if(Number.isNaN(value)) {
          delete fields[key]
        }
      }
      this.product.fields = fields
    // this.product.basePrice["value"] = this.price;
    // this.product.basePrice["currency"] = "USD";


    if (this.selectedFiles != null) {
      const file = this.selectedFiles.item(0);

     await this.imageUploadService.uploadImage(file).subscribe(async(data) => {
        this.product.imageRef = data.documentId;

        await this.addNewProductServiceCall();
      })
    } else {
     await this.addNewProductServiceCall();
    }
  }

  addNewProductServiceCall() {
    if (this.workflowEnabled && this.workflowTenant) {
      this.adminService.addWorkflowProduct(this.product).subscribe((data) => {
        if (!this.category['parentId']) {
          this.router.navigate(["/categorydetails/" + this.categoryId + '/' + this.categoryName + "/categoryproduct/" + this.categoryId])
        } else {
          this.messageService.broadcast('splice', {
            key: 'addnewproduct',
            alternativeKey: 'addnewproduct'
          })
          this.router.navigate(["/subcategorydetails/" + this.categoryId + '/' + this.categoryName + "/categoryproduct/" + this.categoryId])
        }
      });
    } else {
      this.adminService.addProduct(this.product).subscribe((data) => {
        if (!this.category['parentId']) {
          this.router.navigate(["/categorydetails/" + this.categoryId + '/' + this.categoryName + "/categoryproduct/" + this.categoryId])
        } else {
          this.messageService.broadcast('splice', {
            key: 'addnewproduct',
            alternativeKey: 'addnewproduct'
          })
          this.router.navigate(["/subcategorydetails/" + this.categoryId + '/' + this.categoryName + "/categoryproduct/" + this.categoryId])
        }
      });
    }

  }
  onThroughDate(date) {
    this.restrictedFromDate = this.dateValidations.onThroughDate(date)
  }

  onFromDate(date) {
    this.restrictedThroughDate = this.dateValidations.onFromDate(date)
  }
}

