import { Component, OnInit } from '@angular/core';
import { FeatureOptionRefComponent } from '../../feature-ref/feature-option-ref/feature-option-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ProductFeatureRefService } from '../../../product-feature-service/product-feature-ref-service/product-feature-ref.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';

@Component({
  selector: 'app-product-feature-option-ref',
  templateUrl: '../../feature-ref/feature-option-ref/feature-option-ref.component.html',
  styleUrls: ['./product-feature-option-ref.component.css']
})
export class ProductFeatureOptionRefComponent extends FeatureOptionRefComponent implements OnInit {

  featureRefService: any;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public imageUploadService: ImageUploadService,
    public ngbModalService: NgbModal,
    public sanitizer: DomSanitizer,
    public http: HttpClient,
    public backgroundImageService: BackgroundImageService,
    public adminService: AdminService,
    public nestedCategory: NestedCategoryService,
    public messageService: MessageSubjectService) { 
      super(route, router,imageUploadService, ngbModalService, sanitizer,http,backgroundImageService,adminService,nestedCategory,
        messageService);

      this.featureRefService = new ProductFeatureRefService(this.http);
    }

  ngOnInit() {
    this.getFeature();
    this.editFeature = "editproductfeature";
    this.optionFeatureLink = '/productdetails/' + this.id + this.featureId + '/addproductfeatureoption/' + this.id + this.featureId
  }
  
  getFeature() {
    this.features = [];
    this.featureOptions = [];
    this.productIds = [];
    this.featureRefService.getAllFeatures(this.id).subscribe((data) => {
      this.features = data.productWithFeatures.features;
      for (let i = 0; i < this.features.length; i++) {
        if (this.features[i].id == this.featureId) {
          this.currentFeature = this.features[i];
          this.featureOptionProducts = this.currentFeature.options;
          if(this.currentFeature.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: 'productfeaturedetails',
              alternativeKey: 'productfeaturedetails',
              labelName: 'Feature: '+this.currentFeature.name,
              path: this.router.url
            })
          }
          this.featureOptions =this.features[i].options;
          
          let options = []

          for(let j=0; j< this.currentFeature.options.length; j++) {
              let option = {
                productId: this.currentFeature.options[i].productId,
                name: this.currentFeature.options[i].name,
                description: this.currentFeature.options[i].description,
                fields: this.currentFeature.options[i].fields,
                modifiedBy: this.currentFeature.options[i].modifiedBy,
              }

              options.push(option)
          }

          this.previousImageRef = this.currentFeature.imageRef;
          this.editFeatureModel = {
            name: this.currentFeature.name,
            description: this.currentFeature.description,
            defaultUseCount: this.currentFeature.defaultUseCount,
            maxUseCount: this.currentFeature.maxUseCount,
            minUseCount: this.currentFeature.minUseCount,
            price: this.currentFeature.price,
            imageRef: this.currentFeature.imageRef,
            type: this.currentFeature.type,
            modifiedBy: this.user.name,
            options: options,
            fields: this.currentFeature.fields
          }
          console.log(this.editFeatureModel)
          break;
        }
      }
    });
  }

}
