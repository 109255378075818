import { Component, OnInit } from '@angular/core';
import { AddnewFeatureRefComponent } from '../../feature-ref/addnew-feature-ref/addnew-feature-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageUploadService } from 'src/app/admin/image-upload.service';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { HttpClient } from '@angular/common/http';
import { CategoryFeatureRefService } from '../../../product-feature-service/category-feature-ref-service/category-feature-ref.service';
import { FeatureTypeRefServiceService } from 'src/app/admin/api-configuration/api-configuration-services/type-services/feature-type-ref-service/feature-type-ref-service.service';
import { FeatureFieldRefService } from 'src/app/admin/api-configuration/api-configuration-services/field-services/feature-field-ref-service/feature-field-ref.service';
import { AdminService } from 'src/app/admin/admin.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { CasscadeToProductModalService } from 'src/app/shared/casscadetoproduct-modal/casscadetoproduct-modal.service';

@Component({
  selector: 'app-addnew-category-feature-ref',
  templateUrl: '../../feature-ref/addnew-feature-ref/addnew-feature-ref.component.html',
  // styleUrls: ['./addnew-category-feature-ref.component.css']
})
export class AddnewCategoryFeatureRefComponent extends AddnewFeatureRefComponent implements OnInit {

  featureRefService: any;
  typeRefService: any;
  fieldRefService: any;
  categoryName: any;
  
  constructor(
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public router: Router,
    public imageUploadService: ImageUploadService,
    public deleteDialogService: DeleteDialogService,
    public casscadeToProductModalService : CasscadeToProductModalService,
    public modalService: ModalService,
    public http: HttpClient,
    private adminService: AdminService,
    public messageService: MessageSubjectService,
    public translate : TranslateService) {
      super(route, sanitizer, router,imageUploadService, deleteDialogService, casscadeToProductModalService,modalService, http,messageService,translate);
      this.featureRefService = new CategoryFeatureRefService(this.http);
      this.typeRefService = new FeatureTypeRefServiceService(this.http);
      this.fieldRefService = new FeatureFieldRefService(this.http);
     }

  ngOnInit() {
    console.log('category-features1')
    this.isFeatureCategory = true
    this.getFeatureType()
    this.getCategory();
  }
  
  getCategory(){
    this.adminService.getCategoryById(this.id).subscribe((data) => {
      this.categoryName = data.categories[0].name;
      if(!data.categories[0]['parentId']) {
        this.routerFeatureLink = "/categorydetails/"+ this.id + '/' + this.categoryName + '/categoryfeatures/' + this.id;
      }else {
     
        this.routerFeatureLink = "/subcategorydetails/"+ this.id + '/' + this.categoryName + '/categoryfeatures/' + this.id;
      }
    })
  }


}
