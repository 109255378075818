import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/admin/admin.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NestedCategoryService } from 'src/app/services/nested-category.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { sortConstant } from 'src/app/shared/constants/sort-constant';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { StoreService } from '../../../services/store.service';
import { StoreFilters } from 'src/app/shared/constants/filter-model/store-filters.model';

@Component({
  selector: 'app-get-all-sub-stores',
  templateUrl: './get-all-sub-stores.component.html',
  styleUrls: ['./get-all-sub-stores.component.css']
})
export class GetAllSubStoresComponent implements OnInit {
  
  itemCheckProduct: boolean = false;
  allCheckProduct: boolean = false;
  checkboxItemsProduct: Array<boolean> = [];
  myValueProduct: boolean = false;
  checkAllCategories: Array<any> = []
  subStores: any[];
  itemCheck: boolean = false;
  allCheck: boolean = false;
  myValue: boolean = false;
  checkboxItems: Array<boolean> = [];
  checkboxCategories: Array<boolean> = []
  flag: boolean = false;
  dropdownElements: Array<any> = [];
  categories: any;
  subscription: Subscription;
  parentCode: any;
  getAllNestedCategory: Array<any> = []
  category: any;
  unFilteredCategories: any;
  currentStore: any;
  currentCat: any;
  categoriesInStore: Array<any> =[]
  renderCategoryInStore : Array<any> = []
  productsInCategory: Array<any> = []
  productsArray: Array<any> =[]
  categoryId : any;
  categoryProducts: any;
  categoriesAddedInStore: Array<any> = [];
  arrayOfCategoryIds: Array<any> = [];
  noDataShow: Boolean = false;
  deleteArray : Array<any> = [];
  addArray: Array<any> = [];
  noProducts: Boolean = false;
  allCheckCategory: Boolean = false;
  searchName: any;
  currentPage : number =1;
  itemsPerPage :number = 10;
  numberOfPages :number=1;
  totalItems: number;
  filters: StoreFilters;
  pagination: any;
  types: any;
  // sort: Array<any> = sortConstant.SUBTORE_SORT
  sort: Array<any> = [];
  fields: any = {

    fieldName: null,
    sortDirection: null
  };
  // storeRequest: any = {
  //   filters: this.filters,
  //   "itemsPerPage": this.itemsPerPage,
  //   "pageNumber": this.currentPage,
  //   "retrieveInactive": true,
  //   "sort": sortConstant.SUBTORE_SORT
  // }
  
  
  @ViewChild('addCategory',{static: true}) public addCategory: ElementRef;
  @ViewChild('addProduct',{static: true}) public addProduct: ElementRef;
  
  
  constructor(private storeService: StoreService,
    private deleteDialogService: DeleteDialogService,
    private route: ActivatedRoute,
    private backgroundImageService: BackgroundImageService,
    private adminService: AdminService,
    private nestedCategory: NestedCategoryService,
    public modalService: ModalService,
    private ngbModalService: NgbModal,
    private noDataService: NoDataService,
    private breadService: BreadcrumbService,
    private router: Router,
    private messageService: MessageSubjectService) {
      this.filters = new StoreFilters();
    }
    panelOpenState: any;
    
    config = {
      hasSelect: false
    }
    
    ngOnInit() {
      this.route.paramMap.subscribe((map) => {
        this.parentCode = map.get("storecode");
        this.getAllCat()
        this.panelOpenState = false;    
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
        this.getTypes()
        this.getCurrentStore();
        this.getCategoriesAddedInStore()
      });
    }
    
    openModal(id:string){
      this.modalService.open(id);      
    } 
    
    closeModal(id: string) {
      
      this.modalService.close(id);
      this.ngOnInit()
    }
    
    
    getAllCat() {
      this.adminService.getAllCategory().subscribe((data) => {
        this.unFilteredCategories= data.categories;
      });
    }
    
    getAllCategory() {
      return new Promise((resolve,reject) => {
        this.getAllNestedCategory = []
        this.categories = []
        this.allCheckCategory = false;
        if(this.currentStore.parentCode != null) {
          this.getCategoriesAddedInStore()
          this.storeService.getAllCategoryByStoreCode(this.currentStore.parentCode).subscribe((data) => {
            this.categories = data.categories;
            this.getAllNestedCategory = this.getProductCategoryTree(this.categories);
            this.disabledAddedCategory(this.getAllNestedCategory).then(res => this.checkInitialForAllCategory(this.getAllNestedCategory));
          });    
        }else {
          this.getCategoriesAddedInStore()
          this.adminService.getAllCategory().subscribe((data) => {
            this.categories = data.categories;    
            this.getAllNestedCategory = this.getProductCategoryTree(this.categories);
            this.disabledAddedCategory(this.getAllNestedCategory).then(res => this.checkInitialForAllCategory(this.getAllNestedCategory));
          }); 
        }
        resolve();
      });
    }
    
    readOnlyFlag: Array<any> = []
    
    getCategoriesAddedInStore() {
      this.arrayOfCategoryIds = [];
      this.renderCategoryInStore = [];
      this.storeService.getAllCategoryByStoreCode(this.parentCode).subscribe((data) => {
        this.categoriesInStore = data.categories;
        this.renderCategoryInStore = this.nestedCategory.getProductCategoryTree(this.categoriesInStore);
        for(let i=0; i< this.categoriesInStore.length; i++) {
          this.arrayOfCategoryIds[i] = this.categoriesInStore[i].id;
        }
      });
    }
    
    
    
    
    disabledAddedCategory(tree) {
      return new Promise((resolve,reject) => {
        for(let i = 0;i< tree.length;i++){
          if(this.arrayOfCategoryIds.includes(tree[i].id)) {
            // tree[i].selected = true;
            // tree[i].disabled = true;
            tree[i].current = "checked"
            tree[i].initial = "checked"
          }
          if(tree[i].children.length > 0) {
            this.disabledAddedCategory(tree[i].children)
          }
        }
        resolve();
      });
    }
    
    checkInitialForAllCategory(tree) {
      let checkAll = true;
      for(let i = 0;i< tree.length;i++){
        if(tree[i].initial == "unchecked") {
          checkAll = false;
          break;
        }
        if(tree[i].children.length > 0) {
          this.checkInitialForAllCategory(tree[i].children);
        }
      }
      this.allCheckCategory = checkAll
    }
    
    
    
    getProductCategoryTree(categories) {
      var idMap = {};
      // Pass 1 collect all categories into a dictionary using the ID of the category as the key
      for (var i = 0; i < categories.length; i++) {
        categories[i]["children"] = [];
        idMap[categories[i].id] = categories[i];
        // categories[i].selected = false;
        // categories[i].disabled = false;
        categories[i].initial = "unchecked";
        categories[i].current = "unchecked";
        categories[i].changed = categories[i].current != categories[i].initial;
      }
      // Pass 2 link the children of a category with their parents
      for (var i = 0; i < categories.length; i++) {
        if (categories[i].parentId != null) {
          try {
            idMap[categories[i].parentId].children.push(categories[i]);      
          }
          catch {
            
          }
        }
      }
      
      // Pass 3 generate the category dropdown list
      var dropdownElements = [];
      for (var i = 0; i < categories.length; i++) {
        if (categories[i].parentId == null) {
          categories[i].parentId = null;
          dropdownElements.push(categories[i]);
        }
      }
      return dropdownElements;
    }
    currentCategoryContainer: any;
    categoryid: Array<any> = [];
    
    
    
    // getAllSubStores() {      
    //   this.storeService.getChildStores(this.parentCode).subscribe((data) => {
    //     this.subStores = data.stores;
    //     this.noDataShow = this.noDataService.noData(this.subStores);
    //     for (let i = 0; i < this.subStores.length; i++) {
    //       this.checkboxItems[i] = false;
    //       this.subStores[i].imageRef =  this.getBackground(this.subStores[i].imageRef);
    //     }
    //   });
    // }
    
    getAllStoresByQuery(filters,itemsPerPage,pageNumber,sort) {
      this.subStores = [];
      this.pagination = null;
      this.allCheck = false;
      this.filters.parentCode = this.parentCode;
      this.storeService.getAllStoresByQuery(filters,itemsPerPage,pageNumber,sort).subscribe((data) => {
        this.subStores = data.stores;
        this.pagination = data.pagination;
        this.totalItems = this.pagination.totalNumberOfRecords;
        this.numberOfPages=this.pagination.numberOfPages;
        this.noDataShow = this.noDataService.noData(this.subStores);
        this.backgroundImage(this.subStores)
      })
    }
    
    backgroundImage(stores) {
      if(stores.length > 0) {
        this.checkboxItems = []
        for (let i = 0; i < stores.length; i++) {
          stores[i].imageRef = this.getBackground(stores[i].imageRef);
          this.checkboxItems[i] = false;
        }
      }
    }
    
    getCurrentStore() {
      this.storeService.getStoreByCode(this.parentCode).subscribe((data) => {
        this.currentStore = data.stores[0];
        if(!this.currentStore['parentCode']) {
          if(this.currentStore.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "regiondetails",
              alternativeKey: 'regiondetails',
              labelName: 'Region: '+decodeURIComponent(this.currentStore.name),
              path: decodeURI(this.router.url)
            });
          }
        }else {
          if(this.currentStore.name != null) {
            this.messageService.broadcast('bcLabelOverwrite', {
              key: "subregiondetails",
              alternativeKey: 'subregiondetails'+this.currentStore.code,
              labelName: 'Subregion: '+decodeURIComponent(this.currentStore.name),
              path: decodeURI(this.router.url)
            });
          }
        }
        this.getAllCategory()
      })
    }
    
    
    checkAll() {
      this.allCheck = !this.allCheck
      for (let a = 0; a < this.subStores.length; a++) {
        this.checkboxItems[a] = this.allCheck;
      }
    }
    
    onChange(i: number) {
      this.checkboxItems[i] = !this.checkboxItems[i];
      this.myValue = true;
      for (let a = 0; a < this.subStores.length; a++) {
        if (this.checkboxItems[a] == false) {
          this.myValue = false;
        }
      }
      this.allCheck = this.myValue;
    }
    
    // multiple delete method
    delete() {
      
      this.openModal1("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          for (let i = 0; i < this.subStores.length; i++) {
            if (this.checkboxItems[i] == true) {
              this.storeService.deleteStoreByCodes(this.subStores[i].id).subscribe((data) => {
                this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
              })
            }
          }
          this.closeModal1("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeModal1("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      })
      
    }
    
    
    getCategoryId(category,event) {
      if(event.target.checked) {
        if(category.parentId == null) {
          category.current = "checked";
          category.changed = category.current != category.initial
          this.checkForChildCategory(event,category,this.getAllNestedCategory);
        }
        else {
          if(category.children != null) {
            category.current = "checked";
            category.changed = category.current != category.initial;
            this.checkForChildCategory(event,category,this.getAllNestedCategory);
            this.checkForParentCategory(event,category, this.getAllNestedCategory);
          }else {
            category.current = "checked";
            category.changed = category.current != category.initial;
            this.checkForParentCategory(event,category, this.getAllNestedCategory);
            
          }
        }
        this.selectCheckAll(this.getAllNestedCategory)
      }
      else {
        if(category.parentId == null ) {
          category.current = "unchecked"
          category.changed = category.current != category.initial;
          this.unCheckForChildCategory(event,category,this.getAllNestedCategory)
        }else{
          if(category.children != null) {
            category.current = "unchecked"
            category.changed = category.current != category.initial;
            this.unCheckForChildCategory(event,category,this.getAllNestedCategory)  
          }
          else {
            category.current = "unchecked"
            category.changed = category.current != category.initial;
          }
        }  
        this.allCheckCategory = false;     
      } 
    }
    
    selectCheckAll(categories) {
      let checkAll = true;
      for(let i=0; i< categories.length; i++) {
        if(categories[i].current == "unchecked") {
          checkAll = false;
        }
        if(categories[i].children.length > 0) {
          this.selectCheckAll(categories[i].children);
        }
      }
      this.allCheckCategory = checkAll;
    }
    
    
    checkForChildCategory(event,currentCategory1,tree) {
      var currentCategory = currentCategory1
      for(let i=0; i<tree.length; i++) {
        var cat = tree[i];
        if(currentCategory1.id == tree[i].parentId) {
          // tree[i].selected = true;
          tree[i].current = "checked";
          tree[i].changed = tree[i].current != tree[i].initial
          // console.log(tree[i])
          currentCategory = cat;
          // this.categoryIdsArray.push(cat.id)
          // this.checkForDuplicateCategoryId(this.categoryIdsArray);
          this.checkForChildCategory(event,cat,this.getAllNestedCategory);
        }
        if(cat.children.length > 0 ){
          this.checkForChildCategory(event,currentCategory,cat.children)
        }
        
        // this.checkForDuplicateCategoryId(this.categoryIdsArray);
        
      }
    }
    
    unCheckForChildCategory(event, currentCategory1,tree) {
      var currentCategory = currentCategory1;
      for(let i=0; i<tree.length; i++) {
        var cat = tree[i]
        if(cat.parentId != null ) {
          if(currentCategory1.id == tree[i].parentId) {
            // tree[i].selected = false;
            tree[i].current = "unchecked"
            tree[i].changed =  tree[i].current !=  tree[i].initial;
            currentCategory = cat;
            // this.unqiueCategoryIds.splice(this.unqiueCategoryIds.indexOf(cat.id),1);
            this.unCheckForChildCategory(event,cat,this.getAllNestedCategory)
          }
        }        
        if(cat.children.length > 0 ){
          this.unCheckForChildCategory(event,currentCategory,cat.children)
        }
      }
    }
    
    checkForParentCategory(event,currentCategory1,tree) {
      var currentCategory = currentCategory1;
      for(let i=0 ; i<tree.length; i++) {
        var cat = tree[i];
        if(currentCategory.parentId == cat.id) {
          // tree[i].selected = true;
          tree[i].current = "checked";
          tree[i].changed = tree[i].current != tree[i].initial
          // console.log(tree[i])
          currentCategory = cat
          // this.categoryIdsArray.push(cat.id)
          // this.checkForDuplicateCategoryId(this.categoryIdsArray);
          // //console.log(this.unqiueCategoryIds)
          this.checkForParentCategory(event,cat, this.getAllNestedCategory)
        }
        if( cat.children.length > 0) {
          this.checkForParentCategory(event,currentCategory,cat.children)
        }
      }
    }
    
    
    
    
    unCheckForParentCategory(event,currentCategory1,tree) {
      var currentCategory = currentCategory1;
      for(let i=0 ; i< tree.length; i++) {
        var cat = tree[i];
        if(currentCategory.parentId == cat.id) {
          // tree[i].selected = false;
          tree[i].current = "unchecked"
          tree[i].changed =  tree[i].current !=  tree[i].initial;
          currentCategory = cat
          // this.checkForDuplicateCategoryId(this.categoryIdsArray);
          // this.unqiueCategoryIds.splice(this.unqiueCategoryIds.indexOf(cat.id),1)
          // //console.log(this.unqiueCategoryIds)
          // this.unCheckForParentCategory(event,cat, this.getAllNestedCategory)
        }
        if( cat.children.length > 0) {
          this.unCheckForParentCategory(event,currentCategory,cat.children)
        } 
      }
    }
    
    
    openModal1(id: string){
      let data = {};
      data = {
        value: 'delete'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeModal1(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    
    
    
    getBackground(image) {
      return this.backgroundImageService.getBackground(image);
    }
    
    subStoreCategory: Array<any> = [];
    onCategorySelect(i, id) {
      this.checkboxCategories[i] = !this.checkboxCategories[i]
      // //console.log(this.checkboxCategories)
      if(this.checkboxCategories[i] == true){
        this.subStoreCategory[i] = id;
      }
      // //console.log(this.subStoreCategory)
    }
    
    hideShowMethod(event,item) {
      item.childVisible =! item.childVisible;
    } 
    
    onCategorySubmit(id){
      this.deleteArray = [];
      this.addArray = [];
      this.checkForAddAndDeleteCategory(this.getAllNestedCategory).then(res => this.callApiForAddAndDeleteCategory());
    }
    
    checkForAddAndDeleteCategory(tree) {
      return new Promise((resolve,reject) => {
        for(let i=0; i< tree.length; i++) {
          if((tree[i].changed == true) && (tree[i].current == "unchecked") && (tree[i].initial == "checked")) {
            this.deleteArray.push(tree[i].id)
          }
          else if((tree[i].changed == true) && (tree[i].current == "checked") && (tree[i].initial == "unchecked")) {
            this.addArray.push(tree[i].id)
          }
          
          if(tree[i].children.length > 0) {
            this.checkForAddAndDeleteCategory(tree[i].children)
          }
        }
        
        resolve();
      });
    }
    checkAllCategory() {
      this.allCheckCategory = !this.allCheckCategory;
      let status = '';
      if(this.allCheckCategory) {
        status = "checked"
        this.checkAllNestedCategories(this.getAllNestedCategory,status);
      }else {
        status = "unchecked";
        this.checkAllNestedCategories(this.getAllNestedCategory,status);
      }
    }
    
    checkAllNestedCategories(categories,status) {
      for(let i=0; i< categories.length; i++) {
        if(categories.current != "checked") {
          categories[i]['current'] = status;
          categories[i]['changed'] = categories[i]['current'] != categories[i]['initial'];
        }
        if(categories[i].children.length > 0) {
          this.checkAllNestedCategories(categories[i].children,status);
        }
      }
    }
    
    
    callApiForAddAndDeleteCategory() {
      if(this.deleteArray.length > 0) {
        this.storeService.deleteCategoyByIds(this.parentCode,this.deleteArray).subscribe((data) => {
        });
      }
      
      if(this.addArray.length > 0) {
        this.storeService.addCatgoryInStore(this.parentCode,this.addArray).subscribe((data) => {
        })   
      }
      this.ngbModalService.dismissAll(this.addCategory);
      this.getCategoriesAddedInStore();      
    }
    
    resetCategories() {
      this.openResetModal("dialogBox");
      this.subscription = this.deleteDialogService.receiveFromDialog.subscribe((res) => {
        if (res == "yes") {
          this.getAllNestedCategory = this.getProductCategoryTree(this.categories);
          this.disabledAddedCategory(this.getAllNestedCategory).then(res => this.checkInitialForAllCategory(this.getAllNestedCategory));
          this.closeResetModal("dialogBox");
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
          
        } else if (res == "no") {
          this.closeResetModal("dialogBox")
          this.subscription.unsubscribe();
          this.deleteDialogService.clearResponse();
        }
      });
    }
    
    openResetModal(id: string){
      let data = {};
      data = {
        value: 'reset'
      };
      this.deleteDialogService.openDialog(data);
    }
    
    closeResetModal(id: string){
      this.deleteDialogService.closeDialog();
    }
    
    /* ----- Manage Products ----- */
    
    imageArray: Array<any> = [];
    onSelectCategory(event) {
      this.noProducts = false;
      this.categoryId = event.id;
      this.allCheckProduct = false;
      this.productsInCategory = [];
      this.adminService.getAllCategoryProduct(this.categoryId).subscribe((data) => {
        this.productsInCategory = data.products;
        this.noProducts =  this.noDataService.noData(this.productsInCategory);
        for(let i=0;i< this.productsInCategory.length; i++) {
          this.productsInCategory[i].imageRef = this.getBackground(this.productsInCategory[i].imageRef);
          this.checkboxItemsProduct[i] = false; 
          this.productsInCategory[i]['initial'] = "unchecked";
          this.productsInCategory[i]['current'] = "unchecked";
          this.productsInCategory[i]['changed'] =  this.productsInCategory[i]['current'] !=   this.productsInCategory[i]['initial'];
        }
        this.getProductAddedInCategory(this.categoryId);
      });
    }
    
    getProductAddedInCategory(categoryId) {
      this.storeService.getProductByCategoryId(categoryId, this.parentCode).subscribe((data) => {
        let checkAll = true;
        this.categoryProducts = data.products;
        for(let i=0; i< this.productsInCategory.length; i++) {
          for(let j=0; j< this.categoryProducts.length; j++) {
            if(this.categoryProducts[j].id == this.productsInCategory[i].id) {
              this.checkboxItemsProduct[i] = true;
              this.productsInCategory[i]['initial'] = "checked";
              this.productsInCategory[i]['current'] = "checked";
              this.productsInCategory[i]['changed'] =  this.productsInCategory[i]['current'] !=   this.productsInCategory[i]['initial'];
            }
          }
          if(this.productsInCategory[i].initial == "unchecked") {
            checkAll = false;
          }
        }
        this.allCheckProduct = checkAll;
      })
    }
    
    checkAllProduct() {
      this.allCheckProduct = !this.allCheckProduct
      for (let a = 0; a < this.productsInCategory.length; a++) {
        this.checkboxItemsProduct[a] = this.allCheckProduct;
        if(this.checkboxItemsProduct[a]) {
          this.productsInCategory[a]['current'] = "checked"
          this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['initial'];
          this.productsArray[a] = this.productsInCategory[a].id;
        }
        else {
          this.productsArray = [];
          this.productsInCategory[a]['current'] = "unchecked"
          this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['initial'];
          
        }
        
      }
    }
    
    onChangeProduct(i,product,event) {
      this.checkboxItemsProduct[i] = !this.checkboxItemsProduct[i];
      this.myValueProduct = true;
      for (let a = 0; a < this.productsInCategory.length; a++) {
        if (this.checkboxItemsProduct[a] == false) {
          this.myValueProduct = false;
          this.productsInCategory[a]['current'] = "unchecked" 
          this.productsInCategory[a]['changed'] = this.productsInCategory[a]['current'] != this.productsInCategory[a]['initial'];
        }
      }
      if(this.checkboxItemsProduct[i]) {
        this.productsArray.push(this.productsInCategory[i].id);
        this.productsInCategory[i]['current'] = "checked"
        this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['initial'];
        
      }else {
        this.productsInCategory[i]['current'] = "unchecked"
        this.productsInCategory[i]['changed'] = this.productsInCategory[i]['current'] != this.productsInCategory[i]['initial'];
        
        this.productsArray.splice(this.productsArray.indexOf(product.id),1);
      }
      this.allCheckProduct = this.myValueProduct;
      
    }
    deleteProductArray : Array<any> = [];
    addProductsArray:  Array<any> = [];
    addProductInCategory(id){
      this.deleteProductArray = [];
      this.addProductsArray = []
      this.checkForAddAndDeleteProduct(this.productsInCategory).then(res => this.callApiForAddAndDeleteProduct(id))
    }
    
    checkForAddAndDeleteProduct(productsInCategory) {
      return new Promise((resolve,reject) => {
        for(let i=0; i< this.productsInCategory.length; i++) {
          if((this.productsInCategory[i].changed == true) && (this.productsInCategory[i].current == "unchecked") && (this.productsInCategory[i].initial == "checked")) {
            this.deleteProductArray.push(this.productsInCategory[i].id)
          }
          else if((this.productsInCategory[i].changed == true) && (this.productsInCategory[i].current == "checked") && (this.productsInCategory[i].initial == "unchecked")) {
            this.addProductsArray.push(this.productsInCategory[i].id)
          }
        }
        resolve()
      });
    }
    
    callApiForAddAndDeleteProduct(id) {
      if(this.addProductsArray.length > 0) {
        this.storeService.addProductByCategoryIdInStore(this.categoryId,this.parentCode,this.addProductsArray).subscribe((data) => {
          
        });
      }
      if(this.deleteProductArray.length > 0) {
        this.storeService.deleteProductfromCategory(this.categoryId,this.parentCode,this.deleteProductArray).subscribe((data) => {
          
        });
      }
      this.closeProductModal(id);
      this.ngOnInit();
    }
    
    openCategoryModal(content) {
      // this.ngOnInit();
      this.getAllCategory();
      // this.getCategoriesAddedInStore();
      this.ngbModalService.open(content, { size: 'lg', windowClass: 'update-modal'});
    }
    
    
    
    
    closeCategoryModal(content) {
      this.ngbModalService.dismissAll(content);
    }
    
    handleBreadCrumb(name,route)
    {
      var content=new Object();
      content["name"]=name;
      content["url"]=route;
      this.breadService.setBread(content);
      this.router.navigateByUrl(route);
    }
    
    setUp(key,alternativeKey,labelName,path) {
      if(key == 'addnewsubstore') {
        
        this.messageService.broadcast('bcLabelNew', {
          afterBaseOnly: false,
          key: key,
          alternativeKey: alternativeKey,
          labelName: labelName,
          path: path,
          pathParamList: [],
          terminalOnly: false,
          child: []
        });
      }else {
        
        this.messageService.broadcast('nestedLabel', {
          afterBaseOnly: false,
          key: key,
          alternativeKey: alternativeKey,
          labelName: labelName,
          path: path,
          pathParamList: [],
          terminalOnly: false,
          child: []
        });
      }
    }
    
    openProductModal(content) {
      // this.getAllCategoryInStore()
      this.noProducts = false
      this.getCategoriesAddedInStore();
      this.ngbModalService.open(content, { size: 'lg', windowClass: 'update-modal'});
    }
    
    closeProductModal(content) {
      this.ngbModalService.dismissAll(content);
      this.productsArray = []
      this.renderCategoryInStore = [];
      this.productsInCategory = [];
    }
    
    // -------  pagination methods ------ //
    
    onChangePage(event) {
      this.currentPage = event;
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    addItemsPerPage(event) {
      this.itemsPerPage = event;
      this.currentPage = 1;
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    // ------ search bar method ----------- //
    
    findName(event) {
      this.searchName = event;
      if(this.searchName != '') {
        this.filters['name'] = this.searchName;  
        this.currentPage = 1;
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }
    hideCross(event) {
      this.filters['name'] = null;
      this.searchName = '';
      this.currentPage = 1;
      this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
    }
    
    // ------- type method ---------// 
    
    
    getTypes() {
      this.storeService.getAllStoreTypes().subscribe((data) => {
        this.types = data.storeTypes;
      })
    }
    
    onSelectType(event){
      this.currentPage = 1;
      if(event.target.value != '') {
        this.filters['type'] = event.target.value
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }else {
        this.filters['type'] = null;
        this.getAllStoresByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort);
      }
    }

    async onSelectSort(event) {
      this.sort[0] = this.fields
      console.log(this.sort)
      if ((event.target.value == 'ASCENDING') || (event.target.value == 'DESCENDING') ) {
        this.sort[0].sortDirection = await event.target.value
        this.sort[0].fieldName='name'
        console.log(this.sort)
      } else {
        this.sort[0].sortDirection = await null
  
      }
  
      this.getAllStoresByQuery(this.filters, this.itemsPerPage, this.currentPage, this.sort);
  
    }
    
  }
  