import { Component, OnInit } from '@angular/core';
import { FeatureRefComponent } from '../feature-ref/feature-ref.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DeleteDialogService } from 'src/app/shared/delete-dialog/delete-dialog.service';
import { ModalService } from 'src/app/directives/modal.sevice';
import { CategoryFeatureRefService } from '../../product-feature-service/category-feature-ref-service/category-feature-ref.service';
import { HttpClient } from '@angular/common/http';
import { BackgroundImageService } from 'src/app/shared/background-image/background-image.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { AdminService } from 'src/app/admin/admin.service';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { NoDataService } from 'src/app/services/no-data.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-category-feature-ref',
  templateUrl: '../feature-ref/feature-ref.component.html',
  styleUrls: ['./category-feature-ref.component.css']
})
export class CategoryFeatureRefComponent extends FeatureRefComponent implements OnInit {

  featureRefService: any;

  constructor( public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public deleteDialogService: DeleteDialogService,
    public modalService: ModalService,
    public http: HttpClient,
    public backgroundImageService: BackgroundImageService,
   
    public router: Router,
    public adminService: AdminService,
    public messageService: MessageSubjectService,
    public noDataService: NoDataService,
    public translate:TranslateService,
    public dataService:MessageService) {  super(route,sanitizer,deleteDialogService,modalService,http,
      backgroundImageService,router,adminService,messageService,noDataService,translate,dataService)
      
      this.featureRefService = new CategoryFeatureRefService(this.http)
    }

  ngOnInit() {
    // this.getAllFeatures();
    this.featureLink = "addnewcategoryfeature";
    this.featureDetails = "categoryfeaturedetails";
    this.editFeature = "editcategoryfeature";
    this.getTypes();
    this.getAllFeaturesByQuery(this.filters,this.itemsPerPage,this.currentPage,this.sort)
    //console.log(this.featureDetails)
    //console.log(this.editFeature)
    this.adminService.getCategoryById(this.categoryId).subscribe((data) => {
      if(!data['categories'][0]['parentId']) {
        if(data['categories'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "categorydetails",
            alternativeKey: 'categorydetails',
            labelName: 'Category: '+data['categories'][0].name,
            path: this.router.url
          });
        }
      }else {
        if(data['categories'][0].name != null) {
          this.messageService.broadcast('bcLabelOverwrite', {
            key: "subcategorydetails",
            labelName: 'Sub Category: '+data['categories'][0].name,
            alternativeKey: 'subcategorydetails'+data['categories'][0].id,
            path: this.router.url
          });
        }
      }
    })
 }


}
