import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'add-common-custom-fields',
    templateUrl: "add-common-custom-fields.component.html"
})
export class AddCommonCustomFieldsComponent implements OnInit {

    ngOnInit() {
        
    }
}