import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute ,ParamMap} from '@angular/router';
import { Urls } from '../../common/urls';
import { HeaderService } from 'src/app/services/header.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BreadcrumbComponent } from '../../breadcrumb/breadcrumb.component';
import { filter } from 'rxjs/operators';
import { MessageSubjectService } from 'src/app/services/message-subject.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/admin/admin.service';
import { environment } from 'src/environments/environment.prod';
import { NoDataService } from 'src/app/services/no-data.service';
import { LoaderComponent } from '../../loader/loader.component';
import { LoaderService } from '../../loader/loader.service';
import { JbpmUsersService } from 'src/app/services/jbpm-users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @ViewChild('selectLanguage',{static:true}) public selectLanguage: ElementRef;
  logoImage: string;
  user: any;
  headerDetails: Array<any> = [];
  aciveFlag: Array<Boolean> = [];
  parentQueryParams: any;
  parentFragment: any;
  selectLanguages:any
  language:string
  myMap=new Map<string,string>()
  workflowEnabled = environment.WORKFLOW_ENABLED
  workflowTenant : Boolean=false;
  ownerTaskList: any = [];
  noDataShow: Boolean = false;
  timer: any;
  showTask:Boolean=false;

  constructor(private router: Router,
    private headerService: HeaderService,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private messageService: MessageSubjectService,
    private noDataService: NoDataService,
    private adminService: AdminService,
    private jbpmService:JbpmUsersService,
    //private dataService: MessageService,
    private loaderService: LoaderService,

    public translate:TranslateService) {
      this.getHeaderDetails()
      this.myMap = new Map([ ["En", "English"], ["Fr", "Français"]]);
      translate.setDefaultLang('En');
   }

   btns: any;
   @Output() menuItemVisited: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    
    this.user = JSON.parse(sessionStorage.getItem("user"));
    if(environment.WORKFLOW_TENANT.includes(this.user.tenant)){
      this.workflowTenant=true
    }
    
    if(this.workflowEnabled && this.workflowTenant){
     
     this.getTaskDetails();
     
    }
    
    
    this.logoImage = Urls.LOGO_URL;
    let pThis: any = this;
    this.router.events.pipe(filter(value => value instanceof NavigationEnd)).subscribe((value: any) => {
        //Save any parent queryParams in advance in case next actived route is secondary route.                        
        pThis.route.queryParamMap.subscribe((paramMap) => {
            if (Object.keys(paramMap.params).length > 0) {
                pThis.parentQueryParams = paramMap.params;
            }
            else {
                pThis.parentQueryParams = {};
            }
        });
        //Save any parent fragment in advance in case next actived route is secondary route.                        
        pThis.route.fragment.subscribe(value => {
            if (value) {
                pThis.parentFragment = value;
            }
            else {
                pThis.parentFragment = undefined;
            }
        });

    });


  

  }

activeBox : Array<Boolean> = [];
  getHeaderDetails() {
    this.user = JSON.parse(sessionStorage.getItem('user'))
    console.log("yaha print karna hai data", this.user)
    // this.headerService.getHeaderDetails().subscribe((data) => {

    // this.translate.get(['header_products' ,'header.stores' ,'header.marketing' ,'header.customers']).subscribe(translations =>{
    if(this.user.tenant === 'Order Orchestration')  {
      this.headerDetails = [
    
        {
            "name":"Products",
            "url": "productcategories"
        
        },
        {
            "name": "Regions",
            "url": "regions"
        },
        {
            "name": "Rules",
            "url": "marketingdetails/promotions"
        },
        {
            "name": "Customers",
            "url": "customers"
        }
    ]
    }else{
      
    
    this.headerDetails = [
    
        {
            "name":"Products",
            "url": "productcategories"
        
        },
        {
            "name": "Regions",
            "url": "regions"
        },
        {
            "name": "Marketing",
            "url": "marketingdetails/promotions"
        },
        {
            "name": "Customers",
            "url": "customers"
        }
    ]
   }


    
  // })
      this.activeBox =JSON.parse(localStorage.getItem("activeFlag"));
  }



  activeNav(i) {
    if(this.headerDetails[i].name == "Marketing") {
      this.messageService.broadcast('primary', {
        key: this.headerDetails[i].name,
        labelName: this.headerDetails[i].name,
        path: this.headerDetails[i].url,
        alternativeKey: "marketingdetails",
        child: []
      })
    }
    else if(this.headerDetails[i].name == "Products") {
      this.messageService.broadcast('primary', {
        key: this.headerDetails[i].name,
        labelName: 'Categories',
        path: this.headerDetails[i].url,
        alternativeKey: this.headerDetails[i].name,
        child: []
      })
    }
    else {
      
      this.messageService.broadcast('primary', {
        key: this.headerDetails[i].name,
        labelName: this.headerDetails[i].name,
        path: this.headerDetails[i].url,
        alternativeKey: this.headerDetails[i].name,
        child: []
      })
    }
    for(let i=0; i< this.headerDetails.length; i++) {
      this.aciveFlag[i] = false;
    }

    localStorage.setItem("activeFlag",JSON.stringify(this.aciveFlag));
    var abc = JSON.parse(localStorage.getItem("activeFlag"));
    abc[i] = true;
    localStorage.setItem("activeFlag",JSON.stringify(abc));
    this.activeBox = JSON.parse(localStorage.getItem("activeFlag"));
  }


  closeDropDown(){
    sessionStorage.removeItem("user");
    sessionStorage.removeItem('breadcrumbList')
    sessionStorage.removeItem('breadcrumbHistoryList')
    for(let i=0; i< this.headerDetails.length; i++) {
     if(i == 0) {
       this.activeBox[0] = true
     }else {
       this.activeBox[i] = false;
     }
    }
    localStorage.setItem("activeFlag",JSON.stringify(this.aciveFlag));
    this.router.navigate(["/userlogin"]);
  }

  setUp(key,labelName,path) {
    this.messageService.broadcast('primary', {
      key:key,
      alternativeKey: key,
      labelName: labelName,
      path: path,
      child: []
    })
  }



  selectLanguagePrefferd(){
    this.modalService.open(this.selectLanguage, { size: 'lg', windowClass: 'update-language-modal', scrollable: true  });

  }

  closeLanguageModal() {
    this.modalService.dismissAll(this.selectLanguage);
  }
  
  


useLanguage(language: string) {
  console.log(language)
  sessionStorage.setItem('language',JSON.stringify(language));
  this.translate.use(language);
}

onClaim(i) {
  let taskId = this.ownerTaskList[i]["task-id"]
  
  this.adminService.claimWorkflowProduct(this.ownerTaskList[i]["task-proc-inst-id"], taskId.toString(),this.user.name).subscribe((data) => {
      console.log("claimed by",this.user.name)
    })
    this.showTask=false
    setTimeout(()=>{                           // <<<---using ()=> syntax
     
      this.ngOnInit()
      
  }, 2000);  
    
 }

 onClickTask() {

  this.showTask = !this.showTask
}

  getTaskDetails() {
    console.log("tasksummaryyyyy")
    this.jbpmService.getTaskSummariesOfUser().subscribe((data) => {
      this.ownerTaskList = data["taskSummaries"]["task-summary"]
      if (this.ownerTaskList.length == 0) {
        this.noDataShow = this.noDataService.noData(this.ownerTaskList);
      }
    });
  }

//  interval() {
  
//   return setInterval(() => {
//     this.getTaskDetails()
//   }, 5000)
// }

// ngOnDestroy() {
//   clearInterval(this.timer)
// }
}